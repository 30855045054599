import {axiosRequest} from "shared/config/axios";
import {MassUpdateUsers, ProjectObjectLinks} from "../types";

export const  updateProjectUsers =  async (data: MassUpdateUsers) => {
    await axiosRequest({
        method: 'PUT',
        url: `/projects/project-users`,
        data,
    });
}
export const  updateProjectObjectsLinks = async (data: ProjectObjectLinks) => {
    await axiosRequest({
        method: 'PUT',
        url: `/projects/projects-objects-links`,
        data,
    });
}
/**
 * Массовое удаление отклоненных файлов записей задач проектов
*/
export const  deleteTaskRecordFiles = async (rowIds: string[]) => {
    await axiosRequest({
        method: 'DELETE',
        url: `/projects/task-record-files`,
        data: rowIds,
    });
}
