import React from "react";
import {Arrow, Close} from "components/tour/Buttons";
import {TourProvider} from "@reactour/tour";

const WithTour = (component: () => React.ReactNode) => () => {
    return <TourProvider steps={[]} components={{Close, Arrow}}>{component()}</TourProvider>;
};

export default WithTour;

