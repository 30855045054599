const indicators = [
	{
		id: '43',
		selector: '.analytics-actions-basic',
		content:
			'Для построения графиков по интересующим вас конкурсу, году, сессии воспользуйтесь данным фильтром. По умолчанию фильтр выводит данные по всем конкурсам, годам и сессиям',
		role: [],
	},
	{
		id: '44',
		selector: '.doughnut-project',
		content:
			'Данный график отражает общее число проектов, позволяет увидеть количество и долю проектов согласно их статусу в рамках выбранных условий фильтра',
		role: [],
	},
	{
		id: '45',
		selector: '.barType-posting',
		content:
			'Гистограмма выполнения размещения контента выводит показатель текущего отношения фактических размещений к плановым, а также демонстрирует данные за период согласно выбранным условиям фильтра',
		role: [],
	},
	{
		id: '46',
		selector: '.doughnut-posting',
		content:
			'Данные по топ-5 площадкам по количеству размещения позволяют оценить на каких интернет-ресурсах исполнители чаще размещают контент АНО “ИРИ” за определенный период времени',
		role: [],
	},
	{
		id: '47',
		selector: '.horizontalBar-kpiTop',
		content:
			'На графике выводятся топ-5 интернет-ресурсов по объему просмотров произведенного контента, что позволяет понять на каких площадках контент АНО “ИРИ” смотрят чаще за определенный период времени',
		role: [],
	},
	{
		id: '48',
		selector: '.barType-kpi',
		content:
			'Гистограмма выполнения просмотров контента проектов выводит показатель текущего отношения фактических просмотров к плановым, а также демонстрирует данные за период согласно выбранным условиям фильтра',
		role: [],
	},
	{
		id: '49',
		selector: '.horizontalBar-kpiTopProject',
		content: 'Данный график демонстрирует 5 наиболее просматриваемых проектов согласно выбранным условиям фильтра',
		role: [],
	},
]

export default indicators
