import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    selectReportBulkAction,
    selectReportRowsIds,
    selectReportVotes
} from "store/tasks/selectors/selectors-task-report";
import {
    SendReportBulkReviewStartAction,
    SendReportBulkVotesStartAction,
    SetReportBulkAction,
    SetReportRowsIds,
    SetTaskReportFilter
} from "store/tasks/actions/report";
import {TypesTask} from "models/task/types";
import {
    selectContentBulkAction,
    selectContentRowsIds,
    selectContentTasksFilter,
    selectContentVotes
} from "store/tasks/selectors/selectors-task-content";
import {
    CreateFileArchiveStartAction,
    SendContentBulkReviewStartAction,
    SendContentBulkVotesStartAction,
    SetContentBulkAction,
    SetContentRowsIds,
    SetTaskContentFilter
} from "store/tasks/actions/content";
import {NotificationOpen} from "store/notification/actions";
import {TaskApi, TaskTypes} from 'entities/task'
import {useMutation} from "react-query";
import useRecordsExport from "./lib/hooks/useRecordsExport";


const selectBulkAction  = {
    'REPORT' : selectReportBulkAction,
    "CONTENT" : selectContentBulkAction
}

const selectRowsIds  = {
    'REPORT' : selectReportRowsIds,
    "CONTENT" : selectContentRowsIds
}

const selectVotes  = {
    'REPORT' : selectReportVotes,
    "CONTENT" : selectContentVotes
}

type TVotes = {
    [key: string]: TaskTypes.NewVote
}

const sendBulkVotes = (rowsIds: string[], votes: TVotes, type: TaskTypes.TypesTask, reportType: TaskTypes.ReportType, put: any, archival?: boolean) => {
    const data: TaskTypes.NewVote['vote'][] = rowsIds.map(it=> {
        const currentVote = votes[it]['vote']
        return {...currentVote, isApproved: currentVote.isInterimApproved, isInterimApproved: null}
    });
    let isValid = true;
    for (let i = 0; isValid && i < data.length; i++) {
        if (!data[i]?.isApproved && !data[i]?.comment && !archival) {
            isValid = false
            put(NotificationOpen(
                'warning',
                'Ошибка заполения',
                'При отклонении записи укажите причину в комментарии'
            ))
            break;
        }
    }
    if (isValid) {
        if (type === TypesTask.REPORT && reportType) {
            put(SendReportBulkVotesStartAction(reportType, data))
            put(SetReportRowsIds([]))
        } else {
            put(SendContentBulkVotesStartAction(data))
            put(SetContentRowsIds([]))
        }
    }
}

const sendBulkVotesApprove = (rowsIds: string[],type: TaskTypes.TypesTask, reportType: TaskTypes.ReportType, put: any) => {
    const data: TaskTypes.NewVote['vote'][] = rowsIds.map(it=> ({
        comment: null,
        isApproved: true,
        recordId: it,
        isInterimApproved: null
    }));

    if (type === TypesTask.REPORT && reportType) {
            put(SendReportBulkVotesStartAction(reportType,data))
            put(SetReportRowsIds([]))
        } else {
            put(SendContentBulkVotesStartAction(data))
            put(SetContentRowsIds([]))
        }

}

const sendBulkVotesReject = (rowsIds: string[],type: TaskTypes.TypesTask, reportType: TaskTypes.ReportType, put: any) => {
    const data: TaskTypes.NewVote['vote'][] = rowsIds.map(it=> ({
        comment: 'Причину отклонения материала уточнить у проверяющего',
        isApproved: false,
        recordId: it,
        isInterimApproved: null
    }));

    if (type === TypesTask.REPORT && reportType) {
        put(SendReportBulkVotesStartAction(reportType, data))
        put(SetReportRowsIds([]))
    } else {
        put(SendContentBulkVotesStartAction(data))
        put(SetContentRowsIds([]))
    }

}

const sendBulkReview = (rowsIds: string[], type: TaskTypes.TypesTask, reportType: TaskTypes.ReportType, put: any) => {
    if (type === TypesTask.REPORT && reportType) {
        put(SendReportBulkReviewStartAction(reportType,rowsIds))
        put(SetReportRowsIds([]))
    } else {
        put(SendContentBulkReviewStartAction(rowsIds))
        put(SetContentRowsIds([]))
    }
}

const useBulkAction = (type: TaskTypes.TypesTask, reportType?: TaskTypes.ReportType) => {
    const put = useDispatch()
    const filterContent = useSelector(selectContentTasksFilter)
    const bulkAction = useSelector(selectBulkAction[type])
    const rowsIds = useSelector(selectRowsIds[type])
    const votes = useSelector(selectVotes[type])
    const { downloadExcelData }  = useRecordsExport(type, reportType)

    const {mutate} = useMutation({
        mutationFn: TaskApi.recordsBulkActionsContent,
        onSuccess: () => {
            put(SetTaskContentFilter({
                    ...filterContent,
                    filterData: { ...filterContent.filterData, bulkAction: undefined },
                })
            )
        }
    })
    const {mutate: mutateRecordsBulkActionsReport} = useMutation({
        mutationFn: TaskApi.recordsBulkActionsReport,
        onSuccess: () => {
            put(SetTaskReportFilter({
                    ...filterContent,
                    filterData: { ...filterContent.filterData, bulkAction: undefined },
                }, reportType as TaskTypes.ReportType)
            )
        }
    })

    const rowSelection = {
        selectedRowKeys: rowsIds,
        onChange: (selectedRowKeys: React.Key[], _selectedRows: any[]) => {
            if(type === TypesTask.REPORT ) {
                put(SetReportRowsIds(selectedRowKeys as string[]))
            } else {
                put(SetContentRowsIds(selectedRowKeys as string[]))
            }
        },
    }

    const onChangeBulkActions = (val: string | null) => {
        const handleReportChange = () => {
            const updatedFilter = {
                ...filterContent,
                filterData: { ...filterContent.filterData, bulkAction: getNameBulkAction(val) },
            };

            put(SetTaskReportFilter(updatedFilter, reportType as TaskTypes.ReportType));

            if (val) {
                put(SetReportBulkAction(val));
            } else {
                put(SetReportRowsIds([]));
                put(SetReportBulkAction(null));
            }
        };

        const handleContentChange = () => {
            const updatedContentFilter = {
                ...filterContent,
                filterData: { ...filterContent.filterData, bulkAction: getNameBulkAction(val) },
            };

            put(SetTaskContentFilter(updatedContentFilter));

            // Обновляем или очищаем bulkAction для контента
            if (val) {
                put(SetContentBulkAction(val));
            } else {
                put(SetContentRowsIds([]));
                put(SetContentBulkAction(null));
            }
        };

        if (type === TypesTask.REPORT) {
            handleReportChange();
        } else {
            handleContentChange();
        }
    };

    const handleClickActions = (archival?: boolean) => {
        if (bulkAction === 'SET_RECORDS_VERIFICATION_REQUIRED') {
            sendBulkReview(rowsIds, type, reportType as TaskTypes.ReportType, put)
        }
        if (bulkAction === 'SEND_APPROVAL_RESULTS') {
            sendBulkVotes(rowsIds, votes, type, reportType as TaskTypes.ReportType, put, archival)
        }
        if (bulkAction === 'SET_RECORDS_APPROVED') {
            sendBulkVotesApprove(rowsIds, type, reportType as TaskTypes.ReportType, put)
        }
        if (bulkAction === 'SET_RECORDS_REJECTED') {
            sendBulkVotesReject(rowsIds, type, reportType as TaskTypes.ReportType, put)
        }
        if (bulkAction === 'GENERATE_CONTENT_ARCHIVE') {
            put(CreateFileArchiveStartAction(rowsIds))
        }
        if (bulkAction === 'DOWNLOAD_SELECTED_DATA') {
            downloadExcelData(rowsIds, getNameExportExcel(type,reportType))
        }
        if (bulkAction === 'DOWNLOAD_ALL_DATA') {
            downloadExcelData(rowsIds, getNameExportExcel(type,reportType))
        }
        if (bulkAction && bulkAction.includes('SET_IS_')) {
            mutate({rowsIds, bulkAction})
        }
        if (bulkAction && bulkAction.includes('SET_TRUE')) {
            mutateRecordsBulkActionsReport({rowsIds, bulkAction, reportType: reportType as TaskTypes.ReportType})
        }
        if (bulkAction && bulkAction.includes('SET_FALSE')) {
            mutateRecordsBulkActionsReport({rowsIds, bulkAction, reportType: reportType as TaskTypes.ReportType})
        }
    }

    return {
        rowSelection,
        onChangeBulkActions,
        handleClickActions,
        bulkAction,
        rowsIds,
    }
}

export default useBulkAction

function getNameBulkAction(action?: string | null) {
    if (action?.includes('SET_IS_')) {
        return action
    }
    if (action?.includes('SET_TRUE') || action?.includes('SET_FALSE')) {
        return action
    }
    if (action === 'GENERATE_CONTENT_ARCHIVE') {
        return action
    }
    return undefined
}

function getNameExportExcel(type: TaskTypes.TypesTask, reportType?: TaskTypes.ReportType) {
    if (type === TaskTypes.TypesTask.CONTENT) {
        return 'записи контента'
    }

    if (type === TaskTypes.TypesTask.REPORT && reportType) {
        switch (reportType) {
            case TaskTypes.ReportType.CONTENT_REACH_REPORT:
                return 'записи охвата размещённого контента'
            case TaskTypes.ReportType.COPYRIGHT_LIST:
                return 'записи авторского права'
            case TaskTypes.ReportType.PROJECT_ESTIMATE:
                return 'записи сметы проекта'
            case TaskTypes.ReportType.CONTENT_POSTING_REPORT:
                return 'записи размещении контента'
        }
    }

    return 'records';
}
