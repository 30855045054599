import {TasksReportActionTypes} from "../action-types/tasks-report";
import * as actions from '../actions/report'
import {ErrorActionState, StartActionState, SuccessActionState} from "utils/reducerHalper";
import {AuthActionTypes} from "store/auth/action-types";
import {Logout} from "store/auth/reducer";
import {TaskTypes} from "entities/task";
//models
import {TTaskRecordsFilter} from "models/task/task-list";
import {TReachStatistic, TTaskEstimateCost} from "models/task/task-report";
import {InferValueTypes} from "models/common";


type ActionTypes = ReturnType<InferValueTypes<typeof actions>>;


type TResetStatusRecords = {[key: string]: {isLoading: boolean, ok?: boolean, error?: any}}
export type TTasksReportState = {
    timeStamp: string | null
    tasks: TaskTypes.ReportRecords| null
    tasksCost: TTaskEstimateCost[] | null
    filter: TTaskRecordsFilter
    votes: Record<string, TaskTypes.NewVote>
    recordId: string | null
    reachStatistic: TReachStatistic[] | null
    resetStatusRecords: TResetStatusRecords
    bulkAction: string | null
    rowsIds: string[]
    isLoading: boolean
    isLoadingAction: Record<string, boolean>
    isLoaded: boolean
    error: any
}

const initialFilter = {
    page: 1,
    pageSize: 50,
    filterData: {
        searchString: ''
    }
}


export const initialState: TTasksReportState = {
    timeStamp: null,
    tasks: null,
    tasksCost: null,
    filter: initialFilter,
    votes: {},
    recordId: null,
    reachStatistic: null,
    resetStatusRecords: {},
    bulkAction: null,
    rowsIds: [],
    isLoading: false,
    isLoadingAction: {send: false},
    isLoaded: false,
    error: null
}



export default function tasksReducer(state: TTasksReportState = initialState, action: ActionTypes | Logout): TTasksReportState {
    switch (action.type) {
        case AuthActionTypes.LOGOUT:
            return {...initialState}
        //filter
        case TasksReportActionTypes.SET_TASK_FILTER:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    ...action.filter
                }}
        case TasksReportActionTypes.SET_TASK_REPORT_REACH_FILTER:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    ...action.filter
                }
            }
        case TasksReportActionTypes.SET_TASK_REPORT_ESTIMATE_FILTER:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    ...action.filter
                }
            }
        case TasksReportActionTypes.SET_BULK_ACTION:
            return {
                ...state,
                bulkAction: action.bulkAction
            }
        case TasksReportActionTypes.SET_ROWS_IDS:
            return {
                ...state,
                rowsIds: action.rows,
                isLoaded: false
            }
        case TasksReportActionTypes.CLEAR_FILTER:
            return {
                ...state,
                filter: initialFilter,
            }

        //vote
        case TasksReportActionTypes.VOTE_TASK_START:
            return StartActionState(state);
        case TasksReportActionTypes.VOTE_TASK_SUCCESS:
            return {...state}
        case TasksReportActionTypes.VOTE_TASK_ERROR:
            return ErrorActionState(state, action);

        case TasksReportActionTypes.INTERIM_VOTE_TASK_START:
            return StartActionState(state);
        case TasksReportActionTypes.INTERIM_VOTE_TASK_SUCCESS:
            return SuccessActionState(state)
        case TasksReportActionTypes.INTERIM_VOTE_TASK_ERROR:
            return ErrorActionState(state, action);

        case TasksReportActionTypes.VOTE_TASK_STATUS:
            return {
                ...state,
                votes: {
                    ...state.votes,
                    [action.data.vote.recordId]: action.data
                }}
        case TasksReportActionTypes.VOTE_TASK_MULTIPLE_STATUSES:
            return {
                ...state,
                votes: {
                    ...state.votes,
                    ...action.data
                }}
        case TasksReportActionTypes.VOTE_TASK_COMMENT:
            return {
                ...state,
                votes: {
                    ...state.votes,
                    [action.data.vote.recordId]: action.data
                }}
        case TasksReportActionTypes.VOTE_TASK_CHANGE:
            const newObj = Object.assign({}, state.votes);
            delete newObj[action.id];
            return { ...state, votes: {...newObj } }

        //GET_TASKS
        case TasksReportActionTypes.GET_TASKS_START:
            return {...StartActionState(state)}
        case TasksReportActionTypes.GET_TASKS_SUCCESS:
            const {content, page, ...filters} = action.tasks
            return {
                ...SuccessActionState(state),
                tasks: content,
                filter: {...state.filter, page: page + 1, ...filters},
                timeStamp: action.timeStamp, //метка для оптимизации перерисования задач
                tasksCost: action.tasksCost,
                isLoaded: false,
                resetStatusRecords: initialState.resetStatusRecords
            }
        case TasksReportActionTypes.GET_TASKS_ERROR:
            return ErrorActionState(state, action)

        //GET_RECORD_ID
        case TasksReportActionTypes.GET_RECORD_START:
            return {...StartActionState(state)}
        case TasksReportActionTypes.GET_RECORD_SUCCESS:
            return {
                ...SuccessActionState(state),
                tasks: action.data,
                timeStamp: new Date().toISOString(), //метка для оптимизации перерисования задач
                isLoaded: false,
                resetStatusRecords: initialState.resetStatusRecords
            }
        case TasksReportActionTypes.GET_RECORD_ERROR:
            return ErrorActionState(state, action)

        //ADD_TASK
        case TasksReportActionTypes.ADD_TASK_START:
            return {...StartActionState(state), isLoaded: false}
        case TasksReportActionTypes.ADD_TASK_SUCCESS:
            return {...SuccessActionState(state), recordId: action.recordId, isLoaded: false}
        case TasksReportActionTypes.ADD_TASK_ERROR:
            return ErrorActionState(state, action)

        //UPDATE_TASK
        case TasksReportActionTypes.UPDATE_TASK_START:
            return {...StartActionState(state), isLoaded: false}
        case TasksReportActionTypes.UPDATE_TASK_SUCCESS:
            return {...SuccessActionState(state), recordId: null, isLoaded: true}
        case TasksReportActionTypes.UPDATE_TASK_ERROR:
            return ErrorActionState(state, action)

        //DELETE_TASK
        case TasksReportActionTypes.DELETE_TASK_START:
            return StartActionState(state)
        case TasksReportActionTypes.DELETE_TASK_SUCCESS:
            return SuccessActionState(state)
        case TasksReportActionTypes.DELETE_TASK_ERROR:
            return ErrorActionState(state, action)

        //TO_EXAMINATION_TASK
        case TasksReportActionTypes.TO_EXAMINATION_TASK_START:
            return {...state, isLoading: true, isLoadingAction: {...state.isLoadingAction, send: true}}
        case TasksReportActionTypes.TO_EXAMINATION_TASK_SUCCESS:
            return {...state, isLoading: false, isLoadingAction: {...state.isLoadingAction, send: false}}
        case TasksReportActionTypes.TO_EXAMINATION_TASK_ERROR:
            return ErrorActionState(state, action)

        //Bulk Actions Votes
        case TasksReportActionTypes.SEND_BULK_VOTES_START:
            return StartActionState(state)
        case TasksReportActionTypes.SEND_BULK_VOTES_SUCCESS:
            return {...SuccessActionState(state), votes: {}}
        case TasksReportActionTypes.SEND_BULK_VOTES_ERROR:
            return ErrorActionState(state, action)

        //Bulk Actions Review
        case TasksReportActionTypes.SEND_BULK_REVIEW_START:
            return StartActionState(state)
        case TasksReportActionTypes.SEND_BULK_REVIEW_SUCCESS:
            return SuccessActionState(state)
        case TasksReportActionTypes.SEND_BULK_REVIEW_ERROR:
            return ErrorActionState(state, action)

        //Reach Statistic
        case TasksReportActionTypes.GET_REACH_STATISTIC_START:
            return StartActionState(state)
        case TasksReportActionTypes.GET_REACH_STATISTIC_SUCCESS:
            return {...SuccessActionState(state), reachStatistic: action.data}
        case TasksReportActionTypes.GET_REACH_STATISTIC_ERROR:
            return ErrorActionState(state, action)

        //Download template
        case TasksReportActionTypes.DOWNLOAD_TEMPLATE_START:
            return StartActionState(state)
        case TasksReportActionTypes.DOWNLOAD_TEMPLATE_SUCCESS:
            return SuccessActionState(state)
        case TasksReportActionTypes.DOWNLOAD_TEMPLATE_ERROR:
            return ErrorActionState(state, action)

        //Upload template
        case TasksReportActionTypes.UPLOAD_TEMPLATE_START:
            return StartActionState(state)
        case TasksReportActionTypes.UPLOAD_TEMPLATE_SUCCESS:
            return SuccessActionState(state)
        case TasksReportActionTypes.UPLOAD_TEMPLATE_ERROR:
            return ErrorActionState(state, action)

        //RESET STATUS
        case TasksReportActionTypes.RESET_STATUS_TASK_START:
            return {
                ...state,
                resetStatusRecords: {
                    ...state.resetStatusRecords,
                    [action.recordId]: {isLoading: true}
                }
            }
        case TasksReportActionTypes.RESET_STATUS_TASK_SUCCESS:
            return {
                ...SuccessActionState(state),
                resetStatusRecords: {
                    ...state.resetStatusRecords,
                    [action.recordId]: {ok: true, isLoading: false}
                }
        }
        case TasksReportActionTypes.RESET_STATUS_TASK_ERROR:
            return {
                ...ErrorActionState(state, action),
                resetStatusRecords: {
                    ...state.resetStatusRecords,
                    [action.recordId]: {ok: false, error: action.error, isLoading: false}
                }
            }
        default:
            return state
    }
}
