import {z} from "zod";

export const TasksRecordStatusEnum = z.enum([
    'DRAFT',
    'READY_FOR_VERIFICATION',
    'READY_FOR_CUSTOMER_VERIFICATION',
    'VERIFICATION_REQUIRED',
    'CREATED_BY_CUSTOMER',
    'APPROVED',
    'APPROVED_ARCHIVE',
    'REJECTED',
    'REJECTED_AFTER_VOTE_RESET',
    'APPROVED_OUTDATED'
]);
export type TasksRecordStatus = z.infer<typeof TasksRecordStatusEnum>;
