export const TasksReportActionTypes = {
    SET_TASK_FILTER: '[TASK_REPORT] SET_TASK_FILTER',
    SET_TASK_REPORT_REACH_FILTER: '[TASK_REPORT] SET_TASK_REPORT_REACH_FILTER',
    SET_TASK_REPORT_ESTIMATE_FILTER: '[TASK_REPORT] SET_TASK_REPORT_ESTIMATE_FILTER',
    SET_BULK_ACTION: '[TASK_REPORT] SET_BULK_ACTION',
    SET_ROWS_IDS: '[TASK_REPORT] SET_ROWS_IDS',

    CLEAR_FILTER: '[TASK_REPORT] CLEAR_FILTER',

    ADD_TASK_START: '[TASK_REPORT] ADD_TASK_START',
    ADD_TASK_SUCCESS: '[TASK_REPORT] ADD_TASK_SUCCESS',
    ADD_TASK_ERROR: '[TASK_REPORT] ADD_TASK_ERROR',

    GET_TASKS_START: '[TASK_REPORT] GET_TASKS_START',
    GET_TASKS_SUCCESS: '[TASK_REPORT] GET_TASKS_SUCCESS',
    GET_TASKS_ERROR: '[TASK_REPORT] GET_TASKS_ERROR',

    GET_RECORD_START: '[TASK_REPORT] GET_RECORD_START',
    GET_RECORD_SUCCESS: '[TASK_REPORT] GET_RECORD_SUCCESS',
    GET_RECORD_ERROR: '[TASK_REPORT] GET_RECORD_ERROR',

    UPDATE_TASK_START: '[TASK_REPORT] UPDATE_TASK_START',
    UPDATE_TASK_SUCCESS: '[TASK_REPORT] UPDATE_TASK_SUCCESS',
    UPDATE_TASK_ERROR: '[TASK_REPORT] UPDATE_TASK_ERROR',

    DELETE_TASK_START: '[TASK_REPORT] DELETE_TASK_START',
    DELETE_TASK_SUCCESS: '[TASK_REPORT] DELETE_TASK_SUCCESS',
    DELETE_TASK_ERROR: '[TASK_REPORT] DELETE_TASK_ERROR',

    VOTE_TASK_START: '[TASK_REPORT] VOTE_TASK_START',
    VOTE_TASK_SUCCESS: '[TASK_REPORT] VOTE_TASK_SUCCESS',
    VOTE_TASK_ERROR: '[TASK_REPORT] VOTE_TASK_ERROR',

    INTERIM_VOTE_TASK_START: '[TASK_REPORT] INTERIM_VOTE_TASK_START',
    INTERIM_VOTE_TASK_SUCCESS: '[TASK_REPORT] INTERIM_VOTE_TASK_SUCCESS',
    INTERIM_VOTE_TASK_ERROR: '[TASK_REPORT] INTERIM_VOTE_TASK_ERROR',

    VOTE_TASK_STATUS: '[TASK_REPORT] VOTE_TASK_STATUS',
    VOTE_TASK_MULTIPLE_STATUSES: '[TASK_REPORT] VOTE_TASK_MULTIPLE_STATUSES',
    VOTE_TASK_COMMENT: '[TASK_REPORT] VOTE_TASK_COMMENT',
    VOTE_TASK_CHANGE: '[TASK_REPORT] VOTE_TASK_CHANGE',

    TO_EXAMINATION_TASK_START: '[TASK_REPORT] TO_EXAMINATION_TASK_START',
    TO_EXAMINATION_TASK_SUCCESS: '[TASK_REPORT] TO_EXAMINATION_TASK_SUCCESS',
    TO_EXAMINATION_TASK_ERROR: '[TASK_REPORT] TO_EXAMINATION_TASK_ERROR',

    RESET_STATUS_TASK_START: '[TASK_REPORT] RESET_STATUS_TASK_START',
    RESET_STATUS_TASK_SUCCESS: '[TASK_REPORT] RESET_STATUS_TASK_SUCCESS',
    RESET_STATUS_TASK_ERROR: '[TASK_REPORT] RESET_STATUS_TASK_ERROR',

    SEND_BULK_VOTES_START: '[TASK_REPORT] SEND_BULK_VOTES_START',
    SEND_BULK_VOTES_SUCCESS: '[TASK_REPORT] SEND_BULK_VOTES_SUCCESS',
    SEND_BULK_VOTES_ERROR: '[TASK_REPORT] SEND_BULK_VOTES_ERROR',

    SEND_BULK_REVIEW_START: '[TASK_REPORT] SEND_BULK_REVIEW_START',
    SEND_BULK_REVIEW_SUCCESS: '[TASK_REPORT] SEND_BULK_REVIEW_SUCCESS',
    SEND_BULK_REVIEW_ERROR: '[TASK_REPORT] SEND_BULK_REVIEW_ERROR',

    GET_REACH_STATISTIC_START: '[TASK_REPORT] GET_REACH_STATISTIC_START',
    GET_REACH_STATISTIC_SUCCESS: '[TASK_REPORT] GET_REACH_STATISTIC_SUCCESS',
    GET_REACH_STATISTIC_ERROR: '[TASK_REPORT] GET_REACH_STATISTIC_ERROR',

    DOWNLOAD_TEMPLATE_START: '[TASK_REPORT] DOWNLOAD_TEMPLATE_START',
    DOWNLOAD_TEMPLATE_SUCCESS: '[TASK_REPORT] DOWNLOAD_TEMPLATE_SUCCESS',
    DOWNLOAD_TEMPLATE_ERROR: '[TASK_REPORT] DOWNLOAD_TEMPLATE_ERROR',

    UPLOAD_TEMPLATE_START: '[TASK_REPORT] UPLOAD_TEMPLATE_START',
    UPLOAD_TEMPLATE_SUCCESS: '[TASK_REPORT] UPLOAD_TEMPLATE_SUCCESS',
    UPLOAD_TEMPLATE_ERROR: '[TASK_REPORT] UPLOAD_TEMPLATE_ERROR',

} as const;
