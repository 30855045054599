import {axiosRequest} from 'shared/config/axios'
import {Filter, Region, RegionRequest, Regions, RegionSchema} from '../types'
import {downloadXLXS} from "utils/downloadXLXS";
import {IOptions} from "models/feels";


/**
 * Получение всех регионов
 */
export const getRegions = {
	key: '/region/types',
	keyOptions: 'Options: /region/types',
	fetch: async (): Promise<Region[]> => {
		const { data } = await axiosRequest<Region[]>({
			method: 'GET',
			url: '/region/types',
			parser: RegionSchema.array(),
		})
		return data
	},
	fetchOptions: async () => {
		const {data} = await axiosRequest<Region[]>({
			method: 'GET',
			url: '/region/types',
			parser: RegionSchema.array(),
		})
		return  data.map((el) => ({
			value: el.id,
			label: el.name,
			hidden: el.hidden,
		} as IOptions))
	}
}
/**
 * Получение списка типов регионов с пагинацией, сортировкой и поиском
 */
export const getWithFilter = {
	key: '/region/types/search',
	fetch: async (filter: Filter): Promise<Regions> => {
		const newFilter = { ...filter, page: filter.page - 1 }
		const { data } = await axiosRequest<Regions>({
			method: 'POST',
			url: '/region/types/search',
			data: newFilter,
			parser: { list: true, schema: RegionSchema.array() },
		})
		return data
	},
}
/**
 * Создание региона
 */
export const create = async (data: RegionRequest) => {
	await axiosRequest({
		method: 'POST',
		url: '/region/types',
		data,
	})
}

/**
 * Обновление региона
 */
export const update = async ({ id, data }: { id: string; data: RegionRequest }) => {
	await axiosRequest({
		method: 'PUT',
		url: `/region/types/${id}`,
		data,
	})
}

/**
 * Удаление региона
 */
export const remove = async (id: string) => {
	await axiosRequest({
		method: 'DELETE',
		url: `/region/types/${id}`,
	})
}

/**
 * Массовые действия над регионами
 */
export const bulkActions = async ({ rowIds, bulkAction }: { rowIds: string[]; bulkAction: string }) => {
	await axiosRequest({
		method: 'POST',
		url: `/region/types/bulk-actions/${bulkAction}`,
		data: rowIds,
	})
	return bulkAction
}

/**
 * Выполнение выгрузки списка в формате Excel
 */
export const download = async (value: string[]) => {
	const { data }: any = await axiosRequest({
		method: 'POST',
		url: `/region/types/export`,
		responseType: 'arraybuffer',
		headers: {
			'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		},
		data: value,
	})
	downloadXLXS(data, 'regions')
}
/**
 * Получение файла шаблона для выполнения импорта данных регионов
 */
export const downloadTemplate = async () => {
	const { data }: any = await axiosRequest({
		method: 'GET',
		url: '/region/types/import/template',
		responseType: 'arraybuffer',
		headers: {
			'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		},
	})
	downloadXLXS(data, 'regions')
}
/**
 * Выполнение импорта данных регионов из заполненного шаблона в формате Excel
 */
export const upload = async (data: FormData) => {
	await axiosRequest({
		method: 'POST',
		url: '/region/types/import',
		headers: {
			'Content-Type': 'multipart/form-data',
		},
		data,
	})
}

