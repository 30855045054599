import {z} from "zod";
import {ReportType} from './report-type';
import {TypesTask} from './task-types';

export const TaskSearchDtoSchema = z.object({
	searchString: z.string().optional(),
	stageId: z.string().optional(),
	sprintIds: z.array(z.string()).optional(),
	contestIds: z.array(z.string()).optional(),
	sessionIds: z.array(z.string()).optional(),
	projectStatusIds: z.array(z.string()).optional(),
	projectIds: z.array(z.string()).optional(),
	userIds: z.array(z.string()).optional(),
	statusIds: z.array(z.string()).optional(),
	typeIds: z.array(z.string()).optional(),
	typeCodes: z.array(z.nativeEnum(TypesTask)).optional(),
	reportTypeIds: z.array(z.string()).optional(),
	reportTypeCodes: z.array(z.nativeEnum(ReportType)).optional(),
	withScreenshots: z.boolean().optional(),
	isCheckNeeded: z.boolean().optional(),
	isSchedulerExist: z.boolean().optional(),
	deadlineBegin: z.string().nullable().optional(),
	deadlineEnd: z.string().nullable().optional(),
	archival: z.boolean().nullable().optional(),
	bulkAction: z.string(z.enum([
		'SET_TO_IMPLEMENTATION',
		'SET_IN_PROGRESS',
		'SET_READY',
		'DELETE',
	])).optional(),
	inspectorUserId: z.string().optional(),
	inspectorUserVoteExists: z.boolean().nullable().optional(),
	sprintUntilEndDaysMinValue: z.number().optional(),
	sprintUntilEndDaysMaxValue: z.number().optional(),
	taskUntilEndDaysMinValue: z.number().optional(),
	taskUntilEndDaysMaxValue: z.number().optional(),
});
export type TaskSearchDto = z.infer<typeof TaskSearchDtoSchema>;

export const UpdateTaskUserListSchema = z.object({
	taskIds: z.array(z.string()),
	addUserIds: z.array(z.string()),
	deleteUserIds: z.array(z.string()),
})
export type UpdateTaskUserList = z.infer<typeof UpdateTaskUserListSchema>;
