import { TaskTypes } from "entities/task"
import { UserTypes } from "entities/user"
import { ValidateVoting } from './index';
import { NewVote } from '../../../types';
import { Nullable } from 'models/common';
import { ProjectTypes } from "entities/project";

type Props = {
	validate: ValidateVoting
	role: UserTypes.RoleName
	recordStatus: TaskTypes.TasksRecordStatus,
	vote: NewVote | null
	hasComments: boolean
	isReviewed: boolean
	isCanReview: boolean
	voteList?: number,
	projectStatus: Nullable<ProjectTypes.ProjectStatus>
}
export const Users = ({
	validate,
	role,
	recordStatus,
	vote,
	hasComments,
	isCanReview,
	isReviewed,
	projectStatus,
}: Props): ValidateVoting => {
	const validateForVote = () => {
		if (vote !== null) {
			validate.message = 'disabled';
			validate.send = 'disabled';
			if (vote?.vote.isApproved === null) {
				validate.approved = 'view';
				validate.rejected = 'view';
				validate.handlers = true;
			} else {
				validate.changeVote = 'view';
				if (vote?.vote.isApproved || (!!vote?.vote.comment || !!vote?.files?.length)) {
					//Валидация отправки
					validate.send = 'view'
				}
				if (vote?.vote.isApproved) {
					validate.approved = 'view';
					validate.message = 'view';
				} else {
					validate.rejected = 'view';
					validate.message = 'view';
				}
			}
		} else {
			validate.approved = 'view';
			validate.rejected = 'view';
			validate.handlers = true;
			validate.message = 'disabled';
			validate.votesInfo = 'view';
		}
		return validate;
	}
	const validateForVoteArchival = () => {
		validate.message = 'disabled';
		if (vote) {
			validate.changeVote = 'view'
			validate.send = 'view'
			if (vote.vote.isApproved) {
				validate.approved = 'view'
			} else {
				validate.rejected = 'view'
			}
		} else {
			validate.approved = 'view';
			validate.rejected = 'view';
			validate.handlers = true;
		}
		return validate;
	}

	if (recordStatus === 'VERIFICATION_REQUIRED') {
		if (projectStatus === 'ACTUAL') {
			if (([
				'ROLE_ADMIN',
				'ROLE_ADMIN_IS',
			] as UserTypes.RoleName[]).includes(role)) {
				validate.warning = 'view';
				validate.votesInfo = 'view';
				validate.messageList = 'disabled';
				return validate;
			}
			if (([
				'ROLE_PRODUCER',
				'ROLE_PROJECT_MANAGER',
				'ROLE_COORDINATOR',
				'ROLE_ANALYST',
				'ROLE_FINANCIER',
				'ROLE_LAWYER',
				'ROLE_CONTENT_EDITOR',
				'ROLE_MODERATOR',
			] as UserTypes.RoleName[]).includes(role)) {
				if (isCanReview && !isReviewed) {
					return validateForVote()
				} else {
					validate.messageList = 'disabled';
					validate.warning = 'view';
					validate.votesInfo = 'view';
				}
				return validate;
			}
		} else {
			validate.warning = 'view';
			validate.votesInfo = 'view';
			validate.messageList = 'disabled';
			return validate;
		}
	}
	if (recordStatus === 'APPROVED') {
		validate.approved = 'view';
		validate.votesInfo = 'view';
		validate.messageList = hasComments ? 'view' : 'disabled';
		return validate;
	}
	if (recordStatus === 'APPROVED_OUTDATED') {
		validate.approvedOutdated = 'view';
		validate.votesInfo = 'view';
		validate.messageList = hasComments ? 'view' : 'disabled';
		return validate;
	}
	if (recordStatus === 'REJECTED') {
		validate.rejected = 'view';
		validate.votesInfo = 'view';
		validate.messageList = hasComments ? 'view' : 'disabled';
		return validate;
	}
	if (recordStatus === 'REJECTED_AFTER_VOTE_RESET') {
		validate.rejectedAfterVoteReset = 'view';
		validate.votesInfo = 'view';
		validate.messageList = hasComments ? 'view' : 'disabled';
		return validate;
	}
	if (recordStatus === 'CREATED_BY_CUSTOMER') {
		validate.edit = 'disabled';
		validate.votesInfo = 'view';
		validate.messageList = 'disabled';
		return validate;
	}
	if (recordStatus === 'READY_FOR_CUSTOMER_VERIFICATION') {
		if (projectStatus === 'ACTUAL' || projectStatus === 'ARCHIVED') {
			if (
				role === 'ROLE_ADMIN' ||
				role === 'ROLE_ADMIN_IS' ||
				role === 'ROLE_PRODUCER' ||
				role === 'ROLE_MODERATOR' ||
				role === 'ROLE_PROJECT_MANAGER'
			) {
				return validateForVoteArchival()
			}
		} else {
			validate.warning = 'disabled';
			validate.messageList = 'disabled';
			return validate;
		}
	}
	return validate;
}
