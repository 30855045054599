import {z} from "zod";
import {Id_Name_Code_Schema, SortSchema} from "shared/types/common";
import {ProjectStatusEnum, ProjectStatusNativeEnum} from "./statuses";
import {FileSchema} from "shared/types/file";
import { TaskTypes } from "entities/task";
import { ReportTypes } from "entities/reports";
import {ProjectLabelSchema} from "./project-label";
import {TypeProjectSchema} from "./type-project";

export const ProjectsListSchema = z.object({
    id: z.string(),
    name: z.string(),
    contractorOrganizationName: z.string(), //Краткое наименование организации - исполнителя

    budget: z.number().nullable(), //Бюджет проекта
    promotionalBudget: z.number().nullable(), //Бюджет на продвижение
    planKpi: z.number(), //Плановый КПЭ
    actualKpi: z.number(), //Фактический КПЭ
    kpiCompletionPercentage: z.number().min(0), //Процент выполнения КПЭ
    targetAudienceAgeFrom: z.number().nullable(), //Целевая аудитория (от)
    targetAudienceAgeTo: z.number().nullable(), //Целевая аудитория (до)
    readyTaskCount: z.number(), //Количество готовых задач
    otherTaskCount: z.number(), //Количество других задач
    unverifiedRecordCount : z.number(), //Количество записей задач проекта требующих проверки

    advancePayment: z.boolean(), //Авансирование
    isNationalProject: z.boolean().nullable(), //Признак национального проекта
    isFamousPeopleRemarked: z.boolean(), //Признак наличия в проекте данных об известных людях
    isApprovedMasterContentExist: z.boolean(), //Признак наличия в проекте данных контента (апрувнутый, мастер-файл)
    isProjectPosted: z.boolean().nullable(), //Признак размещения проекта
    isRegionsRemarked: z.boolean(), //Признак наличие связи проекта с регионами
    isAwardsRemarked: z.boolean(), //Признак наличие связи проекта с наградами

    contractNumber: z.string().nullable(), //Номер договора
    requestNumber: z.string(),//Номер заявки

    contractDate: z.string().or(z.date()).nullable(), //Дата договора
    agreementDate: z.string().or(z.date()).nullable(), //Дата соглашения
    completionDate: z.string().or(z.date()).nullable(), //Дата завершения проекта

    status: Id_Name_Code_Schema.extend({
        code: z.nativeEnum(ProjectStatusEnum),
    }), //Статус проекта
    direction: z.object({id: z.string(), name: z.string()}).nullable(), //Направление контента проекта
    // contentFormatTypes: z.array(Id_Name_Code_Schema).nullable(), //Форматы контента проекта
    image: FileSchema.nullable(),
    // defaultImage: z.string().nullable(), //Изображение по-умолчанию
    lastReachTask: z.object({
        id: z.string(),
        deadline: z.string(),
        status: Id_Name_Code_Schema.extend({code: TaskTypes.TasksStatusEnum})
    }).nullable(), //Последняя задача проекта на фиксацию охватов
    contentEditorLabels: z.array(ProjectLabelSchema).nullable(),
    moderatorLabels: z.array(ProjectLabelSchema).nullable(),

    type : TypeProjectSchema.nullable(), //Категория проекта
})
export const ProjectsAllResponseSchema = z.object({
    id: z.string(),
    name: z.string(),
    code: z.string(),
    contractorOrganization: z.object({
        id: z.string(),
        name: z.string(),
        inn: z.string(),
        ogrnOgrnip: z.string(),
    }),
    status: Id_Name_Code_Schema
})
export type ProjectsList = z.infer<typeof ProjectsListSchema>;
export type ProjectsAllResponse = z.infer<typeof ProjectsAllResponseSchema>;
export const ProjectsListResponseSchema = z.object({
    page: z.number(),
    pageSize: z.number(),
    totalCount: z.number(),
    sort: SortSchema.optional(),
    content: z.array(ProjectsListSchema)
})
export type ProjectsListResponse = z.infer<typeof ProjectsListResponseSchema>

export const ProjectsAllRequestSchema = z.object({
    statusCodes: z.array(ProjectStatusNativeEnum).optional(),
    statusIds: z.array(z.string()).optional(),
    sprintExist: z.boolean().optional(),
    sessionIds: z.array(z.string()).optional(),
    projectDirectionLinkedWithReportCode: ReportTypes.ReportTypeEnum.optional()
})
export type ProjectsAllRequest = z.infer<typeof ProjectsAllRequestSchema>
