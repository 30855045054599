import React, {lazy, useState} from 'react'
import './handbook.less'
import HandbooksRouting from 'routing/handbooks'
import {TUsersActionsState} from 'store/options/reducer/users-handbook-reducer'
import classNames from 'classnames'

const StatusHandbookForm = lazy(() => import('components/status-element/status-handbook-form'))

type TNameHandbookStoreKey = {
	[key: string]: keyof TUsersActionsState
}

export const nameHandbookStoreKey: TNameHandbookStoreKey = {
	'internet': 'internet',
	'content-delivery': 'contentDelivery',
	'organization': 'organization',
	'actions-users': 'actionsUsers',
	'content-direction': 'contentDirection',
	'legal-form-organization': 'legalFormOrganization',
	'sessions-type': 'sessionsType',
	'category-project': 'categoryProject',
}

export const nameHandbookStoreFilter: TNameHandbookStoreKey = {
	'internet': 'filterInternet',
	'content-delivery': 'filterContentDelivery',
	'actions-users': 'filterActionsUsers',
	'content-direction': 'filterContentDirection',
	'organization': 'filterOrganization',
	'legal-form-organization': 'filterLegalForm',
	'sessions-type': 'filterSessionsType',
	'category-project': 'filterCategoryProject',
}
const Handbook = () => {
	const [open, setOpen] = useState(true)

	const toggleSideBar = () => {
		setOpen((prevState) => !prevState)
	}

	return (
		<div className="handbook">
			<div className={classNames('handbook__status', { isToggleOpen: open })}>
				<StatusHandbookForm toggle={toggleSideBar} isOpen={open} />
			</div>
			<div key={'isToggle' + open} className={classNames('handbook__body', { isToggleOpen: open })}>
				<HandbooksRouting />
			</div>
		</div>
	)
}

export default Handbook
