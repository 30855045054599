import { CalendarModel, CalendarTypes } from 'pages/calendar';
import styles from './gantt-left.module.less';
import React from 'react';
import {useSelector} from "react-redux";
import { SprintTypes } from 'entities/sprints';
import {Tooltip} from "antd";
import { InfoCircleOutlined } from '@ant-design/icons';
import {Link, useHistory} from "react-router-dom";
import PathConverter from "utils/path-converter";
import {PROJECTS} from "routing/names";
import classNames from "classnames";
import TasksModal from "../tasks-modal";
import { ModalEvent } from 'shared/ui/modal';
import ModalInfoAndTeam from "./modal";
import { LightSvg } from 'assets/svg';

const GanttLeft = () => {
    const list = useSelector(CalendarModel.select.list);
    const history = useHistory();

    const onOpenProject = (id: string, name: string) => (e: any) => {
        e.preventDefault()
        history.push(PathConverter(PROJECTS.PROJECT_PARAMS, id), {tooltip: name})

        setTimeout(() => {
            window.scrollTo({top: 100, behavior: 'smooth'})
        },0)
    }


    const onOpenDetails = (project: CalendarTypes.Calendar) => () => {
        ModalEvent.open({
            title: 'Данные проекта и команды',
            width: 745,
            component: <ModalInfoAndTeam project={project}/>,
            closable: true,
            destroyOnClose: true
        })
    }
    return (
        <div className={styles.root}>
            {list?.map((project) => {
                return (
                    <div key={project.id}>
                        <div className={styles.project}>
                            <div className={styles.left}>
                                <Tooltip title={project.name}>
                                    <Link to={`${PROJECTS.PROJECT_PARAMS}?id=${project.id}`}
                                      className={classNames(styles.name, styles.link)}
                                      onClick={onOpenProject(project.id, project.name)}
                                    >
                                        {project.name}
                                    </Link>
                                </Tooltip>
                            </div>
                            <div className={styles.right}>
                                <div className={styles.status}>
                                    {project.status.name}
                                </div>
                                <div className={styles.details} onClick={onOpenDetails(project)}>
                                    <Tooltip title={'Детали'}>
                                        <InfoCircleOutlined />
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                        <div>
                            {project.sprints?.map((sprint) => (
                                <GanttLeftItem key={sprint.id} sprint={sprint}/>
                            ))}
                        </div>
                    </div>
                )
            })}
        </div>
    )
};
type Props = {
    sprint: SprintTypes.Sprint,
}
function GanttLeftItem({sprint}: Props) {
    const onDivClick = () => {
        ModalEvent.open({
            component: <TasksModal sprint={sprint}/>,
            width: 1100
        })
    }
    const stage = getStage(sprint);
    return (
        <div className={styles.sprint}>
            <div className={styles.sprint__left}>
                <Tooltip title={stage?.text} placement={'right'}>
                    <div className={styles.dot} style={{backgroundColor: stage?.color}}/>
                </Tooltip>
                <Tooltip title={sprint.name}>
                    <div className={styles.name}>{sprint.name}</div>
                </Tooltip>
            </div>
            <div className={styles.sprint__right}>
                <div className={styles.stage}>
                    <Tooltip title={stage?.tooltip}>
                        <LightSvg style={{color: stage?.icon}}/>
                    </Tooltip>
                    <span style={{color: stage?.color}}>{stage?.text}</span>
                </div>
                <Tooltip title={`Реализовано ${sprint.readyTaskCount} из ${sprint.totalTaskCount} задач`}>
                    <div
                      onClick={onDivClick}
                      className={styles.task}>Задачи: {sprint.readyTaskCount} / {sprint.totalTaskCount}
                    </div>
                </Tooltip>

            </div>
        </div>
    )
}

function getStage(sprint: SprintTypes.Sprint) {
    const status = sprint.status.code;
    const result = {
        icon: sprint.taskOverdueCount > 0 ? '#FF2E56' : '#EBEBEE',
        tooltip: sprint.taskOverdueCount > 0 ?
          `Содержит просроченные задачи: ${sprint.taskOverdueCount} шт` :
          'Не содержится просроченных задач',
        color: '#76767A',
        text: sprint.status.name,
    }
    switch (status) {
        case 'INACTIVE':
            result.color = '#76767A';
            result.text = 'неактивен';
            break;
        case 'ACTIVE':
            result.color = '#FF9E00';
            result.text = sprint.status.name;
            break;
        case 'CLOSED':
            result.color = '#84BD00';
            result.text = sprint.status.name;
            break;
        case 'ENDED_OPEN':
            result.color = '#FF2E56';
            result.text = sprint.status.name;
            break;
        default:
            break;
    }
    return result;
}
export default GanttLeft;
