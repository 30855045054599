import { z } from 'zod'
import { FilterRequest, Id_Name_Code_Hidden_Schema, SearchResponse } from 'shared/types/common'

export const RankSchema = Id_Name_Code_Hidden_Schema

export type Rank = z.infer<typeof RankSchema>

export type RankRequest = {
    code: string | null
    name: string
    hidden: boolean
}

export type FilterData = {
    searchString?: string
    hidden?: boolean
}

export type Ranks = SearchResponse<Rank>
export type Filter = FilterRequest<FilterData>
