import React, {FC} from 'react';
import styles from './styles.module.less'
import {ProjectTypes} from 'entities/project'
import {UserOutlined} from "@ant-design/icons";
import WhetherTheUser from "components/whetherTheUser";

type Props = {
    data: ProjectTypes.MapProjectUsers
}
const UsersModal: FC<Props> = ({data}) => {
    return (
        <div className={styles.root}>
            {data.get('ROLE_CONTRACTOR')?.length ? (
                <div className={styles.item}>
                    <div className={styles.title}>Представители исполнителя</div>
                    <ul className={styles.users}>
                        {data.get('ROLE_CONTRACTOR')?.map((contractor) => (
                            <User person={contractor} key={contractor.id}/>
                        ))}
                    </ul>
                </div>
            ) : null}
            {data.get('ROLE_PROJECT_MANAGER')?.length ? (
                <div className={styles.item}>
                    <div className={styles.title}>Менеджеры проекта</div>
                    <ul className={styles.users}>
                        {data.get('ROLE_PROJECT_MANAGER')?.map((projectManager) => (
                            <User person={projectManager} key={projectManager.id}/>
                        ))}
                    </ul>
                </div>
            ) : null}
            {data.get('ROLE_PRODUCER')?.length ? (
                <div className={styles.item}>
                    <div className={styles.title}>Продюсеры</div>
                    <ul className={styles.users}>
                        {data.get('ROLE_PRODUCER')?.map((producer) => (
                            <User person={producer} key={producer.id}/>
                        ))}
                    </ul>
                </div>
            ) : null}
            {data.get('ROLE_CONTENT_EDITOR')?.length ? (
                <div className={styles.item}>
                    <div className={styles.title}>Редакторы контента</div>
                    <ul className={styles.users}>
                        {data.get('ROLE_CONTENT_EDITOR')?.map((contentEditor) => (
                            <User person={contentEditor} key={contentEditor.id}/>
                        ))}
                    </ul>
                </div>
            ) : null}
            {data.get('ROLE_COORDINATOR')?.length ? (
                <div className={styles.item}>
                    <div className={styles.title}>Координаторы</div>
                    <ul className={styles.users}>
                        {data.get('ROLE_COORDINATOR')?.map((coordinator) => (
                            <User person={coordinator} key={coordinator.id}/>
                        ))}
                    </ul>
                </div>
            ) : null}
            {data.get('ROLE_ANALYST')?.length ? (
                <div className={styles.item}>
                    <div className={styles.title}>Аналитики</div>
                    <ul className={styles.users}>
                        {data.get('ROLE_ANALYST')?.map((analyst) => (
                            <User person={analyst} key={analyst.id}/>
                        ))}
                    </ul>
                </div>
            ) : null}
            {data.get('ROLE_FINANCIER')?.length ? (
                <div className={styles.item}>
                    <div className={styles.title}>Финансисты</div>
                    <ul className={styles.users}>
                        {data.get('ROLE_FINANCIER')?.map((financier) => (
                            <User person={financier} key={financier.id}/>
                        ))}
                    </ul>
                </div>
            ) : null}
            {data.get('ROLE_LAWYER')?.length ? (
                <div className={styles.item}>
                    <div className={styles.title}>Юристы</div>
                    <ul className={styles.users}>
                        {data.get('ROLE_LAWYER')?.map((lawyer) => (
                            <User person={lawyer} key={lawyer.id}/>
                        ))}
                    </ul>
                </div>
            ) : null}
           <WhetherTheUser role={['ROLE_ADMIN_IS', 'ROLE_ADMIN']} isCan={true}>
               <>
                   {data.get('ROLE_MODERATOR')?.length ? (
                       <div className={styles.item}>
                           <div className={styles.title}>Редактор каталога</div>
                           <ul className={styles.users}>
                               {data.get('ROLE_MODERATOR')?.map((person) => (
                                   <User person={person} key={person.id}/>
                               ))}
                           </ul>
                       </div>
                   ) : null}
                   {data.get('ROLE_ADMIN')?.length ? (
                       <div className={styles.item}>
                           <div className={styles.title}>Администраторы</div>
                           <ul className={styles.users}>
                               {data.get('ROLE_ADMIN')?.map((person) => (
                                   <User person={person} key={person.id}/>
                               ))}
                           </ul>
                       </div>
                   ) : null}
                   {data.get('ROLE_AGENT')?.length ? (
                       <div className={styles.item}>
                           <div className={styles.title}>Представители</div>
                           <ul className={styles.users}>
                               {data.get('ROLE_AGENT')?.map((person) => (
                                   <User person={person} key={person.id}/>
                               ))}
                           </ul>
                       </div>
                   ) : null}
                   {data.get('ROLE_CLIENT')?.length ? (
                       <div className={styles.item}>
                           <div className={styles.title}>Клиенты</div>
                           <ul className={styles.users}>
                               {data.get('ROLE_CLIENT')?.map((person) => (
                                   <User person={person} key={person.id}/>
                               ))}
                           </ul>
                       </div>
                   ) : null}
                   {data.get('ROLE_ADMIN_IS')?.length ? (
                       <div className={styles.item}>
                           <div className={styles.title}>Администаторы ИБ</div>
                           <ul className={styles.users}>
                               {data.get('ROLE_ADMIN_IS')?.map((person) => (
                                   <User person={person} key={person.id}/>
                               ))}
                           </ul>
                       </div>
                   ) : null}
                   {data.get('ROLE_INTEGRATION_USER')?.length ? (
                       <div className={styles.item}>
                           <div className={styles.title}>Интеграционные пользователи </div>
                           <ul className={styles.users}>
                               {data.get('ROLE_INTEGRATION_USER')?.map((person) => (
                                   <User person={person} key={person.id}/>
                               ))}
                           </ul>
                       </div>
                   ) : null}
                   {data.get('ROLE_VIEWER')?.length ? (
                       <div className={styles.item}>
                           <div className={styles.title}>Наблюдатели</div>
                           <ul className={styles.users}>
                               {data.get('ROLE_VIEWER')?.map((person) => (
                                   <User person={person} key={person.id}/>
                               ))}
                           </ul>
                       </div>
                   ) : null}

               </>
           </WhetherTheUser>
        </div>
    )
};
function User({person}: {person: ProjectTypes.ProjectUser}) {
    const name =`${person.lastName} ${person.firstName} ${person.middleName || ''}`
    return <li><UserOutlined />{name}</li>
}
export default UsersModal;
