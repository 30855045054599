import * as Yup from "yup";
import {getValue, statusIsNullOrDraftOrReadyToBeActual} from "./utils";
import {StepFourthDto} from "../dto/project-form_StepFourth.dto";

export const validationStepFourth = Yup.object({
    contractorOrganization: Yup.mixed().test(
        'contractorOrganization',
        'Поле «Исполнитель» заполнено некорректно',
        function ({value}: StepFourthDto["contractorOrganization"], _context) {
            return !!value?.value;
        }
    ),
    projectManagers: Yup.mixed().test(
        'projectManagers',
        'Поле «Менеджер проекта» заполнено некорректно',
        function ({value}: StepFourthDto["projectManagers"], context) {
            if (!value.length && statusIsNullOrDraftOrReadyToBeActual(context)) return true;
            else {
                const form = getValue(context).stepFourth;
                if (form.producers.value.length > 0 && form.coordinators.value.length > 0) return true;
                return value.length > 0
            }
        }
    ),
    contractors: Yup.mixed().test(
        'contractors',
        'Поле «Представитель исполнителя» заполнено некорректно',
        function ({value}: StepFourthDto["contractors"], context) {
            if (!value?.length && statusIsNullOrDraftOrReadyToBeActual(context)) return true;
            else return value?.length > 0
        }
    ),
    producers: Yup.mixed().test(
        'producers',
        'Поле «Продюсер» заполнено некорректно',
        function ({value}: StepFourthDto["producers"], context) {
            if (!value.length && statusIsNullOrDraftOrReadyToBeActual(context)) return true;
            else {
                if (getValue(context).stepFourth.projectManagers.value.length > 0) return true;
                return value.length > 0
            }
        }
    ),
    coordinators: Yup.mixed().test(
        'coordinators',
        'Поле «Координатор» заполнено некорректно',
        function ({value}: StepFourthDto["coordinators"], context) {
            if (!value.length && statusIsNullOrDraftOrReadyToBeActual(context)) return true;
            else {
                if (getValue(context).stepFourth.projectManagers.value.length > 0) return true;
                return value.length > 0
            }
        }
    ),
    lawyers: Yup.mixed().test(
        'lawyers',
        'Поле «Юрист» заполнено некорректно',
        function ({value}: StepFourthDto["lawyers"], context) {
            if (!value.length && statusIsNullOrDraftOrReadyToBeActual(context)) return true;
            else return value.length > 0
        }
    ),
    financiers: Yup.mixed().test(
        'financiers',
        'Поле «Финансист» заполнено некорректно',
        function ({value}: StepFourthDto["financiers"], context) {
            if (!value.length && statusIsNullOrDraftOrReadyToBeActual(context)) return true;
            else return value.length > 0
        }
    ),
    analysts: Yup.mixed().test(
        'analysts',
        'Поле «Аналитик» заполнено некорректно',
        function ({value}: StepFourthDto["analysts"], context) {
            if (!value.length && statusIsNullOrDraftOrReadyToBeActual(context)) return true;
            else return value.length > 0
        }
    ),

})
