import React, {FC, useMemo} from 'react';
import {FieldArray, useFormikContext} from "formik";
import {Button, Col, Row, Spin, Tooltip} from "antd";
import {DeleteOutlined, PlusCircleOutlined} from "@ant-design/icons";
import {TStatusesFeels} from "models/projects/projectFeels";
import useFeelsApp from "hooks/feelsApp";
import {useDispatch, useSelector} from "react-redux";
import {selectFormatSubmissionOptions} from "store/app/selectors";
import {IOptions} from "models/feels";
import ReloadList from "components/forms/components/reload-list/reload-list";
import {GetFeelsStartAction} from "store/app/actions";
import Checkbox from "antd/es/checkbox";
import {ProjectFormDto} from "features/create-or-edit-project-form//lib/dto/project-form.dto";
import {InputNumberComponent, SelectComponent} from 'components/formic-control';
import styles from './styles.module.less'
import classNames from "classnames";

type ContentFormatFieldsProps = {
    name: string,
    disabled?: boolean
    isContent?: boolean
    isAccountedContent?: boolean
    textError?: string
    labelName?: 'accountedContent' | 'nonAccountedContent' | 'workingMaterial'
}
const ContentFormatFields: FC<ContentFormatFieldsProps> = ({name, disabled, isContent = false, isAccountedContent = false, textError, labelName}) => {
    const put = useDispatch()
    const {errors, values, setFieldValue} = useFormikContext<ProjectFormDto>()
    const status = values.stepFirst.status.value;
    const {formatTypes, isLoading} = useFeelsApp([TStatusesFeels.formatTypes])
    const formatSubmission = useSelector(selectFormatSubmissionOptions);
    const errorsStepSecond = errors.stepSecond?.contentFormats;

    const formatSubmissionDisabledOptions = (arr?: IOptions<string>[]) => {
        if ((!status || status?.code === 'DRAFT' || status?.code === 'ACTUAL') && arr?.length) {
            return arr.filter((el) => el.hidden).map((el) => el.value);
        }
        return [];
    }
    const formatTypesDisabledOptions = useMemo(() => {
        if (!status || status?.code === 'DRAFT' || status?.code === 'ACTUAL') {
            return formatTypes?.filter((el) => el.hidden).map((el) => el.value);
        }
        return [];
    }, [status, formatTypes]);

    const handlerUpdateFormatTypes = ()=> {
        put(GetFeelsStartAction(TStatusesFeels.formatTypes))
    }

    const selectCheckboxAll = (name:string, arr: any) => (e: any)=> {
        const checked: boolean = e.target.checked;
        const disabledOptions = formatSubmissionDisabledOptions(arr)
        const filteredOptions = arr.filter((option: any) => {
            return !disabledOptions?.some(value => value === option.value);
        })
        if(checked) setFieldValue(name,filteredOptions)
        else setFieldValue(name,[])
    }

    return (
        <FieldArray
            name={name}
            render={({form, push, remove}) => {
                const count = form.values.stepSecond.contentFormats.value.length;
                const isError = errorsStepSecond && count !== 0;
                return (
                    <div className={styles.contentFormats}>
                        {form.values.stepSecond.contentFormats.value.map((item: any, index: number) => {
                            const disabledOptions = formatSubmissionDisabledOptions(formatSubmission ? formatSubmission[item.type.value] : [])
                            const filteredOptions = formatSubmission ?
                                formatSubmission[item.type.value]?.filter((option: any) => {
                                return !disabledOptions?.some(value => value === option.value)
                            }) : []

                            const contentFormats = form.values.stepSecond.contentFormats.value
                            const currentType = contentFormats[index]?.type
                            const currentContentCount = contentFormats[index]?.contentCount
                            const currentSubmissionForms = contentFormats[index]?.submissionForms

                            if (item.isContent !== isContent) return null
                            if (item.isAccountedContent !== isAccountedContent && labelName !== 'workingMaterial') return null

                            return (
                                <React.Fragment key={index}>
                                    <Row  gutter={[5, 5]} style={{width: '100%', 'marginBottom': '15px'}} wrap={true}>
                                        <Col className={'item'} lg={7} span={24}>
                                            <Spin spinning={isLoading}>
                                                <ReloadList handlerUpdateList={handlerUpdateFormatTypes}/>
                                                <SelectComponent
                                                    name={`${name}[${index}].type`}
                                                    placeholder='Выберите единицы контента'
                                                    options={formatTypes}
                                                    disabledOptionsIds={formatTypesDisabledOptions}
                                                    showError={false}
                                                    disabled={disabled}
                                                    maxTagCount={'responsive'}
                                                />
                                            </Spin>
                                        </Col>
                                        <Col className={'item'} lg={6} span={24}>
                                            <InputNumberComponent
                                                name={`${name}[${index}].contentCount`}
                                                placeholder='Введите количество контента'
                                                showError={false}
                                                disabled={disabled}
                                            />
                                        </Col>
                                        <Col className={'item'} lg={8} span={21}>
                                            <Spin spinning={isLoading}>
                                                <ReloadList handlerUpdateList={handlerUpdateFormatTypes}/>
                                                <SelectComponent
                                                    multi={true}
                                                    name={`${name}[${index}].submissionForms`}
                                                    placeholder='Выберите формат сдачи контента'
                                                    options={formatSubmission ? formatSubmission[item.type.value] : null}
                                                    disabledOptionsIds={
                                                        formatSubmission ?
                                                            formatSubmissionDisabledOptions(formatSubmission[item.type.value]) :
                                                            []
                                                    }
                                                    showError={false}
                                                    disabled={disabled}
                                                    maxTagCount={'responsive'}
                                                    dropdownRender={
                                                        <Checkbox
                                                            className={styles.checkboxInSelect}
                                                            onChange={selectCheckboxAll(`${name}[${index}].submissionForms`,formatSubmission? formatSubmission[item.type.value] : [] )}
                                                            checked={formatSubmission && item.submissionForms?.length ? item.submissionForms?.length === filteredOptions?.length : false}
                                                            disabled={disabled || !(item.type?.value)}
                                                        >
                                                            Выбрать все
                                                        </Checkbox>
                                                    }
                                                />
                                            </Spin>
                                        </Col>
                                        <Col span={3}>
                                            {!disabled && (
                                                <div>
                                                    <Tooltip title="Удалить формат контента?">
                                                        <Button
                                                            className={styles.delete}
                                                            type="link"
                                                            onClick={() => remove(index)}
                                                        >
                                                            <DeleteOutlined/>
                                                        </Button>
                                                    </Tooltip>
                                                </div>
                                            )}
                                        </Col>
                                    </Row>
                                    <Row>
                                        {!disabled && isError &&
                                            !(currentType && currentContentCount && currentSubmissionForms?.length) &&
                                            <div className={classNames(styles.error,"invalid-feel")}>{textError}</div>}
                                    </Row>
                                </React.Fragment>
                            )
                        })}
                        {!disabled && (
                            <div className={styles.btnAdd}>
                                <Button
                                    type="link"
                                    onClick={() => push({
                                        type: '',
                                        contentCount: '',
                                        submissionForms: '',
                                        isAccountedContent: isAccountedContent,
                                        isContent: isContent
                                    })}>
                                    <PlusCircleOutlined/> Добавить ед.контента
                                </Button>
                            </div>
                        )}

                    </div>
                )
            }}
        />
    )
}
export default ContentFormatFields;
