import { getRanks } from '../api'
import { Rank } from '../types'
import { useQuery, UseQueryResult } from 'react-query'
import { useMemo } from 'react'
import {IOptions} from "models/feels";

type onSuccessType = (data: Rank[]) => void
export function useGetRanks(onSuccess?: onSuccessType): UseQueryResult<Rank[]> {
	return useQuery({
		queryKey: [getRanks.key],
		queryFn: () => getRanks.fetch(),
		onSuccess,
	})
}
export function useGetRanksOptions(onSuccess?: onSuccessType) {
	const { data = [], ...rest } = useGetRanks(onSuccess)
	const options = useMemo(() => data.map((el) => {
		return {value: el.id, label: el.name, hidden: el.hidden, code: el.code} as IOptions
	}), [data])

	return { data: options, ...rest }
}
