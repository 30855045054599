import React, {FC, useMemo} from 'react';
import {FieldArray, useFormikContext} from "formik";
import {Button, Col, Row, Spin, Tooltip} from "antd";
import {DeleteOutlined, PlusCircleOutlined} from "@ant-design/icons";
import {InputComponent, SelectComponent} from "components/formic-control";
import useFeelsApp from "hooks/feelsApp";
import {TStatusesFeels} from "models/projects/projectFeels";
import {GetFeelsStartAction} from "store/app/actions";
import {useDispatch} from "react-redux";
import {ProjectFormDto} from "features/create-or-edit-project-form/lib/dto/project-form.dto";
import ReloadList from 'components/forms/components/reload-list/reload-list';
import styles from './styles.module.less';


type InternetResourcesForPublicProps = {
    name: string
    disabled?: boolean
}
const ChannelsFeel: FC<InternetResourcesForPublicProps> = ({name, disabled}) => {
    const put = useDispatch()
    const {errors, touched, values, setFieldValue} = useFormikContext<ProjectFormDto>()
    const {isLoading, internetResources} = useFeelsApp([TStatusesFeels.internetResources])
    const isError = errors.stepThird?.channels;

    const status = values.stepFirst.status.value;

    const internetResourcesDisabledOptions = useMemo(() => {
        if (!status || status?.code === 'DRAFT' || status?.code === 'ACTUAL') {
            return internetResources?.filter((el) => el.hidden).map((el) => el.value);
        }
        return [];
    }, [status, internetResources]);

    function isTouched(id: number) {
        // @ts-ignore
        const internetResource = touched[`stepThird.channels.value[${id - 1}].internetResource`]
        // @ts-ignore
        const link = touched[`stepThird.channels.value[${id - 1}].link`]
        return internetResource || link
    }
    const handlerUpdateInternetResources = ()=> {
        put(GetFeelsStartAction(TStatusesFeels.internetResources))
    }
    const handlerChangeResource = (index: number) => (value: any) => {
        setFieldValue(`${name}[${index}].name`, value.label)
        setFieldValue(`${name}[${index}].link`, value.code)
    }

    return (
        <FieldArray
            name={name}
            render={({form, push, remove}) => {
                const idx = form.values.stepThird.channels.value.length;
                const disableBtn = isError && idx !== 0;
                return (
                    <React.Fragment key={idx}>
                        {form.values.stepThird.channels.value.map((item: any, index: number) => (
                            <Row key={index} className='form-group__row' gutter={[5, 5]} >
                                <Col xl={10} lg={8} md={19} xs={22}>
                                    <Spin spinning={isLoading || !internetResources}>
                                        <ReloadList handlerUpdateList={handlerUpdateInternetResources}/>
                                        <SelectComponent
                                            handler={handlerChangeResource(index)}
                                            options={internetResources}
                                            name={`${name}[${index}].internetResource`}
                                            placeholder={'Выберите площадку размещения'}
                                            showError={false}
                                            disabled={disabled}
                                            disabledOptionsIds={internetResourcesDisabledOptions}
                                            maxTagCount={'responsive'}
                                        />
                                    </Spin>
                                </Col>
                                <Col xl={11} lg={13} md={19} xs={22}>
                                    <InputComponent
                                        name={`${name}[${index}].link`}
                                        type='text'
                                        placeholder={'Добавьте ссылку на канал'}
                                        showError={false}
                                        disabled={disabled}
                                    />
                                </Col>
                                <Col lg={1} md={2} xs={2}>
                                    {!disabled && (
                                        <div className='form-group__btn'>
                                            <Tooltip title="Удалить тип контента?">
                                                <Button
                                                    className={styles.delete}
                                                    type="link"
                                                    onClick={() => remove(index)}
                                                >
                                                    <DeleteOutlined/>
                                                </Button>
                                            </Tooltip>
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        ))}
                        {!disabled && (
                            <>
                                {disableBtn && isTouched(idx) ? (
                                    <div className="invalid-feel">{errors.stepThird?.channels as string}</div>
                                ) : (
                                    <>
                                        <Button type="link" className="btn" onClick={() => push({
                                            internetResource: null,
                                            name: null,
                                            link: '',
                                            planPublicationCount: 1
                                        })}>
                                            <PlusCircleOutlined/> Добавить интернет-ресурс
                                        </Button>
                                        {!!form.values.stepThird.channels.value.length &&
                                            !!form.values.stepThird.channels.value[0].internetResource && (
                                            <Button type="link" className="btn" onClick={() => push({
                                                internetResource: form.values.stepThird.channels.value[idx - 1].internetResource,
                                                name: null,
                                                link: '',
                                                planPublicationCount: 1
                                            })}> <PlusCircleOutlined/> Добавить канал </Button>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </React.Fragment>
                )
            }}
        />
    )
};

export default ChannelsFeel;
