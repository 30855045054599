import { FunctionalityInfoType } from '../types'

export const content: FunctionalityInfoType = [
	{
		LevelGroupName: 'Просмотр списка контента с поиском, фильтрацией, пагинацией',
		LevelGroupData: [
			{
				name: 'Поиск контента',
				text: 'Для поиска контента по названию проекта, номеру договора, номеру заявки:',
				points: ['Нажмите "Поиск контента"', 'Введите текст', 'Нажмите на кнопку поиска'],
				screenshots: ['/images/screenshots/content/search.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_ANALYST',
					'ROLE_CLIENT',
					'ROLE_MODERATOR',
					'ROLE_VIEWER',
					'ROLE_AGENT',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Фильтрация контента',
				text: 'Используйте фильтры для поиска:',
				points: [
					'Нажмите для перехода в окно фильтрации ',
					'Примените фильтры',
					'Нажмите “Найти” для поиска',
					'Нажмите "Сбросить фильтры" для сброса неактуальных фильтров ',
				],
				screenshots: ['/images/screenshots/content/filters.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_ANALYST',
					'ROLE_CLIENT',
					'ROLE_MODERATOR',
					'ROLE_VIEWER',
					'ROLE_AGENT',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Переход в карточку контента',
				text: 'Нажмите для перехода в карточку контента',
				screenshots: ['/images/screenshots/content/card.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_ANALYST',
					'ROLE_CLIENT',
					'ROLE_MODERATOR',
					'ROLE_VIEWER',
					'ROLE_AGENT',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Переход на следующую страницу',
				text: 'Нажмите на номер страницы или стрелочки для перехода на другую страницу',
				screenshots: ['/images/screenshots/pagination.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_ANALYST',
					'ROLE_CLIENT',
					'ROLE_MODERATOR',
					'ROLE_VIEWER',
					'ROLE_AGENT',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Настройка количества карточек контента, отображаемых на странице',
				text: 'Для настройки количества отображаемых на странице карточек контента:',
				points: ['Нажмите на кнопку', 'Выберите значение из выпадающего списка'],
				screenshots: ['/images/screenshots/setting-count-page.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_ANALYST',
					'ROLE_CLIENT',
					'ROLE_MODERATOR',
					'ROLE_VIEWER',
					'ROLE_AGENT',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
		],
	},
]
