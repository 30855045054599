import React, {MouseEvent, useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Button, Radio, RadioChangeEvent, Select, Spin} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import {ExtendedSearchModel} from 'features/extended-search';
import {Fields, Stores} from 'features/extended-search/types';
import {FamousPeopleLib} from 'entities/famous-people'
import {RankLib} from 'entities/rank'

const {Option} = Select;
function useGetsData() {
    const ranks = RankLib.useGetRanksOptions()
    const famousPeopleNames = FamousPeopleLib.useGetFamousPeopleOptions()

    return {
        loading: ranks.isLoading || famousPeopleNames.isLoading,
        options: {
            FAMOUS_PERSON_RANK: ranks.data || [],
            FAMOUS_PERSON: famousPeopleNames.data || [],
        }
    }
}
const optionsFields: Array<{label: string, value: Stores.FamousPeopleKeys | 'Параметр неважен'}> = [
    {label: 'Параметр неважен', value: 'Параметр неважен'},
    {label: 'Звания', value: Fields.FieldsEnums.FAMOUS_PERSON_RANK},
    {label: 'ФИО известных людей проекта', value: Fields.FieldsEnums.FAMOUS_PERSON},
];
export const FamousPeopleItem = () => {
    const put = useDispatch();
    const {loading, options} = useGetsData();

    const {typeContent} = useSelector(ExtendedSearchModel.selectors.main)
    const famousPeople = useSelector(ExtendedSearchModel.selectors.famousPeople)

    const disabledOptions = useMemo(() => {
        return famousPeople[typeContent].fields.map((el) => el.field)
    }, [famousPeople, typeContent]);

    const onGroupChange = (index: number) => (e: RadioChangeEvent) => {
        const arr = [...famousPeople[typeContent].fields];
        arr[index].isAndOrBesides = e.target.value;
        put(ExtendedSearchModel.actions.setFamousPeople(typeContent, arr))
    }
    const onSelectChangeType = (index: number) => (value: Stores.FamousPeopleKeys | 'Параметр неважен') => {
        const newArray = [...famousPeople[typeContent].fields];
        newArray[index]["field"] = value;
        newArray[index]["values"] = [];
        put(ExtendedSearchModel.actions.setFamousPeople(
            typeContent,
            newArray
        ))
    }
    const onSelectChangeValue = (index: number) => (value: Fields.FieldFilter["values"]) => {
        const newArray = [...famousPeople[typeContent].fields];
        newArray[index]["values"] = value;
        put(ExtendedSearchModel.actions.setFamousPeople(
            typeContent,
            newArray
        ))
    }
    const addGroup = () => {
        put(ExtendedSearchModel.actions.setFamousPeople(typeContent, [
            ...famousPeople[typeContent].fields,
            {field: 'Параметр неважен', isAndOrBesides: false, values: []}
        ]))
    }
    const onRemoveHandler = (index: number) => (_event: MouseEvent<HTMLDivElement>) => {
        const newArray = [...famousPeople[typeContent].fields];
        newArray.splice(index, 1)
        put(ExtendedSearchModel.actions.setFamousPeople(
            typeContent,
            newArray
        ))
    }

    return (
        <Spin spinning={loading}>
            <div className="item-wrapper" key={typeContent}>
                {famousPeople[typeContent].fields.map((item, index) => (
                    <div className="item-wrapper__group" key={item.field + index}>
                        <div className="item-wrapper__items">
                            <div className="item">
                                <div className="form-group">
                                    <Select
                                        value={item.field}
                                        onChange={onSelectChangeType(index)}
                                        placeholder={'Параметр неважен'}
                                        getPopupContainer={trigger => trigger.parentNode}
                                    >
                                        {optionsFields.map((el) => (
                                            <Option
                                                disabled={disabledOptions.includes(el.value)}
                                                value={el.value}
                                                key={el.value}
                                            >
                                                {el.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                            <div className="or">
                                <Radio.Group value={item.isAndOrBesides} onChange={onGroupChange(index)}>
                                    <Radio value={true}>И</Radio>
                                    <Radio value={false}>Или</Radio>
                                </Radio.Group>
                            </div>
                            <div className="item item-second">
                                <div className="form-group">
                                    <Select
                                        allowClear={true}
                                        mode={"multiple"}
                                        disabled={item.field === 'Параметр неважен'}
                                        maxTagCount={"responsive"}
                                        value={item.values}
                                        onChange={onSelectChangeValue(index)}
                                        placeholder={'Параметр неважен'}
                                        filterOption={(input, option) => {
                                            if (option === undefined || option === null) return false;
                                            return option['data-name'].toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }}
                                        getPopupContainer={trigger => trigger.parentNode}
                                    >
                                        {item.field !== 'Параметр неважен' && options[item.field].map((el) => (
                                            <Option
                                                value={el.value}
                                                key={el.value}
                                                data-name={el.label}
                                            >
                                                {el.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                        </div>
                        {famousPeople[typeContent].fields.length > 1 && (
                            <div className="item__remove" onClick={onRemoveHandler(index)}>
                                <DeleteOutlined />
                            </div>
                        )}
                    </div>
                ))}
                {famousPeople[typeContent].fields.length < optionsFields.length - 1 && (
                    <div className="btn">
                        <Button type={'link'} onClick={addGroup}>+ Добавить условие</Button>
                    </div>
                )}
            </div>
        </Spin>
    )
}
