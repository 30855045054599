import {put} from 'redux-saga/effects'
import {NotificationOpen} from 'store/notification/actions'
import CreateErrorMessage from 'utils/validation/createErrorMessage'
import HandbookService from 'services/handbookService'
import {DownloadErrorAction, DownloadStartAction, DownloadSuccessAction,} from 'store/options/actions/actions-handbook'
import {AxiosResponse} from 'axios'
import {downloadXLXS} from 'utils/downloadXLXS'
import LegalFormOrganizationService from "services/legalFormOrganizationService";
import SessionTypeService from "services/sessionTypeService";
import CategoryProjectService from "services/categoryProjectService";
import InternetResourcesService from "services/internetResourcesService";
import {UserApi} from 'entities/user'

type TDownloadAction = {
	[key: string]: any
}

const DownloadAction: TDownloadAction = {
	'organization': HandbookService.downloadOrganizations,
	'internet': InternetResourcesService.downloadInternet,
	'content-type': HandbookService.downloadContentType,
	'content-direction': HandbookService.downloadContentDirection,
	'content-delivery': HandbookService.downloadContentDelivery,
	'legal-form-organization': LegalFormOrganizationService.downloadLegalFormOrganization,
	'sessions-type': SessionTypeService.downloadSessionsType,
	'category-project': CategoryProjectService.downloadCategoryProject,
	'actions-users': UserApi.UserActionTypeController.download
}

export function* downloadHandbook({ data, typeName }: ReturnType<typeof DownloadStartAction>) {
	try {
		const { data: response }: AxiosResponse<any> = yield DownloadAction[typeName](data)
		yield put(DownloadSuccessAction())
		downloadXLXS(response, typeName)
		yield put(NotificationOpen('success', 'Данные выгружены успешно'))
	} catch (error: any) {
		yield put(DownloadErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось выгрузить'))
	}
}
