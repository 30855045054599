import {TaskFormDto} from "./domain/dto/task-form.dto";
import {TContents, TContentsForm, TTaskReportType, TTaskRequestDto, TTaskUser} from "models/task";
import replaceQuotesInString from "shared/lib/replaceQuotesInString";
import { transformMaterialTypeInObj} from "./utils/utils";

class StepFirstDto {
    "name": string
    "typeId": string
    "projectId": string
    "deadline": string
    "sprintId": string
    "archival": boolean
    "reportTypeId": string

    constructor(task: TaskFormDto["stepFirst"]) {
        this.name = task.name?.value
        this.typeId = task.typeProject?.value
        this.projectId = task.project?.value?.value as string
        this.deadline = task.deadline?.value as string
        this.sprintId = task.sprint?.value?.value as string
        this.archival = task.archival?.value === 'true'
        this.reportTypeId = task.report?.value as string
    }
}

class StepSecondDto {
    "description": string | null
    "contents": TContents[] | null
    "reachRecordsScreenshotRequired": boolean
    "reachRecordsCounterScreenshotRequired": boolean
    "postingRecordsScreenshotRequired": boolean
    "contentRecordDescriptionContractorVisible": boolean
    "contentTagsContractorVisible": boolean
    "regionTagsContractorVisible": boolean
    "contentTimeCodeRequired": boolean
    "reachRecordsReactionAmountRequired": boolean
    "defaultIsContent": boolean
    "defaultIsAccountedContent": boolean | null

    constructor(task: TaskFormDto["stepSecond"]) {
        this.description = replaceQuotesInString(task.description?.value)
        this.contents = task.contents?.value ? task.contents?.value.map((el) => new ContentFormatsDto(el)) : null
        this.reachRecordsScreenshotRequired = task.reachRecordsScreenshotRequired?.value
        this.reachRecordsCounterScreenshotRequired = task.reachRecordsCounterScreenshotRequired?.value
        this.postingRecordsScreenshotRequired = task.postingRecordsScreenshotRequired?.value === 'true'
        this.contentRecordDescriptionContractorVisible = task.contentRecordDescriptionContractorVisible?.value
        this.contentTagsContractorVisible = task.contentTagsContractorVisible?.value
        this.regionTagsContractorVisible = task.regionTagsContractorVisible?.value
        this.contentTimeCodeRequired = task.contentTimeCodeRequired?.value
        this.reachRecordsReactionAmountRequired = task.reachRecordsReactionAmountRequired?.value
        this.defaultIsContent = transformMaterialTypeInObj(task.materialTypeContent.value)['defaultIsContent']
        this.defaultIsAccountedContent = transformMaterialTypeInObj(task.materialTypeContent.value)['defaultIsAccountedContent']
    }
}

class StepThirdDto {
    "producerIds": string[] | null
    "analystIds":  string[] | null
    "financierIds": string[] | null
    "projectManagerIds": string[] | null
    "contentEditorIds": string[] | null
    "coordinatorIds": TTaskUser[] | null | string[]

    constructor(stepFirst: TaskFormDto["stepFirst"], stepThird: TaskFormDto["stepThird"],type: TTaskReportType) {
        this.coordinatorIds = stepThird.coordinators?.value && Object?.values(stepThird.coordinators?.value).filter((el: any) => el.isMain).length ?
            Object?.values(stepThird.coordinators?.value).filter((el: any) => el.isMain).map((el: any) => el.user)
            : null
        this.producerIds = stepThird.producers?.value && Object?.values(stepThird.producers?.value).filter((el: any) => el.isMain).length ?
            Object?.values(stepThird.producers?.value).filter((el: any) => el.isMain).map((el: any) => el.user)
            : null
        this.projectManagerIds = stepThird.projectManagers?.value && Object?.values(stepThird.projectManagers?.value).filter((el: any) => el.isMain).length ?
            Object?.values(stepThird.projectManagers?.value).filter((el: any) => el.isMain).map((el: any) => el.user)
            : null
        this.analystIds = stepFirst.report?.value && type.PROJECT_ESTIMATE_REPORT.id && type.PROJECT_ESTIMATE_REPORT.id === stepFirst.report?.value ?
            null :
            stepThird.analysts?.value && Object?.values(stepThird.analysts?.value).filter((el: any) => el.isMain).length ?
                Object?.values(stepThird.analysts?.value).filter((el: any) => el.isMain).map((el: any) => el.user)
                : null
        this.financierIds = stepFirst.report?.value && type.CONTENT_REACH_REPORT.id && type.CONTENT_REACH_REPORT.id === stepFirst.report?.value ?
            null :
            stepThird.financiers?.value && Object?.values(stepThird.financiers?.value).filter((el: any) => el.isMain).length ?
                Object?.values(stepThird.financiers?.value).filter((el: any) => el.isMain).map((el: any) => el.user)
                : null
        this.contentEditorIds = stepThird.contentEditors?.value && Object?.values(stepThird.contentEditors?.value).filter((el: any) => el.isMain).length ?
            Object?.values(stepThird.contentEditors?.value).filter((el: any) => el.isMain).map((el: any) => el.user)
            : null
    }
}


export class SendTaskFormDto {
    stepOne: StepFirstDto
    stepSecond: StepSecondDto
    stepThird: StepThirdDto

    constructor(task: TaskFormDto, type: TTaskReportType) {
        this.stepOne = new StepFirstDto(task.stepFirst)
        this.stepSecond = new StepSecondDto(task.stepSecond)
        this.stepThird = new StepThirdDto(task.stepFirst, task.stepThird, type)
    }

    get(): TTaskRequestDto {
        return {...this.stepOne, ...this.stepSecond, ...this.stepThird}
    }
}

class ContentFormatsDto implements TContents {
    "id": string | null
    "contentCount": number
    "formatTypeId": string
    "isAccountedContent": boolean
    "isContent": boolean

    constructor(el: TContentsForm) {
        this.id = null
        this.contentCount = el.contentCount
        this.formatTypeId = el.contentFormatType?.value
        this.isAccountedContent = el.isAccountedContent
        this.isContent = el.isContent
    }
}
