import {put, select, takeEvery} from 'redux-saga/effects'
import {LOCATION_CHANGE, LocationChangeAction} from 'connected-react-router'
import {GetProjectsStartAction, GetProjectStartAction, GetProjectsTypesStartAction,} from 'store/projects/actions'
import {GetAllTasksStartAction, SetTaskFilter} from 'store/tasks/actions/tasks'
import {GetRelatedUsersStartAction} from 'store/options/actions/actions-users'
import {GetTasksGantStartAction, GetTasksKanbanStartAction} from 'store/tasks/actions/gant-kanban'
import {selectParams} from 'store/selectors'
import {selectRelatedUsersIsEmpty} from 'store/options/selectors/selectors-users'
import {TTaskFilter} from 'models/task/task-list'
import {selectTaskListFilter} from 'store/tasks/selectors/selectors-task'
import {ClearEditingKey, ClearHandbookStore,} from 'store/options/actions/actions-handbook'
import {ClearEditingKey as ClearEditingKeySprint} from 'store/sprints/actions'
import {GetContentListStartAction,} from 'store/content/list/actions'
import {
	GetContentStartAction,
	GetContestSuccessAction,
	GetProjectContentStartAction,
} from 'store/content/content/actions'
import {GetContentSettingsList} from "store/content/settings/actions";
import {nameHandbookStoreKey} from 'pages/options/handbook/handbook'
import {GetCalendarValue} from "pages/calendar/model/store/actions";

export default function* pageLoader() {
	yield takeEvery(LOCATION_CHANGE, loaderWorker)
}
function* loaderWorker({ payload }: LocationChangeAction) {
	try {
		const { id } = yield select(selectParams)
		const pathname = payload.location.pathname

		const page = pathname.split('/')[1]
		const type = pathname.split('/')[2]

		if (page === 'projects') {
			yield put(GetProjectsStartAction())  //получение всех проектов c модальным окном сортировки
		}
		if (page === 'project') {
			yield put(GetProjectsTypesStartAction())
			if (id) yield put(GetProjectStartAction(id)) //получение проекта

			if (type === 'calendar' && id) {
				yield put(GetTasksGantStartAction(id))
				yield put(ClearEditingKeySprint()) //очищаем ключ редактирования для спринтов
			}
			if (type === 'tasks' && id) {
				yield put(GetTasksKanbanStartAction(id))
			}
		}
		if (page === 'project-edit') {
			if (id) yield put(GetProjectStartAction(id)) //получение проекта
			yield put(GetProjectsTypesStartAction())
		}
		if (page === 'project-create' || page === 'analytics') {
			yield put(GetProjectsTypesStartAction())
		}
		if (page === 'calendar') {
			yield put(GetCalendarValue())
		}
		if (page === 'tasks') {
			if (type === 'projects') {
				const relatedUsers: boolean = yield select(selectRelatedUsersIsEmpty)
				const filter: TTaskFilter = yield select(selectTaskListFilter)
				if (!relatedUsers) yield put(GetRelatedUsersStartAction())
				yield put(SetTaskFilter({ ...filter, sort: { field: 'deadline', direction: 'DESC' } }))
				yield put(GetAllTasksStartAction())
			}
		}
		if (page === 'options') {
			if (type === 'handbook') {
				const typeHandbook = pathname.split('/')[3]
				yield put(ClearEditingKey())
				yield put(ClearHandbookStore(nameHandbookStoreKey[typeHandbook]))
			}
		}
		if (page === 'content') {
			yield put(GetContentListStartAction())
			if (type === 'info') {
				yield put(GetContentStartAction())
				yield put(GetProjectContentStartAction())
			} else if (type === 'settings') {
				yield put(GetContentSettingsList())
			} else {
				yield put(GetContestSuccessAction(null))
			}
		}

	} catch (error: any) {
		console.log(error)
	}
}
