import {createSelector} from 'reselect'
import {TApplicationState} from '../../aplication-state'
import {IOptions} from "models/feels";

const handBookState = (state: TApplicationState) => state.options.handbook

export const selectHandbookEditingKey = createSelector(handBookState, (state) => state.editingKey)

export const selectHandbookContentDirection = createSelector(handBookState, (state) => state.contentDirection)
export const selectHandbookOrganization = createSelector(handBookState, (state) => state.organization)
export const selectHandbookOrganizationId = createSelector(handBookState, (state) => state.organizationId)
export const selectHandbookActionsUsers = createSelector(handBookState, (state) => state.actionsUsers)
export const selectHandbookContentDelivery = createSelector(handBookState, (state) => state.contentDelivery)

export const selectHandbookLegalForm = createSelector(handBookState, (state) => state.legalFormOrganization)
export const selectHandbookSessionsType = createSelector(handBookState, (state) => state.sessionsType)
export const selectHandbookCategoryProject = createSelector(handBookState, (state) => state.categoryProject)
export const selectHandbookContentDeliveryAll = createSelector(handBookState, (state) =>
	state.contentDeliveryAll
		? state.contentDeliveryAll.map((el) => {
			return { label: el.name, value: el.id } as IOptions
		})
		: null
)
export const selectHandbookInternet = createSelector(handBookState, (state) => state.internet)
export const selectHandbookStatus = createSelector(handBookState, (state) =>  ({
	isLoading: state.isLoading,
	error: state.error
}))
export const selectHandbookOrganizationFilter = createSelector(handBookState, (state) => state.filterOrganization)
export const selectHandbookContentDirectionFilter = createSelector(handBookState, (state) => state.filterContentDirection)
export const selectHandbookActionsUsersFilter = createSelector(handBookState, (state) => state.filterActionsUsers)
export const selectHandbookInternetResourceFilter = createSelector(handBookState, (state) => state.filterInternet)
export const selectHandbookContentDeliveryFilter = createSelector(handBookState, (state) => state.filterContentDelivery)
export const selectHandbookLegalFormFilter = createSelector(handBookState, (state) => state.filterLegalForm)
export const selectHandbookSessionsTypeFilter = createSelector(handBookState, (state) => state.filterSessionsType)
export const selectHandbookCategoryProjectFilter = createSelector(handBookState, (state) => state.filterCategoryProject)
export const selectContentModuleSection = createSelector(handBookState, (state) =>
	state.contentModuleSection
	? state.contentModuleSection.map((el) => {
		return { label: el.name, value: el.id } as IOptions
	})
	: null
)
export const selectBulkAction = createSelector(handBookState, (state) => state.bulkAction)
export const selectRowsIds = createSelector(handBookState, (state) => state.rowsIds)

