import React, {FC, useEffect, useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
//Any
import {PageActionsProps} from './types'
import {HANDBOOK} from 'routing/names'
import {TDataModalError} from "components/modals/modal-error/modal-error";
import {useDispatch, useSelector} from 'react-redux'
//Models
import {THandbookFilter} from 'models/handbook'
import {TFilterData} from "models/user";
//Antd
import {Button, Input, Radio, Select, Spin, Tooltip, Upload} from 'antd'
import {DownloadOutlined, PlusCircleOutlined, SearchOutlined, UploadOutlined} from '@ant-design/icons'
//Hooks
import useDebounce from 'hooks/useDebounce'
import useBulkAction from "./useBulkAction";
//Utils
import {transformRolesBack} from 'utils/transformationValues'
import {transformArrayIdNameForValue} from "utils/transformObjectFeels";
//Store
import {selectLegalFormOrganizationShortNameOptions} from "store/legal-form-organization/selectors";
import {GetAllLegalFormOrganizationStartAction} from "store/legal-form-organization/actions";
import {ModalOpenAction} from "store/modals/actions";
import {
	selectHandbookActionsUsersFilter,
	selectHandbookCategoryProjectFilter,
	selectHandbookContentDeliveryFilter,
	selectHandbookContentDirectionFilter,
	selectHandbookEditingKey,
	selectHandbookInternetResourceFilter,
	selectHandbookLegalFormFilter,
	selectHandbookOrganizationFilter,
	selectHandbookSessionsTypeFilter,
	selectHandbookStatus,
} from 'store/options/selectors/selectors-handbook'
import {
	ClearHandbookError,
	DownloadTemplateStartAction,
	SetHandbookFilter,
	UploadStartAction
} from 'store/options/actions/actions-handbook'
import {useQuery} from "react-query";
import InternetResourcesService from "services/internetResourcesService";
import {RegionLib} from 'entities/region'


const { Option } = Select

type TFilter = {
	[key in any]: any
}
const Filter: TFilter = {
	'internet': selectHandbookInternetResourceFilter,
	'content-delivery': selectHandbookContentDeliveryFilter,
	'actions-users': selectHandbookActionsUsersFilter,
	'content-direction': selectHandbookContentDirectionFilter,
	'organization': selectHandbookOrganizationFilter,
	'legal-form-organization': selectHandbookLegalFormFilter,
	'sessions-type': selectHandbookSessionsTypeFilter,
	'category-project': selectHandbookCategoryProjectFilter,
}

const handleClickAdd = (type: string, addRowHandler: any , history: any) => () => {
	switch (type) {
		case 'organization':
			history.push(HANDBOOK.ORGANIZATION_FORM)
			break
		case 'internet':
			addRowHandler({ id: `internet${Math.random()}`, domainLink: '', name: '', hidden: false, typeColumn: 'new', type: ''})
			break
		case 'content-delivery':
			addRowHandler({ id: `contentDelivery${Math.random()}`, code: '', name: '', hidden: false,
				contentModuleSection: {id:null, name : ''}, type: 'new' })
			break
		case 'content-direction':
			addRowHandler({ id: `contentDelivery${Math.random()}`, code: '', name: '', hidden: false, type: 'new' })
			break
		case 'legal-form-organization':
			addRowHandler({ id: `legal-form-organization${Math.random()}`, code: '', fullName: '',shortName: '', type: 'new' })
			break
		case 'sessions-type':
			addRowHandler({ id: `sessionsType${Math.random()}`, code: '', name: '', type: 'new' })
			break
		case 'category-project':
			addRowHandler({ id: `Category${Math.random()}`, code: '', name: '', hidden: false, type: 'new' })
			break
		case 'actions-users':
			addRowHandler({ id: `Genre${Math.random()}`, code: '', name: '', type: 'new' })
			break
		default:
			return history.push(HANDBOOK.THEMATIC)
	}
}

const PageActions: FC<PageActionsProps> = ({
	addRowHandler,
	handleClickActions,
}) => {
	const put = useDispatch()
	const editingKey = useSelector(selectHandbookEditingKey)
	const {error, isLoading} = useSelector(selectHandbookStatus)
	const organizationShortNameOptions = useSelector(selectLegalFormOrganizationShortNameOptions)
	const {data: regionsOptions} = RegionLib.useGetRegionsOptions()

	const {rowsIds, bulkAction, handlerBulkActions} = useBulkAction()
	const [search, setSearch] = useState<string | undefined>()

	const { pathname } = useLocation()
	const type = pathname.split('/')[3]
	const history = useHistory()
	const handbookFilter = useSelector(Filter[type]) as THandbookFilter

	const {isLoading: loadingSiteList, data: siteList} = useQuery({
		queryKey: [InternetResourcesService.getInternetResourceTypeListKey],
		queryFn: async () => {
			if (type === 'internet') {
				const {data} = await InternetResourcesService.getInternetResourceTypeList()
				return transformArrayIdNameForValue(data || [])
			}
		},
	})

	useEffect(()=>{
		if(!organizationShortNameOptions.length && type === 'organization') put(GetAllLegalFormOrganizationStartAction())
		// eslint-disable-next-line
	},[])

	const handlerDownloadTemplate = () => {
		put(DownloadTemplateStartAction(type))
	}
	const getFilter = (name: keyof TFilterData, value: string | string[]) => {
		const filterData = Object.assign({}, handbookFilter?.filterData);
		if (value === undefined) {
			filterData[name] = null;
		} else {
			filterData[name] = typeof value !== 'string' ? transformRolesBack(value) : value;
		}

		return {
			...handbookFilter,
			filterData: { ...filterData }
		};
	};

	const onSearchChangeFilter = (e: any)=> {
		const filter = getFilter('searchString', e.target.value)
		const val = onSearchValue(type)
		put(SetHandbookFilter({ [val]: {...filter, page: 1} }))
	}
	const validateUpload = (_file: File) => {
		return false
	}

	const handleClickUploadFile = (e:any)=>{
		const length = e.fileList?.length;
		put(UploadStartAction(e.fileList[length-1]['originFileObj'], type))
	}
	const handleChangeSearch = useDebounce(onSearchChangeFilter, 400)

	const onSearchChange = (e: any) => {
		setSearch(e.target.value)
		handleChangeSearch(e)
	}

	const handlerActions = (e: any)=>{
		setSearch(undefined)
		handleClickActions(e)
	}

	const handlerSelectLegalForm = (e: string) => {
		const filter = getFilter('legalFormId', e)
		const val = onSearchValue(type)
		put(SetHandbookFilter({ [val]: {...filter} }))
	}

	const handlerSelectRegion = (e: string) => {
		const filter = getFilter('regionId', e)
		const val = onSearchValue(type)
		put(SetHandbookFilter({ [val]: {...filter} }))
	}

	const handlerSelectSiteList = (e: string) => {
		const newFilter = {
			...handbookFilter,
			filterData: { ...handbookFilter?.filterData, typeIds: e ? [e] : undefined }
		}
		const val = onSearchValue(type)
		put(SetHandbookFilter({ [val]: {...newFilter} }))
	}

	const handlerChangeRadio = (e:any) => {
		const filter = {
			...handbookFilter,
			filterData: { ...handbookFilter?.filterData, isLocal: e.target.value }
		};
		const val = onSearchValue(type)
		put(SetHandbookFilter({ [val]: {...filter} }))
	}

	const clearFunction = () => {
		put(ClearHandbookError())
	}

	const showModal = () => {
		if (error) {
			put(ModalOpenAction('modal-error', {
				clearFunction: clearFunction,
				error: error
			} as TDataModalError))
		}
	}

	return (
		<div className="page__actions--wrapper">
			<div className="page__actions">
				<div className="page__search gutter-row">
					<Tooltip title={titleTooltipSearch(type)} placement="top">
						<Input
							autoComplete="off"
							value={search}
							prefix={<SearchOutlined className="usersPage__search--item-icon"/>}
							placeholder="Поиск"
							onChange={onSearchChange}
						/>
					</Tooltip>
				</div>
				{type === 'organization' && (
					<div className="page__actions-select--wrapper gutter-row form-group">
						<Select
							showSearch
							allowClear={true}
							className="page__actions-select"
							placeholder={'Правовая форма'}
							onChange={handlerSelectLegalForm}
							optionFilterProp="children"
							filterOption={(input, option) =>
								(option?.children as unknown as string).toLowerCase().includes(input.toLowerCase())
							}
						>
							{organizationShortNameOptions?.filter(it => !it.hidden).map((el) => (
								<Option
									key={el.value}
									value={el.value}
								>
									{el.label}
								</Option>
							))}
						</Select>
					</div>
				)}
				{type === 'organization' && (
					<div className="page__actions-select--wrapper gutter-row form-group">
						<Select
							showSearch
							allowClear={true}
							className="page__actions-select"
							placeholder={'Регион организации'}
							onChange={handlerSelectRegion}
							optionFilterProp="children"
							filterOption={(input, option) =>
								(option?.children as unknown as string).toLowerCase().includes(input.toLowerCase())
							}
						>
							{regionsOptions?.map((el) => (
								<Option
									key={el.value}
									value={el.value}
								>
									{el.label}
								</Option>
							))}
						</Select>
					</div>
				)}
				<div className="page__actions-select--wrapper gutter-row">
					<Select
						allowClear={true}
						className="page__actions-select page__actions-select-bulk"
						placeholder="Массовые действия"
						onChange={handlerBulkActions}
						value={bulkAction || undefined}
					>
						{!(type === 'contests' || type === 'sessions' || type === 'actions-users' ) && (
							<>
								<Option value="SHOW">Показать</Option>
								<Option value="HIDE">Скрыть</Option>
							</>
						)}
						<Option value="UNLOADING">Выгрузить выбранные данные</Option>
						<Option value="UNLOADING_ALL">Выгрузить все данные</Option>
						{type !== 'actions-users' && <Option value="DELETE">Удалить</Option>}
					</Select>
				</div>
				{bulkAction ? (
					<div className="page__actions-btn gutter-row">
						<Tooltip
							title={bulkAction === 'UNLOADING_ALL' ? '' : rowsIds.length ? '' : "Выберите значение(я) списка"}>
							<Button onClick={handlerActions} type={'default'}
									disabled={bulkAction === 'UNLOADING_ALL' ? false : !rowsIds.length}>
								Применить
							</Button>
						</Tooltip>
					</div>
				) : null}
				{type !== 'actions-users' && (
					<>
						{type === 'internet' && (
							<div className="page__actions-select--wrapper gutter-row form-group">
								<Spin spinning={loadingSiteList}>
									<Select
										showSearch
										allowClear={true}
										className="page__actions-select"
										placeholder={'Вид площадки'}
										onChange={handlerSelectSiteList}
										optionFilterProp="children"
										filterOption={(input, option) =>
											(option?.children as unknown as string).toLowerCase().includes(input.toLowerCase())
										}
									>
										{siteList?.map((el) => (
											<Option
												key={el.value}
												value={el.value}
											>
												{el.label}
											</Option>
										))}
									</Select>
								</Spin>
							</div>
						)}
						{type === 'internet' ? (
							<>
								<div className="page__actions-radio ">
									<Radio.Group
										name="radiogroup"
										value={handbookFilter.filterData?.isLocal}
										onChange={handlerChangeRadio}
									>
										<Radio value={undefined}>Все</Radio>
										<Radio value={true}>Национальные ресурсы</Radio>
										<Radio value={false}>Иностранные ресурсы</Radio>
									</Radio.Group>
								</div>
							</>
						) : null}
						{type !== 'actions-users' && (
							<>
								<div className="page__actions--download gutter-row" onClick={handlerDownloadTemplate}>
									<DownloadOutlined/>
									<Tooltip placement="top" title="Скачать пустой шаблон">
										<span>Выгрузить шаблон</span>
									</Tooltip>
								</div>
								<div className="page__actions--import gutter-row">
									<Upload
										showUploadList={false}
										multiple={false}
										beforeUpload={validateUpload}
										onChange={handleClickUploadFile}
									>
										<UploadOutlined/>
										<Tooltip placement="top" title="Для загрузки скачайте шаблон">
											<span>Загрузить данные</span>
										</Tooltip>
									</Upload>
								</div>
							</>
						)}
						{typeof error === 'string' && (
							<Button
								onClick={showModal}
								className="error-btn"
								type={"default"}
							>
								Список ошибок <br/> загрузки
							</Button>
						)}
					</>
				)}
				<div className="page__actions--add gutter-row">
					<Button
						onClick={handleClickAdd(type, addRowHandler, history)}
						className={`button ${!!editingKey && 'button-disabled'}`}
						type={'link'}
						disabled={isLoading || !!editingKey}>
						<PlusCircleOutlined/>
						<span>Добавить новые данные</span>
					</Button>
				</div>
			</div>
		</div>
	)
}

export function onSearchValue(type: string) {
	switch (type) {
		case 'internet':
			return 'filterInternet'
		case 'content-delivery':
			return 'filterContentDelivery'
		case 'content-direction':
			return 'filterContentDirection'
		case 'actions-users':
			return 'filterActionsUsers'
		case 'organization':
			return 'filterOrganization'
		case 'legal-form-organization':
			return 'filterLegalForm'
		case 'sessions-type':
			return 'filterSessionsType'
		case 'category-project':
			return 'filterCategoryProject'
		default:
			return 'filterThematic'
	}
}
function titleTooltipSearch (type: string | undefined) {
	switch (type) {
		case "internet":
			return "Поиск по названию ресурса и ссылке"
		case "organization":
			return "Поиск по названию, инн, огрн/огрнип"
		case "actions-users":
			return "Поиск по наименованию действий"
		default: return "Поиск по коду и названию"
	}
}

export default PageActions
