import { z } from 'zod'
import { TasksRecordStatusEnum } from '../record-status'
import { VoteSchema } from '../vote'
import { FileSchema } from 'shared/types/file'

export const ReachRecordsSchema = z.object({
	status: TasksRecordStatusEnum,
	votes: z.array(VoteSchema).nullable(),
	lastTaskRecordWithVotesResponse: z.object({
		id: z.string(),
		votes: z.array(VoteSchema).nullable(),
	}),
	id: z.string(),
	actualPublicationDate: z.string().nullable(),
	link: z.string().nullable(),
	workingName: z.string().nullable(),
	publishedName: z.string().nullable(),
	reachAmount: z.number().nullable(),
	reactionAmount: z.number().nullable(),
	reachAmountSpread: z.number().nullable(),
	reachAmountMargin: z.number().nullable(),
	reactionEngagementRate: z.number().nullable(),
	reachDateTime: z.date().or(z.string()).nullable(),
	reactionDateTime: z.date().or(z.string()).nullable(),
	screenshots: z.array(FileSchema),
	counterScreenshots: z.array(FileSchema),
	isCounterTypeClosed: z.boolean(),
	isReachRecordsReactionAmountRequired: z.boolean(),
	internetResourceErvRange: z.object({
		id: z.string(),
		ervIndexAmount: z.number(),
		minReachAmount: z.number(),
		maxReachAmount: z.number(),
	}),
	isReplaced: z.boolean(),
	task: z
		.object({
			deadline: z.string(),
			number: z.number(),
			id: z.string(),
			name: z.string(),
			code: z.string(),
		})
		.optional(),
})
export type ReachRecords = z.infer<typeof ReachRecordsSchema>
