import {useDispatch, useSelector} from "react-redux";
import React, {useMemo} from "react";
import {
    selectBulkAction,
    selectHandbookActionsUsersFilter,
    selectHandbookCategoryProjectFilter,
    selectHandbookContentDeliveryFilter,
    selectHandbookContentDirectionFilter,
    selectHandbookInternetResourceFilter,
    selectHandbookLegalFormFilter,
    selectHandbookOrganizationFilter,
    selectHandbookSessionsTypeFilter,
    selectRowsIds
} from "store/options/selectors/selectors-handbook";
import {
    ClearHandbookFilter,
    SetHandbookBulkAction,
    SetHandbookFilter,
    SetHandbookRowsIds
} from "store/options/actions/actions-handbook";
import {THandbookFilter} from "models/handbook";
import {useLocation} from "react-router-dom";
import {nameHandbookStoreFilter} from "./handbook";

type TObj = {
    [key in any]: any
}

const Filter: TObj = {
    'internet': selectHandbookInternetResourceFilter,
    'content-delivery': selectHandbookContentDeliveryFilter,
    'actions-users': selectHandbookActionsUsersFilter,
    'content-direction': selectHandbookContentDirectionFilter,
    'organization': selectHandbookOrganizationFilter,
    'legal-form-organization': selectHandbookLegalFormFilter,
    'sessions-type': selectHandbookSessionsTypeFilter,
    'category-project': selectHandbookCategoryProjectFilter,
}

 const NAME_FILTER: TObj = {
    'internet': 'filterInternet',
    'content-delivery': 'filterContentDelivery',
    'actions-users': 'filterActionsUsers',
    'content-direction': 'filterContentDirection',
    'organization': 'filterOrganization',
    'legal-form-organization': 'filterLegalForm',
    'sessions-type': 'filterSessionsType',
    'category-project': 'filterCategoryProject',
 }

const useBulkAction = () => {
    const put = useDispatch()
    const { pathname } = useLocation()
    const type = pathname.split('/')[3]
    const bulkAction = useSelector(selectBulkAction)
    const rowsIds = useSelector(selectRowsIds)
    const handbookFilter = useSelector(Filter[type]) as THandbookFilter

    const rowSelection = useMemo(()=>{
        return {
            selectedRowKeys: rowsIds,
            onChange: (selectedRowKeys: React.Key[], _selectedRows: any[]) => {
                    put(SetHandbookRowsIds(selectedRowKeys as string[]))
            },
        }
    },[put, rowsIds])

    const clearFilter = ()=> {
        put(ClearHandbookFilter(nameHandbookStoreFilter[type]))
    }
    const handlerBulkActions = (e: any) => {
        if (e === 'SHOW') {
            const nameFilter = NAME_FILTER[type]
            put(SetHandbookFilter({ [nameFilter]: {...handbookFilter,filterData: {...handbookFilter.filterData, hidden: true}} }))
        }else if (e === 'HIDE') {
            const nameFilter = NAME_FILTER[type]
            put(SetHandbookFilter({ [nameFilter]: {...handbookFilter,filterData: {...handbookFilter.filterData, hidden: false}} }))
        } else {
            const nameFilter = NAME_FILTER[type]
            put(SetHandbookFilter({ [nameFilter]: {...handbookFilter,filterData: {...handbookFilter.filterData, hidden: null}} }))
        }
        put(SetHandbookBulkAction(e))
    }

    return {
        rowSelection,
        bulkAction,
        rowsIds,
        handlerBulkActions,
        clearFilter
    }
}

export default useBulkAction
