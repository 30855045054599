import { put } from 'redux-saga/effects'
import { NotificationOpen } from 'store/notification/actions'
import CreateErrorMessage from 'utils/validation/createErrorMessage'
import {
	GetHandbookStartAction,
	UpdateCategoryProjectErrorAction,
	UpdateCategoryProjectStartAction,
	UpdateCategoryProjectSuccessAction,
} from 'store/options/actions/actions-handbook'
import { TStatusesHandbookFeels } from 'models/handbook'
import CategoryProjectService from 'services/categoryProjectService'

export function* updateCategoryProject({ id, data }: ReturnType<typeof UpdateCategoryProjectStartAction>) {
	try {
		yield CategoryProjectService.updateCategoryProjectRecord(data, id)
		yield put(UpdateCategoryProjectSuccessAction())
		yield put(GetHandbookStartAction(TStatusesHandbookFeels.categoryProject))
		yield put(NotificationOpen('success', 'Формат обновлен'))
	} catch (error: any) {
		yield put(UpdateCategoryProjectErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось обновить формат'))
	}
}
