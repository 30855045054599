import React, {FC} from 'react'
import {useMutation, useQuery} from 'react-query'
import {useDispatch, useSelector} from 'react-redux'
import styles from './styles.module.less'
import {Form, Formik, FormikProps} from 'formik'
import {Button, Spin} from 'antd'
import {ModalEvent} from 'shared/ui/modal'
import {
	ContentFormatType,
	ContentTiming,
	DateComponent,
	FirstAssignmentTags,
	FirstTags,
	MaterialType,
	NameContent,
	NameDownLoadFile,
	Regions,
	SecondAssignmentTags,
	SecondTags,
	SubmissionForm,
	ThirdTags,
	ZeroTags
} from './items'
//models
import {TypeContent} from 'models/content'
import {IOptions} from 'models/feels'
import {TTaskRecord} from 'models/task/task-content'
//shared
import Banner from 'shared/ui/banner'
import {useErrorNotification} from 'shared/lib/error-notification'
import replaceQuotesInString from 'shared/lib/replaceQuotesInString'
import {validateTime} from 'shared/lib/format-time'
//store
import {NotificationOpen} from 'store/notification/actions'
import {GetContentStartAction} from 'store/content/content/actions'
//others
import {calculateTotalSeconds, secondsToTimeString} from 'utils/convertTime'
import TasksContentService from 'services/tasksContentService'
import {validation} from './validation'
import {transformArrayIdNameForValue} from 'utils/transformObjectFeels'
import {Moment} from 'moment/moment'
import BannerComponent from './components/banner'
import {selectRole} from "store/auth/selectors";
import WhetherTheUser from "components/whetherTheUser";
import {getMaterialTypeValue} from "./utils";
import {TextAreaQuill} from "components/formic-control";

export type TInitValues = {
	date: string | Date | Moment
	isMasterFile: boolean
	name: string
	description: string
	submissionForm: IOptions | null
	contentFormatType: IOptions | null
	nameFile: string
	regionsFor: IOptions[]
	regionsAbout: IOptions[]
	zeroTags: IOptions[]
	firstTags: IOptions[]
	firstAssignmentTags: IOptions[]
	secondTags: IOptions[]
	secondAssignmentTags: IOptions[]
	thirdTags: IOptions[]
	contentTiming: string
	isContent: boolean
	isAccountedContent: boolean
	disabled: boolean
	disabledForRoles: boolean
	materialType?: IOptions
}

type TProps = {
	el: TypeContent
}
const ModalContentRecords: FC<TProps> = ({ el }) => {
	const put = useDispatch()
	const errorFunction = useErrorNotification('Не удалось обновить')
	const role = useSelector(selectRole)

	const { data: record } = useQuery({
		queryKey: [TasksContentService.getTaskRecordKey(el.id)],
		queryFn: async () => {
			const { data } = await TasksContentService.getTaskRecord(el.id)
			return data
		},
	})

	const { mutate: sendVote, isLoading: isLoadingVote } = useMutation({
		mutationFn: () =>
			TasksContentService.voteTaskRecord({
				vote: {
					comment: null,
					isApproved: true,
					recordId: record?.id || '',
					isInterimApproved: null
				},
				files: [],
			}),
		onSuccess: () => {
			put(GetContentStartAction())
			put(NotificationOpen('success', 'Данные обновлены'))
			ModalEvent.close()
		},
		onError: errorFunction,
	})
	const isReady = record?.task?.status?.code === 'READY'
	const isReadyForCustomerArchival = record?.status === 'READY_FOR_CUSTOMER_VERIFICATION' && record?.task?.archival

	const close = () => {
		if (isReadyForCustomerArchival) sendVote()
		else {
			put(GetContentStartAction())
			ModalEvent.close()
		}
	}
	const { mutate, isLoading } = useMutation({
		mutationFn: (data: TTaskRecord) => TasksContentService.updateTaskRecord(el.id, data),
		onSuccess: () => {
			close()
			put(NotificationOpen('success', 'Данные обновлены'))
		},
		onError: errorFunction,
	})

	const initValues: TInitValues = {
		date: record?.dateTime ? record?.dateTime : '',
		isMasterFile: !!record?.isMasterFile,
		name: record?.name || '',
		description: record?.description || '',
		contentFormatType: record?.contentFormatType
			? { value: record.contentFormatType.id, label: record.contentFormatType.name }
			: null,
		submissionForm: record?.submissionForm
			? { value: record.submissionForm.id, label: record.submissionForm.name }
			: null,
		nameFile: record?.file?.name || '',
		regionsFor: record?.regionForSet.length ? transformArrayIdNameForValue(record.regionForSet) : [],
		regionsAbout: record?.regionAboutSet.length ? transformArrayIdNameForValue(record.regionAboutSet) : [],
		zeroTags: record?.secondLevelTags.length ? transformArrayIdNameForValue(record.zeroLevelTags) : [],
		firstTags: record?.secondLevelTags.length ? transformArrayIdNameForValue(record.firstLevelTags) : [],
		firstAssignmentTags: record?.firstLevelAssignmentTags.length ? transformArrayIdNameForValue(record.firstLevelAssignmentTags) : [],
		secondTags: record?.secondLevelTags.length ? transformArrayIdNameForValue(record.secondLevelTags) : [],
		secondAssignmentTags: record?.secondLevelAssignmentTags.length ? transformArrayIdNameForValue(record.secondLevelAssignmentTags) : [],
		thirdTags: record?.thirdLevelTags.length ? transformArrayIdNameForValue(record.thirdLevelTags) : [],
		contentTiming: record?.contentTiming ? secondsToTimeString(record?.contentTiming || 0) : '',
		isContent: !!record?.isContent ,
		isAccountedContent: !!record?.isAccountedContent,
		materialType: getMaterialTypeValue({isContent: !!record?.isContent, isAccountedContent: !!record?.isAccountedContent}),
		disabled: !!isReady,
		disabledForRoles: role !== 'ROLE_ADMIN' && role !== 'ROLE_ADMIN_IS' && role !== 'ROLE_MODERATOR'
	}

	const onSubmit = (data: TInitValues) => {
		let digitsOnly = data.contentTiming.replace(/\D/g, '')
		const hours = digitsOnly.slice(0, 2)
		const minutes = validateTime(digitsOnly.slice(2, 4))
		const seconds = validateTime(digitsOnly.slice(4, 6))
		const secondsTime = calculateTotalSeconds(hours, minutes, seconds)
		const values: TTaskRecord = {
			dateTime: data.date ? data.date : '',
			isMasterFile: true,
			name: data.name,
			description: replaceQuotesInString(data.description) || null,
			contentTiming: data.contentTiming ? secondsTime : null,
			fileId: el.file.id,
			submissionFormId: data.submissionForm?.value ? data.submissionForm?.value : '',
			contentFormatTypeId: data.contentFormatType?.value ? data.contentFormatType?.value : '',
			zeroTagIds: data.zeroTags.length ? data.zeroTags.map((it) => it.value) : [],
			firstTagIds: data.firstTags.length ? data.firstTags.map((it) => it.value) : [],
			secondTagIds: data.secondTags.length ? data.secondTags.map((it) => it.value) : [],
			thirdTagIds: data.thirdTags.length ? data.thirdTags.map((it) => it.value) : [],
			firstAssignmentTagIds: data.firstAssignmentTags.length ? data.firstAssignmentTags.map((it) => it.value) : [],
			secondAssignmentTagIds: data.secondAssignmentTags.length ? data.secondAssignmentTags.map((it) => it.value) : [],
			regionForIds: data.regionsFor.length ? data.regionsFor.map((it) => it.value) : [],
			regionAboutIds: data.regionsAbout.length ? data.regionsAbout.map((it) => it.value) : [],
			isContent: data?.isContent,
			isAccountedContent: data?.isAccountedContent,
			timeCodes: record?.timeCodes
		}
		mutate(values)
	}

	return (
		<Formik
			enableReinitialize
			initialValues={initValues}
			onSubmit={onSubmit}
			validationSchema={validation}
			validateOnMount={true}
		>
			{(formik: FormikProps<any>) => {
				return (
					<>
						<Spin spinning={isLoading || isLoadingVote}>
							<Form className={styles.container}>
								<div className={styles.header}>
									<span className={styles.title}>Редактирование единиц контента проекта</span>
								</div>
								<hr />
								{(isReady) && (
									<Banner>
										<BannerComponent record={record} el={el} />
									</Banner>
								)}
								<DateComponent />
								<MaterialType />
								<NameContent />
								<TextAreaQuill
									label={'Описание единицы контента'}
									name={'description'}
									placeholder="Описание контента"
								/>
								<ContentFormatType />
								<SubmissionForm />
								<ContentTiming />
								<NameDownLoadFile />
								<ZeroTags />
								<FirstTags />
								<SecondTags />
								<ThirdTags />
								<WhetherTheUser role={['ROLE_CONTRACTOR', 'ROLE_VIEWER', 'ROLE_AGENT', 'ROLE_CLIENT']} isCan={false}>
									<>
										<FirstAssignmentTags/>
										<SecondAssignmentTags/>
									</>
								</WhetherTheUser>
								<Regions />

								<div className={styles.buttons}>
									<Button type={'default'} onClick={close} disabled={isReady}>
										Отменить
									</Button>
									<Button
										htmlType={'submit'}
										disabled={!formik.isValid || isReady}
										type={'primary'}
									>
										{isReadyForCustomerArchival ? 'Сохранить и согласовать запись' : 'Сохранить'}
									</Button>
								</div>
							</Form>
						</Spin>
					</>
				)
			}}
		</Formik>
	)
}
export default ModalContentRecords

