import React from 'react';
import { Spin } from 'antd';
import {
    CheckboxComponent,
    DatePickerComponent,
    InputComponent,
    InputNumberComponent,
    SelectComponent,
    TextAreaQuill
} from "components/formic-control";
import {useFormikContext} from "formik";
import {IOptions} from "models/feels";
import {useSelector} from "react-redux";
import useFeelsApp from "hooks/feelsApp";
import {TStatusesFeels} from "models/projects/projectFeels";
import {selectProjectsTypesOptions} from "store/projects/selectors";
import classNames from "classnames";
import styles from './step-first.module.less';
import {ProjectFormDto} from "features/create-or-edit-project-form/lib/dto/project-form.dto";
import Contest from "./contest";
import {selectUser} from "store/auth/selectors";
import WhetherTheUser from "components/whetherTheUser";

function filingName(key: string): string {
    return `stepFirst.${key}.value`
}

const StepFirst = () => {
    const user = useSelector(selectUser)
    const {values, errors} = useFormikContext<ProjectFormDto>();

    const {
        isLoading: isLoadingFeels,
        statuses,
    } = useFeelsApp([
        TStatusesFeels.statuses,
    ])
    const projectTypes = useSelector(selectProjectsTypesOptions)

    const form = values.stepFirst;
    const formErrors = errors.stepFirst;


    const handlerProjectType = (val: IOptions) => {
        if (val.code) {
            values.stepSecond.validateProjectType(val.code)
            values.stepThird.validateProjectType(val.code)
        }
    }

    return (
        <>
            <div className={styles.container}>

                <div className={classNames(styles.name_label, 'label')}>
                    Название проекта<sup>*</sup>
                </div>
                <div className={classNames(styles.name)}>
                    <InputComponent
                        type="text"
                        name={filingName('name')}
                        placeholder="Введите название проекта"
                        disabled={form.name.isDisabled}
                        errorMessage={errors.stepFirst?.name?.value as string}
                    />
                </div>

                <div className={classNames(styles.type_label, styles.text_right, 'label')}>
                    Категория проекта <sup>*</sup>
                </div>
                <div className={classNames(styles.type)}>
                    <SelectComponent
                        options={projectTypes}
                        name={`${filingName('projectType')}`}
                        placeholder={'Выберите тип проекта'}
                        disabled={form.projectType.isDisabled}
                        handler={handlerProjectType}
                    />
                </div>

                {/*-----------------*/}

                <div className={classNames(styles.requestNumber_label, 'label')}>
                    Номер заявки<sup>*</sup>
                </div>
                <div className={classNames(styles.requestNumber)}>
                    <InputComponent
                        type="text"
                        name={filingName('requestNumber')}
                        placeholder="Введите номер заявки"
                        disabled={form.requestNumber.isDisabled}
                        errorMessage={formErrors?.requestNumber?.value as string}
                    />
                </div>

                <div className={classNames(styles.budget_label, styles.text_right, 'label')}>
                    Бюджет<sup>*</sup>
                </div>
                <div className={classNames(styles.budget)}>
                    <InputNumberComponent
                        name={filingName('budget')}
                        placeholder="Введите бюджет проекта"
                        showError={false}
                        disabled={form.budget.isDisabled}
                        errorMessage={formErrors?.budget as string}
                    />
                </div>
                <div className={classNames(styles.promotionalBudget_label, styles.text_right, 'label')}>
                    Бюджет на продвижение
                </div>
                <WhetherTheUser role={['ROLE_CONTRACTOR']} isCan={false}>
                    <div className={classNames(styles.promotionalBudget)}>
                        <InputNumberComponent
                            name={filingName('promotionalBudget')}
                            placeholder="Введите бюджет на продвижение"
                            showError={false}
                            disabled={form.promotionalBudget.isDisabled}
                            errorMessage={formErrors?.budget as string}
                        />
                    </div>
                </WhetherTheUser>


                {/*-------------*/}

                <div className={classNames(styles.contractNumber_label, 'label')}>
                    Договор<sup>*</sup>
                </div>
                <div className={classNames(styles.contractNumber)}>
                    <InputComponent
                        type="text"
                        name={filingName('contractNumber')}
                        placeholder="Введите номер договора"
                        disabled={form.contractNumber.isDisabled}
                        errorMessage={errors.stepFirst?.contractNumber?.value as string}
                    />
                </div>

                <div className={classNames(styles.contractDate_label, styles.text_right, 'label')}>
                    Дата договора{!form.agreementDate.value && <sup>*</sup>}
                </div>
                <div className={classNames(styles.contractDate)}>
                    <DatePickerComponent
                        name={filingName('contractDate')}
                        disabled={form.contractDate.isDisabled}
                        errorMessage={formErrors?.contractDate as string}
                    />
                </div>

                <div className={classNames(styles.agreementDate_label, styles.text_right, 'label')}>
                    Дата Соглашения{!form.contractDate.value && <sup>*</sup>}
                </div>
                <div className={classNames(styles.agreementDate)}>
                    <DatePickerComponent
                        name={filingName('agreementDate')}
                        disabled={form.agreementDate.isDisabled}
                        errorMessage={formErrors?.agreementDate as string}
                    />
                </div>

                {user?.privateProjectViewAccess && (
                    <>
                        <div className={classNames(styles.isPrivateProject_label, 'label')}>
                            Конфиденциальный проект
                        </div>
                        <div className={classNames(styles.isPrivateProject)}>
                            <CheckboxComponent
                                name={filingName('isPrivateProject')}
                                disabled={form.isPrivateProject.isDisabled}
                                label={`${form.isPrivateProject.value ? '' : 'не '}является конфиденциальным проектом`}
                            />
                        </div>
                    </>
                )}
                <div className={classNames(styles.igk_label, styles.text_right, 'label')}>
                    ИГК<sup>*</sup>
                </div>
                <div className={classNames(styles.igk)}>
                    <InputComponent
                        type="text"
                        name={filingName('igk')}
                        placeholder="Введите № ИГК"
                        disabled={form.igk.isDisabled}
                        errorMessage={formErrors?.igk?.value || formErrors?.igk as string}
                        prefix={form.igk.value?.length}
                    />
                </div>

                <div className={classNames(styles.advancePayment_label)}>
                    <div className={classNames('label')}>Авансирование</div>
                    <CheckboxComponent
                        name={filingName('advancePayment')}
                        disabled={form.advancePayment.isDisabled}
                    />
                </div>


                {/*----------------*/}
                <Contest/>
                {/*-------*/}

                <div className={classNames(styles.goal_label, 'label')}>
                    Цель проекта
                </div>
                <div className={classNames(styles.goal)}>
                    <TextAreaQuill
                        name={filingName('goal')}
                        placeholder="Введите цель проекта"
                        disabled={form.goal.isDisabled}
                        limit={1200}
                        errorMessage={formErrors?.goal as string}
                    />
                </div>

                {/*--------*/}

                <div className={classNames(styles.description_label, 'label')}>
                    Описание проекта
                </div>
                <div className={classNames(styles.description)}>
                    <TextAreaQuill
                        name={filingName('description')}
                        placeholder="Введите описание проекта"
                        disabled={form.description.isDisabled}
                        limit={5000}
                        errorMessage={formErrors?.description as string}
                    />
                </div>

                {/*-----------*/}

                <div className={classNames(styles.status_label, 'label')}>
                    Статус
                </div>
                <div className={classNames(styles.status)}>
                    <Spin spinning={isLoadingFeels}>
                        <SelectComponent
                            options={statuses}
                            placeholder={'Не определено'}
                            name={filingName('status')}
                            disabled={true}
                            disabledOptions={form.statusDisabledOptions}
                        />
                    </Spin>
                </div>

                <div className={classNames(styles.completionDate_label, styles.text_right, 'label')}>
                    Дата завершения проекта<sup>*</sup>
                </div>
                <div className={classNames(styles.completionDate)}>
                    <DatePickerComponent
                        name={filingName('completionDate')}
                        disabled={!(form.contractDate.value || form.agreementDate.value) || form.completionDate.isDisabled}
                        errorMessage={formErrors?.completionDate as string}
                        isAfter={true}
                        isAfterDate={form.contractDate.value}
                    />
                </div>


            </div>

        </>

    );
};

export default React.memo(StepFirst);
