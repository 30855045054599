import {Nullable} from "models/common";

type User = {
	firstName: Nullable<string>;
	lastName: Nullable<string>;
	middleName: Nullable<string>;
};
type UserTextProps = User | Record<keyof User, Nullable<string>>;
export function getUserText({ firstName, lastName, middleName }: UserTextProps) {
	if (!firstName && !lastName && !middleName) return '—';
	return `${lastName || ''} ${firstName || ''} ${middleName || ''}`;
}

