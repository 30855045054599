import {AxiosResponse} from "axios";
import $api from "../http";
import {
    TReachStatistic,
    TTaskCopyrightNew,
    TTaskEstimateCost,
    TTaskEstimateNew,
    TTaskPostingNew,
    TTaskReach,
    TTaskReachNew,
    TTaskReportFilter
} from "../models/task/task-report";
import {TTaskRecordsFilter} from "../models/task/task-list";
import {UploadFile} from "antd/es/upload/interface";
import {TaskTypes} from "entities/task";

/**
 * @category Service
 * @subcategory Tasks
 * @class TasksReportPostingService
 */
class TasksReportPostingService {
    /**
     * Добавление записи для задачи на публикацию контента
     */
    static async addTaskReport(taskId: string): Promise<AxiosResponse<string>> {
        return $api.post<string>(`/tasks/posting/${taskId}/records`, undefined, {
            headers: {
                'Actioncode': 'CREATE_TASKS_POSTING_TASK_ID_RECORDS'
            }
        })
    }
    /**
     * Обновление записи для задачи на публикацию контента
     */
    static async updateTaskReport(recordId: string, data: TTaskPostingNew): Promise<AxiosResponse<any>> {
        const formData = new FormData();
        const oldImages: string[] = [];
        const newImages: UploadFile[] = [];
        data.screenshots?.forEach((el: UploadFile) => {
            if (el.status === 'uploading' || el.status === 'done') {
                oldImages.push(el.uid);
            } else newImages.push(el)
        })
        const record = {
            'dateTime': data.dateTime,
            'publishedName': data.publishedName,
            'ageCategory': data.ageCategory || null,
            'link': data.link,
            'screenshotDate': data.screenshotDate,
            'contentUnitDimension': data.contentUnitDimension,
            'resourceId': data.resourceId,
            'workingName': data.workingName,
            'planPublicationDate': data.planPublicationDate,
            'actualPublicationDate': data.actualPublicationDate,
            'screenshotIds': oldImages.length ? oldImages : null,
            'contentTaskRecordId': data.contentTaskRecordId
        } as Omit<TTaskPostingNew, 'screenshot'>;
        formData.append('record', JSON.stringify(record))

        if (newImages.length) {
            newImages.forEach((el) => {
                formData.append('screenshots', el.originFileObj as Blob)
            })
        }

        return $api({
            method: 'PUT',
            url: `/tasks/posting/records/${recordId}`,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data: formData
        })
    }
    /**
     * Удаление записей задачи на публикацию контента
     */
    static async deleteTaskReport(recordId: string): Promise<AxiosResponse<any>> {
        return $api.delete<any>(`/tasks/posting/records/${recordId}`)
    }
    /**
     * Голосование за запись
     */
    static async voteTaskReport({files, ...rest}: TaskTypes.NewVote): Promise<AxiosResponse<string>> {
        const formData = new FormData();
        formData.append('vote', JSON.stringify(rest.vote));
        files?.forEach((el) => {
            formData.append('files', el as unknown as Blob)
        })
        return $api.post<string>('/tasks/posting/records/vote', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    }
    /**
     * Удаление промежуточного голоса текущего пользователя по записи задачи
     */
    static async deleteInterimVoteRecord(recordId: string): Promise<AxiosResponse<any>> {
        return $api.delete<any>(`/tasks/posting/records/${recordId}/vote`, {
            headers: {
                'Actioncode': 'DELETE_TASKS_POSTING_RECORDS_RECORD_ID_VOTE'
            }
        })
    }
    /**
     * Отправка записи на проверку
     */
    static async ToExaminationTaskReport(recordId: string): Promise<AxiosResponse<string>> {
        return $api.put<string>(`/tasks/posting/records/${recordId}/to-examination`, undefined, {
            headers: {
                'Actioncode': 'UPDATE_TASKS_POSTING_RECORDS_RECORD_ID_TO_EXAMINATION'
            }
        })
    }

    /**
     * Получение списка записей задач
     */
    static async getTaskRecords(filterProps: TTaskRecordsFilter): Promise<AxiosResponse<TaskTypes.TaskRecordsResponse<TaskTypes.PostingRecords[]>>> {
        const filter = {...filterProps, page: filterProps.page - 1};
        return $api.post<TaskTypes.TaskRecordsResponse<TaskTypes.PostingRecords[]>>('/tasks/posting/records/search', filter)
    }
    /**
     * Получение одной записи задачи
     */
    static async getRecord(recordId: string): Promise<AxiosResponse<TaskTypes.PostingRecords>> {
        return $api.get<TaskTypes.PostingRecords>(`/tasks/posting/records/search/${recordId}`)
    }
    /**
     * Сброс итогов голосования финального статуса записи
     */
    static async resetTaskRecordsStatus(recordId: string): Promise<AxiosResponse<any>> {
        return $api.put<any>(`/tasks/posting/records/${recordId}/status-reset`, undefined, {
            headers: {
                'Actioncode': 'UPDATE_TASKS_POSTING_RECORDS_RECORD_ID_STATUS_RESET'
            }
        })
    }
    /**
     * Массовое голосование за записи
     */
    static async sendBulkVote(data: TaskTypes.NewVote['vote'][]): Promise<AxiosResponse<any>> {
        return $api.post<any>('/tasks/posting/records/bulk-vote', data)
    }
    /**
     * Массовая отправка записей на проверку
     */
    static async sendBulkReview(data: string[]): Promise<AxiosResponse<any>> {
        return $api.put<any>('/tasks/posting/records/bulk/to-examination', data)
    }
    /**
     *   Cкачать шаблон
     */
    static async downloadTemplate(rowIds: string[]): Promise<AxiosResponse<any>> {
        return  $api({
            method: 'POST',
            url:  '/tasks/posting/records/import/template',
            responseType:'arraybuffer',
            data: rowIds
        })
    }
    /**
     *   Импорт данных из заполненного шаблона в формате Excel
     */
    static async uploadTemplate (data: any): Promise<AxiosResponse> {
        return $api({
            method: 'POST',
            url: `/tasks/posting/records/import`,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data,
        })
    }
    /**
     *   Выполнение выгрузки списка в формате Excel
     */
    static async recordsExport(usersIds: string[]): Promise<AxiosResponse> {
        return $api({
            method: 'POST',
            url: '/tasks/posting/records/export',
            responseType:'arraybuffer',
            headers: {
                'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
            data: usersIds,
        })
    }
}

/**
 * @category Service
 * @subcategory Tasks
 * @class TasksReportEstimateService
 * @description  Сметный отчет записи задач
 */
class TasksReportEstimateService {
    /**
     * Добавление записи для задачи сметы проекта
     */
    static async addTaskReport(taskId: string): Promise<AxiosResponse<string>> {
        return $api.post<string>(`/tasks/estimate/${taskId}/records`, undefined, {
            headers: {
                'Actioncode': 'CREATE_TASKS_ESTIMATE_TASK_ID_RECORDS'
            }
        })
    }
    /**
     * Получение списка записей задачи по статье расходов
     */
    static async getAllTaskCostReport(taskId: string, filter: TTaskReportFilter): Promise<AxiosResponse<TTaskEstimateCost[]>> {
        return $api.post<TTaskEstimateCost[]>(`/tasks/estimate/${taskId}/cost-types/records`, filter, {
            headers: {
                'Actioncode': 'POST_TASKS_ESTIMATE_TASK_ID_COST_TYPES_RECORDS'
            }
        })
    }
    /**
     * Обновление записи для задачи сметы проекта
     */
    static async updateTaskReport(recordId: string, data: TTaskEstimateNew): Promise<AxiosResponse<any>> {
        return $api({
            method: 'PUT',
            url: `/tasks/estimate/records/${recordId}`,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: data
        })
    }
    /**
     * Удаление записей задачи сметы проекта
     */
    static async deleteTaskReport(recordId: string,): Promise<AxiosResponse<any>> {
        return $api.delete<any>(`/tasks/estimate/records/${recordId}`)
    }
    /**
     * Голосование за запись
     */
    static async voteTaskReport({files, ...rest}: TaskTypes.NewVote): Promise<AxiosResponse<string>> {
        const formData = new FormData();
        formData.append('vote', JSON.stringify(rest.vote));
        files?.forEach((el) => {
            formData.append('files', el as unknown as Blob)
        })
        return $api.post<string>('/tasks/estimate/records/vote', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    }
    /**
     * Удаление промежуточного голоса текущего пользователя по записи задачи
     */
    static async deleteInterimVoteRecord(recordId: string): Promise<AxiosResponse<any>> {
        return $api.delete<any>(`/tasks/estimate/records/${recordId}/vote`,{
            headers: {
                'Actioncode': 'DELETE_TASKS_ESTIMATE_RECORDS_RECORD_ID_VOTE'
            }
        })
    }
    /**
     * Отправка записи на проверку
     */
    static async ToExaminationTaskReport(recordId: string): Promise<AxiosResponse<string>> {
        return $api.put<string>(`/tasks/estimate/records/${recordId}/to-examination`, undefined, {
            headers: {
                'Actioncode': 'UPDATE_TASKS_ESTIMATE_RECORDS_RECORD_ID_TO_EXAMINATION'
            }
        })
    }

    /**
     * Получение списка записей задач
     */
    static async getTaskRecords(data: TTaskRecordsFilter): Promise<AxiosResponse<TaskTypes.TaskRecordsResponse<TaskTypes.EstimateRecords[]>>> {
        const filter = {...data, page: data.page - 1};
        return $api.post<TaskTypes.TaskRecordsResponse<TaskTypes.EstimateRecords[]>>(`/tasks/estimate/records/search`, filter)
    }
    /**
     * Получение одной записи задачи
     */
    static async getRecord(recordId: string): Promise<AxiosResponse<TaskTypes.EstimateRecords>> {
        return $api.get<TaskTypes.EstimateRecords>(`/tasks/estimate/records/search/${recordId}`)
    }
    /**
     * Сброс итогов голосования финального статуса записи
     */
    static async resetTaskRecordsStatus(recordId: string): Promise<AxiosResponse<any>> {
        return $api.put<any>(`/tasks/estimate/records/${recordId}/status-reset`, undefined, {
            headers: {
                'Actioncode': 'UPDATE_TASKS_ESTIMATE_RECORDS_RECORD_ID_STATUS_RESET'
            }
        })
    }
    /**
     * Массовое голосование за записи
     */
    static async sendBulkVote(data: TaskTypes.NewVote['vote'][]): Promise<AxiosResponse<any>> {
        return $api.post<any>('/tasks/estimate/records/bulk-vote', data)
    }
    /**
     * Массовая отправка записей на проверку
     */
    static async sendBulkReview(data: string[]): Promise<AxiosResponse<any>> {
        return $api.put<any>('/tasks/estimate/records/bulk/to-examination', data)
    }
    /**
     * Получение файлов апрувнутых записей задачи сметы проекта
     */
    static async getFileArchive(id: string): Promise<AxiosResponse<any>> {
        return $api({
            method: 'POST',
            url: `/tasks/estimate/cost-types/records/file-archive-request`,
            responseType:'arraybuffer',
            params: {
                projectId: id
            }
        })
    }
    /**
     *   Cкачать шаблон
     */
    static async downloadTemplate(rowIds: string[]): Promise<AxiosResponse<any>> {
        return  $api({
            method: 'POST',
            url:  '/tasks/estimate/records/import/template',
            responseType:'arraybuffer',
            data: rowIds
        })
    }
    /**
     *   Импорт данных из заполненного шаблона в формате Excel
     */
    static async uploadTemplate (data: any): Promise<AxiosResponse> {
        return $api({
            method: 'POST',
            url: `/tasks/estimate/records/import`,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data,
        })
    }
    /**
     *   Выполнение выгрузки списка в формате Excel
     */
    static async recordsExport(usersIds: string[]): Promise<AxiosResponse> {
        return $api({
            method: 'POST',
            url: '/tasks/estimate/records/export',
            responseType:'arraybuffer',
            headers: {
                'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
            data: usersIds,
        })
    }
}

/**
 * Задачи авторских прав
 */
class TasksReportCopyrightService {
    /**
     * Добавление записи для задачи авторских прав
     */
    static async addTaskReport(taskId: string): Promise<AxiosResponse<string>> {
        return $api.post<string>(`/tasks/copyright/${taskId}/records`, undefined, {
            headers: {
                'Actioncode': 'CREATE_TASKS_COPYRIGHT_TASK_ID_RECORDS'
            }
        })
    }
    /**
     * Обновление записи для задачи авторских прав
     */
    static async updateTaskReport(recordId: string, data: TTaskCopyrightNew): Promise<AxiosResponse<any>> {
        return $api.put<any>(`/tasks/copyright/records/${recordId}`, data)
    }
    /**
     * Удаление записей задачи авторских прав
     */
    static async deleteTaskReport(recordId: string,): Promise<AxiosResponse<any>> {
        return $api.delete<any>(`/tasks/copyright/records/${recordId}`)
    }
    /**
     * Голосование за запись
     */
    static async voteTaskReport({files, ...rest}: TaskTypes.NewVote): Promise<AxiosResponse<string>> {
        const formData = new FormData();
        formData.append('vote', JSON.stringify(rest.vote));
        files?.forEach((el) => {
            formData.append('files', el as unknown as Blob)
        })
        return $api.post<string>('/tasks/copyright/records/vote', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    }
    /**
     * Удаление промежуточного голоса текущего пользователя по записи задачи
     */
    static async deleteInterimVoteRecord(recordId: string): Promise<AxiosResponse<any>> {
        return $api.delete<any>(`/tasks/copyright/records/${recordId}/vote`,{
            headers: {
                'Actioncode': 'DELETE_TASKS_COPYRIGHT_RECORDS_RECORD_ID_VOTE'
            }
        })
    }
    /**
     * Отправка записи на проверку
     */
    static async ToExaminationTaskReport(recordId: string): Promise<AxiosResponse<string>> {
        return $api.put<string>(`/tasks/copyright/records/${recordId}/to-examination`, undefined, {
            headers: {
                'Actioncode': 'UPDATE_TASKS_COPYRIGHT_RECORDS_RECORD_ID_TO_EXAMINATION'
            }
        })
    }

    /**
     * Получение списка записей задач
     */
    static async getTaskRecords(data: TTaskRecordsFilter): Promise<AxiosResponse<TaskTypes.TaskRecordsResponse<TaskTypes.CopyrightRecords[]>>> {
        const filter = {...data, page: data.page - 1};
        return $api.post<TaskTypes.TaskRecordsResponse<TaskTypes.CopyrightRecords[]>>(`/tasks/copyright/records/search`, filter)
    }
    /**
     * Получение одной записи задачи
     */
    static async getRecord(recordId: string): Promise<AxiosResponse<TaskTypes.CopyrightRecords>> {
        return $api.get<TaskTypes.CopyrightRecords>(`/tasks/copyright/records/search/${recordId}`)
    }
    /**
     * Сброс итогов голосования финального статуса записи
     */
    static async resetTaskRecordsStatus(recordId: string): Promise<AxiosResponse<any>> {
        return $api.put<any>(`/tasks/copyright/records/${recordId}/status-reset`, undefined, {
            headers: {
                'Actioncode': 'UPDATE_TASKS_COPYRIGHT_RECORDS_RECORD_ID_STATUS_RESET'
            }
        })
    }
    /**
     * Массовое голосование за записи
     */
    static async sendBulkVote(data: TaskTypes.NewVote['vote'][]): Promise<AxiosResponse<any>> {
        return $api.post<any>('/tasks/copyright/records/bulk-vote', data)
    }
    /**
     * Массовая отправка записей на проверку
     */
    static async sendBulkReview(data: string[]): Promise<AxiosResponse<any>> {
        return $api.put<any>('/tasks/copyright/records/bulk/to-examination', data)
    }
    /**
     *   Cкачать шаблон
     */
    static async downloadTemplate(rowIds: string[]): Promise<AxiosResponse<any>> {
        return  $api({
            method: 'POST',
            url:  '/tasks/copyright/records/import/template',
            responseType:'arraybuffer',
            data: rowIds
        })
    }
    /**
     *   Импорт данных из заполненного шаблона в формате Excel
     */
    static async uploadTemplate (data: any): Promise<AxiosResponse> {
        return $api({
            method: 'POST',
            url: `/tasks/copyright/records/import`,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data,
        })
    }
    /**
     *   Выполнение выгрузки списка в формате Excel
     */
    static async recordsExport(usersIds: string[]): Promise<AxiosResponse> {
        return $api({
            method: 'POST',
            url: '/tasks/copyright/records/export',
            responseType:'arraybuffer',
            headers: {
                'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
            data: usersIds,
        })
    }
}


/**
 * Отчёт об охвате размещённого контента
 */
class TasksReportReachService {
    /**
     * Обновление записи для задачи авторских прав
     */
    static async updateTaskReport(recordId: string, data: TTaskReachNew): Promise<AxiosResponse<any>> {
        const formData = new FormData();
        const {record, screenshots, counterScreenshots} = data;
        formData.append('record', JSON.stringify(record))
        screenshots?.forEach((el) => {
            formData.append('screenshots', el.originFileObj as Blob)
        })
        counterScreenshots?.forEach((el) => {
            formData.append('counterScreenshots', el.originFileObj as Blob)
        })
        return $api({
            method: 'PUT',
            url: `/tasks/reach/records/${recordId}`,
            withCredentials: true,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data: formData
        })
    }
    static async deleteTaskReport(recordId: string,): Promise<AxiosResponse<any>> {
        return $api.delete<any>(`/tasks/reach/records/${recordId}`)
    }
    /**
     * Голосование за запись
     */
    static async voteTaskReport({files, ...rest}: TaskTypes.NewVote): Promise<AxiosResponse<string>> {
        const formData = new FormData();
        formData.append('vote', JSON.stringify(rest.vote));
        files?.forEach((el) => {
            formData.append('files', el as unknown as Blob)
        })
        return $api.post<string>('/tasks/reach/records/vote', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    }
    /**
     * Удаление промежуточного голоса текущего пользователя по записи задачи
     */
    static async deleteInterimVoteRecord(recordId: string): Promise<AxiosResponse<any>> {
        return $api.delete<any>(`/tasks/reach/records/${recordId}/vote`, {
            headers: {
                'Actioncode': 'DELETE_TASKS_REACH_RECORDS_RECORD_ID_VOTE'
            }
        })
    }
    /**
     * Отправка записи на проверку
     */
    static async ToExaminationTaskReport(recordId: string): Promise<AxiosResponse<string>> {
        return $api.put<string>(`/tasks/reach/records/${recordId}/to-examination`, undefined, {
            headers: {
                'Actioncode': 'UPDATE_TASKS_REACH_RECORDS_RECORD_ID_TO_EXAMINATION'
            }
        })
    }

    /**
     * Получение списка записей задач
     */
    static async getTaskRecords(data: TTaskRecordsFilter): Promise<AxiosResponse<TaskTypes.TaskRecordsResponse<TTaskReach[]>>> {
        const filter = {...data, page: data.page - 1};
        return $api.post<TaskTypes.TaskRecordsResponse<TTaskReach[]>>('/tasks/reach/records/search', filter)
    }
    /**
     * Получение одной записи задачи
     */
    static async getRecord(recordId: string): Promise<AxiosResponse<TaskTypes.ReachRecords>> {
        return $api.get<TaskTypes.ReachRecords>(`/tasks/reach/records/search/${recordId}`)
    }
    /**
     * Сброс итогов голосования финального статуса записи
     */
    static async resetTaskRecordsStatus(recordId: string): Promise<AxiosResponse<any>> {
        return $api.put<any>(`/tasks/reach/records/${recordId}/status-reset`, undefined, {
            headers: {
                'Actioncode': 'UPDATE_TASKS_REACH_RECORDS_RECORD_ID_STATUS_RESET'
            }
        })
    }
    /**
     * Массовое голосование за записи
     */
    static async sendBulkVote(data: TaskTypes.NewVote['vote'][]): Promise<AxiosResponse<any>> {
        return $api.post<any>('/tasks/reach/records/bulk-vote', data)
    }
    /**
     * Массовая отправка записей на проверку
     */
    static async sendBulkReview(data: string[]): Promise<AxiosResponse<any>> {
        return $api.put<any>('/tasks/reach/records/bulk/to-examination', data)
    }

    /**
     * Получение списка записей задач на охваты, связанных с той же записью на размещение, что и указанная запись
     */
    static async getReachStatistic (id: string,reachDateTime: string): Promise<AxiosResponse<TReachStatistic[]>> {
        return $api.get<any>(`/tasks/reach/records/${id}/statistic`,{
            params: {
                reachDateTime
            },
            headers: {
                'Actioncode': 'GET_TASKS_REACH_RECORDS_RECORD_ID_STATISTIC'
            }
        })
    }
    /**
     *   Cкачать шаблон
     */
    static async downloadTemplate(rowIds: string[]): Promise<AxiosResponse<any>> {
        return  $api({
            method: 'POST',
            url:  '/tasks/reach/records/import/template',
            responseType:'arraybuffer',
            data: rowIds
        })
    }
    /**
     *   Импорт данных из заполненного шаблона в формате Excel
     */
    static async uploadTemplate (data: any): Promise<AxiosResponse> {
        return $api({
            method: 'POST',
            url: `/tasks/reach/records/import`,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data,
        })
    }
    /**
     *   Выполнение выгрузки списка в формате Excel
     */
    static async recordsExport(usersIds: string[]): Promise<AxiosResponse> {
        return $api({
            method: 'POST',
            url: '/tasks/reach/records/export',
            responseType:'arraybuffer',
            headers: {
                'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
            data: usersIds,
        })
    }
}

export {
    TasksReportPostingService,
    TasksReportEstimateService,
    TasksReportCopyrightService,
    TasksReportReachService,
}
