import { z } from 'zod'
import { FilterRequest, Id_Name_Code_Hidden_Schema, SearchResponse } from 'shared/types/common'

export const RegionSchema = Id_Name_Code_Hidden_Schema

export type Region = z.infer<typeof RegionSchema>

export type RegionRequest = {
    code: string | null
    name: string
    hidden: boolean
}

export type FilterData = {
    searchString?: string
    hidden?: boolean
}

export type Regions = SearchResponse<Region>
export type Filter = FilterRequest<FilterData>
