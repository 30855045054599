export const OptionsGeneralActionTypes = {
	CLEAR_HANDBOOK_STORE: '[OPTIONS] CLEAR_HANDBOOK_STORE',
	CLEAR_HANDBOOK_FILTER: '[OPTIONS] CLEAR_HANDBOOK_FILTER',
	CLEAR_HANDBOOK_ERROR: '[OPTIONS] CLEAR_HANDBOOK_ERROR',

	GET_CONTENT_MODULE_SECTION_START: '[OPTIONS] GET_CONTENT_MODULE_SECTION_START',
	GET_CONTENT_MODULE_SECTION_SUCCESS: '[OPTIONS] GET_CONTENT_MODULE_SECTION_SUCCESS',
	GET_CONTENT_MODULE_SECTION_ERROR: '[OPTIONS] GET_CONTENT_MODULE_SECTION_ERROR',

	SET_BULK_ACTION: '[OPTIONS] SET_BULK_ACTION',
	SET_ROWS_IDS: '[OPTIONS] SET_ROWS_IDS',
} as const
export const UsersActionTypes = {
	CLEAR_USERS_ERROR: '[USERS] CLEAR_USERS_ERROR',
	CLEAR_USERS_IS_SUCCESS: '[USERS] CLEAR_USERS_IS_SUCCESS',

	GET_USERS_LIST_START: '[USERS] GET_USERS_LIST_START',
	GET_USERS_LIST_SUCCESS: '[USERS] GET_USERS_LIST_SUCCESS',
	GET_USERS_LIST_ERROR: '[USERS] GET_USERS_LIST_ERROR',

	GET_USER_START: '[USERS] GET_USER_START',
	GET_USER_SUCCESS: '[USERS] GET_USER_SUCCESS',
	GET_USER_ERROR: '[USERS] GET_USER_ERROR',

	SEND_USER_DETAILS_START: '[USERS] SEND_USER_DETAILS_START',
	SEND_USER_DETAILS_SUCCESS: '[USERS] SEND_USER_DETAILS_SUCCESS',
	SEND_USER_DETAILS_ERROR: '[USERS] SEND_USER_DETAILS_ERROR',

	SET_USERS_FILTER: '[USERS] SET_USERS_FILTER',

	CREATE_USER_START: '[USERS] CREATE_USER_START',
	CREATE_USER_SUCCESS: '[USERS] CREATE_USER_SUCCESS',
	CREATE_USER_ERROR: '[USERS] CREATE_USER_ERROR',

	UPDATE_USER_START: '[USERS] UPDATE_USER_START',
	UPDATE_USER_SUCCESS: '[USERS] UPDATE_USER_SUCCESS',
	UPDATE_USER_ERROR: '[USERS] UPDATE_USER_ERROR',

	UPDATE_PERSONAL_DATA_START: '[USERS] UPDATE_PERSONAL_DATA_START',
	UPDATE_PERSONAL_DATA_SUCCESS: '[USERS] UPDATE_PERSONAL_DATA_SUCCESS',
	UPDATE_PERSONAL_DATA_ERROR: '[USERS] UPDATE_PERSONAL_DATA_ERROR',

	DELETE_USER_START: '[USERS] DELETE_USER_START',
	DELETE_USER_SUCCESS: '[USERS] DELETE_USER_SUCCESS',
	DELETE_USER_ERROR: '[USERS] DELETE_USER_ERROR',

	DELETE_USERS_START: '[USERS] DELETE_USERS_START',
	DELETE_USERS_SUCCESS: '[USERS] DELETE_USERS_SUCCESS',
	DELETE_USERS_ERROR: '[USERS] DELETE_USERS_ERROR',

	CHANGE_USER_STATUS_START: '[USERS] CHANGE_USER_STATUS_START',
	CHANGE_USER_STATUS_SUCCESS: '[USERS] CHANGE_USER_STATUS_SUCCESS',
	CHANGE_USER_STATUS_ERROR: '[USERS] CHANGE_USER_STATUS_ERROR',

	CHANGE_PASSWORD_START: '[USERS] CHANGE_PASSWORD_START',
	CHANGE_PASSWORD_SUCCESS: '[USERS] CHANGE_PASSWORD_SUCCESS',
	CHANGE_PASSWORD_ERROR: '[USERS] CHANGE_PASSWORD_ERROR',

	GET_RELATED_USERS_START: '[USERS] GET_RELATED_USERS_START',
	GET_RELATED_USERS_SUCCESS: '[USERS] GET_RELATED_USERS_SUCCESS',
	GET_RELATED_USERS_ERROR: '[USERS] GET_RELATED_USERS_ERROR',

	UNLOADING_USERS_START: '[USERS] UNLOADING_USERS_START',
	UNLOADING_USERS_SUCCESS: '[USERS] UNLOADING_USERS_SUCCESS',
	UNLOADING_USERS_ERROR: '[USERS] UNLOADING_USERS_ERROR',

	IMPORT_TEMPLATE_USERS_START: '[USERS] IMPORT_TEMPLATE_USERS_START',
	IMPORT_TEMPLATE_USERS_SUCCESS: '[USERS] IMPORT_TEMPLATE_USERS_SUCCESS',
	IMPORT_TEMPLATE_USERS_ERROR: '[USERS] IMPORT_TEMPLATE_USERS_ERROR',

	IMPORT_USERS_START: '[USERS] IMPORT_USERS_START',
	IMPORT_USERS_SUCCESS: '[USERS] IMPORT_USERS_SUCCESS',
	IMPORT_USERS_ERROR: '[USERS] IMPORT_USERS_ERROR',

	GET_ORGANIZATIONS_USERS_START: '[USERS] GET_ORGANIZATIONS_USERS_START',
	GET_ORGANIZATIONS_USERS_SUCCESS: '[USERS] GET_ORGANIZATIONS_USERS_SUCCESS',
	GET_ORGANIZATIONS_USERS_ERROR: '[USERS] GET_ORGANIZATIONS_USERS_ERROR',

	RECOVER_BULK_START: '[USERS] RECOVER_BULK_START',
	RECOVER_BULK_SUCCESS: '[USERS] RECOVER_BULK_SUCCESS',
	RECOVER_BULK_ERROR: '[USERS] RECOVER_BULK_ERROR',

	GET_INSPECTORS_START: '[USERS] GET_INSPECTORS_START',
	GET_INSPECTORS_SUCCESS: '[USERS] GET_INSPECTORS_SUCCESS',
	GET_INSPECTORS_ERROR: '[USERS] GET_INSPECTORS_ERROR',

	GET_LIST_ACTIVE_SYNCED_USERS_START: '[USERS] GET_LIST_ACTIVE_SYNCED_USERS_START',
	GET_LIST_ACTIVE_SYNCED_USERS_SUCCESS: '[USERS] GET_LIST_ACTIVE_SYNCED_USERS_SUCCESS',
	GET_LIST_ACTIVE_SYNCED_USERS_ERROR: '[USERS] GET_LIST_ACTIVE_SYNCED_USERS_ERROR',

	CLEAR_BULK_ACTIONS: '[USERS] CLEAR_BULK_ACTIONS',
} as const

export const UsersDoingTypes = {
	GET_ACTIVITY_USERS_LIST_START: '[USERS] GET_ACTIVITY_USERS_LIST_START',
	GET_ACTIVITY_USERS_LIST_SUCCESS: '[USERS] GET_ACTIVITY_USERS_LIST_SUCCESS',
	GET_ACTIVITY_USERS_LIST_ERROR: '[USERS] GET_ACTIVITY_USERS_LIST_ERROR',

	GET_ACTIONS_USERS_START: '[USERS] GET_ACTIONS_USERS_START',
	GET_ACTIONS_USERS_SUCCESS: '[USERS] GET_ACTIONS_USERS_SUCCESS',
	GET_ACTIONS_USERS_ERROR: '[USERS] GET_ACTIONS_USERS_ERROR',

	SET_ACTIVITY_USERS_FILTER: '[USERS] SET_ACTIVITY_USERS_FILTER',
	SET_ACTIONS_USERS_FILTER: '[USERS] SET_ACTIONS_USERS_FILTER',

} as const

export const UsersHandbookThematicsTypes = {
	GET_HANDBOOK_START: '[HANDBOOK] GET_HANDBOOK_START',
	GET_HANDBOOK_SUCCESS: '[HANDBOOK] GET_HANDBOOK_SUCCESS',
	GET_HANDBOOK_ERROR: '[HANDBOOK] GET_HANDBOOK_ERROR',

	SET_HANDBOOK_FILTER: '[HANDBOOK] SET_HANDBOOK_FILTER',
} as const

export const UsersHandbookDownloadTypes = {
	DOWNLOAD_START: '[HANDBOOK] DOWNLOAD_START',
	DOWNLOAD_SUCCESS: '[HANDBOOK] DOWNLOAD_SUCCESS',
	DOWNLOAD_ERROR: '[HANDBOOK] DOWNLOAD_ERROR',

	DOWNLOAD_TEMPLATE_START: '[HANDBOOK] DOWNLOAD_TEMPLATE_START',
	DOWNLOAD_TEMPLATE_SUCCESS: '[HANDBOOK] DOWNLOAD_TEMPLATE_SUCCESS',
	DOWNLOAD_TEMPLATE_ERROR: '[HANDBOOK] UNLOADING_TEMPLATE_ERROR',

	UPLOAD_START: '[HANDBOOK] UPLOAD_START',
	UPLOAD_SUCCESS: '[HANDBOOK] UPLOAD_SUCCESS',
	UPLOAD_ERROR: '[HANDBOOK] UPLOAD_ERROR',
} as const

export const UsersHandbookInternetTypes = {
	ADD_INTERNET_START: '[HANDBOOK] ADD_INTERNET_START',
	ADD_INTERNET_SUCCESS: '[HANDBOOK] ADD_INTERNET_SUCCESS',
	ADD_INTERNET_ERROR: '[HANDBOOK] ADD_INTERNET_ERROR',

	UPDATE_INTERNET_START: '[HANDBOOK] UPDATE_INTERNET_START',
	UPDATE_INTERNET_SUCCESS: '[HANDBOOK] UPDATE_INTERNET_SUCCESS',
	UPDATE_INTERNET_ERROR: '[HANDBOOK] UPDATE_INTERNET_ERROR',

	DELETE_INTERNET_START: '[HANDBOOK] DELETE_INTERNET_START',
	DELETE_INTERNET_SUCCESS: '[HANDBOOK] DELETE_INTERNET_SUCCESS',
	DELETE_INTERNET_ERROR: '[HANDBOOK] DELETE_INTERNET_ERROR',

	BULK_INTERNET_ACTIONS_START: '[HANDBOOK] BULK_INTERNET_ACTIONS_START',
	BULK_INTERNET_ACTIONS_SUCCESS: '[HANDBOOK] BULK_INTERNET_ACTIONS_SUCCESS',
	BULK_INTERNET_ACTIONS_ERROR: '[HANDBOOK] BULK_INTERNET_ACTIONS_ERROR',
} as const

export const UsersHandbookContentDeliveryTypes = {
	GET_ALL_CONTENT_DELIVERY_START: '[HANDBOOK] GET_ALL_CONTENT_DELIVERY_START',
	GET_ALL_CONTENT_DELIVERY_SUCCESS: '[HANDBOOK] GET_ALL_CONTENT_DELIVERY_SUCCESS',
	GET_ALL_CONTENT_DELIVERY_ERROR: '[HANDBOOK] GET_ALL_CONTENT_DELIVERY_ERROR',

	ADD_CONTENT_DELIVERY_START: '[HANDBOOK] ADD_CONTENT_DELIVERY_START',
	ADD_CONTENT_DELIVERY_SUCCESS: '[HANDBOOK] ADD_CONTENT_DELIVERY_SUCCESS',
	ADD_CONTENT_DELIVERY_ERROR: '[HANDBOOK] ADD_CONTENT_DELIVERY_ERROR',

	UPDATE_CONTENT_DELIVERY_START: '[HANDBOOK] UPDATE_CONTENT_DELIVERY_START',
	UPDATE_CONTENT_DELIVERY_SUCCESS: '[HANDBOOK] UPDATE_CONTENT_DELIVERY_SUCCESS',
	UPDATE_CONTENT_DELIVERY_ERROR: '[HANDBOOK] UPDATE_CONTENT_DELIVERY_ERROR',

	DELETE_CONTENT_DELIVERY_START: '[HANDBOOK] DELETE_CONTENT_DELIVERY_START',
	DELETE_CONTENT_DELIVERY_SUCCESS: '[HANDBOOK] DELETE_CONTENT_DELIVERY_SUCCESS',
	DELETE_CONTENT_DELIVERY_ERROR: '[HANDBOOK] DELETE_CONTENT_TYPE_ERROR',

	BULK_CONTENT_DELIVERY_ACTIONS_START: '[HANDBOOK] BULK_CONTENT_DELIVERY_ACTIONS_START',
	BULK_CONTENT_DELIVERY_ACTIONS_SUCCESS: '[HANDBOOK] BULK_CONTENT_DELIVERY_ACTIONS_SUCCESS',
	BULK_CONTENT_DELIVERY_ACTIONS_ERROR: '[HANDBOOK] BULK_CONTENT_DELIVERY_ACTIONS_ERROR',
} as const

export const UsersHandbookContentDirectionTypes = {
	ADD_CONTENT_DIRECTION_START: '[HANDBOOK] ADD_CONTENT_DIRECTION_START',
	ADD_CONTENT_DIRECTION_SUCCESS: '[HANDBOOK] ADD_CONTENT_DIRECTION_SUCCESS',
	ADD_CONTENT_DIRECTION_ERROR: '[HANDBOOK] ADD_CONTENT_DIRECTION_ERROR',

	UPDATE_CONTENT_DIRECTION_START: '[HANDBOOK] UPDATE_CONTENT_DIRECTION_START',
	UPDATE_CONTENT_DIRECTION_SUCCESS: '[HANDBOOK] UPDATE_CONTENT_DIRECTION_SUCCESS',
	UPDATE_CONTENT_DIRECTION_ERROR: '[HANDBOOK] UPDATE_CONTENT_DIRECTION_ERROR',

	DELETE_CONTENT_DIRECTION_START: '[HANDBOOK] DELETE_CONTENT_DIRECTION_START',
	DELETE_CONTENT_DIRECTION_SUCCESS: '[HANDBOOK] DELETE_CONTENT_DIRECTION_SUCCESS',
	DELETE_CONTENT_DIRECTION_ERROR: '[HANDBOOK] DELETE_CONTENT_TYPE_ERROR',

	BULK_CONTENT_DIRECTION_ACTIONS_START: '[HANDBOOK] BULK_CONTENT_DIRECTION_ACTIONS_START',
	BULK_CONTENT_DIRECTION_ACTIONS_SUCCESS: '[HANDBOOK] BULK_CONTENT_DIRECTION_ACTIONS_SUCCESS',
	BULK_CONTENT_DIRECTION_ACTIONS_ERROR: '[HANDBOOK] BULK_CONTENT_DIRECTION_ACTIONS_ERROR',
} as const

export const UsersHandbookOrganizationTypes = {
	ADD_ORGANIZATION_START: '[HANDBOOK] ADD_ORGANIZATION_START',
	ADD_ORGANIZATION_SUCCESS: '[HANDBOOK] ADD_ORGANIZATION_SUCCESS',
	ADD_ORGANIZATION_ERROR: '[HANDBOOK] ADD_ORGANIZATION_ERROR',

	UPDATE_ORGANIZATION_START: '[HANDBOOK] UPDATE_ORGANIZATION_START',
	UPDATE_ORGANIZATION_SUCCESS: '[HANDBOOK] UPDATE_ORGANIZATION_SUCCESS',
	UPDATE_ORGANIZATION_ERROR: '[HANDBOOK] UPDATE_ORGANIZATION_ERROR',

	DELETE_ORGANIZATION_START: '[HANDBOOK] DELETE_ORGANIZATION_START',
	DELETE_ORGANIZATION_SUCCESS: '[HANDBOOK] DELETE_ORGANIZATION_SUCCESS',
	DELETE_ORGANIZATION_ERROR: '[HANDBOOK] DELETE_CONTENT_TYPE_ERROR',

	BULK_ORGANIZATION_ACTIONS_START: '[HANDBOOK] BULK_ORGANIZATION_ACTIONS_START',
	BULK_ORGANIZATION_ACTIONS_SUCCESS: '[HANDBOOK] BULK_ORGANIZATION_ACTIONS_SUCCESS',
	BULK_ORGANIZATION_ACTIONS_ERROR: '[HANDBOOK] BULK_ORGANIZATION_ACTIONS_ERROR',

	GET_ORGANIZATION_START: '[HANDBOOK] GET_ORGANIZATION_START',
	GET_ORGANIZATION_SUCCESS: '[HANDBOOK] GET_ORGANIZATION_SUCCESS',
	GET_ORGANIZATION_ERROR: '[HANDBOOK] GET_ORGANIZATION_ERROR',
} as const

export const UsersHandbookUsersActionsTypes = {
	UPDATE_USERS_ACTIONS_START: '[HANDBOOK] UPDATE_USERS_ACTIONS_START',
	UPDATE_USERS_ACTIONS_SUCCESS: '[HANDBOOK] UPDATE_USERS_ACTIONS_SUCCESS',
	UPDATE_USERS_ACTIONS_ERROR: '[HANDBOOK] UPDATE_USERS_ACTIONS_ERROR',
} as const

export const OptionsHandbookTypes = {
	SET_EDITING_KEY: '[HANDBOOK] SET_EDITING_KEY',
	CLEAR_EDITING_KEY: '[HANDBOOK] CLEAR_EDITING_KEY',
} as const

export const OptionsNotificationsActionsTypes = {
	GET_NOTIFICATIONS_START: '[NOTIFICATIONS] GET_NOTIFICATIONS_START',
	GET_NOTIFICATIONS_SUCCESS: '[NOTIFICATIONS] GET_NOTIFICATIONS_SUCCESS',
	GET_NOTIFICATIONS_ERROR: '[NOTIFICATIONS] GET_NOTIFICATIONS_ERROR',

	GET_ACTIVE_NOTIFICATIONS_CHANNELS_START: '[NOTIFICATIONS] GET_ACTIVE_NOTIFICATIONS_CHANNELS_START',
	GET_ACTIVE_NOTIFICATIONS__CHANNELS_SUCCESS: '[NOTIFICATIONS] GET_ACTIVE_NOTIFICATIONS__CHANNELS_SUCCESS',
	GET_ACTIVE_NOTIFICATIONS__CHANNELS_ERROR: '[NOTIFICATIONS] GET_ACTIVE_NOTIFICATIONS__CHANNELS_ERROR',

	CLEAR_BULK_ACTIONS: '[NOTIFICATIONS] CLEAR_BULK_ACTIONS',

	SET_NOTIFICATION_FILTER: '[NOTIFICATIONS] SET_NOTIFICATION_FILTER',
} as const

export const PersonalSettingTypes = {
	GET_PERSONAL_SETTING_START: '[PERSONAL SETTING] GET_PERSONAL_SETTING_START',
	GET_PERSONAL_SETTING_SUCCESS: '[PERSONAL SETTING] GET_PERSONAL_SETTING_SUCCESS',
	GET_PERSONAL_SETTING_ERROR: '[PERSONAL SETTING] GET_PERSONAL_SETTING_ERROR',

	UPDATE_PASSWORD_LENGTH_START: '[PERSONAL SETTING] UPDATE_PASSWORD_LENGTH_START',
	UPDATE_CONTAIN_SYMBOLS_START: '[PERSONAL SETTING] UPDATE_CONTAIN_SYMBOLS_START',
	UPDATE_LETTERS_NUMBERS_START: '[PERSONAL SETTING] UPDATE_LETTERS_NUMBERS_START',

	UPDATE_PERSONAL_SETTING_START: '[PERSONAL SETTING] UPDATE_PERSONAL_SETTING_START',
	UPDATE_PERSONAL_SETTING_SUCCESS: '[PERSONAL SETTING] UPDATE_PERSONAL_SETTING_SUCCESS',
	UPDATE_PERSONAL_SETTING_ERROR: '[PERSONAL SETTING] UPDATE_PERSONAL_SETTING_ERROR',

	CONFIRM_EMAIL_START: '[PERSONAL SETTING] CONFIRM_EMAIL_START',
	CONFIRM_EMAIL_SUCCESS: '[PERSONAL SETTING] CONFIRM_EMAIL_SUCCESS',
	CONFIRM_EMAIL_ERROR: '[PERSONAL SETTING] CONFIRM_EMAIL_ERROR',

	CONFIRM_TELEGRAM_START: '[PERSONAL SETTING] CONFIRM_TELEGRAM_START',
	CONFIRM_TELEGRAM_SUCCESS: '[PERSONAL SETTING] CONFIRM_TELEGRAM_SUCCESS',
	CONFIRM_TELEGRAM_ERROR: '[PERSONAL SETTING] CONFIRM_TELEGRAM_ERROR',

	SEND_CONFIRM_EMAIL_START: '[PERSONAL SETTING] SEND_CONFIRM_EMAIL_START',
	SEND_CONFIRM_EMAIL_SUCCESS: '[PERSONAL SETTING] SEND_CONFIRM_EMAIL_SUCCESS',
	SEND_CONFIRM_EMAIL_ERROR: '[PERSONAL SETTING] SEND_CONFIRM_EMAIL_ERROR',

	CHANGE_PASSWORD_PERSONAL_SETTING_START: '[PERSONAL SETTING] CHANGE_PASSWORD_PERSONAL_SETTING_START',
	CHANGE_PASSWORD_PERSONAL_SETTING_SUCCESS: '[PERSONAL SETTING] CHANGE_PASSWORD_PERSONAL_SETTING_SUCCESS',
	CHANGE_PASSWORD_PERSONAL_SETTING_ERROR: '[PERSONAL SETTING] CHANGE_PASSWORD_PERSONAL_SETTING_ERROR',

	CLEAR_INPUT: '[PERSONAL SETTING] CLEAR_INPUT',
} as const

export const OptionsHandbookSessionsType = {
	ADD_SESSIONS_TYPE_START: '[HANDBOOK] ADD_SESSIONS_TYPE_START',
	ADD_SESSIONS_TYPE_SUCCESS: '[HANDBOOK] ADD_SESSIONS_TYPE_SUCCESS',
	ADD_SESSIONS_TYPE_ERROR: '[HANDBOOK] ADD_SESSIONS_TYPE_ERROR',

	UPDATE_SESSIONS_TYPE_START: '[HANDBOOK] UPDATE_SESSIONS_TYPE_START',
	UPDATE_SESSIONS_TYPE_SUCCESS: '[HANDBOOK] UPDATE_SESSIONS_TYPE_SUCCESS',
	UPDATE_SESSIONS_TYPE_ERROR: '[HANDBOOK] UPDATE_SESSIONS_TYPE_ERROR',

	DELETE_SESSIONS_TYPE_START: '[HANDBOOK] DELETE_SESSIONS_TYPE_START',
	DELETE_SESSIONS_TYPE_SUCCESS: '[HANDBOOK] DELETE_SESSIONS_TYPE_SUCCESS',
	DELETE_SESSIONS_TYPE_ERROR: '[HANDBOOK] DELETE_SESSIONS_TYPE_ERROR',

	BULK_SESSIONS_TYPE_ACTIONS_START: '[HANDBOOK] BULK_SESSIONS_TYPE_ACTIONS_START',
	BULK_SESSIONS_TYPE_ACTIONS_SUCCESS: '[HANDBOOK] BULK_SESSIONS_TYPE_ACTIONS_SUCCESS',
	BULK_SESSIONS_TYPE_ACTIONS_ERROR: '[HANDBOOK] BULK_SESSIONS_TYPE_ACTIONS_ERROR',
} as const

export const OptionsHandbookLegalForm = {
	ADD_LEGAL_FORM_START: '[HANDBOOK] ADD_LEGAL_FORM_START',
	ADD_LEGAL_FORM_SUCCESS: '[HANDBOOK] ADD_LEGAL_FORM_SUCCESS',
	ADD_LEGAL_FORM_ERROR: '[HANDBOOK] ADD_LEGAL_FORM_ERROR',

	UPDATE_LEGAL_FORM_START: '[HANDBOOK] UPDATE_LEGAL_FORM_START',
	UPDATE_LEGAL_FORM_SUCCESS: '[HANDBOOK] UPDATE_LEGAL_FORM_SUCCESS',
	UPDATE_LEGAL_FORM_ERROR: '[HANDBOOK] UPDATE_LEGAL_FORM_ERROR',

	DELETE_LEGAL_FORM_START: '[HANDBOOK] DELETE_LEGAL_FORM_START',
	DELETE_LEGAL_FORM_SUCCESS: '[HANDBOOK] DELETE_LEGAL_FORM_SUCCESS',
	DELETE_LEGAL_FORM_ERROR: '[HANDBOOK] DELETE_LEGAL_FORM_ERROR',

	BULK_LEGAL_FORM_ACTIONS_START: '[HANDBOOK] BULK_LEGAL_FORM_ACTIONS_START',
	BULK_LEGAL_FORM_ACTIONS_SUCCESS: '[HANDBOOK] BULK_LEGAL_FORM_ACTIONS_SUCCESS',
	BULK_LEGAL_FORM_ACTIONS_ERROR: '[HANDBOOK] BULK_LEGAL_FORM_ACTIONS_ERROR',
} as const

export const OptionsHandbookCategoryProject= {
	ADD_CATEGORY_PROJECT_START: '[HANDBOOK] ADD_CATEGORY_PROJECT_START',
	ADD_CATEGORY_PROJECT_SUCCESS: '[HANDBOOK] ADD_CATEGORY_PROJECT_SUCCESS',
	ADD_CATEGORY_PROJECT_ERROR: '[HANDBOOK] ADD_CATEGORY_PROJECT_ERROR',

	UPDATE_CATEGORY_PROJECT_START: '[HANDBOOK] UPDATE_CATEGORY_PROJECT_START',
	UPDATE_CATEGORY_PROJECT_SUCCESS: '[HANDBOOK] UPDATE_CATEGORY_PROJECT_SUCCESS',
	UPDATE_CATEGORY_PROJECT_ERROR: '[HANDBOOK] UPDATE_CATEGORY_PROJECT_ERROR',

	DELETE_CATEGORY_PROJECT_START: '[HANDBOOK] DELETE_CATEGORY_PROJECT_START',
	DELETE_CATEGORY_PROJECT_SUCCESS: '[HANDBOOK] DELETE_CATEGORY_PROJECT_SUCCESS',
	DELETE_CATEGORY_PROJECT_ERROR: '[HANDBOOK] DELETE_CATEGORY_PROJECT_ERROR',

	BULK_CATEGORY_PROJECT_ACTIONS_START: '[HANDBOOK] BULK_CATEGORY_PROJECT_ACTIONS_START',
	BULK_CATEGORY_PROJECT_ACTIONS_SUCCESS: '[HANDBOOK] BULK_CATEGORY_PROJECT_ACTIONS_SUCCESS',
	BULK_CATEGORY_PROJECT_ACTIONS_ERROR: '[HANDBOOK] BULK_CATEGORY_PROJECT_ACTIONS_ERROR',
} as const

