import React, {FC, useEffect, useMemo} from 'react'
import './task-form.less'
import {Col, Row, Spin} from 'antd'
import {useDispatch, useSelector} from 'react-redux'
import useHandlerStatus from 'hooks/handlStatus'
import {TaskFormDto} from "./domain/dto/task-form.dto";
//components
import StatusTaskForm from 'components/status-element/status-task-form'
import ErrorComponent from 'components/error-boundary'
//store
import {ClearStatusTasks, GetTaskStartAction} from 'store/tasks/actions/tasks'
import {selectTask, selectTaskStatus} from 'store/tasks/selectors/selectors-task'
import {selectRole} from 'store/auth/selectors'
import TaskFormComponent from './form'
import {GetSprintsStartAction} from "store/sprints/actions";
//models
import {TaskTypes} from 'entities/task'
import {TRole} from 'models/user'
import {IOptions} from 'models/feels'
import {ProjectStatusEnum} from "entities/project/types";
import {useGetReturnLink} from "shared/lib/hooks/get-return-link";
import {useHistory} from "react-router-dom";

type TaskFormProps = {
	id?: string | null
	projectId?: string | null
	sprint?: IOptions
	definitionSidebar?: boolean
	closeFunction: Function
}

const TaskForm: FC<TaskFormProps> = ({id, projectId, sprint, closeFunction, definitionSidebar}) => {
	const put = useDispatch()
	const history = useHistory();
	const ReturnLink = useGetReturnLink();
	const {statusCount, handlerStatus} = useHandlerStatus({init: 1, limit: 3})
	const task = useSelector(selectTask)
	const {isLoaded, isLoading} = useSelector(selectTaskStatus)
	const role = useSelector(selectRole)

	const typeForm = id ? 'edit' : 'create';

	const returnFunction = () => {
		if (ReturnLink) {
			put(ClearStatusTasks())
			history.push(ReturnLink)
		} else if (closeFunction) {
			closeFunction()
		} else {
			history.push('/')
		}
	}

	useEffect(() => {
		if (isLoaded && closeFunction) {
			put(ClearStatusTasks())
			returnFunction()
		}// eslint-disable-next-line
	}, [isLoaded, closeFunction])

	useEffect(() => {
		if (id) put(GetTaskStartAction(id))
		// eslint-disable-next-line
	}, [id])

	useEffect(()=>{
		if (task && task.project && !projectId) put(GetSprintsStartAction(task.project.id))
		if (projectId) put(GetSprintsStartAction(projectId))
		// eslint-disable-next-line
	},[task, projectId])

	const info: TInfoFormTask = useMemo(() => {
		if (!task?.status.code && !role) return null
		return {
			status: task?.status.code as TaskTypes.TasksStatus,
			role: role as TRole,
			projectStatus: task?.project.status.code,
		}
	}, [task, role])

	const initialValue: TaskFormDto = useMemo(() => {
			const data = typeForm === 'edit' ? new TaskFormDto(task) : new TaskFormDto()
			if (!id && sprint) data.stepFirst.setSprint(sprint)
			if (projectId) data.stepFirst.setProject(projectId);
			if (typeForm === 'edit') {
				data.stepFirst
					.validate(info)
				data.stepSecond
					.validate(info)
					.validateAutofillParameters()
				data.stepThird
					.validate(info)
			}
			return data
		// eslint-disable-next-line
	}, [id, task]);
	return (
			<Row className="taskForm">
				<Col xs={24} sm={4} md={2} className="taskForm__status">
					<StatusTaskForm status={statusCount} handler={handlerStatus}  />
				</Col>
				<Col xs={24} sm={20} md={22} className="taskForm__form">
					<ErrorComponent>
						<Spin spinning={isLoading}>
							<TaskFormComponent
								value={initialValue}
								statusCount={statusCount}
								handlerStatus={handlerStatus}
								id={id}
								closeFunction={returnFunction}
								definitionSidebar={definitionSidebar}
							/>
						</Spin>
					</ErrorComponent>
				</Col>
			</Row>

	)
}
export type TInfoFormTask = {
	status: TaskTypes.TasksStatus
	role: TRole
	projectStatus?: ProjectStatusEnum
} | null
export default TaskForm
