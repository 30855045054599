import {TKpi, TOption} from "models/projects/project-form";
import {TDefault} from "./project-form.dto";
import {TChannels} from "models/projects/channels";
import {TKpis} from "models/projects/kpis";
import {ProjectTypes} from "entities/project";
import {FileType} from 'shared/types/file'
import {TInfoProject} from "../../ui/form-container";

export class StepThirdDto {
    channels: TDefault<TChannels<TOption>[] | []>
    kpis: TDefault<TKpi[] | []>
    image: TDefault<FileType[] | []>
    existingImage: TDefault<FileType[] | []>

    info?: TInfoProject;
    constructor(project?: ProjectTypes.Project | null) {
        this.channels = project ? {value: project.channels.map((el) => new ChannelsDto(el))} : {value: []}
        this.kpis = project ? {value: project.kpis.map((el) => new KpisDto(el))} : {value: []}
        this.image = {value: []}
        this.existingImage = project && project.image ? {value: [project.image]} : {value: []}
    }

    validate(info: TInfoProject) {
        this.info = info;
        for (const key in this) {
            // @ts-ignore
            if (this[key]) this[key].isDisabled = validate(key, info)
        }
        return this;
    }

    validateProjectType(type?: string) {
        if (type === 'CONTENT_CREATION') {
            this.channels = {value: [], isDisabled: true};
            this.kpis = {value: [], isDisabled: true}
        } else {
            if (this.info) {
                this.validate(this.info)
            } else {
                this.channels.isDisabled = false;
                this.kpis.isDisabled = false;
            }
        }
        return this
    }
}

class ChannelsDto implements TChannels<TOption> {
    id;
    internetResource;
    link;
    constructor(channel: TChannels) {
        this.internetResource = {
            label: channel.internetResource.name as string,
            value: channel.internetResource.id as string
        }
        this.link = channel.link
        this.id = channel.id
    }
}

class KpisDto implements TKpi {
    count;
    type;

    constructor(el: TKpis) {
        this.count = el.planCount;
        this.type = {value: el.type.id, label: el.type.name}
    }
}

function validate(field: string, info: TInfoProject): boolean {
    let res = false;
    if (!info) return res
    const {role, status} = info;
    if (role === 'ROLE_CONTRACTOR') res = true
    if ((role === 'ROLE_ADMIN') || (role ==='ROLE_ADMIN_IS')) {
        if (status === 'DRAFT') res = false;
        else if (status === 'SUSPENDED' || status === 'COMPLETED') res = true
        else if (status === 'ACTUAL' || status === 'ARCHIVED') res = false
    }
    if ((role === 'ROLE_PRODUCER')) {
        if (status === 'DRAFT') res = true
        else if (status === 'SUSPENDED' || status === 'COMPLETED')   res = true
        else if (status === 'ACTUAL' || status === 'ARCHIVED') res = false
    }
    if ((role === 'ROLE_COORDINATOR') || (role ==='ROLE_MODERATOR') || (role === 'ROLE_PROJECT_MANAGER')) {
        if (status === 'DRAFT') res = false;
        else if (status === 'SUSPENDED' || status === 'COMPLETED') res = true
        else if (status === 'ACTUAL' || status === 'ARCHIVED') res = false
    }
    if ((role === 'ROLE_ANALYST') || (role === 'ROLE_FINANCIER') || (role === 'ROLE_LAWYER') || role === 'ROLE_CONTRACTOR' || role === 'ROLE_CONTENT_EDITOR') res = true;


    return res
}
