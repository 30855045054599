import { FunctionalityInfoType } from '../types'

export const projectDocuments: FunctionalityInfoType = [
	{
		LevelGroupName: 'Просмотр списка документов с поиском, фильтрацией, сортировкой, пагинацией',
		LevelGroupData: [
			{
				name: 'Поиск документов',
				text: 'Для поиска документов по наименованию:',
				points: ['Нажмите "Поиск"', 'Введите текст'],
				screenshots: ['/images/screenshots/project-documents/search.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Фильтрация документов',
				text: 'Для фильтрации документов по типам:',
				points: ['Нажмите "Выберите тип документа"', 'Выберите тип документа из выпадающего списка'],
				screenshots: ['/images/screenshots/project-documents/filter.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Сортировка отображения таблицы документов',
				text: 'Нажимайте на стрелки для сортировки отображения таблицы документов проекта',
				screenshots: ['/images/screenshots/project-documents/sorting.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Переход на следующую страницу',
				text: 'Нажмите на номер страницы или стрелочки для перехода на другую страницу',
				screenshots: ['/images/screenshots/pagination.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
		],
	},
	{
		LevelGroupName: 'Создание нового документа',
		LevelGroupData: [
			{
				name: 'Добавление документа',
				text: 'Для добавления документа:',
				points: ['Нажмите "Добавить файл"', 'Заполните поля формы', 'Нажмите на галочку для сохранения формы'],
				screenshots: [
					'/images/screenshots/project-documents/add.png',
					'/images/screenshots/project-documents/table.png',
				],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
		],
	},
	{
		LevelGroupName: 'Редактирование данных документа',
		LevelGroupData: [
			{
				name: 'Редактирование документа',
				text: 'Для редактирования документа:',
				points: [
					'Нажмите на карандаш',
					'Заполните поля формы',
					'Нажмите на галочку для сохранения формы',
				],
				topIcons: ['edit-blue'],
				screenshots: ['/images/screenshots/project-documents/table.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Удаление документа',
				text: 'Нажмите для удаления документа проекта',
				topIcons: ['delete-red'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
		],
	},
	{
		LevelGroupName: 'Скачивание файла',
		LevelGroupData: [
			{
				name: 'Скачивание файла',
				text: 'Нажмите для скачивания файла',
				screenshots: ['/images/screenshots/project-documents/download.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
		],
	},
]
