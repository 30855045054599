import { FunctionalityInfoType } from '../types'

export const tasksSettings: FunctionalityInfoType = [
	{
		LevelGroupName: 'Просмотр списка расписаний дублирования задач с поиском, фильтрацией, сортировкой, пагинацией',
		LevelGroupData: [
			{
				name: 'Поиск задач',
				text: 'Для поиска задач по коду, названию и номеру:',
                points: ['Нажмите "Поиск задач проектов"', 'Введите текст','Нажмите на кнопку поиска'],
				screenshots: ['/images/screenshots/tasks-settings/search.png'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN', 'ROLE_ANALYST', 'ROLE_PRODUCER', 'ROLE_PROJECT_MANAGER'],
			},
			{
				name: 'Сортировка отображения таблицы',
				text: 'Нажмите для сортировки отображения таблицы по статусу расписания "Включено" / "Выключено"',
				screenshots: ['/images/screenshots/tasks-settings/radio-sorting.png'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN', 'ROLE_ANALYST', 'ROLE_PRODUCER', 'ROLE_PROJECT_MANAGER'],
			},
			{
				name: 'Сортировка отображения таблицы расписаний дублирования задач',
				text: 'Нажимайте на стрелки для сортировки отображения таблицы расписаний дублирования задач',
				screenshots: ['/images/screenshots/tasks-settings/table-sorting.png'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN', 'ROLE_ANALYST', 'ROLE_PRODUCER', 'ROLE_PROJECT_MANAGER'],
			},
			{
				name: 'Переход на следующую страницу',
				text: 'Нажмите на номер страницы или стрелочки для перехода на другую страницу',
				screenshots: ['/images/screenshots/pagination.png'],
                roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN', 'ROLE_ANALYST', 'ROLE_PRODUCER', 'ROLE_PROJECT_MANAGER'],

            },
			{
				name: 'Настройка количества расписаний, отображаемых на странице',
				text: 'Для настройки количества отображаемых на странице расписаний дублирования задач:',
				points: ['Нажмите на кнопку', 'Выберите значение из выпадающего списка'],
				screenshots: ['/images/screenshots/setting-count-page.png'],
                roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN', 'ROLE_ANALYST', 'ROLE_PRODUCER', 'ROLE_PROJECT_MANAGER'],

            },
		],
	},
	{
		LevelGroupName: 'Создание расписаний дублирования',
		LevelGroupData: [
			{
				name: 'Массовое создание расписаний дублирования',
				points: [
                    'Нажмите на кнопку для перехода в окно "Настройка расписания автосоздания задач"',
                    'Заполните обязательные и доступные поля формы',
                    'Нажмите “Сохранить”',
                ],
                screenshots: ['/images/screenshots/tasks-settings/mass-duplication-schedules.png'],
                roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN', 'ROLE_ANALYST', 'ROLE_PRODUCER', 'ROLE_PROJECT_MANAGER'],
			},
		],
	},
	{
		LevelGroupName: 'Редактирование данных расписаний дублирования',
		LevelGroupData: [
			{
				name: 'Массовое обновление расписаний дублирования',
				points: [
					'Нажмите на кнопку для перехода в окно "Настройка расписания автосоздания задач"',
					'Заполните обязательные и доступные поля формы',
					'Нажмите “Сохранить”'
				],
				screenshots: ['/images/screenshots/tasks-settings/mass-update-schedules.png'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN', 'ROLE_ANALYST', 'ROLE_PRODUCER', 'ROLE_PROJECT_MANAGER'],
			},
			{
				name: 'Обновление расписания дублирования',
				points: [
					'Нажмите на кнопку для перехода в окно "Настройка расписания автосоздания задач"',
					'Заполните обязательные и доступные поля формы',
					'Нажмите “Сохранить”'
				],
				screenshots: ['/images/screenshots/tasks-settings/update-schedules.png'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN', 'ROLE_ANALYST', 'ROLE_PRODUCER', 'ROLE_PROJECT_MANAGER'],
			},
			{
				name: 'Обновление статуса расписания дублирования "Включено / выключено"',
				text: 'Нажмите на переключатель "Включено / Выключено" для обновления статуса расписания дублирования',
				screenshots: ['/images/screenshots/tasks-settings/update-status.png'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN', 'ROLE_ANALYST', 'ROLE_PRODUCER', 'ROLE_PROJECT_MANAGER'],
			},
			{
				name: 'Расширенный поиск задач проектов',
				text: 'Нажмите на кнопку для фильтрации задач по конкурсу, году, сессии, проекту в модальном окне "Настройка расписания автосоздания задач"',
				screenshots: ['/images/screenshots/tasks-settings/search-task.png'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN', 'ROLE_ANALYST', 'ROLE_PRODUCER', 'ROLE_PROJECT_MANAGER'],
			},
		],
	},
	{
		LevelGroupName: 'Просмотр данных расписаний дублирования',
		LevelGroupData: [
			{
				name: 'Просмотр дат дублирования задач',
				text: 'Нажмите на период создания первого и последнего дублей для просмотра модального окна с перечнем всех дат дублирования',
				screenshots: ['/images/screenshots/tasks-settings/view-date.png'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN', 'ROLE_ANALYST', 'ROLE_PRODUCER', 'ROLE_PROJECT_MANAGER'],
			},
		],
	},
]
