import {combineReducers} from 'redux';
import {connectRouter} from "connected-react-router";
import {History} from 'history';
import {TApplicationState} from "./aplication-state";
import sidebarsRouter from './sidebars/reducer'
import appRouter from './app/reducer'
import logsRouter from './logs/reducer'
import modalsRouter from './modals/reducer'
import authReducer from './auth/reducer'
import notificationReducer from './notification/reducer'
import projectsReducer from './projects/reducer'
import optionsReducer from './options/reducer'
import tasksReducer from './tasks/reducer'
import filesReducer from './files/reducer'
import reportingReducer from './project-reporting/reducer'
import analyticsReducer from './analytics/reducer'
import UploaderReducer from './uploader/reducer'
import StagesReducer from './sprints/reducer'
import ProjectDocumentsReducer from './project-documents/reducer'
import ContentReducer from './content'
import TagsReducer from './tags/reducer'
import ArchiveReducer from './archive/reducer'
import LegalFormOrganizationReducer from './legal-form-organization/reducer'
import SessionTypeReducer from './session-type/reducer'
import NationalProjectTag1Reducer from './national-project-tag1/reducer'
import NationalProjectTag2Reducer from './national-project-tag2/reducer'
import {CalendarModel} from 'pages/calendar';
import {FeatureReducer} from './features';


const store = (history: History) => combineReducers<TApplicationState>({
    router: connectRouter(history),
    feature: FeatureReducer,
    app: appRouter,
    logs: logsRouter,
    modals: modalsRouter,
    sidebars: sidebarsRouter,
    auth: authReducer,
    notification: notificationReducer,
    projects: projectsReducer,
    calendar: CalendarModel.Reducer,
    options: optionsReducer,
    tasks: tasksReducer,
    files: filesReducer,
    reporting: reportingReducer,
    analytics: analyticsReducer,
    uploader: UploaderReducer,
    sprints: StagesReducer,
    projectDocuments: ProjectDocumentsReducer,
    content: ContentReducer,
    tags: TagsReducer,
    archive: ArchiveReducer,
    legalFormOrganization: LegalFormOrganizationReducer,
    sessionsType:SessionTypeReducer,
    nationalProjectTag1: NationalProjectTag1Reducer,
    nationalProjectTag2: NationalProjectTag2Reducer
});

export default store;
