import React, {useMemo} from 'react';
import styles from "./styles.module.less";
//antd
import Checkbox from "antd/es/checkbox";
import {Spin} from "antd";
//hooks
//components
import ReloadList from "components/forms/components/reload-list/reload-list";
import {SelectComponent} from "components/formic-control";
//
//others
import {useFormikContext} from "formik";
import {ProjectFormDto} from "../../../lib";
import {RegionLib} from 'entities/region'

function filingName(key: string): string {
    return `stepFifth.${key}.value`
}
const LocationRegions = () => {
    const {values, errors, setFieldValue} = useFormikContext<ProjectFormDto>();
    const {data: regions, isLoading, refetch} = RegionLib.useGetRegionsOptions()

    const status = values.stepFirst.status.value
    const form = values.stepFifth
    const formErrors = errors.stepFifth

    const regionsOptionsDisabled = useMemo(() => {
        if (!status || status?.code === 'DRAFT' || status?.code === 'ACTUAL') {
            return regions?.filter((el) => el.hidden).map((el) => el.value);
        }
        return [];
    }, [status, regions]);

    const regionsFilteredOptions = regions?.filter(option => {
        return !regionsOptionsDisabled?.some(value => value === option.value);
    })

    const handlerUpdateRegions = () => refetch()

    const selectCheckboxAll = (e: any) => {
        const checked: boolean = e.target.checked
        if (checked) setFieldValue(filingName('contentLocationRegions'), regionsFilteredOptions)
        else setFieldValue(filingName('contentLocationRegions'), [])
    }

    return (
        <Spin spinning={isLoading || !regions}>
            <ReloadList className="ident" handlerUpdateList={handlerUpdateRegions} />
            <SelectComponent
                name={filingName('contentLocationRegions')}
                placeholder="Выберите регион/регионы"
                options={regions}
                disabledOptionsIds={regionsOptionsDisabled}
                multi={true}
                showError={false}
                disabled={form.contentLocationRegions.isDisabled}
                errorMessage={formErrors?.contentLocationRegions as string}
                maxTagCount={'responsive'}
                dropdownRender={
                    <Checkbox
                        className={styles.checkboxInSelect}
                        onChange={selectCheckboxAll}
                        checked={regions?.length ? form.contentLocationRegions?.value?.length === regionsFilteredOptions?.length : false}
                        disabled={form.contentLocationRegions.isDisabled}
                    >
                        <span className={styles.checkboxText}>Выбрать все</span>
                    </Checkbox>
                }
            />
        </Spin>
    );
};

export default LocationRegions;
