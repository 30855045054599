import React, {useRef} from 'react'
import styles from './styles.module.less'
import {SelectComponent} from 'components/formic-control'
import {useQuery} from 'react-query'
import TasksService from 'services/tasksService'
import {IOptions} from 'models/feels'
import {TaskTypes} from 'entities/task'
import {useFormikContext} from 'formik'
import {TForm} from './modal'
import {FilterOutlined} from '@ant-design/icons'
import {Popover} from 'antd'
import PopoverContent from '../popover-content/popover-content'
import {useLocation} from "react-router-dom";

export type PopoverRefType = {
	sessionIds?: TaskTypes.TaskSearchDto['sessionIds']
	contestIds?: TaskTypes.TaskSearchDto['contestIds']
	projectIds?: TaskTypes.TaskSearchDto['projectIds']
}
const Tasks = () => {
	const { values } = useFormikContext<TForm>()
	const popoverRef = useRef<PopoverRefType>({})
	const location = useLocation()
	const page = location.pathname.split('/')[1]

	const { data: tasks, isLoading } = useQuery({
		queryKey: [TasksService.getAllTasksKey, TaskTypes.TypesTask.REPORT, TaskTypes.ReportType.CONTENT_REACH_REPORT],
		queryFn: async () => {
			const { data } = await TasksService.getAllTasks({
				typeCodes: [TaskTypes.TypesTask.REPORT],
				reportTypeCodes: [TaskTypes.ReportType.CONTENT_REACH_REPORT],
				isSchedulerExist: false,
				...(popoverRef.current.contestIds?.length && { contestIds: popoverRef.current.contestIds }),
				...(popoverRef.current.sessionIds?.length && { sessionIds: popoverRef.current.sessionIds }),
				...(popoverRef.current.projectIds?.length && { projectIds: popoverRef.current.projectIds }),
			})
			return data.map((el) => ({ value: el.id, label: el.name } as IOptions))
		},
	})
	const handlerChangeRef = (value: PopoverRefType) => {
		popoverRef.current = { ...popoverRef.current, ...value }
	}

	return (
		<>
			<div className={styles.item}>
				<span className={styles.label}>
					Задачи
				</span>
				<div className={styles.field}>
					<Popover content={<PopoverContent handlerChangeRef={handlerChangeRef} />} placement={'right'}>
						<FilterOutlined className={styles.select_suffix} />
					</Popover>
					<SelectComponent
						allowClear={false}
						options={tasks || []}
						multi={false}
						maxTagCount={'responsive'}
						loading={isLoading}
						disabled={!values.enabled || page === 'project'}
						name={'task'}
						placeholder={'Выберите задачу для согласования'}
					/>
				</div>
			</div>
		</>
	)
}

export default Tasks
