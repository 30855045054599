import React from 'react'
import styles from '../styles.module.less'
import {SelectComponent} from 'components/formic-control'
import {useFormikContext} from 'formik'
import {TInitValues} from '../modal-content-records'
import {IOptions} from "models/feels";
import {getMaterialTypeValue} from "../utils";

const options = [
	{ label: 'Учитываемый контент', value: 'accountedContent' },
	{ label: 'Неучитываемый контент', value: 'nonAccountedContent' },
	{ label: 'Рабочие материалы', value: 'workingMaterial' },
]

const MaterialType = () => {
	const { values,setValues } = useFormikContext<TInitValues>()
	const onChange = (value: IOptions) => {
		const typeValue = value.value as 'accountedContent' | 'nonAccountedContent' | 'workingMaterial'

		const dataMap = {
			accountedContent: { isContent: true, isAccountedContent: true },
			nonAccountedContent: { isContent: true, isAccountedContent: false },
			workingMaterial: { isContent: false, isAccountedContent: true },
		};

		setValues(prevState => ({
			...prevState,
			...dataMap[typeValue],
			materialType: getMaterialTypeValue(dataMap[typeValue])
		}))

	}
	return (
		<div className={styles.item}>
			<span className={styles.label}>
				Тип материала <sup>*</sup>
			</span>
			<div className={styles.field}>
				<SelectComponent
					allowClear={false}
					disabled={values.disabled}
					handler={onChange}
					options={options}
					name={'materialType'}
					placeholder={'Выберите значение'}
					notSetField={true}
				/>
			</div>
		</div>
	)
}

export default MaterialType


