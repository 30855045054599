import {z} from "zod";
import ZodParser from "shared/services/zod-parser";
import {FileSchema} from "shared/types/file";
import {StepThirdDto} from "../dto/project-form_StepThird.dto";

const schema = z.object({
    channels: z.array(z.object({
        id: z.string(),
        link: z.string(),
        internetResourceId: z.string(),
    })).nullable(),
    kpis: z.array(z.object({
        id: z.string().optional(),
        planCount: z.number(),
        typeId: z.string()
    })).nullable(),
    imageId: z.string().nullable(),
    image: FileSchema.nullable()
})
export type EditSchema3 = z.infer<typeof schema>
export function step3(step: StepThirdDto): EditSchema3 {
    const mapped: EditSchema3 = {
        channels: step.channels.value.length > 0
            ? step.channels.value.map((it) => ({
                id: it.id as string,
                link: it.link,
                internetResourceId: it.internetResource.value
            })) : null,
        kpis: step.kpis.value.length > 0
            ? step.kpis.value.map((it) => ({
                id: it.id as string,
                planCount: it.count,
                typeId: it.type?.value as string
            })) : null,
        imageId: step.existingImage.value[0]?.id || null,
        image: step.image.value[0] || null
    }
    ZodParser.parse(schema, mapped, 'Edit step3')
    return mapped
}
