import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
    selectHandbookActionsUsers,
    selectHandbookCategoryProject,
    selectHandbookContentDelivery,
    selectHandbookContentDirection,
    selectHandbookInternet,
    selectHandbookLegalForm,
    selectHandbookOrganization,
    selectHandbookSessionsType,
    selectHandbookStatus,
} from "store/options/selectors/selectors-handbook";
import {TStatusesHandbookFeels} from "models/handbook";
import {GetHandbookStartAction} from "store/options/actions/actions-handbook";

const useFeelsHandbook= (feels?: TStatusesHandbookFeels[]) => {
    const put = useDispatch()

    const data = {
        'internet': useSelector(selectHandbookInternet),
        'contentDelivery': useSelector(selectHandbookContentDelivery),
        'actionsUsers' : useSelector(selectHandbookActionsUsers),
        'contentDirection': useSelector(selectHandbookContentDirection),
        'organization' : useSelector(selectHandbookOrganization),
        'legalFormOrganization' : useSelector(selectHandbookLegalForm),
        'sessionsType' : useSelector(selectHandbookSessionsType),
        'categoryProject' : useSelector(selectHandbookCategoryProject),
    }

    const { isLoading } = useSelector(selectHandbookStatus)

    useEffect(() => {
        if (feels) {
            feels.forEach((el) => {
                if (data[el] === null) {
                    put(GetHandbookStartAction(el))
                }
            })
        }// eslint-disable-next-line
    }, [])

    return { isLoading, ...data }
}
export default useFeelsHandbook
