import {put} from 'redux-saga/effects'
import {GetHandbookStartAction, SetHandbookFilter} from 'store/options/actions/actions-handbook'
import {TStatusesHandbookFeelsForFilter} from 'models/handbook'

type TStatusString = {
	[key: string]: TStatusesHandbookFeelsForFilter
}

const StatusString: TStatusString = {
	'filterInternet': TStatusesHandbookFeelsForFilter.filterInternet,
	'filterContentDelivery': TStatusesHandbookFeelsForFilter.filterContentDelivery,
	'filterActionsUsers': TStatusesHandbookFeelsForFilter.filterActionsUsers,
	'filterContentDirection': TStatusesHandbookFeelsForFilter.filterContentDirection,
	'filterOrganization': TStatusesHandbookFeelsForFilter.filterOrganization,
	'filterLegalForm': TStatusesHandbookFeelsForFilter.filterLegalForm,
	'filterSessionsType': TStatusesHandbookFeelsForFilter.filterSessionsType,
	'filterCategoryProject': TStatusesHandbookFeelsForFilter.filterCategoryProject,
}

export function* setHandbookFilter({ filter }: ReturnType<typeof SetHandbookFilter>) {
	try {
		const filterName = Object.keys(filter)[0]
		yield put(GetHandbookStartAction(StatusString[filterName]))
	} catch (error: any) {
		console.log(error)
	}
}
