import {TasksActionTypes} from "../action-types/tasks";

import {ErrorActionState, StartActionState, SuccessActionState} from "../../../utils/reducerHalper";

import {InferValueTypes} from "../../../models/common";
import * as actions from '../actions/tasks'
import {TPostingTask, TTaskResponse} from "../../../models/task";
import {TAllTasksListResponse, TTaskFilter, TTaskListContent} from "../../../models/task/task-list";
import {AuthActionTypes} from "../../auth/action-types";
import {Logout} from "../../auth/reducer";

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>;
export type TTapeTask = 'gant' | 'kanban' | 'list'
export type TGetTaskArray = TTapeTask[]
export type TTasksState = {
    task: TTaskResponse | null
    taskList: TTaskListContent[] | null
    tasksNP: TAllTasksListResponse[] | null
    filter: TTaskFilter
    posting: TPostingTask[] | null
    rowIds: string[]
    isLoaded: boolean
    isLoading: boolean
    error: any
}

export const initialFilter =  {
    page: 1,
    pageSize: 50,
    filterData: {
        archival: null
    }
}

export const initialState: TTasksState = {
    task: null,
    taskList: null,
    tasksNP: null,
    filter: initialFilter,
    posting: null,
    rowIds: [],
    isLoaded: false,
    isLoading: false,
    error: null
}

export default function tasks(state: TTasksState = initialState, action: ActionTypes | Logout): TTasksState {
    switch (action.type) {
        case AuthActionTypes.LOGOUT:
            return {...initialState}
        case TasksActionTypes.SET_TASK_FILTER:
            return { ...state, filter: action.filter }
        case TasksActionTypes.RESET_TASK_FILTER:
            return { ...state, filter: action.filter }
        case TasksActionTypes.CLEAR_ALL_TASKS:
            return { ...state, tasksNP: null }
        case TasksActionTypes.CLEAR_STATUS:
            return {...state, isLoaded: false}
        case TasksActionTypes.SET_ROW_IDS:
            return {...state, rowIds: action.rowIds}
        case TasksActionTypes.CLEAR_ROW_IDS:
            return {...state, rowIds: []}
        // START
        case TasksActionTypes.CREATE_TASK_START:
            return {...StartActionState(state), task: null};
        case TasksActionTypes.GET_TASK_START:
            return {...StartActionState(state), task: null, isLoaded: false};
        case TasksActionTypes.DELETE_TASK_START:
            return {...StartActionState(state), task: null, isLoaded: false};
        case TasksActionTypes.GET_TASKS_START:
            return {...StartActionState(state), isLoaded: false}
        case TasksActionTypes.UPDATE_STATUS_TASK_START:
            return StartActionState(state)
        case TasksActionTypes.UPDATE_TASK_START:
            return {...StartActionState(state), isLoaded: false}
        case TasksActionTypes.DUPLICATE_TASKS_START:
            return StartActionState(state)
        case TasksActionTypes.GET_ALL_TASKS_START:
            return StartActionState(state)
        //SUCCESS
        case TasksActionTypes.UPDATE_STATUS_TASK_SUCCESS:
            return SuccessActionState(state)
        case TasksActionTypes.UPDATE_TASK_SUCCESS:
            return {...SuccessActionState(state), isLoaded: true}
        case TasksActionTypes.CREATE_TASK_SUCCESS:
            return {...SuccessActionState(state), isLoaded: true}
        case TasksActionTypes.GET_TASK_SUCCESS:
            return {
                ...SuccessActionState(state),
                task: action.task
            }
        case TasksActionTypes.DELETE_TASK_SUCCESS:
            return {...SuccessActionState(state), isLoaded: true}
        case TasksActionTypes.GET_TASKS_SUCCESS:
            const {content, page, ...filters} = action.tasks;
            return {
                ...SuccessActionState(state),
                taskList: content,
                filter: {
                    ...state.filter,
                    page: page + 1,
                    ...filters,
                }
            }
        case TasksActionTypes.DUPLICATE_TASKS_SUCCESS:
            return SuccessActionState(state)
        case TasksActionTypes.GET_ALL_TASKS_SUCCESS:
            return {
                ...SuccessActionState(state),
                tasksNP: action.tasks
            }
        //ERROR
        case TasksActionTypes.CREATE_TASK_ERROR:
            return ErrorActionState(initialState, action)
        case TasksActionTypes.GET_TASK_ERROR:
            return ErrorActionState(initialState, action)
        case TasksActionTypes.DELETE_TASK_ERROR:
            return ErrorActionState(initialState, action)
        case TasksActionTypes.UPDATE_STATUS_TASK_ERROR:
            return ErrorActionState(state, action)
        case TasksActionTypes.GET_TASKS_ERROR:
            return ErrorActionState(initialState, action)
        case TasksActionTypes.DUPLICATE_TASKS_ERROR:
            return ErrorActionState(state, action)
        case TasksActionTypes.GET_ALL_TASKS_ERROR:
            return ErrorActionState(state, action)
        case TasksActionTypes.UPDATE_TASK_ERROR:
            return ErrorActionState(state, action)
        default:
            return state
    }
}
