import React from 'react';
import {Radio, RadioChangeEvent, Select} from "antd";
import {useDispatch, useSelector} from "react-redux";
import './index.less';
import {SearchContentProjectModel, SearchContentProjectTypes} from 'features/search-project-content';
import {IOptions} from "models/feels";

const {Option} = Select;

type TKey = keyof Omit<
    SearchContentProjectTypes.Stores.IndicatorsContent, 'isToggled'>

const options: SearchContentProjectTypes.Stores.IndicatorsContentOptions = {
    material: [
        { label: 'Учитываемый контент', value: 'accountedContent' },
        { label: 'Неучитываемый контент', value: 'nonAccountedContent' },
        { label: 'Рабочие материалы', value: 'workingMaterial' }
    ],
    isMasterContent: [
        {label: 'Все проекты', value: 'Все проекты'},
        {label: 'Мастер файл', value: 'true'},
        {label: 'Предмастер файл', value: 'false'},
    ]
}

export const IndicatorContentItem = () => {
    return (
        <div className="item-wrapper IndicatorsContent">
             <MaterialTypeSelect/>
            <Item
                options={options.isMasterContent}
                label={'Тип файла'}
                name={'isMasterContent'}
            />
            <SecondItem/>
        </div>
    )
}
function MaterialTypeSelect() {
    const put = useDispatch();
    const { typeContent } = useSelector(SearchContentProjectModel.selectors.main);
    const indicatorsContent = useSelector(SearchContentProjectModel.selectors.indicatorsContent);

    const handleSelectChange = (value: string) => {
        let updates = {};
        switch (value) {
            case 'accountedContent':
                updates = { isContent: true, isAccountedContent: true };
                break;
            case 'nonAccountedContent':
                updates = { isContent: true, isAccountedContent: false };
                break;
            case 'workingMaterial':
                updates = { isContent: false, isAccountedContent: undefined };
                break;
            default:  updates = { isContent: undefined, isAccountedContent: undefined };
                break;
        }
        put(SearchContentProjectModel.actions.setIndicatorsContent(typeContent, updates));
    };

    return (
        <div className="item">
            <div className="label">Тип материала</div>
            <div className="form-group">
                <Select
                    allowClear
                    value={getSelectValue(indicatorsContent, typeContent)}
                    onChange={handleSelectChange}
                    placeholder="Выберите тип материала"
                >
                    {options.material.map((option) => (
                        <Option key={option.value} value={option.value}>
                            {option.label}
                        </Option>
                    ))}
                </Select>
            </div>
        </div>
    );
}
type ItemProps = {
    options: IOptions[],
    name: TKey,
    label: string
}
function Item({options, name, label}: ItemProps) {
    const put = useDispatch();
    const {typeContent} = useSelector(SearchContentProjectModel.selectors.main)
    const indicatorsContent = useSelector(SearchContentProjectModel.selectors.indicatorsContent)
    const onGroupChange = (e: RadioChangeEvent) => {
        put(SearchContentProjectModel.actions.setIndicatorsContent(typeContent, {[name]: e.target.value}))
    }
    return (
        <div className="item">
            <div className="label">{label}</div>
            <div className="form-group form-group--radio">
                <Radio.Group
                    value={indicatorsContent[typeContent][name]}
                    onChange={onGroupChange}
                >
                    {options.map((option: IOptions) => {
                        return (
                            <Radio
                                checked={indicatorsContent[typeContent][name] === (option.value)}
                                key={option.label}
                                value={option.value}
                            >
                                {option.label}
                            </Radio>
                        )
                    })}
                </Radio.Group>
            </div>
        </div>
    )
}

function SecondItem() {
    const put = useDispatch();

    const {typeContent} = useSelector(SearchContentProjectModel.selectors.main)
    const values = useSelector(SearchContentProjectModel.selectors.indicatorsContent)
    function onSelectChange(value: string[]) {
        put(SearchContentProjectModel.actions.setValuesIndicatorsContent(typeContent, {
            contentStatusCodes: value
        }))
    }
    const data: IOptions[] = [
        {label: 'Согласовано', value: 'APPROVED'},
        {label: 'Отклонено', value: 'REJECTED'}
    ]

    if (typeContent === 'content') return null

    return (
        <div className="item">
            <div className="label">Статус согласования</div>
            <div className="form-group">
                <Select
                    getPopupContainer={trigger => trigger.parentNode}
                    allowClear={true}
                    onChange={onSelectChange}
                    maxTagCount={"responsive"}
                    mode={"multiple"}
                    value={values[typeContent]['contentStatusCodes']}
                    placeholder={'Искать среди всех'}
                    filterOption={(input, option) => {
                        if (option === undefined || option === null) return false;
                        return option['data-name'].toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }}
                >
                    {data && data.map((el) => (
                        <Option
                            key={el.value}
                            value={el.value}
                            data-name={el.label}
                        >
                            {el.label}
                        </Option>
                    ))}
                </Select>
            </div>
        </div>
    )
}


function getSelectValue (indicatorsContent: SearchContentProjectTypes.Stores.TIndicatorsContentState, typeContent: SearchContentProjectTypes.Stores.TTypeStore) {
    const content = indicatorsContent[typeContent];
    if (content.isContent === "Все проекты" || content.isContent === undefined) {
        return undefined;
    }

    if (content.isContent) {
        return content.isAccountedContent
            ? 'accountedContent'
            : 'nonAccountedContent';
    }

    return 'workingMaterial';
};
