import {TypesTask} from 'entities/task/types'
import {Content, Report, TActions} from 'entities/task/types/records'

export function validateForCoordinator({
	archival,
	typeTask,
	recordStatus,
}: Content | Report): TActions {
	const view: TActions = {
		edit: 'hidden',
		delete: 'hidden',
		changeStatus: 'hidden',
		send: 'hidden',
		cancel: 'hidden',
		save: 'hidden',
	}
	if (archival) {
		if (typeTask === TypesTask.REPORT) {
			if (recordStatus === 'APPROVED') {
				view.edit = 'view'
				view.delete = 'view'
			}
		}
	} else {
		if (typeTask === TypesTask.REPORT) {
			if (recordStatus === 'APPROVED') {
				view.edit = 'view'
				view.delete = 'view'
			}
		}
	}
	return view
}
