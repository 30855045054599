import {TRole} from "models/user";

export default function ConvertRole(type: TRole | string) {
    if(type?.split('_')[0] === 'ROLE') {
        switch (type) {
            case 'ROLE_ADMIN':
                return 'Администратор'
            case 'ROLE_CONTRACTOR':
                return 'Исполнитель'
            case 'ROLE_COORDINATOR':
                return 'Координатор'
            case 'ROLE_ANALYST':
                return 'Аналитик'
            case 'ROLE_PRODUCER':
                return 'Продюсер'
            case 'ROLE_FINANCIER':
                return 'Финансист'
            case 'ROLE_LAWYER':
                return 'Юрист'
            case 'ROLE_CLIENT':
                return 'Клиент'
            case 'ROLE_MODERATOR':
                return 'Редактор каталога'
            case 'ROLE_AGENT':
                return 'Представитель'
            case 'ROLE_CONTENT_EDITOR':
                return 'Редактор контента'
            case 'ROLE_PROJECT_MANAGER':
                return 'Менеджер проектов'
            case 'ROLE_VIEWER':
                return 'Наблюдатель'
            case 'ROLE_ADMIN_IS':
                return 'Администратор информационной безопасности'
        }
    } else {
        return type
    }
}
