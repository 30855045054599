import {TApplicationState} from "store/aplication-state";
import {createSelector} from "reselect";
import { SearchContentProjectTypes } from "features/search-project-content";


const getState = (state: TApplicationState) => state.feature.searchProjectContent;
export const isToggle = createSelector(getState, (state) => ({
    projects: {
        contests: state.contests.projects.isToggled,
        status: state.status.projects.isToggled,
        indicators: state.indicators.projects.isToggled,
        indicatorsContent: state.indicatorsContent.projects.isToggled,
        thematics: state.thematics.projects.isToggled,
        regionals: state.regionals.projects.isToggled,
        content: state.content.projects.isToggled,
        dates: state.dates.projects.isToggled,
        categoriesGenres: state.categoriesGenres.projects.isToggled,
    },
    content: {
        contests: state.contests.content.isToggled,
        status: state.status.content.isToggled,
        indicators: state.indicators.content.isToggled,
        indicatorsContent: state.indicatorsContent.content.isToggled,
        thematics: state.thematics.content.isToggled,
        regionals: state.regionals.content.isToggled,
        content: state.content.content.isToggled,
        dates: state.dates.content.isToggled,
        categoriesGenres: state.categoriesGenres.content.isToggled,
    },
    calendar: {
        contests: state.contests.calendar.isToggled,
        status: state.status.calendar.isToggled,
        indicators: state.indicators.calendar.isToggled,
        indicatorsContent: state.indicatorsContent.calendar.isToggled,
        thematics: state.thematics.calendar.isToggled,
        regionals: state.regionals.calendar.isToggled,
        content: state.content.calendar.isToggled,
        dates: state.dates.calendar.isToggled,
        categoriesGenres: state.categoriesGenres.calendar.isToggled,
    }
}))
export const isToggleFilter = (type: SearchContentProjectTypes.Stores.TTypeStore) =>
    createSelector(isToggle, (state) => {
        const values = Object.values(state[type])
        return values.some((el) => el === true)
    })
export const main = createSelector(getState, (state) => state.main)
export const contests = createSelector(getState, (state) => state.contests)
export const status = createSelector(getState, (state) => state.status)
export const indicators = createSelector(getState, (state) => state.indicators)
export const indicatorsContent = createSelector(getState, (state) => state.indicatorsContent)
export const thematics = createSelector(getState, (state) => state.thematics)
export const regionals = createSelector(getState, (state) => state.regionals)
export const content = createSelector(getState, (state) => state.content)
export const dates = createSelector(getState, (state) => state.dates)
export const categoriesGenres = createSelector(getState, (state) => state.categoriesGenres)

export const filter = (type: SearchContentProjectTypes.Stores.TTypeStore) => createSelector(
    getState, (state) => ({
        contests: {
            contestId: state.contests[type].contestId,
            sessionId: state.contests[type].sessionId,
            year: Number(state.contests[type].year),
        },
        status: {
            statusIds: state.status[type].statusIds,
            contentDirectionIds: state.status[type].contentDirectionIds,
        },
        indicators: {
          isProjectPosted: getBoolean(state.indicators[type].isProjectPosted),
          isFavoriteProject: getBoolean(state.indicators[type].isFavoriteProject),
        },
        indicatorsContent: {
            isContent: getAccountedContent(state.indicatorsContent[type].isContent),
            isMasterContent: getBoolean(state.indicatorsContent[type].isMasterContent),
            isAccountedContent: getAccountedContent(state.indicatorsContent[type].isAccountedContent),
            contentStatusCodes: state.indicatorsContent[type].contentStatusCodes
        },
        thematics: state.thematics[type],
        regionals: state.regionals[type].fields.filter((el) => el.field !== 'Параметр неважен'),
        categoriesGenres: state.categoriesGenres[type].fields.filter((el) => el.field !== 'Параметр неважен'),
        content: {
          contentFormatTypeIds: state.content[type].contentFormatTypeIds,
          contentFilterContentSubmissionFormIds: state.content[type].contentFilterContentSubmissionFormIds,
          contentSubmissionFormIds: state.content[type].contentSubmissionFormIds,
          contentFilterContentFormatTypeIds: state.content[type].contentFilterContentFormatTypeIds,
        },
        dates: state.dates[type].fields.filter((el) => el.field !== 'Параметр неважен'),
    })
)
function getBoolean(value: SearchContentProjectTypes.Stores.ValueTypes) {
    if (value === 'true') return true;
    else if (value === 'false') return false;
    return undefined;
}
function getAccountedContent(value: string | boolean) {
    return  typeof value === 'boolean' ? value : undefined
}
