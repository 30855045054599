import React from 'react';
import {Select} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {selectFilter} from "store/content/content/selectors";
import {SetSortingFilter} from "store/content/content/actions";
import {TFilterData} from "models/content";

const options = [
  { label: 'Учитываемый контент', value: 'accountedContent' },
  { label: 'Неучитываемый контент', value: 'nonAccountedContent' },
  { label: 'Рабочие материалы', value: 'workingMaterial' },
]


const MaterialTypeSelect = () => {
  const put = useDispatch()
  const filter = useSelector(selectFilter)

  const onChange = (value: string) => {
    let newFilter: TFilterData = {};
    if (filter.filterData) newFilter = { ...filter.filterData }

    const typeValue = value as 'accountedContent' | 'nonAccountedContent' | 'workingMaterial'

    const dataMap = {
      accountedContent: { isContent: true, isAccountedContent: true },
      nonAccountedContent: { isContent: true, isAccountedContent: false },
      workingMaterial: { isContent: false, isAccountedContent: undefined },
    }

    const data = dataMap[typeValue] || { isContent: undefined, isAccountedContent: undefined }

    newFilter = { ...newFilter, ...data}

    put(SetSortingFilter({ ...filter, filterData: newFilter }))
  }
  return (
    <div className="sorting__item">
      <Select
        value={getIsContentValue(filter.filterData?.isContent, filter.filterData?.isAccountedContent)}
        style={{ 'width': '200px' }}
        placeholder="Все значение"
        onChange={onChange}
        maxTagCount={"responsive"}
        allowClear
        showSearch
        options={options}
        optionFilterProp="children"
        filterOption={(input, option) =>
          (option?.label as unknown as string).toLowerCase().includes(input.toLowerCase())
        }
      />
    </div>
  )
}

function getIsContentValue(isContent?: boolean, isAccountedContent?: boolean) {
  if (isContent && isAccountedContent) return 'accountedContent'
  if (isContent && !isAccountedContent) return 'nonAccountedContent'
  if (typeof isContent === 'boolean' && !isContent) return 'workingMaterial'

  return undefined
}

export default MaterialTypeSelect;
