import {put, select} from 'redux-saga/effects'
import {NotificationOpen} from 'store/notification/actions'
import CreateErrorMessage from 'utils/validation/createErrorMessage'
import {AxiosResponse} from 'axios'
import {downloadXLXS} from 'utils/downloadXLXS'
import {
	DownloadReportTemplateErrorAction,
	DownloadReportTemplateStartAction,
	DownloadReportTemplateSuccessAction
} from "store/tasks/actions/report";
import {ReportType} from "models/task/types";
import {
	TasksReportCopyrightService,
	TasksReportEstimateService,
	TasksReportPostingService,
	TasksReportReachService
} from "services/tasksReportService";
import {ClearKanbanCardIds, SetTasksKanbanFilter} from "store/tasks/actions/gant-kanban";
import {GetAllTasksStartAction} from "../../../store/tasks/actions/tasks";
import {selectParams} from "../../../store/selectors";

export function* downloadTemplate({ reportType, rowIds }: ReturnType<typeof DownloadReportTemplateStartAction>) {
	try {
		const {id: projectId} =  yield select(selectParams)
		const location = window.location.href.split('/');
		const page = location[3]

		if (reportType === ReportType.CONTENT_POSTING_REPORT) {
			const {data}: AxiosResponse<string> = yield TasksReportPostingService.downloadTemplate(rowIds)
			downloadXLXS(data, `шаблон размещении контента`)

		}
		if (reportType === ReportType.CONTENT_REACH_REPORT) {
			const {data}: AxiosResponse<string> = yield TasksReportReachService.downloadTemplate(rowIds)
			downloadXLXS(data, `шаблон охвата размещённого контента`)

		}
		if (reportType === ReportType.COPYRIGHT_LIST) {
			const {data}: AxiosResponse<string> = yield TasksReportCopyrightService.downloadTemplate(rowIds)
			downloadXLXS(data, `шаблон списка объектов авторского права`)

		}
		if (reportType === ReportType.PROJECT_ESTIMATE) {
			const {data}: AxiosResponse<string> = yield TasksReportEstimateService.downloadTemplate(rowIds)
			downloadXLXS(data, `шаблон сметы проекта`)
		}
		yield put(DownloadReportTemplateSuccessAction())

		if (page === 'project') {
			yield put(ClearKanbanCardIds())
			yield put(SetTasksKanbanFilter(projectId,{ filterData: { searchString: null, stageId: null } }))
		}
		if (page === 'tasks') {
			yield put(GetAllTasksStartAction())
		}

	} catch (error: any) {
		yield put(DownloadReportTemplateErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось скачать шаблон'))
	}
}

