import React, {Key, useEffect, useState} from 'react';
import styles from './styles.module.less';
import {useHistory} from 'react-router-dom';
import {Button, Input, Radio, Table, Tooltip} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import classNames from 'classnames';
import {TablePaginationConfig} from 'antd/lib/table/interface';
import {ColumnsType} from 'antd/es/table';
import {useQuery} from 'react-query';
import {TASKS} from 'routing/names';
import NameCenterCell from 'components/table-cells/name-center-cell';
import ActionCell from 'components/table-cells/action-cell';
import PathConverter from 'utils/path-converter';
import LinkPropsCell from 'components/table-cells/link-props-cell';
import handlerTable from 'utils/handleTable';
import {ModalEvent} from 'shared/ui/modal';
import ModalScheduler from '../modal-scheduler';
import ModalDuplication from '../modal-duplication';
import {action, select} from '../../model';
import {DayOfWeekHandbook} from '../../lib';
import {DuplicateSchedule} from '../../types';
import {TaskDuplicationSchedulerController} from 'features/сonfiguring-task-autocreation/api';
import SwitchCell from './switch-cell';
import {useDebounceValue} from 'hooks/useDebounce';
import ScheduleCell from './schedule-cell';

const TableScheduler = () => {
	const put = useDispatch();
	const history = useHistory();
	const filter = useSelector(select.filter);
	const [searchString, setSearchString] = useState(filter.filterData?.searchString);
	const debouncedValue = useDebounceValue(searchString, 2000)

	useEffect(() => {
		if (debouncedValue) {
			put(action.setValue({...filter, filterData: {...filter.filterData, searchString: debouncedValue}}))
		}
		// eslint-disable-next-line
	}, [debouncedValue]);

	const {data, isLoading} = useQuery({
		queryKey: [TaskDuplicationSchedulerController.search.key, filter.page, filter.pageSize, filter.sort, filter.filterData],
		queryFn: async () => {
			const {content, page, totalCount,...rest} = await TaskDuplicationSchedulerController.search.fetch(filter);
			put(action.setValue({...rest, page: page + 1}))
			return {content, totalCount}
		}
	})
	const onOpenModal = (mode: 'create' |'update') => () => {
		ModalEvent.open({
			component: <ModalScheduler mode={mode}/>,
			destroyOnClose: true,
			width: 780
		})
	}
	const onOpenUpdateModal = (taskDuplicationScheduleId: string, rowData: DuplicateSchedule) => () => {
		ModalEvent.open({
			component: (
				<ModalDuplication
					taskName={rowData.task.name}
					taskId={rowData.task.id}
					duplicationSchedule={{
						taskId: rowData.task.id,
						id: taskDuplicationScheduleId,
						enabled: rowData.enabled,
						dayOfWeek: rowData.dayOfWeek,
						scheduleDates: rowData.scheduleDates,
						daysToDeadline: rowData.daysToDeadline,
						lastTaskDate: rowData.lastTaskDate,
						firstTaskDate: rowData.firstTaskDate
					}}
				/>),
			destroyOnClose: true,
			width: 780
		})
	}
	const onSearchSearch = (value: string) => {
		if (value) {
			put(action.setValue({...filter, filterData: {...filter.filterData, searchString: value}}))
		} else {
			put(action.setValue({...filter, filterData: {...filter.filterData, searchString: undefined}}))
		}
	}
	const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		if (value) {
			setSearchString(value)
		} else {
			put(action.setValue({...filter, filterData: {...filter.filterData, searchString: undefined}}))
		}
	}
	const onRadioChange = (event: any) => {
		put(action.setValue({...filter, filterData: {...filter.filterData, enabled: event.target.value}}))
	}

	const handlerPagination = async (
		pagination: TablePaginationConfig,
		filter: Record<string, (Key | boolean)[] | null>,
		sorter: any,
	) => {
		const {sort, ...rest}: any = handlerTable(pagination, filter, sorter)
		put(action.setValue({sort, ...rest}))
	}

	const columns: ColumnsType<DuplicateSchedule> = [
		{
			title: 'Включение / выключение',
			dataIndex: 'enabled',
			key: 'enabled',
			sorter: true,
			width: 100,
			render: SwitchCell
		},
		{
			title: 'Дата создания расписания',
			dataIndex: 'createDateTime',
			key: 'createDateTime',
			width: 100,
			sorter: true,
			render: (creationDate: string) => NameCenterCell(moment(creationDate).format('DD.MM.YYYY'))
		},
		{
			title: 'Тип расписания',
			dataIndex: 'scheduleDates',
			key: 'scheduleDates',
			width: 100,
			render: (scheduleDates: string[]) => NameCenterCell(
				!!scheduleDates.length ? 'по конкретным датам' : 'по заданным условиям'
			)
		},
		{
			title: 'Период создания первого и последнего дублей',
			dataIndex: 'firstTaskDate',
			key: 'firstTaskDate',
			width: 130,
			render: ScheduleCell
		},
		{
			title: 'Частота создания (День недели)',
			dataIndex: 'dayOfWeek',
			key: 'dayOfWeek',
			width: 100,
			render: (_: any, rowData) => NameCenterCell(
				rowData.dayOfWeek ? DayOfWeekHandbook[rowData.dayOfWeek] : '-'
			)
		},
		{
			title: 'Количество дней до дедлайна задач',
			dataIndex: 'daysToDeadline',
			key: 'daysToDeadline',
			width: 100,
			render: (_: any, rowData) => NameCenterCell(String(rowData.daysToDeadline))
		},
		{
			title: 'Задача, работающая по расписанию',
			dataIndex: 'task',
			key: 'task',
			width: 150,
			render: (task: DuplicateSchedule['task']) => LinkPropsCell(task.id,
				() => {
					history.push(PathConverter(TASKS.TASK, task.id), {tooltip: task.name})
				}, `${task.code}-${task.number} - ${task.name}`,
				`Перейти в карточку задачи: ${task.name}`
			)
		},
		{
			title: '',
			dataIndex: 'id',
			key: 'id',
			width: 100,
			render: (id: string, rowData) => ActionCell({
				onEdit: onOpenUpdateModal
			})(id, rowData)
		},
	]

	return (
		<div className={classNames(styles.container)}>
			<div className={styles.filter}>
				<div className={styles.wrapper}>
					<Tooltip title={'Поиск по коду, названию и номеру задачи'}>
						<div className={classNames("form-group", styles.search)}>
							<Input.Search
								onChange={onSearchChange}
								onSearch={onSearchSearch}
								allowClear
								placeholder={'Поиск задач проектов'}
							/>
						</div>
					</Tooltip>
					<Radio.Group
						className={styles.radio_group}
						name="radiogroup"
						value={filter.filterData?.enabled}
						onChange={onRadioChange}
					>
						<Radio value={undefined}>Все</Radio>
						<Radio value={true}>Включено</Radio>
						<Radio value={false}>Выключено</Radio>
					</Radio.Group>
				</div>


				<div className={styles.buttons}>
					<Button
						type={'primary'}
						onClick={onOpenModal('create')}
						className="tour-button-create"
					>
						Массовое создание расписаний дублирования
					</Button>
					<Button
						className={classNames(styles.second,'tour-button-update')}
						type={'primary'}
						onClick={onOpenModal('update')}
					>
						Массовое обновление расписаний дублирования
					</Button>
				</div>
			</div>
			<div className={styles.table}>
				<Table
					loading={isLoading}
					dataSource={data?.content || []}
					columns={columns}
					className='table'
					sticky
				 	scroll={{x: 1000}}
					pagination={{
						current: filter.page,
						pageSize: filter.pageSize,
						total: data?.content ? data?.totalCount : 1,
						position: ['bottomCenter'],
						showSizeChanger: true,
						pageSizeOptions: ['50', '100', '500', '1000' ,'3000']
					}}
					onChange={handlerPagination}
					rowKey='id'
				/>
			</div>
		</div>
	)
};

export default TableScheduler;
