export const LogMapWithParams: Record<string, Record<string, string>> = {
    head: {
        "/files": "GET_FILES_FILE_ID",
    },
    get: {
        "/awards/fields-data": "GET_AWARDS_FIELDS_DATA_FIELDCODE",
        "/contents/projects": "GET_CONTENTS_PROJECTS_PROJECT_ID",
        "/contests": "GET_CONTESTS_CONTEST_ID",
        "/files": "GET_FILES_FILE_ID",
        "/files/pdf-preview-created": "GET_FILES_PDF_PREVIEW_CREATED__ID",
        "/filters/types": "GET_FILTERS_TYPES_TYPECODE",
        "/help-desk/auth/check": "GET_HELP_DESK_AUTH_CHECK_EMAIL",
        "/internet-resources/project-id": "GET_INTERNET_RESOURCES_PROJECT__ID_PROJECT_ID",
        "/notifications": "GET_NOTIFICATIONS_NOTIFICATION_ID",
        "/notifications/browser-notification/not-read": "GET_NOTIFICATIONS_BROWSER_NOTIFICATION_NOT_READ_USER_ID",
        "/notifications/browser-notifications": "GET_NOTIFICATIONS_BROWSER_NOTIFICATIONS_USER_ID",
        "/notifications/browser-notifications/count": "GET_NOTIFICATIONS_BROWSER_NOTIFICATIONS_COUNT_USER_ID",
        "/notifications/browser-notifications/count/unread": "GET_NOTIFICATIONS_BROWSER_NOTIFICATIONS_COUNT_UNREAD_USER_ID",
        "/notifications/email": "GET_NOTIFICATIONS_EMAIL_NOTIFICATION_ID",
        "/notifications/personal": "GET_NOTIFICATIONS_PERSONAL_USER_ID",
        "/notifications/setting": "GET_NOTIFICATIONS_SETTING_NOTIFICATION_ID",
        "/notifications/sms": "GET_NOTIFICATIONS_SMS_NOTIFICATION_ID",
        "/notifications/system-message": "GET_NOTIFICATIONS_SYSTEM_MESSAGE_NOTIFICATION_ID",
        "/notifications/telegram": "GET_NOTIFICATIONS_TELEGRAM_NOTIFICATION_ID",
        "/organizations": "GET_ORGANIZATIONS__ID",
        "/organizations/by-role": "GET_ORGANIZATIONS_BY_ROLE_ROLE_ID",
        "/projects": "GET_PROJECTS_PROJECT_ID",
        "/projects/documents": "GET_PROJECTS_DOCUMENTS_PROJECT_ID",
        "/regulated-integration/project-data": "GET_REGULATED_INTEGRATION_PROJECT_DATA_PROJECT_ID",
        "/reports": "GET_REPORTS__ID",
        "/reports/pdf-created": "GET_REPORTS_PDF_CREATED__ID",
        "/reports/schedulers": "GET_REPORTS_SCHEDULERS_REPORTSCHEDULE_ID",
        "/sessions": "GET_SESSIONS_SESSION_ID",
        "/sprints": "GET_SPRINTS_SPRINT_ID",
        "/sprints/all": "GET_SPRINTS_ALL_PROJECT_ID",
        "/tasks": "GET_TASKS_TASK_ID",
        "/tasks/content/records": "GET_TASKS_CONTENT_RECORDS__ID",
        "/tasks/duplication/schedulers": "GET_TASKS_DUPLICATION_SCHEDULERS_TASKDUPLICATIONSCHEDULE_ID",
        "/ui-table-view-restriction": "GET_UI_TABLE_VIEW_RESTRICTION_TYPECODE",
        "/users": "GET_USERS_USER_ID",
        "/tasks/reach/records/search": "GET_TASKS_REACH_RECORDS_SEARCH_RECORD_ID",
        "/tasks/posting/records/search": "GET_TASKS_POSTING_RECORDS_SEARCH_RECORD_ID",
        "/tasks/estimate/records/search": "GET_TASKS_ESTIMATE_RECORDS_SEARCH_RECORD_ID",
        "/tasks/copyright/records/search": "GET_TASKS_COPYRIGHT_RECORDS_SEARCH_RECORD_ID",
        "/tasks/content/records/search": "GET_TASKS_CONTENT_RECORDS_SEARCH_RECORD_ID",
        "/system-settings-parameters": "GET_SYSTEM_SETTINGS_PARAMETERS_PARAMETER_ID",
        "/notifications/telegram/user/subscriptions": "GET_NOTIFICATIONS_TELEGRAM_USER_SUBSCRIPTIONS_TELEGRAM_USER_ID"
    },
    post: {
        "/category-types/bulk-actions": "POST_CATEGORY_TYPES_BULK_ACTIONS_ACTION",
        "/contests/bulk-actions": "POST_CONTESTS_BULK_ACTIONS_ACTION",
        "/document-types/bulk-actions": "POST_DOCUMENT_TYPES_BULK_ACTIONS_ACTION",
        "/genre-types/bulk-actions": "POST_GENRE_TYPES_BULK_ACTIONS_ACTION",
        "/help-desk/auth/init": "POST_HELP_DESK_AUTH_INIT_EMAIL",
        "/internet-resources/bulk-actions": "POST_INTERNET_RESOURCES_BULK_ACTIONS_ACTION",
        "/kpi-types/bulk-actions": "POST_KPI_TYPES_BULK_ACTIONS_ACTION",
        "/notifications": "POST_NOTIFICATIONS_NOTIFICATION_ID",
        "/notifications/setting/bulk-actions": "POST_NOTIFICATIONS_SETTING_BULK_ACTIONS_ACTION",
        "/organization-legal-forms/bulk-actions": "POST_ORGANIZATION_LEGAL_FORMS_BULK_ACTIONS_ACTION",
        "/organizations/bulk-actions": "POST_ORGANIZATIONS_BULK_ACTIONS_ACTION",
        "/project-tags/bulk-actions": "POST_PROJECT_TAGS_BULK_ACTIONS_ACTION",
        "/projects/bulk-actions": "POST_PROJECTS_BULK_ACTIONS_ACTION",
        "/projects/directions/bulk-actions": "POST_PROJECTS_DIRECTIONS_BULK_ACTIONS_ACTION",
        "/projects/format_types/bulk-actions": "POST_PROJECTS_FORMAT_TYPES_BULK_ACTIONS_ACTION",
        "/projects/submission_forms/bulk-actions": "POST_PROJECTS_SUBMISSION_FORMS_BULK_ACTIONS_ACTION",
        "/projects/thematics/bulk-actions": "POST_PROJECTS_THEMATICS_BULK_ACTIONS_ACTION",
        "/rank/bulk-actions": "POST_RANK_BULK_ACTIONS_ACTION",
        "/region/types/bulk-actions": "POST_REGION_TYPES_BULK_ACTIONS_ACTION",
        "/regulated-integration/project-data": "POST_REGULATED_INTEGRATION_PROJECT_DATA_PROJECT_ID",
        "/sessions/bulk-actions": "POST_SESSIONS_BULK_ACTIONS_ACTION",
        "/sessions/types/bulk-actions": "POST_SESSIONS_TYPES_BULK_ACTIONS_ACTION",
        "/tasks/content/records/bulk-actions": "POST_TASKS_CONTENT_RECORDS_BULK_ACTIONS_ACTION",
        "/tasks/posting/records/bulk-actions": "POST_TASKS_POSTING_RECORDS_BULK_ACTIONS_ACTION",
        "/tasks/reach/records/bulk-actions": "POST_TASKS_REACH_RECORDS_BULK_ACTIONS_ACTION",
        "/tasks/set-status": "POST_TASKS_SET_STATUS_STATUS",
        "/users/bulk-actions": "POST_USERS_BULK_ACTIONS_ACTION",
        "/users/set-status": "POST_USERS_SET_STATUS_STATUS",
        "/projects/content-categories/bulk-actions": "POST_PROJECTS_CONTENT_CATEGORIES_BULK_ACTIONS_ACTION",
        "/film-cluster/bulk-actions" : "POST_FILM_CLUSTER_BULK_ACTIONS_ACTION",
        "/famous-people/bulk-actions" : "POST_FAMOUS_PEOPLE_BULK_ACTIONS_ACTION",
        "/notifications/confirmation/telegram" : "POST_NOTIFICATIONS_CONFIRMATION_TELEGRAM_TELEGRAM_USER_ID",
    },
    put: {
        "/system-settings-parameters": "PUT_SYSTEM_SETTINGS_PARAMETERS_PARAMETER_ID",
        "/awards": "UPDATE_AWARDS__ID",
        "/category-types": "UPDATE_CATEGORY_TYPES__ID",
        "/contests": "UPDATE_CONTESTS_CONTENT_ID",
        "/document-types": "UPDATE_DOCUMENT_TYPES__ID",
        "/filters": "UPDATE_FILTERS__ID",
        "/genre-types": "UPDATE_GENRE_TYPES__ID",
        "/help-desk/auth/confirm": "UPDATE_HELP_DESK_AUTH_CONFIRM_CODE",
        "/integration/awards": "UPDATE_INTEGRATION_AWARDS_CODE",
        "/integration/ranks": "UPDATE_INTEGRATION_RANKS_CODE",
        "/internet-resources": "UPDATE_INTERNET_RESOURCES__ID",
        "/kpi-types": "UPDATE_KPI_TYPES_TYPE_ID",
        "/notifications/browser-notification/read": "UPDATE_NOTIFICATIONS_BROWSER_NOTIFICATION_READ__ID",
        "/notifications/browser-notification/unread": "UPDATE_NOTIFICATIONS_BROWSER_NOTIFICATION_UNREAD__ID",
        "/notifications/browser-notifications/all-read": "UPDATE_NOTIFICATIONS_BROWSER_NOTIFICATIONS_ALL_READ_USER_ID",
        "/notifications/confirmation/telegram/confirm": "UPDATE_NOTIFICATIONS_CONFIRMATION_TELEGRAM_CONFIRM_CONFIRMATIONCODE",
        "/notifications/email": "UPDATE_NOTIFICATIONS_EMAIL_NOTIFICATION_ID",
        "/notifications/personal/update": "UPDATE_NOTIFICATIONS_PERSONAL_UPDATE_USER_ID",
        "/notifications/setting": "UPDATE_NOTIFICATIONS_SETTING_NOTIFICATION_ID",
        "/notifications/sms": "UPDATE_NOTIFICATIONS_SMS_NOTIFICATION_ID",
        "/notifications/system-message": "UPDATE_NOTIFICATIONS_SYSTEM_MESSAGE_NOTIFICATION_ID",
        "/notifications/telegram": "UPDATE_NOTIFICATIONS_TELEGRAM_NOTIFICATION_ID",
        "/organization-legal-forms": "UPDATE_ORGANIZATION_LEGAL_FORMS_ORGANIZATIONLEGALFORM_ID",
        "/organizations": "UPDATE_ORGANIZATIONS__ID",
        "/project-labels": "UPDATE_PROJECT_LABELS__ID",
        "/project-tags": "UPDATE_PROJECT_TAGS__ID",
        "/projects": "UPDATE_PROJECTS_PROJECT_ID",
        "/projects/directions": "UPDATE_PROJECTS_DIRECTIONS__ID",
        "/projects/documents": "UPDATE_PROJECTS_DOCUMENTS__ID",
        "/projects/format_types": "UPDATE_PROJECTS_FORMAT_TYPES__ID",
        "/projects/submission_forms": "UPDATE_PROJECTS_SUBMISSION_FORMS__ID",
        "/projects/thematics": "UPDATE_PROJECTS_THEMATICS__ID",
        "/rank": "UPDATE_RANK_RANK_ID",
        "/region/types": "UPDATE_REGION_TYPES_REGIONTYPE_ID",
        "/regulated-integration/project-data": "UPDATE_REGULATED_INTEGRATION_PROJECT_DATA_PROJECT_ID",
        "/reports/schedulers": "UPDATE_REPORTS_SCHEDULERS_REPORTSCHEDULE_ID",
        "/sessions": "UPDATE_SESSIONS_SESSION_ID",
        "/sessions/types": "UPDATE_SESSIONS_TYPES__ID",
        "/sprints": "UPDATE_SPRINTS_SPRINT_ID",
        "/task-comments": "UPDATE_TASK_COMMENTS__ID",
        "/task-duplication-schedule-date-presets": "UPDATE_TASK_DUPLICATION_SCHEDULE_DATE_PRESETS__ID",
        "/tasks": "UPDATE_TASKS_TASK_ID",
        "/tasks/content/records": "UPDATE_TASKS_CONTENT_RECORDS_RECORD_ID",
        "/tasks/copyright/records": "UPDATE_TASKS_COPYRIGHT_RECORDS_RECORD_ID",
        "/tasks/duplication/schedulers": "UPDATE_TASKS_DUPLICATION_SCHEDULERS_TASKDUPLICATIONSCHEDULE_ID",
        "/tasks/estimate/records": "UPDATE_TASKS_ESTIMATE_RECORDS_RECORD_ID",
        "/tasks/posting/records": "UPDATE_TASKS_POSTING_RECORDS_RECORD_ID",
        "/tasks/reach/records": "UPDATE_TASKS_REACH_RECORDS_RECORD_ID",
        "/users": "UPDATE_USERS_USER_ID",
        "/users/actions/types": "UPDATE_USERS_ACTIONS_TYPES_ACTIONTYPE_ID",
        "/projects/content-categories": "PUT_PROJECTS_CONTENT_CATEGORIES_CONTENT_FORMAT_TYPE_CATEGORY_ID",
        "/notifications": "PUT_NOTIFICATIONS_NOTIFICATION_ID",
        "/film-cluster": "PUT_FILM_CLUSTER_ID",
        "/famous-people": "PUT_FAMOUS_PEOPLE_ID",
        "/erv-ranges": "PUT_ERV_RANGES_RANGEID",
    },
    delete: {
        "/awards": "DELETE_AWARDS__ID",
        "/category-types": "DELETE_CATEGORY_TYPES__ID",
        "/contests": "DELETE_CONTESTS_CONTEST_ID",
        "/document-types": "DELETE_DOCUMENT_TYPES__ID",
        "/file-archive-requests": "DELETE_FILE_ARCHIVE_REQUESTS__ID",
        "/files/uploading": "DELETE_FILES_UPLOADING_FILEUPLOAD_ID",
        "/filters": "DELETE_FILTERS__ID",
        "/genre-types": "DELETE_GENRE_TYPES__ID",
        "/integration/awards": "DELETE_INTEGRATION_AWARDS_CODE",
        "/integration/ranks": "DELETE_INTEGRATION_RANKS_CODE",
        "/internet-resources": "DELETE_INTERNET_RESOURCES__ID",
        "/kpi-types": "DELETE_KPI_TYPES__ID",
        "/organization-legal-forms": "DELETE_ORGANIZATION_LEGAL_FORMS__ID",
        "/organizations": "DELETE_ORGANIZATIONS__ID",
        "/project-labels": "DELETE_PROJECT_LABELS__ID",
        "/project-tags": "DELETE_PROJECT_TAGS__ID",
        "/projects": "DELETE_PROJECTS_PROJECT_ID",
        "/projects/directions": "DELETE_PROJECTS_DIRECTIONS__ID",
        "/projects/documents}": "DELETE_PROJECTS_DOCUMENTS__ID",
        "/projects/format_types": "DELETE_PROJECTS_FORMAT_TYPES__ID",
        "/projects/submission_forms": "DELETE_PROJECTS_SUBMISSION_FORMS__ID",
        "/projects/thematics": "DELETE_PROJECTS_THEMATICS__ID",
        "/rank": "DELETE_RANK__ID",
        "/region/types": "DELETE_REGION_TYPES__ID",
        "/reports/schedulers": "DELETE_REPORTS_SCHEDULERS_REPORTSCHEDULE_ID",
        "/sessions": "DELETE_SESSIONS_SESSION_ID",
        "/sessions/types": "DELETE_SESSIONS_TYPES__ID",
        "/sprints": "DELETE_SPRINTS_SPRINT_ID",
        "/task-comments": "DELETE_TASK_COMMENTS__ID",
        "/task-duplication-schedule-date-presets": "DELETE_TASK_DUPLICATION_SCHEDULE_DATE_PRESETS__ID",
        "/tasks": "DELETE_TASKS_TASK_ID",
        "/tasks/content/records": "DELETE_TASKS_CONTENT_RECORDS_RECORD_ID",
        "/tasks/copyright/records": "DELETE_TASKS_COPYRIGHT_RECORDS_RECORD_ID",
        "/tasks/estimate/records": "DELETE_TASKS_ESTIMATE_RECORDS_RECORD_ID",
        "/tasks/posting/records": "DELETE_TASKS_POSTING_RECORDS_RECORD_ID",
        "/tasks/reach/records": "DELETE_TASKS_REACH_RECORDS_RECORD_ID",
        "/users": "DELETE_USERS_USER_ID",
        "/users/actions/types": "DELETE_USERS_ACTIONS_TYPES_ID",
        "/projects/content-categories": "DELETE_PROJECTS_CONTENT_CATEGORIES_ID",
        "/film-cluster": "DELETE_FILM_CLUSTER_ID",
        "/famous-people": "DELETE_FAMOUS_PEOPLE_ID",
        "/erv-ranges": "DELETE_ERV_RANGES_ID",
        "/notifications/telegram/user/subscriptions": "DELETE_NOTIFICATIONS_TELEGRAM_USER_SUBSCRIPTIONS_TELEGRAM_USER_ID"
    }
}

export const LogMap: Record<string, Record<string, string>> = {
    head: {
       "/files/uploading": "FILES_UPLOADING"
    },
    patch: {
        "/files/uploading": "FILES_UPLOADING"
    },
    delete: {
        "/file-archive-requests/bulk": "DELETE_FILE_ARCHIVE_REQUESTS_BULK",
        "/tasks/bulk": "DELETE_TASKS_BULK",
        "/users/bulk": "DELETE_USERS_BULK",
    },
    get: {
        "/awards": "GET_AWARDS",
        "/projects/content-categories/import/template": "GET_PROJECTS_CONTENT_CATEGORIES_IMPORT_TEMPLATE",
        "/analytics/summary-data": "GET_ANALYTICS_SUMMARY_DATA",
        "/category-types": "GET_CATEGORY_TYPES",
        "/category-types/import/template": "GET_CATEGORY_TYPES_IMPORT_TEMPLATE",
        "/content-module-section": "GET_CONTENT_MODULE_SECTION",
        "/contents/content-module-sections": "GET_CONTENTS_CONTENT_MODULE_SECTIONS",
        "/contents/project-tags": "GET_CONTENTS_PROJECT_TAGS",
        "/contents/regions_about": "GET_CONTENTS_REGIONS_ABOUT",
        "/contents/regions_for": "GET_CONTENTS_REGIONS_FOR",
        "/contests": "GET_CONTESTS",
        "/contests/import/template": "GET_CONTESTS_IMPORT_TEMPLATE",
        "/contests/with-projects": "GET_CONTESTS_WITH_PROJECTS",
        "/cost-types": "GET_COST_TYPES",
        "/current_user": "GET_CURRENT_USER",
        "/document-types": "GET_DOCUMENT_TYPES",
        "/document-types/import/template": "GET_DOCUMENT_TYPES_IMPORT_TEMPLATE",
        "/files/uploading/unfinished": "GET_FILES_UPLOADING_UNFINISHED",
        "/genre-types": "GET_GENRE_TYPES",
        "/genre-types/import/template": "GET_GENRE_TYPES_IMPORT_TEMPLATE",
        "/integration/awards/all": "GET_INTEGRATION_AWARDS_ALL",
        "/integration/categories/all": "GET_INTEGRATION_CATEGORIES_ALL",
        "/integration/genres/all": "GET_INTEGRATION_GENRES_ALL",
        "/integration/projects/contents/format-types": "GET_INTEGRATION_PROJECTS_CONTENTS_FORMAT_TYPES",
        "/integration/projects/contents/regions-about": "GET_INTEGRATION_PROJECTS_CONTENTS_REGIONS_ABOUT",
        "/integration/projects/contents/regions-for": "GET_INTEGRATION_PROJECTS_CONTENTS_REGIONS_FOR",
        "/integration/projects/contents/submission-forms": "GET_INTEGRATION_PROJECTS_CONTENTS_SUBMISSION_FORMS",
        "/integration/projects/contents/tags": "GET_INTEGRATION_PROJECTS_CONTENTS_TAGS",
        "/integration/projects/contests": "GET_INTEGRATION_PROJECTS_CONTESTS",
        "/integration/projects/directions": "GET_INTEGRATION_PROJECTS_DIRECTIONS",
        "/integration/projects/format-types": "GET_INTEGRATION_PROJECTS_FORMAT_TYPES",
        "/integration/projects/project-statuses": "GET_INTEGRATION_PROJECTS_PROJECT_STATUSES",
        "/integration/projects/regions-about": "GET_INTEGRATION_PROJECTS_REGIONS_ABOUT",
        "/integration/projects/regions-for": "GET_INTEGRATION_PROJECTS_REGIONS_FOR",
        "/integration/projects/submission-forms": "GET_INTEGRATION_PROJECTS_SUBMISSION_FORMS",
        "/integration/projects/tags": "GET_INTEGRATION_PROJECTS_TAGS",
        "/integration/ranks/all": "GET_INTEGRATION_RANKS_ALL",
        "/integration/regions/all": "GET_INTEGRATION_REGIONS_ALL",
        "/integration/tags/all": "GET_INTEGRATION_TAGS_ALL",
        "/internet-resources": "GET_INTERNET_RESOURCES",
        "/internet-resources/import/template": "GET_INTERNET_RESOURCES_IMPORT_TEMPLATE",
        "/internet-resources/types": "GET_INTERNET_RESOURCES_TYPES",
        "/kpi-types": "GET_KPI_TYPES",
        "/kpi-types/import/template": "GET_KPI_TYPES_IMPORT_TEMPLATE",
        "/notifications": "GET_NOTIFICATIONS",
        "/notifications/active-notification-channels": "GET_NOTIFICATIONS_ACTIVE_NOTIFICATION_CHANNELS",
        "/notifications/browser-notifications/current_user": "GET_NOTIFICATIONS_BROWSER_NOTIFICATIONS_CURRENT_USER",
        "/notifications/for-bulk-send": "GET_NOTIFICATIONS_FOR_BULK_SEND",
        "/notifications/notification-channels": "GET_NOTIFICATIONS_NOTIFICATION_CHANNELS",
        "/notifications/setting/priorities": "GET_NOTIFICATIONS_SETTING_PRIORITIES",
        "/notifications/setting/priorities-email": "GET_NOTIFICATIONS_SETTING_PRIORITIES_EMAIL",
        "/notifications/setting/priorities-sms": "GET_NOTIFICATIONS_SETTING_PRIORITIES_SMS",
        "/notifications/setting/priorities-system": "GET_NOTIFICATIONS_SETTING_PRIORITIES_SYSTEM",
        "/notifications/setting/priorities-telegram": "GET_NOTIFICATIONS_SETTING_PRIORITIES_TELEGRAM",
        "/notifications/setting/send-notifications-after": "GET_NOTIFICATIONS_SETTING_SEND_NOTIFICATIONS_AFTER",
        "/organization-legal-forms": "GET_ORGANIZATION_LEGAL_FORMS",
        "/organization-legal-forms/import/template": "GET_ORGANIZATION_LEGAL_FORMS_IMPORT_TEMPLATE",
        "/organizations": "GET_ORGANIZATIONS",
        "/organizations/import/template": "GET_ORGANIZATIONS_IMPORT_TEMPLATE",
        "/organizations/with-contractors": "GET_ORGANIZATIONS_WITH_CONTRACTORS",
        "/project-labels": "GET_PROJECT_LABELS",
        "/project-tags": "GET_PROJECT_TAGS",
        "/project-tags/import/template": "GET_PROJECT_TAGS_IMPORT_TEMPLATE",
        "/projects": "GET_PROJECTS",
        "/projects/channel-names": "GET_PROJECTS_CHANNEL_NAMES",
        "/projects/codes": "GET_PROJECTS_CODES",
        "/projects/contract-numbers": "GET_PROJECTS_CONTRACT_NUMBERS",
        "/projects/directions": "GET_PROJECTS_DIRECTIONS",
        "/projects/directions/import/template": "GET_PROJECTS_DIRECTIONS_IMPORT_TEMPLATE",
        "/projects/documents": "GET_PROJECTS_DOCUMENTS",
        "/projects/famous-people-names": "GET_PROJECTS_FAMOUS_PEOPLE_NAMES",
        "/projects/format_types": "GET_PROJECTS_FORMAT_TYPES",
        "/projects/format_types/import/template": "GET_PROJECTS_FORMAT_TYPES_IMPORT_TEMPLATE",
        "/projects/format_types/submission-forms": "GET_PROJECTS_FORMAT_TYPES_SUBMISSION_FORMS",
        "/projects/igks": "GET_PROJECTS_IGKS",
        "/projects/import/template": "GET_PROJECTS_IMPORT_TEMPLATE",
        "/projects/request-numbers": "GET_PROJECTS_REQUEST_NUMBERS",
        "/projects/statuses": "GET_PROJECTS_STATUSES",
        "/projects/submission_forms": "GET_PROJECTS_SUBMISSION_FORMS",
        "/projects/submission_forms/import/template": "GET_PROJECTS_SUBMISSION_FORMS_IMPORT_TEMPLATE",
        "/projects/thematics": "GET_PROJECTS_THEMATICS",
        "/projects/thematics/import/template": "GET_PROJECTS_THEMATICS_IMPORT_TEMPLATE",
        "/projects/types": "GET_PROJECTS_TYPES",
        "/projects/with-reach-data": "GET_PROJECTS_WITH_REACH_DATA",
        "/rank": "GET_RANK",
        "/rank/import/template": "GET_RANK_IMPORT_TEMPLATE",
        "/region/types": "GET_REGION_TYPES",
        "/region/types/import/template": "GET_REGION_TYPES_IMPORT_TEMPLATE",
        "/regulated-integration/projects/parameters": "GET_REGULATED_INTEGRATION_PROJECTS_PARAMETERS",
        "/reports/schedulers": "GET_REPORTS_SCHEDULERS",
        "/reports/templates": "GET_REPORTS_TEMPLATES",
        "/reports/types": "GET_REPORTS_TYPES",
        "/roles": "GET_ROLES",
        "/roles/bulk-actions": "GET_ROLES_BULK_ACTIONS",
        "/sessions": "GET_SESSIONS",
        "/sessions/import/template": "GET_SESSIONS_IMPORT_TEMPLATE",
        "/sessions/types": "GET_SESSIONS_TYPES",
        "/sessions/types/import/template": "GET_SESSIONS_TYPES_IMPORT_TEMPLATE",
        "/sessions/with-projects": "GET_SESSIONS_WITH_PROJECTS",
        "/sessions/with-projects/years": "GET_SESSIONS_WITH_PROJECTS_YEARS",
        "/sessions/years": "GET_SESSIONS_YEARS",
        "/sprints/statuses": "GET_SPRINTS_STATUSES",
        "/task-duplication-schedule-date-presets/all": "GET_TASK_DUPLICATION_SCHEDULE_DATE_PRESETS_ALL",
        "/tasks/content/records/approved": "GET_TASKS_CONTENT_RECORDS_APPROVED",
        "/tasks/content/records/regions_about": "GET_TASKS_CONTENT_RECORDS_REGIONS_ABOUT",
        "/tasks/content/records/regions_for": "GET_TASKS_CONTENT_RECORDS_REGIONS_FOR",
        "/tasks/copyright/records/import/template": "GET_TASKS_COPYRIGHT_RECORDS_IMPORT_TEMPLATE",
        "/tasks/duplication/schedulers": "GET_TASKS_DUPLICATION_SCHEDULERS",
        "/tasks/estimate/records/import/template": "GET_TASKS_ESTIMATE_RECORDS_IMPORT_TEMPLATE",
        "/tasks/posting": "GET_TASKS_POSTING",
        "/tasks/posting/records/import/template": "GET_TASKS_POSTING_RECORDS_IMPORT_TEMPLATE",
        "/tasks/reach/records/import/template": "GET_TASKS_REACH_RECORDS_IMPORT_TEMPLATE",
        "/tasks/records/statuses/statuses": "GET_TASKS_RECORDS_STATUSES_STATUSES",
        "/tasks/stages": "GET_TASKS_STAGES",
        "/tasks/statuses": "GET_TASKS_STATUSES",
        "/tasks/types": "GET_TASKS_TYPES",
        "/users/actions/types": "GET_USERS_ACTIONS_TYPES",
        "/users/current/tasks/inspectors": "GET_USERS_CURRENT_TASKS_INSPECTORS",
        "/users/current/tasks/related-users": "GET_USERS_CURRENT_TASKS_RELATED_USERS",
        "/users/import/template": "GET_USERS_IMPORT_TEMPLATE",
        "/projects/content-categories": "GET_PROJECTS_CONTENT_CATEGORIES",
        "/film-cluster": "GET_FILM_CLUSTER",
        "/famous-people": "GET_FAMOUS_PEOPLE",
        "/erv-ranges": "GET_ERV_RANGES",
        "/film-cluster/import/template": "GET_FILM_CLUSTER_IMPORT_TEMPLATE",
        "/famous-people/import/template": "GET_FAMOUS_PEOPLE_IMPORT_TEMPLATE",
        "/notifications/subjects": "GET_NOTIFICATIONS_SUBJECTS",
        "/notifications/subjects-with-descriptions": "GET_NOTIFICATIONS_SUBJECTS_WITH_DESCRIPTIONS",
    },
    post: {
        "/awards": "CREATE_AWARDS",
        "/category-types": "CREATE_CATEGORY_TYPES",
        "/category-types/export": "POST_CATEGORY_TYPES_EXPORT",
        "/category-types/import": "POST_CATEGORY_TYPES_IMPORT",
        "/category-types/search": "POST_CATEGORY_TYPES_SEARCH",
        "/contents/search": "POST_CONTENTS_SEARCH",
        "/contests": "CREATE_CONTESTS",
        "/contests/export": "POST_CONTESTS_EXPORT",
        "/contests/import": "POST_CONTESTS_IMPORT",
        "/contests/search": "POST_CONTESTS_SEARCH",
        "/document-types": "CREATE_DOCUMENT_TYPES",
        "/document-types/export": "POST_DOCUMENT_TYPES_EXPORT",
        "/document-types/import": "POST_DOCUMENT_TYPES_IMPORT",
        "/document-types/search": "POST_DOCUMENT_TYPES_SEARCH",
        "/file-archive-requests/search": "POST_FILE_ARCHIVE_REQUESTS_SEARCH",
        "/files/generate-preview-files": "POST_FILES_GENERATE_PREVIEW_FILES",
        "/filters": "CREATE_FILTERS",
        "/filters/types": "POST_FILTERS_TYPES",
        "/genre-types": "CREATE_GENRE_TYPES",
        "/genre-types/export": "POST_GENRE_TYPES_EXPORT",
        "/genre-types/import": "POST_GENRE_TYPES_IMPORT",
        "/genre-types/search": "POST_GENRE_TYPES_SEARCH",
        "/integration/awards": "CREATE_INTEGRATION_AWARDS",
        "/integration/projects/sessions": "POST_INTEGRATION_PROJECTS_SESSIONS",
        "/integration/projects/sessions-years": "POST_INTEGRATION_PROJECTS_SESSIONS_YEARS",
        "/integration/ranks": "CREATE_INTEGRATION_RANKS",
        "/internet-resources": "CREATE_INTERNET_RESOURCES",
        "/internet-resources/export": "POST_INTERNET_RESOURCES_EXPORT",
        "/internet-resources/import": "POST_INTERNET_RESOURCES_IMPORT",
        "/internet-resources/search": "POST_INTERNET_RESOURCES_SEARCH",
        "/kpi-types": "CREATE_KPI_TYPES",
        "/kpi-types/export": "POST_KPI_TYPES_EXPORT",
        "/kpi-types/import": "POST_KPI_TYPES_IMPORT",
        "/kpi-types/search": "POST_KPI_TYPES_SEARCH",
        "/notifications": "POST_NOTIFICATIONS",
        "/notifications/bulk-send/email": "POST_NOTIFICATIONS_BULK_SEND_EMAIL",
        "/notifications/search": "POST_NOTIFICATIONS_SEARCH",
        "/organization-legal-forms": "CREATE_ORGANIZATION_LEGAL_FORMS",
        "/organization-legal-forms/export": "POST_ORGANIZATION_LEGAL_FORMS_EXPORT",
        "/organization-legal-forms/import": "POST_ORGANIZATION_LEGAL_FORMS_IMPORT",
        "/organization-legal-forms/search": "POST_ORGANIZATION_LEGAL_FORMS_SEARCH",
        "/organizations": "CREATE_ORGANIZATIONS",
        "/organizations/export": "POST_ORGANIZATIONS_EXPORT",
        "/organizations/import": "POST_ORGANIZATIONS_IMPORT",
        "/organizations/search": "POST_ORGANIZATIONS_SEARCH",
        "/project-labels": "CREATE_PROJECT_LABELS",
        "/project-tags": "CREATE_PROJECT_TAGS",
        "/project-tags/export": "POST_PROJECT_TAGS_EXPORT",
        "/project-tags/import": "POST_PROJECT_TAGS_IMPORT",
        "/project-tags/search": "POST_PROJECT_TAGS_SEARCH",
        "/projects": "CREATE_PROJECTS",
        "/projects/all": "POST_PROJECTS_ALL",
        "/projects/allowable-reports": "POST_PROJECTS_ALLOWABLE_REPORTS",
        "/projects/calendar/extended-search": "POST_PROJECTS_CALENDAR_EXTENDED_SEARCH",
        "/projects/calendar/search": "POST_PROJECTS_CALENDAR_SEARCH",
        "/projects/directions": "CREATE_PROJECTS_DIRECTIONS",
        "/projects/directions/export": "POST_PROJECTS_DIRECTIONS_EXPORT",
        "/projects/directions/import": "POST_PROJECTS_DIRECTIONS_IMPORT",
        "/projects/directions/search": "POST_PROJECTS_DIRECTIONS_SEARCH",
        "/projects/documents": "POST_PROJECTS_DOCUMENTS",
        "/projects/documents/search": "POST_PROJECTS_DOCUMENTS_SEARCH",
        "/projects/export": "POST_PROJECTS_EXPORT",
        "/projects/extended-search": "POST_PROJECTS_EXTENDED_SEARCH",
        "/projects/format_types": "CREATE_PROJECTS_FORMAT_TYPES",
        "/projects/format_types/export": "POST_PROJECTS_FORMAT_TYPES_EXPORT",
        "/projects/format_types/import": "POST_PROJECTS_FORMAT_TYPES_IMPORT",
        "/projects/format_types/search": "POST_PROJECTS_FORMAT_TYPES_SEARCH",
        "/projects/import": "POST_PROJECTS_IMPORT",
        "/projects/search": "POST_PROJECTS_SEARCH",
        "/projects/submission_forms": "CREATE_PROJECTS_SUBMISSION_FORMS",
        "/projects/submission_forms/export": "POST_PROJECTS_SUBMISSION_FORMS_EXPORT",
        "/projects/submission_forms/import": "POST_PROJECTS_SUBMISSION_FORMS_IMPORT",
        "/projects/submission_forms/search": "POST_PROJECTS_SUBMISSION_FORMS_SEARCH",
        "/projects/thematics": "CREATE_PROJECTS_THEMATICS",
        "/projects/thematics/export": "POST_PROJECTS_THEMATICS_EXPORT",
        "/projects/thematics/import": "POST_PROJECTS_THEMATICS_IMPORT",
        "/projects/thematics/search": "POST_PROJECTS_THEMATICS_SEARCH",
        "/rank": "CREATE_RANK",
        "/rank/export": "POST_RANK_EXPORT",
        "/rank/import": "POST_RANK_IMPORT",
        "/rank/search": "POST_RANK_SEARCH",
        "/region/types": "CREATE_REGION_TYPES",
        "/region/types/export": "POST_REGION_TYPES_EXPORT",
        "/region/types/import": "POST_REGION_TYPES_IMPORT",
        "/region/types/search": "POST_REGION_TYPES_SEARCH",
        "/regulated-integration/project-data/search": "POST_REGULATED_INTEGRATION_PROJECT_DATA_SEARCH",
        "/regulated-integration/projects/with-approved-content": "POST_REGULATED_INTEGRATION_PROJECTS_WITH_APPROVED_CONTENT",
        "/reports": "CREATE_REPORTS",
        "/reports/schedulers": "CREATE_REPORTS_SCHEDULERS",
        "/reports/schedulers/search": "POST_REPORTS_SCHEDULERS_SEARCH",
        "/reports/search": "POST_REPORTS_SEARCH",
        "/sessions": "CREATE_SESSIONS",
        "/sessions/export": "POST_SESSIONS_EXPORT",
        "/sessions/import": "POST_SESSIONS_IMPORT",
        "/sessions/search": "POST_SESSIONS_SEARCH",
        "/sessions/types": "CREATE_SESSIONS_TYPES",
        "/sessions/types/export": "POST_SESSIONS_TYPES_EXPORT",
        "/sessions/types/import": "POST_SESSIONS_TYPES_IMPORT",
        "/sessions/types/search": "POST_SESSIONS_TYPES_SEARCH",
        "/sprints": "CREATE_SPRINTS",
        "/task-comments": "CREATE_TASK_COMMENTS",
        "/task-comments/search": "POST_TASK_COMMENTS_SEARCH",
        "/task-duplication-schedule-date-presets": "CREATE_TASK_DUPLICATION_SCHEDULE_DATE_PRESETS",
        "/tasks": "CREATE_TASKS",
        "/tasks/all": "POST_TASKS_ALL",
        "/tasks/bulk-creation": "CREATE_TASKS_BULK_CREATION",
        "/tasks/content/records/bulk-vote": "POST_TASKS_CONTENT_RECORDS_BULK_VOTE",
        "/tasks/content/records/file-archive-request": "CREATE_TASKS_CONTENT_RECORDS_FILE_ARCHIVE_REQUEST",
        "/tasks/content/records/search": "POST_TASKS_CONTENT_RECORDS_SEARCH",
        "/tasks/content/records/vote": "POST_TASKS_CONTENT_RECORDS_VOTE",
        "/tasks/copyright/records/bulk-vote": "POST_TASKS_COPYRIGHT_RECORDS_BULK_VOTE",
        "/tasks/copyright/records/import": "POST_TASKS_COPYRIGHT_RECORDS_IMPORT",
        "/tasks/copyright/records/search": "POST_TASKS_COPYRIGHT_RECORDS_SEARCH",
        "/tasks/copyright/records/vote": "POST_TASKS_COPYRIGHT_RECORDS_VOTE",
        "/tasks/duplication/schedulers": "CREATE_TASKS_DUPLICATION_SCHEDULERS",
        "/tasks/duplication/schedulers/bulk-creation": "CREATE_TASKS_DUPLICATION_SCHEDULERS_BULK_CREATION",
        "/tasks/duplication/schedulers/search": "POST_TASKS_DUPLICATION_SCHEDULERS_SEARCH",
        "/tasks/estimate/cost-types/records/file-archive-request": "CREATE_TASKS_ESTIMATE_COST_TYPES_RECORDS_FILE_ARCHIVE_REQUEST",
        "/tasks/estimate/records/bulk-vote": "POST_TASKS_ESTIMATE_RECORDS_BULK_VOTE",
        "/tasks/estimate/records/import": "POST_TASKS_ESTIMATE_RECORDS_IMPORT",
        "/tasks/estimate/records/search": "POST_TASKS_ESTIMATE_RECORDS_SEARCH",
        "/tasks/estimate/records/vote": "POST_TASKS_ESTIMATE_RECORDS_VOTE",
        "/tasks/posting/records/bulk-vote": "POST_TASKS_POSTING_RECORDS_BULK_VOTE",
        "/tasks/posting/records/import": "POST_TASKS_POSTING_RECORDS_IMPORT",
        "/tasks/posting/records/search": "POST_TASKS_POSTING_RECORDS_SEARCH",
        "/tasks/posting/records/vote": "POST_TASKS_POSTING_RECORDS_VOTE",
        "/tasks/reach/records/bulk-vote": "POST_TASKS_REACH_RECORDS_BULK_VOTE",
        "/tasks/reach/records/export": "POST_TASKS_REACH_RECORDS_EXPORT",
        "/tasks/reach/records/import": "POST_TASKS_REACH_RECORDS_IMPORT",
        "/tasks/reach/records/search": "POST_TASKS_REACH_RECORDS_SEARCH",
        "/tasks/reach/records/vote": "POST_TASKS_REACH_RECORDS_VOTE",
        "/tasks/records/bulk-vote": "POST_TASKS_RECORDS_BULK_VOTE",
        "/tasks/search": "POST_TASKS_SEARCH",
        "/tasks/statistic": "POST_TASKS_STATISTIC",
        "/ui-table-view-restriction": "CREATE_UI_TABLE_VIEW_RESTRICTION",
        "/users": "CREATE_USERS",
        "/users/actions": "POST_USERS_ACTIONS",
        "/users/actions/search": "POST_USERS_ACTIONS_SEARCH",
        "/users/actions/statistic": "POST_USERS_ACTIONS_STATISTIC",
        "/users/actions/types/export": "POST_USERS_ACTIONS_TYPES_EXPORT",
        "/users/actions/types/search": "POST_USERS_ACTIONS_TYPES_SEARCH",
        "/users/all": "POST_USERS_ALL",
        "/users/export": "POST_USERS_EXPORT",
        "/users/import": "POST_USERS_IMPORT",
        "/users/leaders": "POST_USERS_LEADERS",
        "/users/recover/bulk": "POST_USERS_RECOVER_BULK",
        "/users/search": "POST_USERS_SEARCH",
        "/keycloak/users/sync" : "POST_KEYCLOAK_USERS_SYNC",
        "/files/uploading": "POST_FILES_UPLOADING",
        "/users/actions/types": "POST_USERS_ACTIONS_TYPES",
        "/tasks/reach/records/import/template": "POST_TASKS_REACH_RECORDS_IMPORT_TEMPLATE",
        "/tasks/posting/records/import/template": "POST_TASKS_POSTING_RECORDS_IMPORT_TEMPLATE",
        "/tasks/estimate/records/import/template": "POST_TASKS_ESTIMATE_RECORDS_IMPORT_TEMPLATE",
        "/tasks/posting/copyright/import/template": "POST_TASKS_COPYRIGHT_RECORDS_IMPORT_TEMPLATE",
        "/system-settings-parameters/search": "POST_SYSTEM_SETTINGS_PARAMETERS_SEARCH",
        "/regulated-integration/projects/with-approved-content/additional-info": "POST_REGULATED_INTEGRATION_PROJECTS_WITH_APPROVED_CONTENT_ADDITIONAL_INFO",
        "/projects/content-categories": "POST_PROJECTS_CONTENT_CATEGORIES",
        "/projects/content-categories/search": "POST_PROJECTS_CONTENT_CATEGORIES_SEARCH",
        "/projects/content-categories/import": "POST_PROJECTS_CONTENT_CATEGORIES_IMPORT",
        "/projects/content-categories/export": "POST_PROJECTS_CONTENT_CATEGORIES_EXPORT",
        "/film-cluster": "POST_FILM_CLUSTER",
        "/famous-people": "POST_FAMOUS_PEOPLE",
        "/erv-ranges": "POST_ERV_RANGES",
        "/film-cluster/search": "POST_FILM_CLUSTER_SEARCH",
        "/famous-people/search": "POST_FAMOUS_PEOPLE_SEARCH",
        "/erv-ranges/search": "POST_ERV_RANGES_SEARCH",
        "/film-cluster/import": "POST_FILM_CLUSTER_IMPORT",
        "/famous-people/import": "POST_FAMOUS_PEOPLE_IMPORT",
        "/erv-ranges/import": "POST_ERV_RANGES_IMPORT",
        "/film-cluster/export": "POST_FILM_CLUSTER_EXPORT",
        "/famous-people/export": "POST_FAMOUS_PEOPLE_EXPORT",
        "/erv-ranges/export": "POST_ERV_RANGES_EXPORT",
    },
    put: {
        "/projects/project-users": "UPDATE_PROJECTS_PROJECT_USERS",
        "/projects/projects-objects-links": "UPDATE_PROJECTS_PROJECTS_OBJECTS_LINKS",
        "/tasks/content/records/bulk/to-examination": "UPDATE_TASKS_CONTENT_RECORDS_BULK_TO_EXAMINATION",
        "/tasks/content/records/content-users": "UPDATE_TASKS_CONTENT_RECORDS_CONTENT_USERS",
        "/tasks/copyright/records/bulk/to-examination": "UPDATE_TASKS_COPYRIGHT_RECORDS_BULK_TO_EXAMINATION",
        "/tasks/duplication/schedulers/bulk-update": "UPDATE_TASKS_DUPLICATION_SCHEDULERS_BULK_UPDATE",
        "/tasks/estimate/records/bulk/to-examination": "UPDATE_TASKS_ESTIMATE_RECORDS_BULK_TO_EXAMINATION",
        "/tasks/posting/records/bulk/to-examination": "UPDATE_TASKS_POSTING_RECORDS_BULK_TO_EXAMINATION",
        "/tasks/reach/records/bulk/to-examination": "UPDATE_TASKS_REACH_RECORDS_BULK_TO_EXAMINATION",
        "/users/confirm-agreement": "UPDATE_USERS_CONFIRM_AGREEMENT",
        "/users/confirm-download-rights": "UPDATE_USERS_CONFIRM_DOWNLOAD_RIGHTS",
        "/users/confirm-receiving-notifications": "UPDATE_USERS_CONFIRM_RECEIVING_NOTIFICATIONS",
        "/users/current-user": "UPDATE_USERS_CURRENT_USER",
        "/users/personal-data": "UPDATE_USERS_PERSONAL_DATA"
    }
}
