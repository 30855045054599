import React, {MouseEvent, ReactNode, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Button, Checkbox, Select, Spin} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import {NotificationOpen} from "store/notification/actions";
import AppService from "services/appService";
import {SearchContentProjectModel} from 'features/search-project-content';
import {Fields, Stores} from 'features/search-project-content/types';
import {transformArrayIdNameForValue} from "utils/transformObjectFeels";
import {GenreApi} from 'entities/genres'

type TState = Stores.CategoriesGenresOptions;

const {Option} = Select;
function useGetsData(callback: (val: TState) => void) {
    const put = useDispatch();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    async function getData() {
        setLoading(true);
        try {
            const [
                {data: categories},
                genres,
            ] = await Promise.all([
                AppService.getCategoryProject(),
                GenreApi.getGenres.fetchOptions(),
            ])
            callback({
                CATEGORY: transformArrayIdNameForValue(categories),
                GENRE: genres,
            })
        } catch (e) {
            setError(true)
            put(NotificationOpen('error', '', String(e)))
        } finally {
            setLoading(false)
        }
    }
    return {loading, error, getData}
}
const optionsFields: Array<{label: string, value: Stores.CategoriesGenresKeys | 'Параметр неважен'}> = [
    {label: 'Параметр неважен', value: 'Параметр неважен'},
    {label: 'Формат', value: Fields.FieldsEnums.CATEGORY},
    {label: 'Жанр', value: Fields.FieldsEnums.GENRE},
];
export const CategoriesGenresItem = () => {
    const put = useDispatch();
    const [options, setOptions] = useState<TState>({
        CATEGORY: [],
        GENRE: [],
    });
    const {typeContent} = useSelector(SearchContentProjectModel.selectors.main)
    const categoriesGenres = useSelector(SearchContentProjectModel.selectors.categoriesGenres)
    const {loading, getData} = useGetsData(setOptions);

    useEffect(() => {
        getData()
    }, []);//eslint-disable-line

    const disabledOptions = useMemo(() => {
        return categoriesGenres[typeContent].fields.map((el) => el.field)
    }, [categoriesGenres, typeContent]);


    const onSelectChangeType = (index: number) => (value: Stores.CategoriesGenresKeys | 'Параметр неважен') => {
        const newArray = [...categoriesGenres[typeContent].fields];
        newArray[index]["field"] = value;
        newArray[index]["values"] = [];
        put(SearchContentProjectModel.actions.setCategoriesGenres(
            typeContent,
            newArray
        ))
    }
    const onSelectChangeValue = (index: number) => (value: Fields.FieldFilter["values"]) => {
        const newArray = [...categoriesGenres[typeContent].fields];
        newArray[index]["values"] = value;
        put(SearchContentProjectModel.actions.setCategoriesGenres(
            typeContent,
            newArray
        ))
    }
    const addGroup = () => {
        put(SearchContentProjectModel.actions.setCategoriesGenres(typeContent, [
            ...categoriesGenres[typeContent].fields,
            {field: 'Параметр неважен', isAndOrBesides: false, values: []}
        ]))
    }
    const onRemoveHandler = (index: number) => (_event: MouseEvent<HTMLDivElement>) => {
        const newArray = [...categoriesGenres[typeContent].fields];
        newArray.splice(index, 1)
        put(SearchContentProjectModel.actions.setCategoriesGenres(
            typeContent,
            newArray
        ))
    }
    const onCheckAll = (index: number, field: Stores.CategoriesGenresKeys | 'Параметр неважен') => (event: any) => {
        if (field === 'Параметр неважен') return;
        const checked = event.target.checked;
        const newArray = [...categoriesGenres[typeContent].fields];
        if (checked) {
            newArray[index]["values"] = options[field].map((el) => el.value);
        } else {
            newArray[index]["values"] = []
        }
        put(SearchContentProjectModel.actions.setCategoriesGenres(
            typeContent,
            newArray
        ))
    }

    return (
        <Spin spinning={loading}>
            <div className="item-wrapper" key={typeContent}>
                {categoriesGenres[typeContent].fields.map((item, index) => (
                    <div className="item-wrapper__group" key={item.field + index}>
                        <div className="item-wrapper__items">
                            <div className="item">
                                <div className="form-group">
                                    <Select
                                        value={item.field}
                                        onChange={onSelectChangeType(index)}
                                        placeholder={'Параметр неважен'}
                                        getPopupContainer={trigger => trigger.parentNode}
                                    >
                                        {optionsFields.map((el) => (
                                            <Option
                                                disabled={disabledOptions.includes(el.value)}
                                                value={el.value}
                                                key={el.value}
                                            >
                                                {el.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                            <div className="item item-second">
                                <div className="form-group">
                                    <Select
                                        allowClear={true}
                                        mode={"multiple"}
                                        disabled={item.field === 'Параметр неважен'}
                                        maxTagCount={"responsive"}
                                        value={item.values}
                                        onChange={onSelectChangeValue(index)}
                                        placeholder={'Параметр неважен'}
                                        filterOption={(input, option) => {
                                            if (option === undefined || option === null) return false;
                                            return option['data-name'].toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }}
                                        getPopupContainer={trigger => trigger.parentNode}
                                        dropdownRender={(originNode: ReactNode) => (
                                            <>
                                                <div className={'item__checkbox'}>
                                                    <Checkbox
                                                        checked={
                                                            item.field !== 'Параметр неважен' &&
                                                            item.values.length === options[item.field].length
                                                        }
                                                        onChange={onCheckAll(index, item.field)}
                                                    >
                                                        Выбрать все
                                                    </Checkbox></div>
                                                {originNode}
                                            </>
                                        )}
                                    >
                                        {item.field !== 'Параметр неважен' && options[item.field].map((el) => (
                                            <Option
                                                value={el.value}
                                                key={el.value}
                                                data-name={el.label}
                                            >
                                                {el.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                        </div>
                        {categoriesGenres[typeContent].fields.length > 1 && (
                            <div className="item__remove" onClick={onRemoveHandler(index)}>
                                <DeleteOutlined />
                            </div>
                        )}
                    </div>
                ))}
                {categoriesGenres[typeContent].fields.length < optionsFields.length - 1 && (
                    <div className="btn">
                        <Button type={'link'} onClick={addGroup}>+ Добавить условие</Button>
                    </div>
                )}
            </div>
        </Spin>
    )
}
