import {put, select} from 'redux-saga/effects'
import {NotificationOpen} from '../../../store/notification/actions'
import CreateErrorMessage from '../../../utils/validation/createErrorMessage'
import {
	GetTaskReportStartAction,
	UploadReportTemplateErrorAction,
	UploadReportTemplateStartAction,
	UploadReportTemplateSuccessAction
} from "../../../store/tasks/actions/report";
import {ReportType} from "../../../models/task/types";
import {
	TasksReportCopyrightService,
	TasksReportEstimateService,
	TasksReportPostingService,
	TasksReportReachService
} from "../../../services/tasksReportService";
import {selectParams} from "../../../store/selectors";
import {GetAllTasksStartAction} from "../../../store/tasks/actions/tasks";

export function* uploadTemplate({ reportType,data }: ReturnType<typeof UploadReportTemplateStartAction>) {
	try {
		const { id } = yield select(selectParams)
		const location = window.location.href.split('/');
		const page = location[3]

		const formData = new FormData()
		formData.append('excel', data)

		if (reportType === ReportType.CONTENT_POSTING_REPORT) {
			yield TasksReportPostingService.uploadTemplate(formData)
		}
		if (reportType === ReportType.CONTENT_REACH_REPORT) {
			yield TasksReportReachService.uploadTemplate(formData)
		}
		if (reportType === ReportType.COPYRIGHT_LIST) {
			 yield TasksReportCopyrightService.uploadTemplate(formData)
		}
		if (reportType === ReportType.PROJECT_ESTIMATE) {
			yield TasksReportEstimateService.uploadTemplate(formData)
		}
		yield put(UploadReportTemplateSuccessAction())
		if (page === 'tasks') { // для страницы tasks/projects
			yield put(GetAllTasksStartAction())
		} else {
			yield put(GetTaskReportStartAction(id, reportType))
		}
		yield put(NotificationOpen('success', 'OK', 'Данные загружены успешно'))

	} catch (error: any) {
		yield put(UploadReportTemplateErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не загрузить excel шаблон'))
	}
}

