import {TFilterData,} from "../user";
import {Nullable, T_ID_NAME_CODE_HIDDEN, TPaginationDefault} from "../common";
import {IOptions} from "../feels";

export type THandbookFilter = {
    filterData?: TFilterData
} & TPaginationDefault

export type THandbookCodeNameHidden = {
    code: string | null
    hidden: boolean
    name: string
}

export type TRanks =  {
    code: string | null
    hidden: boolean
    name: string
    id?: any
}

export type TNationalTag =  {
    code: string | null
    hidden: boolean
    name: string
    id?: any
}

export type TNationalProjectTag2 =  {
    code: string | null
    hidden: boolean
    name: string
    id: any
    parent : T_ID_NAME_CODE_HIDDEN
}
export type TNationalProjectTag2Request =  {
    code: string | null
    hidden: boolean
    name: string
    parentId : string
}

export type TSessionsType =  {
    code: string | null
    hidden: boolean
    name: string
    id?: any
}

export type TThematicContests =    {
    id: string
    code: string | null
    shortName: string
    name: string
    startDate: string
    endDate: string
}
export type TThematic =  {
    code?: string | null,
    hidden: boolean,
    id?: string,
    name: string,
    contests: Array<TThematicContests>
    projectTags: Array<T_ID_NAME_CODE_HIDDEN>
}

export type TInternet =  {
    domainLink: string,
    hidden:boolean,
    image: string | null,
    name: string
    id: string
}

export type TTypeOverallDataRequest =  {
    code?: string | null,
    hidden?: boolean,
    name?: string
    image?: string | null
    id?:any
    requirements?: Nullable<string>
    contentModuleSectionId?: string
    submissionFormIds?: string[]
    reportTemplateIds?: string[]
    parentId?: string
    parent?: T_ID_NAME_CODE_HIDDEN
}

export type TInternetContent = {
    domainLink: string,
    hidden: boolean,
    id: string,
    image: string,
    name: string
}

export type TTHandbookContentOverAll = {
    code: string,
    hidden?: boolean,
    image?: string,
    id: string,
    name: string
    type?: string
}

export type TLegalFormOrganization = {
    id: string,
    code: string | null,
    shortName: string,
    fullName: string,
    hidden: boolean,
    type?: string
}

export type TTagParent = {
    parent: TTypeOverallDataRequest
}

export type THandbookOrganization = {
    bik: Nullable<string>,
    correspondentAccount: Nullable<string>,
    fullName: string,
    hidden?: boolean,
    inn: string,
    kpp: Nullable<string>,
    legalAddress: string,
    mailingAddress: Nullable<string>,
    name: string,
    ogrnOgrnip: string,
    okato: Nullable<string>,
    okpo: Nullable<string>,
    okved: Nullable<string>,
    paymentAccount: Nullable<string>,
    phone: Nullable<string>,
    site: Nullable<string>,
    isContentCustomer: boolean,
    region: T_ID_NAME_CODE_HIDDEN,
    legalForm: TLegalFormOrganization
}

export type THandbookOrganizationRequest = {regionId: Nullable<string>, legalFormId: string} & Omit<THandbookOrganization, "region" | "legalForm">
export type THandbookOrganizationAction = {region: IOptions, legalForm: IOptions} & Omit<THandbookOrganization, "region" | "legalForm">

export type TTagsResponseContent = TTHandbookContentOverAll & TTagParent

export type TTagsResponse = {
    content: Array<TTagsResponseContent>
} & TPaginationDefault

export type TInternetResourceResponse = {
    content: TInternetContent[]
} & TPaginationDefault

export type THandbookContentResponse = {
    content: TTHandbookContentOverAll[]
} & TPaginationDefault

export type THandbookLegaFormOrganizationResponse = {
    content: TLegalFormOrganization[]
} & TPaginationDefault

export type THandbookOrganizationResponse = {
    content: THandbookOrganization[]
} & TPaginationDefault

export enum TStatusesHandbookFeels {
    internet = 'internet',
    contentDelivery = 'contentDelivery',
    actionsUsers = 'actionsUsers',
    contentDirection = 'contentDirection',
    organization = 'organization',
    legalFormOrganization = 'legalFormOrganization',
    sessionsType = 'sessionsType',
    categoryProject = 'categoryProject',
}
export enum TStatusesHandbookFeelsForFilter {
    filterInternet = 'internet',
    filterContentDelivery = 'contentDelivery',
    filterActionsUsers = 'actionsUsers',
    filterContentDirection = 'contentDirection',
    filterOrganization = 'organization',
    filterLegalForm = 'legalFormOrganization',
    filterSessionsType = 'sessionsType',
    filterCategoryProject = 'categoryProject',
}
export type THandbookFeels = {
    internet?: TInternetResourceResponse
    contentType?: THandbookContentResponse,
    contentDelivery?: THandbookContentResponse
    actionsUsers?: THandbookContentResponse
    contentDirection?: THandbookContentResponse
    organization?: THandbookOrganization
    legalFormOrganization? : THandbookLegaFormOrganizationResponse
    sessionsType? : THandbookContentResponse
    categoryProject? : THandbookContentResponse
}
export type THandbookFilterFeels = {
    filterInternet?: THandbookFilter
    filterContentType?: THandbookFilter
    filterContentDelivery?:THandbookFilter
    filterActionsUsers?: THandbookFilter
    filterContentDirection?: THandbookFilter
    filterOrganization?: THandbookFilter
    filterLegalForm?: THandbookFilter
    filterSessionsType?: THandbookFilter
    filterCategoryProject?: THandbookFilter
}

export type TBulkAction = 'DELETE' | 'SHOW' | 'HIDE' | 'UNLOADING'
