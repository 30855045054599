import React, {MouseEvent, useMemo} from 'react';
import {Button, Radio, RadioChangeEvent, Select, Spin} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {ExtendedSearchModel, ExtendedSearchTypes} from 'features/extended-search/index';
import {DeleteOutlined} from "@ant-design/icons";
import {useQuery} from "react-query";
import {UserApi} from 'entities/user'

const {Option} = Select;


function useGetsData() {
    const contractors = useQuery({
        queryKey: [UserApi.UserController.keyOptionsRoleName, {roleNames: ['ROLE_CONTRACTOR']}],
        queryFn: ()=> UserApi.UserController.optionsRoleName({roleNames: ['ROLE_CONTRACTOR']}),
    })
    const producers = useQuery({
        queryKey: [UserApi.UserController.keyOptionsRoleName, {roleNames: ['ROLE_PRODUCER']}],
        queryFn: ()=> UserApi.UserController.optionsRoleName({roleNames: ['ROLE_PRODUCER']}),
    })
    const coordinators = useQuery({
        queryKey: [UserApi.UserController.keyOptionsRoleName, {roleNames: ['ROLE_COORDINATOR']}],
        queryFn: ()=> UserApi.UserController.optionsRoleName({roleNames: ['ROLE_COORDINATOR']}),
    })
    const lawyers = useQuery({
        queryKey: [UserApi.UserController.keyOptionsRoleName, {roleNames: ['ROLE_LAWYER']}],
        queryFn: ()=> UserApi.UserController.optionsRoleName({roleNames: ['ROLE_LAWYER']}),
    })
    const analysts = useQuery({
        queryKey: [UserApi.UserController.keyOptionsRoleName, {roleNames: ['ROLE_ANALYST']}],
        queryFn: ()=> UserApi.UserController.optionsRoleName({roleNames: ['ROLE_ANALYST']}),
    })
    const financiers = useQuery({
        queryKey: [UserApi.UserController.keyOptionsRoleName, {roleNames: ['ROLE_FINANCIER']}],
        queryFn: ()=> UserApi.UserController.optionsRoleName({roleNames: ['ROLE_FINANCIER']}),
    })
    const projectManagers = useQuery({
        queryKey: [UserApi.UserController.keyOptionsRoleName, {roleNames: ['ROLE_PROJECT_MANAGER']}],
        queryFn: ()=> UserApi.UserController.optionsRoleName({roleNames: ['ROLE_PROJECT_MANAGER']}),
    })
    const contentEditors = useQuery({
        queryKey: [UserApi.UserController.keyOptionsRoleName, {roleNames: ['ROLE_CONTENT_EDITOR']}],
        queryFn: ()=> UserApi.UserController.optionsRoleName({roleNames: ['ROLE_CONTENT_EDITOR']}),
    })

    return {
        loading: [
            contractors.isLoading,
            producers.isLoading,
            coordinators.isLoading,
            lawyers.isLoading,
            analysts.isLoading,
            financiers.isLoading,
            projectManagers.isLoading,
            contentEditors.isLoading,
        ].some((status) => status),
        options: {
            ROLE_CONTRACTOR: contractors.data || [],
            ROLE_PRODUCER: producers.data || [],
            ROLE_COORDINATOR: coordinators.data || [],
            ROLE_LAWYER: lawyers.data || [],
            ROLE_ANALYST: analysts.data || [],
            ROLE_FINANCIER: financiers.data || [],
            ROLE_PROJECT_MANAGER: projectManagers.data || [],
            ROLE_CONTENT_EDITOR: contentEditors.data || [],
        }
    }
}
const optionsFields: Array<{label: string, value: ExtendedSearchTypes.Stores.UsersKeys | 'Параметр неважен'}> = [
    {label: 'Параметр неважен', value: 'Параметр неважен'},
    {label: 'Исполнитель', value: 'ROLE_CONTRACTOR'},
    {label: 'Продюсер', value: 'ROLE_PRODUCER'},
    {label: 'Координатор', value: 'ROLE_COORDINATOR'},
    {label: 'Юрист', value: 'ROLE_LAWYER'},
    {label: 'Аналитик', value: 'ROLE_ANALYST'},
    {label: 'Финансист', value: 'ROLE_FINANCIER'},
    {label: 'Менеджер проектов', value: 'ROLE_PROJECT_MANAGER'},
    {label: 'Редактор контента', value: 'ROLE_CONTENT_EDITOR'},
];
export const UsersItem = () => {
    const put = useDispatch();
    const {loading, options} = useGetsData();
    const {typeContent} = useSelector(ExtendedSearchModel.selectors.main)
    const users = useSelector(ExtendedSearchModel.selectors.users)


    const disabledOptions = useMemo(() => {
        return users[typeContent].fields.map((el) => el.field)
    }, [users, typeContent]);

    const onGroupChange = (index: number) => (e: RadioChangeEvent) => {
        const arr = [...users[typeContent].fields];
        arr[index].isAndOrBesides = e.target.value;
        put(ExtendedSearchModel.actions.setUsers(typeContent, arr))
    }

    const onSelectChangeType = (index: number) => (value: ExtendedSearchTypes.Stores.UsersKeys | 'Параметр неважен') => {
        const newArray = [...users[typeContent].fields];
        newArray[index]["field"] = value;
        newArray[index]["values"] = [];
        put(ExtendedSearchModel.actions.setUsers(
            typeContent,
            newArray
        ))
    }
    const onSelectChangeValue = (index: number) => (value: ExtendedSearchTypes.Fields.FieldFilter["values"]) => {
        const newArray = [...users[typeContent].fields];
        newArray[index]["values"] = value;
        put(ExtendedSearchModel.actions.setUsers(
            typeContent,
            newArray
        ))
    }
    const addGroup = () => {
        put(ExtendedSearchModel.actions.setUsers(typeContent, [
            ...users[typeContent].fields,
            {field: 'Параметр неважен', isAndOrBesides: false, values: []}
        ]))
    }

    const onRemoveHandler = (index: number) => (_event: MouseEvent<HTMLDivElement>) => {
        const newArray = [...users[typeContent].fields];
        newArray.splice(index, 1)
        put(ExtendedSearchModel.actions.setUsers(
            typeContent,
            newArray
        ))
    }

    return (
        <Spin spinning={loading}>
            <div className="item-wrapper" key={typeContent}>
                {users[typeContent].fields.map((item, index) => (
                    <div className="item-wrapper__group" key={item.field + index}>
                        <div className="item-wrapper__items">
                            <div className="item">
                                <div className="form-group">
                                    <Select
                                        value={item.field}
                                        onChange={onSelectChangeType(index)}
                                        placeholder={'Параметр неважен'}
                                        getPopupContainer={trigger => trigger.parentNode}
                                    >
                                        {optionsFields.map((el) => (
                                            <Option
                                                disabled={disabledOptions.includes(el.value)}
                                                value={el.value}
                                                key={el.value}
                                            >
                                                {el.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                            <div className="or">
                                <Radio.Group value={item.isAndOrBesides} onChange={onGroupChange(index)}>
                                    <Radio value={true}>И</Radio>
                                    <Radio value={false}>Или</Radio>
                                </Radio.Group>
                            </div>
                            <div className="item item-second">
                                <div className="form-group">
                                    <Select
                                        allowClear={true}
                                        mode={"multiple"}
                                        disabled={item.field === 'Параметр неважен'}
                                        maxTagCount={"responsive"}
                                        value={item.values}
                                        onChange={onSelectChangeValue(index)}
                                        placeholder={'Параметр неважен'}
                                        filterOption={(input, option) => {
                                            if (option === undefined || option === null) return false;
                                            return option['data-name'].toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }}
                                        getPopupContainer={trigger => trigger.parentNode}
                                    >
                                        {item.field !== 'Параметр неважен' && options[item.field].map((el) => (
                                            <Option
                                                value={el.value}
                                                key={el.value}
                                                data-name={el.label}
                                            >
                                                {el.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                        </div>
                        {users[typeContent].fields.length > 1 && (
                            <div className="item__remove" onClick={onRemoveHandler(index)}>
                                <DeleteOutlined />
                            </div>
                        )}
                    </div>
                ))}
                {users[typeContent].fields.length < optionsFields.length - 1 && (
                    <div className="btn">
                        <Button type={'link'} onClick={addGroup}>+ Добавить условие</Button>
                    </div>
                )}
            </div>
        </Spin>
    )
}
