import React, { FC } from 'react';
import { Tooltip } from 'antd';
import WhetherTheUser from 'components/whetherTheUser';
import { TaskTypes } from 'entities/task';
import {ReactComponent as DuplicationIconOff} from 'assets/svg/DuplicationIconOff.svg'
import {ReactComponent as DuplicationIconOn} from 'assets/svg/DuplicationIconOn.svg'

import { TTasksKanbanItem } from 'models/task/task.kanban';
import { ModalEvent } from 'shared/ui/modal';
import ModalDuplication from '../modal-duplication';

type Props = {
	reportType?: TaskTypes.ReportType,
	taskName: string,
	enabled?: boolean
	taskId: string
	duplicationSchedule: TTasksKanbanItem['duplicationSchedule']
}
const ButtonComponent: FC<Props> = ({reportType, enabled, duplicationSchedule, taskId, taskName}) => {

	const handleClickDuplicateSchedule = (e: any) => {
		e.stopPropagation();
		ModalEvent.open({
			component: (
				<ModalDuplication
					taskId={taskId}
					taskName={taskName}
					duplicationSchedule={duplicationSchedule}
				/>
			),
			width: 860,
			destroyOnClose: true,
		})
	}
	if (reportType !== 'CONTENT_REACH_REPORT') return null;
	return (
		<WhetherTheUser
			role={['ROLE_PRODUCER', 'ROLE_ANALYST', 'ROLE_ADMIN', 'ROLE_PROJECT_MANAGER', 'ROLE_ADMIN_IS']}
			isCan={true}
		>
			<span className="duplication" onClick={handleClickDuplicateSchedule}>
				<Tooltip
					title={`Дублирование задач: ${enabled ? 'включено' : 'выключено'}`}
				>
					{enabled ? <DuplicationIconOn /> : <DuplicationIconOff />}
				</Tooltip>
			</span>
		</WhetherTheUser>
	)
};

export default ButtonComponent;