import React, {useMemo, useRef} from 'react';
import {Spin} from "antd";
import {useFormikContext} from "formik";
import {SelectComponent} from "components/formic-control";
import Checkbox from "antd/es/checkbox";
import {RegionLib} from 'entities/region'
import {ProjectFormDto} from "features/create-or-edit-project-form/lib/dto/project-form.dto";
import ReloadList from 'components/forms/components/reload-list/reload-list';
import styles from './styles.module.less'
import classNames from "classnames";

function filingName(key: string): string {
    return `stepSecond.${key}.value`
}

const RegionalTagsField = () => {
    const {values, setFieldValue} = useFormikContext<ProjectFormDto>();
    const form = values.stepSecond;

    const checkboxAllAboutRef = useRef(false)
    const checkboxAllForRef = useRef(false)
    const {data: tags, isLoading: loading, refetch} = RegionLib.useGetRegionsOptions()


    const disabledRegionsTags = useMemo(() => {
        if (tags.length) {
            return tags.filter((el) => el.hidden).map((el) => el.value)
        } else return []
    }, [tags]);

    const handlerUpdateTags = () => {
        refetch()
    }
    const selectCheckboxAllAbout = (e: any)=> {
        const checked: boolean = e.target.checked;
        checkboxAllAboutRef.current = checked
        if(checked) setFieldValue(filingName('regionAboutSet'),tags)
        else setFieldValue(filingName('regionAboutSet'),[])
    }

    const selectCheckboxAllFor = (e: any)=> {
        const checked: boolean = e.target.checked;
        checkboxAllForRef.current = checked
        if(checked) setFieldValue(filingName('regionForSet'),tags)
        else setFieldValue(filingName('regionForSet'),[])
    }

    return (
        <>
            <div className={classNames(styles.region_label, 'label')}>
                Региональные теги
            </div>
            <div className={styles.region1}>
                <Spin spinning={loading}>
                    <ReloadList className="ident" handlerUpdateList={handlerUpdateTags}/>
                    <SelectComponent
                        name={filingName('regionAboutSet')}
                        placeholder={'Выберите регион/ы, о котором контент проекта'}
                        options={tags}
                        multi={true}
                        disabled={form.regionAboutSet.isDisabled}
                        disabledOptionsIds={disabledRegionsTags}
                        handler={()=>checkboxAllAboutRef.current=false}
                        maxTagCount={'responsive'}
                        dropdownRender={
                            <Checkbox className={styles.checkboxInSelect} onChange={selectCheckboxAllAbout} checked={checkboxAllAboutRef.current} disabled={form.regionAboutSet.isDisabled}>
                                <span className={styles.checkboxText}>Выбрать все</span>
                            </Checkbox>
                        }
                    />

                </Spin>
            </div>
            <div className={styles.region2}>
                <Spin spinning={loading}>
                    <SelectComponent
                        name={filingName('regionForSet')}
                        options={tags}
                        placeholder={'Выберите регион/ы, для которых предназначен контент проекта'}
                        multi={true}
                        disabled={form.regionForSet.isDisabled}
                        disabledOptionsIds={disabledRegionsTags}
                        handler={()=>checkboxAllForRef.current=false}
                        maxTagCount={'responsive'}
                        dropdownRender={
                            <Checkbox className={styles.checkboxInSelect} onChange={selectCheckboxAllFor} checked={checkboxAllForRef.current} disabled={form.regionForSet.isDisabled}>
                                <span className={styles.checkboxText}>Выбрать все</span>
                            </Checkbox>
                        }
                    />

                </Spin>
            </div>
        </>

    )
};
export default React.memo(RegionalTagsField);
