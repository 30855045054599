import React, {MouseEventHandler} from 'react';
import './index.less';
import {Tooltip} from "antd";
import {Link} from "react-router-dom";
import {TASKS} from "routing/names";

const LinkPropsCell = (id: string, handler: MouseEventHandler<HTMLElement>, text: string | React.ReactNode, tooltip?: string) => {
    const handlerClick = (e: any) => {
        e.preventDefault()
        handler(e)
    }
    return (
        <Tooltip title={tooltip ? tooltip : ''}>
            <Link to={`${TASKS.TASK}?id=${id}`} onClick={handlerClick} className="LinkPropsCell">
                {text}
            </Link>
        </Tooltip>

    )
};
export default LinkPropsCell;
