import { axiosRequest } from 'shared/config/axios'
import { Genre, GenreSchema, Genres, GenreRequest, Filter } from '../types'
import { IOptions } from 'models/feels'
import {downloadXLXS} from "utils/downloadXLXS";

/**
 * Получение всех жанров
 */
export const getGenres = {
	key: '/genre-types',
	keyOptions: 'Options: /genre-types',
	fetch: async (): Promise<Genre[]> => {
		const { data } = await axiosRequest<Genre[]>({
			method: 'GET',
			url: '/genre-types',
			parser: GenreSchema.array(),
		})
		return data
	},
	fetchOptions: async () => {
		const {data} = await axiosRequest<Genre[]>({
			method: 'GET',
			url: '/genre-types',
			parser: GenreSchema.array(),
		})
		return  data.map((el) => ({
			value: el.id,
			label: el.name,
			hidden: el.hidden,
		} as IOptions))
	}
}
/**
 * Получение списка типов жанров с пагинацией, сортировкой и поиском
 */
export const getWithFilter = {
	key: '/genre-types/search',
	fetch: async (filter: Filter): Promise<Genres> => {
		const newFilter = { ...filter, page: filter.page - 1 }
		const { data } = await axiosRequest<Genres>({
			method: 'POST',
			url: '/genre-types/search',
			data: newFilter,
			parser: { list: true, schema: GenreSchema.array() },
		})
		return data
	},
}
/**
 * Создание жанра
 */
export const create = async (data: GenreRequest) => {
	await axiosRequest({
		method: 'POST',
		url: '/genre-types',
		data,
	})
}

/**
 * Обновление жанра
 */
export const update = async ({ id, data }: { id: string; data: GenreRequest }) => {
	await axiosRequest({
		method: 'PUT',
		url: `/genre-types/${id}`,
		data,
	})
}

/**
 * Удаление жанра
 */
export const remove = async (id: string) => {
	await axiosRequest({
		method: 'DELETE',
		url: `/genre-types/${id}`,
	})
}

/**
 * Массовые действия над жанрами
 */
export const bulkActions = async ({ rowIds, bulkAction }: { rowIds: string[]; bulkAction: string }) => {
	await axiosRequest({
		method: 'POST',
		url: `/genre-types/bulk-actions/${bulkAction}`,
		data: rowIds,
	})
	return bulkAction
}

/**
 * Выполнение выгрузки списка в формате Excel
 */
export const download = async (value: string[]) => {
	const { data }: any = await axiosRequest({
		method: 'POST',
		url: `/genre-types/export`,
		responseType: 'arraybuffer',
		headers: {
			'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		},
		data: value,
	})
	downloadXLXS(data, 'genres')
}
/**
 * Получение файла шаблона для выполнения импорта данных жанров
 */
export const downloadTemplate = async () => {
	const { data }: any = await axiosRequest({
		method: 'GET',
		url: '/genre-types/import/template',
		responseType: 'arraybuffer',
		headers: {
			'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		},
	})
	downloadXLXS(data, 'genres')
}
/**
 * Выполнение импорта данных жанров из заполненного шаблона в формате Excel
 */
export const upload = async (data: FormData) => {
	await axiosRequest({
		method: 'POST',
		url: '/genre-types/import',
		headers: {
			'Content-Type': 'multipart/form-data',
		},
		data,
	})
}

