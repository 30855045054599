import {axiosRequest} from 'shared/config/axios'
import {Filter, Rank, RankRequest, RankSchema} from '../types'
import {downloadXLXS} from "utils/downloadXLXS";
import {IOptions} from "models/feels";


/**
 * Получение всех званий
 */
export const getRanks = {
	key: '/rank',
	keyOptions: 'Options: /rank',
	fetch: async (): Promise<Rank[]> => {
		const { data } = await axiosRequest<Rank[]>({
			method: 'GET',
			url: '/rank',
			parser: RankSchema.array(),
		})
		return data
	},
	fetchOptions: async () => {
		const {data} = await axiosRequest<Rank[]>({
			method: 'GET',
			url: '/rank',
			parser: RankSchema.array(),
		})
		return  data.map((el) => ({
			value: el.id,
			label: el.name,
			hidden: el.hidden,
		} as IOptions))
	}
}
/**
 * Получение списка типов званий с пагинацией, сортировкой и поиском
 */
export const getWithFilter = {
	key: '/rank/search',
	fetch: async (filter: Filter): Promise<Rank> => {
		const newFilter = { ...filter, page: filter.page - 1 }
		const { data } = await axiosRequest<Rank>({
			method: 'POST',
			url: '/rank/search',
			data: newFilter,
			parser: { list: true, schema: RankSchema.array() },
		})
		return data
	},
}
/**
 * Создание звания
 */
export const create = async (data: RankRequest) => {
	await axiosRequest({
		method: 'POST',
		url: '/rank',
		data,
	})
}

/**
 * Обновление звания
 */
export const update = async ({ id, data }: { id: string; data: RankRequest }) => {
	await axiosRequest({
		method: 'PUT',
		url: `/rank/${id}`,
		data,
	})
}

/**
 * Удаление звания
 */
export const remove = async (id: string) => {
	await axiosRequest({
		method: 'DELETE',
		url: `/rank/${id}`,
	})
}

/**
 * Массовые действия над званиями
 */
export const bulkActions = async ({ rowIds, bulkAction }: { rowIds: string[]; bulkAction: string }) => {
	await axiosRequest({
		method: 'POST',
		url: `/rank/bulk-actions/${bulkAction}`,
		data: rowIds,
	})
	return bulkAction
}

/**
 * Выполнение выгрузки списка в формате Excel
 */
export const download = async (value: string[]) => {
	const { data }: any = await axiosRequest({
		method: 'POST',
		url: `/rank/export`,
		responseType: 'arraybuffer',
		headers: {
			'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		},
		data: value,
	})
	downloadXLXS(data, 'ranks')
}
/**
 * Получение файла шаблона для выполнения импорта данных званий
 */
export const downloadTemplate = async () => {
	const { data }: any = await axiosRequest({
		method: 'GET',
		url: '/rank/import/template',
		responseType: 'arraybuffer',
		headers: {
			'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		},
	})
	downloadXLXS(data, 'ranks')
}
/**
 * Выполнение импорта данных званий из заполненного шаблона в формате Excel
 */
export const upload = async (data: FormData) => {
	await axiosRequest({
		method: 'POST',
		url: '/rank/import',
		headers: {
			'Content-Type': 'multipart/form-data',
		},
		data,
	})
}

