import React, {FC} from 'react';
import './main-menu.less'
import {NavLink, useLocation} from 'react-router-dom';
import {ANALYTICS, CALENDAR, CONTENT, OPTIONS, PROJECTS, REPORTING, TASKS} from "routing/names";
import useToggleSidebar from "hooks/toggleSidebar";
import WhetherTheUser from "../../whetherTheUser";
import InfoMenuUploader from "../info-menu/info-menu-uploader";
import {useSelector} from "react-redux";
import {selectUser} from "store/auth/selectors";


type MainMenuProps = {
    mode: 'horizontal' | 'inline'
}
const MainMenu: FC<MainMenuProps> = ({mode}) => {
    const user = useSelector(selectUser)
    const {handlerSidebar} = useToggleSidebar();
    const location = useLocation()
    const type = location.pathname.split('/')[1]
    const handleCloseSidebar = () => {
        if (mode === 'inline') handlerSidebar('menu')()
    }
    return (
        <nav className='mainMenu'>
            <ul>
                <WhetherTheUser role={["ROLE_CLIENT", "ROLE_AGENT"]} isCan={false}>
                    <li>
                        <NavLink to={PROJECTS.PROJECTS} onClick={handleCloseSidebar} className={`${type === 'project' && 'active'}`}>
                            Проекты
                        </NavLink>
                    </li>
                </WhetherTheUser>

                <WhetherTheUser role={["ROLE_CLIENT", "ROLE_AGENT", 'ROLE_VIEWER']} isCan={false}>
                    <li>
                        <NavLink
                            to={CALENDAR.CALENDAR}
                            onClick={handleCloseSidebar}
                            className={`${type === 'calendar' && 'active'}`}
                        >
                            Календарь проектов
                        </NavLink>
                    </li>
                </WhetherTheUser>

                <WhetherTheUser role={["ROLE_CLIENT", "ROLE_VIEWER", "ROLE_AGENT"]} isCan={false}>
                    <li>
                        <NavLink to={TASKS.TASKS_PROJECTS} onClick={handleCloseSidebar} className={`${type === 'tasks' && 'active'}`} >Задачи</NavLink>
                    </li>
                </WhetherTheUser>

                <WhetherTheUser role={['ROLE_CONTRACTOR', "ROLE_CLIENT", "ROLE_AGENT"]} isCan={false}>
                    <li>
                        <NavLink to={ANALYTICS.ANALYTICS_INDICATORS} className={`${type === 'analytics' && 'active'}`}
                                 onClick={handleCloseSidebar}>Аналитика
                        </NavLink>
                    </li>
                </WhetherTheUser>
                {mode === 'inline' && (
                    <li>
                        <NavLink to={OPTIONS.OPTIONS} onClick={handleCloseSidebar}>Настройки</NavLink>
                    </li>
                )}
                <WhetherTheUser role={['ROLE_CONTRACTOR', "ROLE_CLIENT", "ROLE_VIEWER", "ROLE_AGENT"]} isCan={false}>
                    <li className="mainMenu__report">
                        <NavLink to={REPORTING.REPORTING_REPORTS} className={`${type === 'reporting' && 'active'}`}
                                 onClick={handleCloseSidebar}>Отчетность и шаблоны
                        </NavLink>
                    </li>
                </WhetherTheUser>
                {user?.contentViewAccess && (
                    <li>
                        <NavLink to={CONTENT.CONTENT} onClick={handleCloseSidebar}>Контент</NavLink>
                    </li>
                )}
                {mode === 'inline' && (
                    <li><InfoMenuUploader type={"mobile"}/></li>
                )}
            </ul>
        </nav>

    )
};
export default MainMenu;
