import React, {useMemo, useState} from 'react'
import './index.less'
import moment from 'moment/moment'
import { useSelector } from 'react-redux'
import { selectProject } from 'store/projects/selectors'
import { HomeOutlined, StarOutlined, TrophyOutlined } from '@ant-design/icons'
import WhetherTheUser from 'components/whetherTheUser'
import { Tooltip } from 'antd'
import Banner from 'shared/ui/banner'
import {Id_Name_Code_Hidden} from "../../../../../../shared/types/common";

const ProjectInfo = () => {
	const project = useSelector(selectProject)
	return (
		<div className="ProjectCardInfo">
			{project?.isPrivateProject && (
				<WhetherTheUser role={['ROLE_CONTRACTOR', 'ROLE_VIEWER', 'ROLE_CLIENT']} isCan={false}>
					<div style={{ marginBottom: 15 }}>
						<Banner>
							<span>
								Информация проекта несет конфиденциальный характер и предоставляется ограниченному кругу сотрудников
							</span>
						</Banner>
					</div>
				</WhetherTheUser>
			)}

			<div className="ProjectInfo">
				<div className="ProjectInfo__item">
					<span className="name red">Заявка:</span>
					<span>{project?.requestNumber || '—'}</span>
				</div>

				<div className="ProjectInfo__item">
					<span className="name red">Договор:</span>
					<span>
						{project?.contractNumber}
						{project?.contractDate ? (
							<WhetherTheUser role={['ROLE_VIEWER']} isCan={false}>
								<> от {moment(project?.contractDate).format('DD.MM.yy')}</>
							</WhetherTheUser>
						) : (
							'—'
						)}
					</span>
				</div>

				<WhetherTheUser role={['ROLE_VIEWER']} isCan={false}>
					<div className="ProjectInfo__item">
						<span className="name red">Дата соглашения:</span>
						{project?.agreementDate ? <span>от {moment(project.agreementDate).format('DD.MM.yy')} </span> : '—'}
					</div>
				</WhetherTheUser>

				<div className="ProjectInfo__item">
					<span className="name red">Организация:</span>
					<span>{project?.contractorOrganization.name || '—'}</span>
				</div>
				<div className="ProjectInfo__item">
					<span className="name red">ИГК:</span>
					<span>{project?.igk || '—'}</span>
				</div>
				<div className="ProjectInfo__item">
					<span className="name">Код проекта:</span>
					<span>{project?.code || '—'}</span>
				</div>
			</div>

			<div className="ProjectInfo__group">
				<div className="ProjectInfo__item">
					<span className="name">Количество ед.контента (План/Факт):</span>
					<span>
						{project?.contentFormats.length
							? `${project.contentFormats.reduce((acc, it) => acc + it.contentCount, 0)}/${project?.factContentCount}`
							: '—'}
					</span>
				</div>
				<div className="ProjectInfo__item">
					<span className="name">Кол-во согласованных учитываемых ед.контента:</span>
					<span>{project?.factApprovedCountedContentCount}</span>
				</div>
				<div className="ProjectInfo__item">
					<span className="name">Кол-во согласованных неучитываемых ед.контента:</span>
					<span>{project?.factApprovedUncountedContentCount}</span>
				</div>
			</div>
			<div className="ProjectInfo__item">
				<span className="name">Категория проекта:</span>
				<span>{project?.type?.name}</span>
			</div>
			<WhetherTheUser role={['ROLE_VIEWER', 'ROLE_CONTRACTOR']} isCan={false}>
				<>
					<div className="ProjectInfo__item">
						<span className="name">Метка редактора контента:</span>
						<span>
							{project?.contentEditorLabels?.length
								? project?.contentEditorLabels.map((it) => it.name).join(', ')
								: '—'}
						</span>
					</div>
					<div className="ProjectInfo">
						<div className="ProjectInfo__item">
							<span className="name">Метка редактора каталога:</span>
							<span>
								{project?.moderatorLabels?.length ? project?.moderatorLabels.map((it) => it.name).join(', ') : '—'}
							</span>
						</div>
					</div>
				</>
			</WhetherTheUser>
			<div className="ProjectInfo" style={{ marginBottom: 5 }}>
				<div className="ProjectInfo__item">
					<span className="name">Формат:</span>
					<span>{project?.categoryTypes?.length ? project?.categoryTypes.map((it) => it.name).join(', ') : '—'}</span>
				</div>
				<div className="ProjectInfo__item">
					<span className="name">Жанр:</span>
					<span>{project?.genreTypes?.length ? project?.genreTypes.map((it) => it.name).join(', ') : '—'}</span>
				</div>
			</div>
			<div className="ProjectInfo">
				<div className="ProjectInfo__item">
					<div className="title">
						<TrophyOutlined/> Конкурс
					</div>
					<div className="element">
						<span className="name">Название</span>
						<span>
							{project?.session ? (
								<>
									({project?.session?.contest.code}) {project?.session?.contest.shortName}
								</>
							) : ('—')}
						</span>
					</div>
					<div className="element">
						<span className="name">Сессия</span>
						<span>
							{project?.session ? (
								<>
									{project?.session?.sessionNumber} {project?.session?.sessionType.name} {project?.session?.year}
								</>
							) : ('—')}
						</span>
					</div>
				</div>

				<div className="ProjectInfo__item">
					<div className="title">
						<HomeOutlined/> Регионы
					</div>
					<div className="element">
						<span className="name">О каком регионе</span>
						<ExpandableList arr={project?.regionAboutSet || []}/>
					</div>
					<div className="element">
						<span className="name">Для какого региона</span>
						<ExpandableList arr={project?.regionForSet || []}/>
					</div>
					<div className="element">
						<span className="name mt15">Место съемок</span>
						<ExpandableList arr={project?.contentLocationRegions || []}/>
					</div>
					<div className="element">
						<span className="name mt15">Кинокластер</span>
						<ExpandableList arr={project?.filmClusters || []}/>
					</div>
				</div>

				<div className="ProjectInfo__item ProjectInfo__item--width100">
					<div className="title">
						<StarOutlined/> Свод тематических тегов проекта
					</div>
					<div className="element">
						<span className="name">0 уровень</span>
						<ExpandableList arr={project?.zeroLevelTags || []}/>
					</div>
					<div className="element">
						<span className="name">1 уровень</span>
						<ExpandableList arr={project?.firstLevelTags || []}/>
					</div>
					<div className="element">
						<span className="name mt15">2 уровень</span>
						<ExpandableList arr={project?.secondLevelTags || []}/>
					</div>
					<div className="element">
						<span className="name mt15">3 уровень</span>
						<ExpandableList arr={project?.thirdLevelTags || []}/>
					</div>
				</div>
				<WhetherTheUser role={['ROLE_CONTRACTOR', 'ROLE_VIEWER', 'ROLE_AGENT', 'ROLE_CLIENT']} isCan={false}>
					<div className="ProjectInfo__item ProjectInfo__item--width100">
						<div className="title">
							<StarOutlined/> Свод тематических тегов  поручений проекта
						</div>
						<div className="element">
							<span className="name">1 уровень</span>
							<ExpandableList arr={project?.firstLevelAssignmentTags || []}/>
						</div>
						<div className="element">
							<span className="name">2 уровень</span>
							<ExpandableList arr={project?.secondLevelAssignmentTags || []}/>
						</div>
					</div>
				</WhetherTheUser>
				<div className="ProjectInfo__item ProjectInfo__item--width100">
					<div className="title">
						<StarOutlined/> Свод тематических тегов проекта национального проекта
					</div>
					<div className="element">
						<span className="name">1 уровень</span>
						<ExpandableList arr={project?.firstLevelNationalTags || []}/>
					</div>
					<div className="element">
						<span className="name mt15">2 уровень</span>
						<ExpandableList arr={project?.secondLevelNationalTags || []}/>
					</div>
				</div>
				<WhetherTheUser role={['ROLE_CONTRACTOR', 'ROLE_VIEWER', 'ROLE_AGENT', 'ROLE_CLIENT']} isCan={false}>
					<div className="ProjectInfo__item ProjectInfo__item--width100">
						<div className="title">
							<StarOutlined/> Свод тематических тегов поручений национального проекта
						</div>
						<div className="element">
							<span className="name">1 уровень</span>
							<ExpandableList arr={project?.firstLevelNationalAssignmentTags || []}/>
						</div>
						<div className="element">
							<span className="name mt15">2 уровень</span>
							<ExpandableList arr={project?.secondLevelNationalAssignmentTags || []}/>
						</div>
					</div>
				</WhetherTheUser>
			</div>
		</div>
	)
}

type TExpandableListProps = {
	arr: Id_Name_Code_Hidden[]
}

function ExpandableList({arr}: TExpandableListProps) {
	const [state, setState] = useState(true)

	const toggleShowHide = () => {
		setState((prevState) => !prevState)
	}

	const shortListStr = useMemo(() => arr.slice(0, 4).map((el) => el.name).join(', '), [arr]);
	const fullListStr = useMemo(() => arr.map((el) => el.name).join(', '), [arr]);
	const remainingItemCount = arr.length - 4;

	if (arr.length === 0) {
		return <span className="element-span">—</span>;
	}

	return (
		<span className="element-span">
			{state ? shortListStr : fullListStr}
			{arr.length > 4 && (
				<span className="ProjectInfo__item__amount" onClick={toggleShowHide}>
					<Tooltip placement="top" title={state ? `Развернуть` : 'Свернуть'}>
						{state ? `+${remainingItemCount}` : '—'}
					</Tooltip>
				</span>
			)}
		</span>
	)
}

export default ProjectInfo
