import {getGenres} from '../api'
import { Genre } from '../types'
import { useQuery, UseQueryResult } from 'react-query'
import { useMemo } from 'react'
import {IOptions} from "models/feels";

type onSuccessType = (data: Genre[]) => void
export function useGetGenre(onSuccess?: onSuccessType): UseQueryResult<Genre[]> {
	return useQuery({
		queryKey: [getGenres.key],
		queryFn: () => getGenres.fetch(),
		onSuccess,
	})
}
export function useGetGenreOptions(onSuccess?: onSuccessType) {
	const { data = [], ...rest } = useGetGenre(onSuccess)
	const options = useMemo(() => data.map((el) => {
		return {value: el.id, label: el.name, hidden: el.hidden, code: el.code} as IOptions
	}), [data])

	return { data: options, ...rest }
}
