import {IOptions} from "../feels";
import {TPerson} from "../user";
import {Nullable, T_ID_NAME, T_ID_NAME_CODE} from "../common";
import {Sprint} from "../sprints";
import {ReportType} from "./types";
import {ProjectStatusEnum} from "entities/project/types";
import {UploadFile} from "antd/es/upload/interface";
import { TaskTypes } from "entities/task";

type TTContentName = 'Видео' | 'Изображение' | 'Аудио' | 'Текст' | 'Код' | 'Другое'
type TContent = {
    name: TTContentName
    count: number
}

type TTask = {
    name: string
    typeProject: string
    project: number
    status: string
    timeOfExecution: string | Date
    stage: string
    payment: boolean
    cost: number
    taskDescription: string
    content: TContent[]

    repeat: string
    report: string

    performer: string
    checking: string[]
    coordinator: string[]
}
export enum TTasksTypesFields {
    types = 'types',
    statuses = 'statuses',
    stages = 'stages',
    costType = 'costType',
    recordsStatuses = 'recordsStatuses',
}

export type TContents = {
    contentCount: number
    id: string | null
    formatTypeId: string
    isAccountedContent: boolean
    isContent: boolean
}
export type TContentsResponse = {
    contentCount: number
    id: string | null
    contentFormatType: T_ID_NAME_CODE
    isAccountedContent: boolean
    isContent: boolean
}
export type TContentsForm = {
    contentCount: number
    id: string | null
    contentFormatType: IOptions
    isAccountedContent: boolean
    isContent: boolean
}
/**
 * @category TTaskUser
 * @description TTaskUser
 */
export type TTaskUser = {
    isMain: boolean
    userId: string
}

export type TTaskRole = {
    [key: string] :TTaskUser
}

/**
 * @category Types
 * @description TPostingTask
 */
export type TPostingTask = {
    code: string
    id: string
    name: string
    number: string
}
/**
 * @category Types
 * @description TTaskRequest
 */
export type TTaskRequest = {
    contents: TContents[] | null
    coordinatorIds:  Nullable<TTaskUser[] | string[]>
    deadline: string
    description: Nullable<string>
    executorId: string
    name: string
    price: number
    producerIds: Nullable<string[]>
    analystIds:   Nullable<string[]>
    financierIds:  Nullable<string[]>
    projectId: string
    reportTypeId: string
    typeId: string
    sprintId: string

    postingTaskIds: string[]
    reachRecordsScreenshotRequired: boolean
    contentRecordDescriptionContractorVisible: boolean
}

export type TTaskRequestDto = Omit<TTaskRequest, "price"| "executorId"| "postingTaskIds">

export type TTaskProject = {
    coordinators: TPerson[]
    contractors: TPerson[]
    contentEditors: TPerson[]
    projectManagers: TPerson[]
    contractorOrganization: {
        id: string
        inn: string
        name: string
        ogrnOgrnip: string
    }
    id: string
    name: string
    owner: TPerson
    producers: TPerson[]
    co: TPerson[]
    analysts: TPerson[]
    financiers: TPerson[]
    status: {code: ProjectStatusEnum} & T_ID_NAME
    code: string
    contentTagRequired: Nullable<boolean>
}

export type TTaskResponse = {
    code: string
    contents: TContentsResponse[]
    coordinators: TPerson[]
    deadline: string
    sprint: Sprint | null
    description?: string
    id: string
    name: string
    number: string
    price: number
    producers: TPerson[]
    analysts: TPerson[]
    financiers: TPerson[]
    contentEditors: TPerson[]
    projectManagers: TPerson[]
    contractors: TPerson[]
    project: TTaskProject
    reportType: { code: ReportType } & T_ID_NAME
    stage: T_ID_NAME_CODE
    status: { code: TaskTypes.TasksStatus } & T_ID_NAME
    type: { code: TaskTypes.TypesTask } & T_ID_NAME
    reachRecordsScreenshotRequired?: boolean
    reachRecordsCounterScreenshotRequired?: boolean
    postingRecordsScreenshotRequired?: boolean
    contentRecordDescriptionContractorVisible: boolean
    reachRecordsReactionAmountRequired: boolean
    postingTasks?: TPostingTask[]
    contentTagsContractorVisible: Nullable<boolean>
    regionTagsContractorVisible: Nullable<boolean>
    contentTimeCodeRequired: boolean
    contentTiming: number | null
    commentsCount: number
    draftRecordsCount: number // Черновики
    readyForVerificationRecordsCount: number // Готовы к согласованию
    verificationRequiredRecordsCount: number // На согласовании
    rejectedRecordsCount: number // Отклонены к исправлению
    correctionRecordsCount: number // На исправлении
    approvedRecordsCount: number // Согласованы
    approvedOutdatedRecordsCount: number // Одобрено, но неактуально
    rejectedAfterVoteResetRecordsCount: number // Отклонено, после сброса голосов раннее согласованной записи
    archival: boolean
    frozen: boolean
    isRecordsExists: boolean
    defaultIsContent: boolean
    defaultIsAccountedContent: Nullable<boolean>
}

export type TTaskForm = {
    name: string
    archival: boolean
    typeProject: string
    project: IOptions | null
    report: string | null
    deadline: string | Date
    status?: IOptions | null
    sprint: IOptions | null
    description: string
    contents: TContentsForm[] | null
    producers: TTaskRole | null
    coordinators: TTaskRole |  null
    analysts: TTaskRole | null
    financiers: TTaskRole | null
    projectManagers: TTaskRole | null
    reachRecordsScreenshotRequired: boolean | 'true' | 'false'
    reachRecordsCounterScreenshotRequired: boolean | 'true' | 'false'
    postingRecordsScreenshotRequired: boolean | 'true' | 'false'
    isContentRecordDescription: boolean
    contentTagRequired: boolean
    regionTagsRequired: boolean
    contentTimeCodeRequired: boolean
}

export type TTaskReportType = {
    CONTENT_REACH_REPORT: T_ID_NAME_CODE
    PROJECT_ESTIMATE_REPORT: T_ID_NAME_CODE
}

export type TTaskVote = {
    comment: string | null,
    isApproved: boolean,
    recordId: string,
    files: Blob[] | UploadFile[] | null
}
export default TTask;
