import moment from "moment";
import {FieldFilter, FieldsEnums, TFilterData} from "./types/fileds";
import {ExtendedSearchTypes } from "features/extended-search";

export function mapData(filter: ExtendedSearchTypes.Stores.TAllFilterValues): Partial<TFilterData> {
    const {fields, contentFields} = getFieldFilters(filter);
    return {
        ...(!!filter.project.requestNumbers.length && { requestNumbers: filter.project.requestNumbers}),
        ...(!!filter.project.contractNumbers.length && { contractNumbers: filter.project.contractNumbers}),
        ...(!!filter.project.contractNumbers.length && { contractNumbers: filter.project.contractNumbers}),
        ...(!!filter.project.codes.length && { codes: filter.project.codes}),


        ...(filter.agreementOfProjectTaskRecords?.isNotVotedRecordsExists !== undefined && { isNotVotedRecordsExists: filter.agreementOfProjectTaskRecords?.isNotVotedRecordsExists}),
        ...(!!filter.agreementOfProjectTaskRecords?.notVotedRecordsInspectorUserIds?.length && {notVotedRecordsInspectorUserIds: filter.agreementOfProjectTaskRecords?.notVotedRecordsInspectorUserIds}),

        ...(!!filter.organizations.organizationIds.length && { organizationIds: filter.organizations.organizationIds}),
        ...(!!filter.organizations.legalFormIds.length && { legalFormIds: filter.organizations.legalFormIds}),

        ...(!!filter.contests.contestIds.length && { contestIds: filter.contests.contestIds}),
        ...(!!filter.contests.years.length && { years: filter.contests.years}),
        ...(!!filter.contests.sessionIds.length && { sessionIds: filter.contests.sessionIds}),

        ...(!!filter.status.statusIds.length && { statusIds: filter.status.statusIds}),
        ...(!!filter.status.sprintStatusIds.length && { sprintStatusIds: filter.status.sprintStatusIds}),
        ...(!!filter.status.contentDirectionIds.length && { contentDirectionIds: filter.status.contentDirectionIds}),
        ...(!!filter.status.contentEditorLabelIds.length && { contentEditorLabelIds: filter.status.contentEditorLabelIds}),
        ...(!!filter.status.moderatorLabelIds.length && { moderatorLabelIds: filter.status.moderatorLabelIds}),

        ...(filter.indicators.advancePayment !== undefined && { advancePayment: filter.indicators.advancePayment}),
        ...(filter.indicators.isNationalProject !== undefined && { isNationalProject: filter.indicators.isNationalProject}),
        ...(filter.indicators.isDataChecked !== undefined && { isDataChecked: filter.indicators.isDataChecked}),
        ...(filter.indicators.isFamousPeopleRemarked !== undefined && { isFamousPeopleRemarked: filter.indicators.isFamousPeopleRemarked}),
        ...(filter.indicators.isContentCreationProjectType !== undefined && { isContentCreationProjectType: filter.indicators.isContentCreationProjectType}),
        ...(filter.indicators.isProjectPosted !== undefined && { isProjectPosted: filter.indicators.isProjectPosted}),
        ...(filter.indicators.isPrivateProject !== undefined && { isPrivateProject: filter.indicators.isPrivateProject}),
        ...(filter.indicators.isTaskOverdueExist !== undefined && { isTaskOverdueExist: filter.indicators.isTaskOverdueExist}),
        ...(filter.indicators.isFavoriteProject !== undefined && { isFavoriteProject: filter.indicators.isFavoriteProject}),

        ...(filter.indicatorsContent.isContent !== undefined && { isContent: filter.indicatorsContent.isContent}),
        ...(filter.indicatorsContent.isAccountedContent !== undefined && { isAccountedContent: filter.indicatorsContent.isAccountedContent}),
        ...(filter.indicatorsContent.isMasterContent !== undefined && { isMasterContent: filter.indicatorsContent.isMasterContent}),
        ...(!!filter.indicatorsContent.contentStatusCodes?.length && { contentStatusCodes: filter.indicatorsContent.contentStatusCodes}),


        ...(!!fields.length && { fieldFilters: fields}),
        ...(!!contentFields.length && { contentFieldFilters: contentFields}),
    }
}
function getFieldFilters(filter: ExtendedSearchTypes.Stores.TAllFilterValues): {
    fields: TFilterData["fieldFilters"],
    contentFields: TFilterData["contentFieldFilters"]
} {
    const fields: TFilterData["fieldFilters"]= [];
    const contentFields: TFilterData["contentFieldFilters"] = [];
    if (!!filter.awards.length) {
        filter.awards.forEach((el) => {
            if (el.values.length) {
                fields.push({
                    field: el.field,
                    isAndOrBesides: el.isAndOrBesides,
                    values: el.values
                } as FieldFilter)
            }
        })
    }
    if (!!filter.thematics.length) {
        filter.thematics.forEach((el) => {
            if (el.values.length) {
                if (el.field === 'CONTENT_FILTER_ZERO_LVL_TAG' ||
                    el.field === 'CONTENT_FILTER_FIRST_LVL_TAG' ||
                    el.field === 'CONTENT_FILTER_SECOND_LVL_TAG' ||
                    el.field === 'CONTENT_FILTER_THIRD_LVL_TAG') {
                    contentFields.push({
                        field: el.field,
                        isAndOrBesides: el.isAndOrBesides,
                        values: el.values
                    })
                } else {
                    fields.push({
                        field: el.field,
                        isAndOrBesides: el.isAndOrBesides,
                        values: el.values
                    } as FieldFilter)
                }
            }
        })
    }
    if (!!filter.regionals.length) {
        filter.regionals.forEach((el) => {
            if (el.values.length) {
                if (el.field === 'CONTENT_FILTER_REGION_ABOUT' || el.field === 'CONTENT_FILTER_REGION_FOR') {
                    contentFields.push({
                        field: el.field,
                        isAndOrBesides: el.isAndOrBesides,
                        values: el.values
                    })
                } else {
                    fields.push({
                        field: el.field,
                        isAndOrBesides: el.isAndOrBesides,
                        values: el.values
                    } as FieldFilter)
                }
            }
        })
    }
    if (!!filter.content.length) {
        filter.content.forEach((el) => {
            if (el.values.length) {
                if (
                  el.field === 'CONTENT_FILTER_CONTENT_SUBMISSION_FORM' ||
                  el.field === 'CONTENT_FILTER_CONTENT_FORMAT_TYPE'
                ) {
                    contentFields.push({
                        field: el.field,
                        isAndOrBesides: el.isAndOrBesides,
                        values: el.values
                    })
                } else {
                    fields.push({
                        field: el.field,
                        isAndOrBesides: el.isAndOrBesides,
                        values: el.values
                    } as FieldFilter)
                }
            }
        })
    }
    if (!!filter.channels.length) {
        filter.channels.forEach((el) => {
            if (el.values.length) {
                fields.push({
                    field: el.field,
                    isAndOrBesides: el.isAndOrBesides,
                    values: el.values
                } as FieldFilter)
            }
        })
    }
    if (!!filter.users.length) {
        filter.users.forEach((el) => {
            if (el.values.length) {
                fields.push({
                    field: FieldsEnums.USER,
                    isAndOrBesides: el.isAndOrBesides,
                    values: el.values
                } as FieldFilter)
            }

        })
    }
    if (!!filter.famousPeople.length) {
        filter.famousPeople.forEach((el) => {
            if (el.values.length) {
                fields.push({
                    field: el.field,
                    isAndOrBesides: el.isAndOrBesides,
                    values: el.values
                } as FieldFilter)
            }
        })
    }
    if (!!filter.dates.length) {
        filter.dates.forEach((el) => {
            if (el.startDate && el.endDate) {
                fields.push({
                    field: el.field,
                    startDate: moment(el.startDate, 'DD.MM.YYYY').toISOString(true),
                    endDate: moment(el.endDate, 'DD.MM.YYYY').toISOString(true)
                } as FieldFilter)
            }
        })
    }
    if (!!filter.counts.length) {
        filter.counts.forEach((el) => {
            if (el.maxValue && el.minValue) {
                if (el.field === 'CONTENT_TIMING_MIN_MAX') {
                    contentFields.push({
                        field: el.field,
                        minValue: +el.minValue,
                        maxValue: +el.maxValue,
                        isAndOrBesides: el.isAndOrBesides
                    })
                } else {
                    fields.push({
                        field: el.field,
                        minValue: +el.minValue,
                        maxValue: +el.maxValue,
                        isAndOrBesides: el.isAndOrBesides
                    } as FieldFilter)
                }
            }
        })
    }
    if (!!filter.categoriesGenres.length) {
        filter.categoriesGenres.forEach((el) => {
            if (el.values.length) {
                fields.push({
                    field: el.field,
                    isAndOrBesides: el.isAndOrBesides,
                    values: el.values
                } as FieldFilter)
            }
        })
    }
    return {fields, contentFields}
}
