import { TaskTypes } from 'entities/task';
import { ValidateVoting } from './index';

type Props = {
	validate: ValidateVoting
	recordStatus: TaskTypes.TasksRecordStatus,
	hasComments: boolean
}
export const ContractorAndClient = ({validate, recordStatus, hasComments}: Props): ValidateVoting => {
	if (recordStatus === 'READY_FOR_CUSTOMER_VERIFICATION' || recordStatus === 'VERIFICATION_REQUIRED') {
		validate.warning = 'disabled';
		validate.messageList = 'disabled';
		return validate;
	}
	if ((['DRAFT', 'READY_FOR_VERIFICATION', 'CREATED_BY_CUSTOMER'] as TaskTypes.TasksRecordStatus[]).includes(recordStatus)) {
		validate.edit = 'disabled';
		validate.messageList = 'disabled';
		return validate;
	}
	if (recordStatus === 'APPROVED_OUTDATED') {
		validate.approvedOutdated = 'view';
		validate.messageList = hasComments ? 'view' : 'disabled'
		return validate;
	}
	if (recordStatus === 'APPROVED') {
		validate.approved = 'view';
		validate.messageList = hasComments ? 'view' : 'disabled'
		return validate;
	}
	if (recordStatus === 'REJECTED') {
		validate.rejected = 'view';
		validate.messageList = hasComments ? 'view' : 'disabled'
		return validate;
	}
	if (recordStatus === 'REJECTED_AFTER_VOTE_RESET') {
		validate.rejectedAfterVoteReset = 'view';
		validate.messageList = hasComments ? 'view' : 'disabled'
		return validate;
	}

	return validate;
}
