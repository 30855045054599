import React, {FC} from 'react';
import {ImageComponent} from "components/image-component";
import {Button, Tooltip} from "antd";
import {ModalOpenAction} from "store/modals/actions";
import {ModalTeamsData} from "components/modals/modal-teams/modal-teams";
import {useDispatch} from "react-redux";
import { UserTypes } from 'entities/user';
import { ProjectTypes } from 'entities/project/index';

const Person: FC<{
    person: ProjectTypes.UserProject | null,
    role: UserTypes.RoleName,
    persons: ProjectTypes.UserProject[],
    contractorOrganization?: ProjectTypes.Project["contractorOrganization"]
}> = ({
    person, role, persons, contractorOrganization
}) => {
    const put = useDispatch();
    const user = {
        lastName: person?.lastName ?? '',
        firstName: person?.firstName ?? '',
        middleName: person?.middleName ?? '',
    }
    return (
        <div className='item'>
            <div className="item__header">
                <ImageComponent file={person?.image} altImg={window.location.origin + '/images/avatar.png'}/>
                <span>{rolePerson(role)}</span>
            </div>
            <div className="item__content">
                <Tooltip title={`${person?.lastName} ${person?.firstName} ${person?.middleName || ''}`}>
                    <span>{user.lastName} {user.firstName[0]} {user?.middleName[0] || ''}</span>
                </Tooltip>
            </div>
            {persons.length >= 1 && (
                <Button type={"default"} style={{marginTop: '15px'}} onClick={() => {
                    put(ModalOpenAction('team', {
                        personList: persons,
                        type: "all",
                        role,
                        contractorOrganization: contractorOrganization
                    } as ModalTeamsData))
                }}>
                    Показать всех
                </Button>
            )}
        </div>
    )
}
function rolePerson(person: UserTypes.RoleName) {
    switch (person) {
        case "ROLE_CONTRACTOR":
            return 'Исполнитель'
        case "ROLE_PRODUCER":
            return 'Продюсер'
        case "ROLE_COORDINATOR":
            return 'Координатор'
        case "ROLE_FINANCIER":
            return 'Финансист'
        case "ROLE_ANALYST":
            return 'Аналитик'
        case "ROLE_LAWYER":
            return 'Юрист'
        case "ROLE_CONTENT_EDITOR":
            return 'Редактор контента'
        case "ROLE_PROJECT_MANAGER":
            return 'Менеджер проекта'
        default:
            return null;
    }
}
export default Person;
