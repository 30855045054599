import React from 'react';
import {Radio, RadioChangeEvent} from "antd";
import {useDispatch, useSelector} from "react-redux";
import './index.less';
import {ExtendedSearchModel, ExtendedSearchTypes} from 'features/extended-search/index';
import {IOptions} from "models/feels";
import {selectUser} from "store/auth/selectors";

type TKey = keyof Omit<
    ExtendedSearchTypes.Stores.Indicators, 'isToggled'>

const options: ExtendedSearchTypes.Stores.IndicatorsOptions = {
    advancePayment: [
        {label: 'Все проекты', value: 'Все проекты'},
        {label: 'Авансирование', value: 'true'},
        {label: 'Пост оплата', value: 'false'},
    ],
    isContentCreationProjectType: [
        {label: 'Все проекты', value: 'Все проекты'},
        {label: 'Проекты на создание', value: 'true'},
        {label: 'Проекты на создание + размещение', value: 'false'},
    ],
    isNationalProject: [
        {label: 'Все проекты', value: 'Все проекты'},
        {label: 'Национальные проекты', value: 'true'},
        {label: 'Обычные проекты', value: 'false'},
    ],
    isDataChecked: [
        {label: 'Все проекты', value: 'Все проекты'},
        {label: 'Проверены', value: 'true'},
        {label: 'Не проверены', value: 'false'},
    ],
    isFamousPeopleRemarked: [
        {label: 'Все проекты', value: 'Все проекты'},
        {label: 'Присутствуют', value: 'true'},
        {label: 'Отсутствуют', value: 'false'},
    ],
    isProjectPosted: [
        {label: 'Все проекты', value: 'Все проекты'},
        {label: 'Размещается', value: 'true'},
        {label: 'Не размещается', value: 'false'},
    ],
    isPrivateProject: [
        {label: 'Все проекты', value: 'Все проекты'},
        {label: 'Конфиденциальные', value: 'true'},
        {label: 'Не конфиденциальные', value: 'false'},
    ],
    isTaskOverdueExist: [
        {label: 'Все проекты', value: 'Все проекты'},
        {label: 'Присутствуют', value: 'true'},
        {label: 'Отсутствуют', value: 'false'},
    ],
    isFavoriteProject: [
        {label: 'Все проекты', value: 'Все проекты'},
        {label: 'Избранные', value: 'true'},
        {label: 'Остальные', value: 'false'},
    ],
}
export const IndicatorItem = () => {
    const user = useSelector(selectUser)
    return (
        <div className="item-wrapper Indicators">
            <Item
                options={options.advancePayment}
                label={'Форма оплаты'}
                name={'advancePayment'}
            />
            <Item
                options={options.isContentCreationProjectType}
                label={'Категория проекта'}
                name={'isContentCreationProjectType'}
            />
            <Item
                options={options.isNationalProject}
                label={'Признак проекта'}
                name={'isNationalProject'}
            />
            <Item
                options={options.isDataChecked}
                label={'Данные проекта'}
                name={'isDataChecked'}
            />
            <Item
                options={options.isFamousPeopleRemarked}
                label={'Известные люди'}
                name={'isFamousPeopleRemarked'}
            />
            <Item
                options={options.isProjectPosted}
                label={'Размещение проекта'}
                name={'isProjectPosted'}
            />
            {user?.privateProjectViewAccess && (
                <Item
                    options={options.isPrivateProject}
                    label={'Конфиденциальный проект'}
                    name={'isPrivateProject'}
                />
            )}
            <Item
              options={options.isTaskOverdueExist}
              label={'Просроченные задачи'}
              name={'isTaskOverdueExist'}
            />
          <Item
              options={options.isFavoriteProject}
              label={'Избранный проект'}
              name={'isFavoriteProject'}
            />
        </div>
    )
}
type ItemProps = {
    options: IOptions[],
    name: TKey,
    label: string
}
function Item({options, name, label}: ItemProps) {
    const put = useDispatch();
    const {typeContent} = useSelector(ExtendedSearchModel.selectors.main)
    const indicators = useSelector(ExtendedSearchModel.selectors.indicators)
    const onGroupChange = (e: RadioChangeEvent) => {
        put(ExtendedSearchModel.actions.setIndicators(typeContent, {[name]: e.target.value}))
    }
    return (
        <div className="item">
            <div className="label">{label}</div>
            <div className="form-group form-group--radio">
                <Radio.Group
                    value={indicators[typeContent][name]}
                    onChange={onGroupChange}
                >
                    {options.map((option: IOptions) => {
                        return (
                            <Radio
                                checked={indicators[typeContent][name] === (option.value)}
                                key={option.label}
                                value={option.value}
                            >
                                {option.label}
                            </Radio>
                        )
                    })}
                </Radio.Group>
            </div>
        </div>
    )
}
