import {takeEvery, takeLatest} from 'redux-saga/effects'
import {
	OptionsGeneralActionTypes,
	OptionsHandbookCategoryProject,
	OptionsHandbookLegalForm,
	OptionsHandbookSessionsType,
	UsersHandbookContentDeliveryTypes,
	UsersHandbookContentDirectionTypes,
	UsersHandbookDownloadTypes,
	UsersHandbookInternetTypes,
	UsersHandbookOrganizationTypes,
	UsersHandbookThematicsTypes,
	UsersHandbookUsersActionsTypes
} from 'store/options/action-types'
import {getHandbook} from "./getHandbook";
import {setHandbookFilter} from "./setHandbookFilter";
import {addInternet} from "./internet/add";
import {bulkInternetActions} from "./internet/bulk-actions";
import {deleteInternet} from "./internet/delete";
import {updateInternet} from "./internet/update";
import {addContentDelivery} from "./content-delivery/add";
import {updateContentDelivery} from "./content-delivery/update";
import {deleteContentDelivery} from "./content-delivery/delete";
import {bulkContentDeliveryActions} from "./content-delivery/bulk-actions";
import {addContentDirection} from "./content-direction/add";
import {updateContentDirection} from "./content-direction/update";
import {deleteContentDirection} from "./content-direction/delete";
import {bulkContentDirectionActions} from "./content-direction/bulk-actions";
import {updateActionsUsers} from "./users-actions/update";
import {addOrganization} from "./organization/add-organization";
import {bulkOrganizationsActions} from "./organization/bulk-actions";
import {deleteOrganization} from "./organization/delete";
import {downloadHandbook} from "./download-excel";
import {getOrganization} from "./organization/getOrganization";
import {updateOrganization} from "./organization/update";
import {downloadHandbookTemplate} from "./download-template";
import {uploadExcel} from "./upload-excel";
import {getAllContentDelivery} from "./content-delivery/getAll";
import {getContentModuleSection} from "./getContentModuleSection";
import {addLegalFormOrganizationRecord} from "./legal-form-organization/add";
import {bulkLegalFormOrganizationActions} from "./legal-form-organization/bulk-actions";
import {deleteLegalFormOrganizationRecord} from "./legal-form-organization/delete";
import {updateLegalFormOrganization} from "./legal-form-organization/update";
import {addSessionsTypeRecord} from "./sessions-type/add";
import {bulkSessionsTypeActions} from "./sessions-type/bulk-actions";
import {deleteSessionsTypeRecord} from "./sessions-type/delete";
import {updateSessionsType} from "./sessions-type/update";
import {bulkCategoryProjectActions} from "./category-project/bulk-actions";
import {deleteCategoryProject} from "./category-project/delete";
import {updateCategoryProject} from "./category-project/update";
import {addCategoryProject} from "./category-project/add";

export default function* handbookSaga() {
	yield takeEvery(UsersHandbookThematicsTypes.GET_HANDBOOK_START, getHandbook)
	yield takeEvery(UsersHandbookThematicsTypes.SET_HANDBOOK_FILTER, setHandbookFilter)
	yield takeLatest(UsersHandbookInternetTypes.ADD_INTERNET_START, addInternet)
	yield takeLatest(UsersHandbookContentDeliveryTypes.ADD_CONTENT_DELIVERY_START, addContentDelivery)
	yield takeLatest(UsersHandbookContentDeliveryTypes.GET_ALL_CONTENT_DELIVERY_START, getAllContentDelivery)
	yield takeLatest(UsersHandbookContentDirectionTypes.ADD_CONTENT_DIRECTION_START, addContentDirection)
	yield takeLatest(UsersHandbookOrganizationTypes.ADD_ORGANIZATION_START, addOrganization)
	yield takeLatest(UsersHandbookOrganizationTypes.GET_ORGANIZATION_START, getOrganization)
	yield takeLatest(OptionsHandbookLegalForm.ADD_LEGAL_FORM_START, addLegalFormOrganizationRecord)
	yield takeLatest(OptionsHandbookSessionsType.ADD_SESSIONS_TYPE_START, addSessionsTypeRecord)
	yield takeLatest(OptionsHandbookCategoryProject.ADD_CATEGORY_PROJECT_START, addCategoryProject)

	yield takeLatest(UsersHandbookDownloadTypes.UPLOAD_START, uploadExcel)
	yield takeLatest(UsersHandbookDownloadTypes.DOWNLOAD_START, downloadHandbook)
	yield takeLatest(UsersHandbookDownloadTypes.DOWNLOAD_TEMPLATE_START, downloadHandbookTemplate)

	yield takeLatest(UsersHandbookInternetTypes.UPDATE_INTERNET_START, updateInternet)
	yield takeLatest(UsersHandbookContentDeliveryTypes.UPDATE_CONTENT_DELIVERY_START, updateContentDelivery)
	yield takeLatest(UsersHandbookContentDirectionTypes.UPDATE_CONTENT_DIRECTION_START, updateContentDirection)
	yield takeLatest(UsersHandbookUsersActionsTypes.UPDATE_USERS_ACTIONS_START, updateActionsUsers)
	yield takeLatest(UsersHandbookOrganizationTypes.UPDATE_ORGANIZATION_START, updateOrganization)
	yield takeLatest(OptionsHandbookLegalForm.UPDATE_LEGAL_FORM_START, updateLegalFormOrganization)
	yield takeLatest(OptionsHandbookSessionsType.UPDATE_SESSIONS_TYPE_START, updateSessionsType)
	yield takeLatest(OptionsHandbookCategoryProject.UPDATE_CATEGORY_PROJECT_START, updateCategoryProject)

	yield takeLatest(UsersHandbookInternetTypes.DELETE_INTERNET_START, deleteInternet)
	yield takeLatest(UsersHandbookContentDeliveryTypes.DELETE_CONTENT_DELIVERY_START, deleteContentDelivery)
	yield takeLatest(UsersHandbookContentDirectionTypes.DELETE_CONTENT_DIRECTION_START, deleteContentDirection)
	yield takeLatest(UsersHandbookOrganizationTypes.DELETE_ORGANIZATION_START, deleteOrganization)
	yield takeLatest(OptionsHandbookLegalForm.DELETE_LEGAL_FORM_START, deleteLegalFormOrganizationRecord)
	yield takeLatest(OptionsHandbookSessionsType.DELETE_SESSIONS_TYPE_START, deleteSessionsTypeRecord)
	yield takeLatest(OptionsHandbookCategoryProject.DELETE_CATEGORY_PROJECT_START, deleteCategoryProject)

	yield takeLatest(UsersHandbookInternetTypes.BULK_INTERNET_ACTIONS_START, bulkInternetActions)
	yield takeLatest(UsersHandbookContentDeliveryTypes.BULK_CONTENT_DELIVERY_ACTIONS_START, bulkContentDeliveryActions)
	yield takeLatest(UsersHandbookContentDirectionTypes.BULK_CONTENT_DIRECTION_ACTIONS_START, bulkContentDirectionActions)
	yield takeLatest(UsersHandbookOrganizationTypes.BULK_ORGANIZATION_ACTIONS_START, bulkOrganizationsActions)
	yield takeLatest(OptionsHandbookLegalForm.BULK_LEGAL_FORM_ACTIONS_START, bulkLegalFormOrganizationActions)
	yield takeLatest(OptionsHandbookSessionsType.BULK_SESSIONS_TYPE_ACTIONS_START, bulkSessionsTypeActions)
	yield takeLatest(OptionsHandbookCategoryProject.BULK_CATEGORY_PROJECT_ACTIONS_START, bulkCategoryProjectActions)

	yield takeLatest(OptionsGeneralActionTypes.GET_CONTENT_MODULE_SECTION_START, getContentModuleSection)

}
