import React, {useMemo} from 'react';
import styles from './styles.module.less'

import {useSelector} from "react-redux";
//antd
import {Spin} from "antd";
import Checkbox from "antd/es/checkbox";
//models
import {TRole} from "models/user";
//components
import ReloadList from "components/forms/components/reload-list/reload-list";
import {SelectComponent} from 'components/formic-control';
//others
import {useFormikContext} from "formik";
import {selectRole} from "store/auth/selectors";
import {ProjectFormDto} from "../../../lib";
import classNames from "classnames";
import {TagApi, TagLib} from "entities/tags";
import {Query} from 'processes/query'

function filingName(key: string): string {
    return `stepSecond.${key}.value`
}

function useGetsData(values: ProjectFormDto, role: TRole | undefined) {
    const form = values.stepSecond;
    const status = values.stepFirst.status.value;
    role && role !== 'ROLE_CONTENT_EDITOR' && form.validateLevelTags(status?.code);

    const tagsZeroLevel = TagLib.useGetProjectTagsOptions({level: 0, isForNationalProject: false })
    const tagsFirstLevel = TagLib.useGetProjectTagsOptions({level: 1, isForNationalProject: false, isAssignment: false})
    const tagsSecondLevel = TagLib.useGetProjectTagsOptions({level: 2, isForNationalProject: false, isAssignment: false})
    const tagsThirdLevel = TagLib.useGetProjectTagsOptions({level: 3, isForNationalProject: false })

    const tagsAssignmentFirstLevel = TagLib.useGetProjectTagsOptions({level: 1, isForNationalProject: false, isAssignment: true})
    const tagsAssignmentSecondLevel = TagLib.useGetProjectTagsOptions({level: 2, isForNationalProject: false, isAssignment: true})


    return {
        zeroLoading: tagsZeroLevel.isLoading,
        firstLoading: tagsFirstLevel.isLoading,
        secondLoading: tagsSecondLevel.isLoading,
        thirdLoading: tagsThirdLevel.isLoading,
        firstAssignmentLoading: tagsAssignmentFirstLevel.isLoading,
        secondAssignmentLoading: tagsAssignmentSecondLevel.isLoading,
        options: {
            ZERO_LVL_TAG: tagsZeroLevel.data || [],
            FIRST_LVL_TAG: tagsFirstLevel.data || [],
            SECOND_LVL_TAG: tagsSecondLevel.data || [],
            THIRD_LVL_TAG: tagsThirdLevel.data || [],
            FIRST_LVL_ASSIGNMENT_TAG: tagsAssignmentFirstLevel.data || [],
            SECOND_LVL_ASSIGNMENT_TAG: tagsAssignmentSecondLevel.data || [],
        }
    }
}

const TagsField = () => {
    const role = useSelector(selectRole)
    const {values,setFieldValue} = useFormikContext<ProjectFormDto>();
    const form = values.stepSecond;
    const status = values.stepFirst.status.value;
    const {zeroLoading, firstLoading, secondLoading, thirdLoading, firstAssignmentLoading, secondAssignmentLoading, options} = useGetsData(values, role)

    const zeroLevelOptionsDisabled = useMemo(() => {
        if (!status || status?.code === 'DRAFT' || status?.code === 'ACTUAL') {
            return options?.ZERO_LVL_TAG?.filter((el) => el.hidden).map((el) => el.value);
        }
        return [];
    }, [status, options]);

    const firstLevelOptionsDisabled = useMemo(() => {
        if (!status || status?.code === 'DRAFT' || status?.code === 'ACTUAL') {
            return options?.FIRST_LVL_TAG?.filter((el) => el.hidden).map((el) => el.value);
        }
        return [];
    }, [status, options]);
    const secondLevelOptionsDisabled = useMemo(() => {
        if (!status || status?.code === 'DRAFT' || status?.code === 'ACTUAL') {
            return options?.SECOND_LVL_TAG?.filter((el) => el.hidden).map((el) => el.value);
        }
        return [];
    }, [status, options]);

    const thirdLevelOptionsDisabled = useMemo(() => {
        if (!status || status?.code === 'DRAFT' || status?.code === 'ACTUAL') {
            return options?.THIRD_LVL_TAG?.filter((el) => el.hidden).map((el) => el.value);
        }
        return [];
    }, [status, options]);
    const firstLevelAssignmentOptionsDisabled = useMemo(() => {
        if (!status || status?.code === 'DRAFT' || status?.code === 'ACTUAL') {
            return options?.FIRST_LVL_ASSIGNMENT_TAG?.filter((el) => el.hidden).map((el) => el.value);
        }
        return [];
    }, [status, options]);
    const secondLevelAssignmentOptionsDisabled = useMemo(() => {
        if (!status || status?.code === 'DRAFT' || status?.code === 'ACTUAL') {
            return options?.SECOND_LVL_ASSIGNMENT_TAG?.filter((el) => el.hidden).map((el) => el.value);
        }
        return [];
    }, [status, options]);

    //Options для checkbox чтобы при нажатии на "Выбрать все" не выбирались заблокированные
    const zeroFilteredOptions = options?.ZERO_LVL_TAG.filter(option => {
        return !zeroLevelOptionsDisabled.some(value => value === option.value);
    })
    const firstFilteredOptions = options?.FIRST_LVL_TAG.filter(option => {
        return !firstLevelOptionsDisabled.some(value => value === option.value);
    })
    const secondFilteredOptions = options?.SECOND_LVL_TAG.filter(option => {
        return !secondLevelOptionsDisabled.some(value => value === option.value);
    })
    const thirdFilteredOptions = options?.THIRD_LVL_TAG.filter(option => {
        return !thirdLevelOptionsDisabled.some(value => value === option.value);
    })

    const firstAssignmentFilteredOptions = options?.FIRST_LVL_ASSIGNMENT_TAG.filter(option => {
        return !firstLevelAssignmentOptionsDisabled.some(value => value === option.value);
    })
    const secondAssignmentFilteredOptions = options?.SECOND_LVL_ASSIGNMENT_TAG.filter(option => {
        return !secondLevelAssignmentOptionsDisabled.some(value => value === option.value);
    })


    const handlerUpdateZeroTag = () => {
        Query.invalidate([TagApi.getProjectTags.key, {level: 0, isForNationalProject: false }]);
    }

    const handlerUpdateFirstTag = () => {
         Query.invalidate([TagApi.getProjectTags.key, {level: 1, isForNationalProject: false, isAssignment: false}]);
    }
    const handlerUpdateSecondTag = () => {
         Query.invalidate([TagApi.getProjectTags.key, {level: 2, isForNationalProject: false, isAssignment: false}]);
    }
    const handlerUpdateThirdTag = () => {
         Query.invalidate([TagApi.getProjectTags.key, {level: 3, isForNationalProject: false }]);
    }
    const handlerUpdateFirstAssignmentTag = () => {
        Query.invalidate([TagApi.getProjectTags.key, {level: 1, isForNationalProject: false, isAssignment: true}]);
    }
    const handlerUpdateSecondAssignmentTag = () => {
        Query.invalidate([TagApi.getProjectTags.key, {level: 2, isForNationalProject: false, isAssignment: true}]);
    }

    const selectCheckboxZeroTag = (e: any)=> {
        const checked: boolean = e.target.checked;
        if(checked) setFieldValue(filingName('zeroLevelTags'),zeroFilteredOptions)
        else setFieldValue(filingName('zeroLevelTags'),[])
    }

    const selectCheckboxFirstTag = (e: any)=> {
        const checked: boolean = e.target.checked;
        if(checked) setFieldValue(filingName('firstLevelTags'),firstFilteredOptions)
        else setFieldValue(filingName('firstLevelTags'),[])
    }

    const selectCheckboxSecondTag = (e: any)=> {
        const checked: boolean = e.target.checked;
        if(checked) setFieldValue(filingName('secondLevelTags'),secondFilteredOptions)
        else setFieldValue(filingName('secondLevelTags'),[])
    }

    const selectCheckboxThirdTag = (e: any)=> {
        const checked: boolean = e.target.checked;
        if(checked) setFieldValue(filingName('thirdLevelTags'),thirdFilteredOptions)
        else setFieldValue(filingName('thirdLevelTags'),[])
    }
    const selectCheckboxFirstAssignmentTag = (e: any)=> {
        const checked: boolean = e.target.checked;
        if(checked) setFieldValue(filingName('firstLevelAssignmentTags'),firstAssignmentFilteredOptions)
        else setFieldValue(filingName('firstLevelAssignmentTags'),[])
    }
    const selectCheckboxSecondAssignmentTag = (e: any)=> {
        const checked: boolean = e.target.checked;
        if(checked) setFieldValue(filingName('secondLevelAssignmentTags'),secondAssignmentFilteredOptions)
        else setFieldValue(filingName('secondLevelAssignmentTags'),[])
    }

    return (
        <>
            <div className={classNames(styles.tags_label, 'label')}>
                Свод тематических тегов проекта
            </div>
            <div className={styles.tags0}>
                <Spin spinning={zeroLoading}>
                    <ReloadList className="ident" handlerUpdateList={handlerUpdateZeroTag}/>
                    <SelectComponent
                        name={filingName('zeroLevelTags')}
                        placeholder={'Выберите теги 0-уровня'}
                        options={options?.ZERO_LVL_TAG}
                        multi={true}
                        disabled={form.zeroLevelTags.isDisabled}
                        disabledOptionsIds={zeroLevelOptionsDisabled}
                        maxTagCount={'responsive'}
                        dropdownRender={
                            <Checkbox
                                className={styles.checkboxInSelect}
                                onChange={selectCheckboxZeroTag}
                                checked={options?.ZERO_LVL_TAG.length ? form.zeroLevelTags?.value?.length === zeroFilteredOptions?.length : false}
                                disabled={form.zeroLevelTags.isDisabled}
                            >
                                <span className={styles.checkboxText}>Выбрать все</span>
                            </Checkbox>
                        }
                    />
                </Spin>
            </div>
            <div className={styles.tags1}>
                <Spin spinning={firstLoading}>
                    <ReloadList className="ident" handlerUpdateList={handlerUpdateFirstTag}/>
                    <SelectComponent
                        name={filingName('firstLevelTags')}
                        placeholder={'Выберите теги 1-уровня'}
                        options={options?.FIRST_LVL_TAG}
                        multi={true}
                        disabled={form.firstLevelTags.isDisabled}
                        disabledOptionsIds={firstLevelOptionsDisabled}
                        maxTagCount={'responsive'}
                        dropdownRender={
                            <Checkbox
                                className={styles.checkboxInSelect}
                                onChange={selectCheckboxFirstTag}
                                checked={options?.FIRST_LVL_TAG.length ? form.firstLevelTags?.value?.length === firstFilteredOptions?.length : false}
                                disabled={form.firstLevelTags.isDisabled}
                            >
                                <span className={styles.checkboxText}>Выбрать все</span>
                            </Checkbox>
                        }
                    />
                </Spin>
            </div>
            <div className={styles.tags2}>
                <Spin spinning={secondLoading}>
                    <ReloadList className="ident" handlerUpdateList={handlerUpdateSecondTag}/>
                    <SelectComponent
                        name={filingName('secondLevelTags')}
                        options={options?.SECOND_LVL_TAG}
                        placeholder={'Выберите теги 2-уровня'}
                        multi={true}
                        disabled={form.secondLevelTags.isDisabled}
                        disabledOptionsIds={secondLevelOptionsDisabled}
                        maxTagCount={'responsive'}
                        dropdownRender={
                            <Checkbox
                                className={styles.checkboxInSelect}
                                onChange={selectCheckboxSecondTag}
                                checked={options?.SECOND_LVL_TAG.length ? form.secondLevelTags?.value?.length === secondFilteredOptions?.length : false}
                                disabled={form.secondLevelTags.isDisabled}
                            >
                                <span className={styles.checkboxText}>Выбрать все</span>
                            </Checkbox>
                        }
                    />
                </Spin>
            </div>
            <div className={styles.tags3}>
                <Spin spinning={thirdLoading}>
                    <ReloadList className="ident" handlerUpdateList={handlerUpdateThirdTag}/>
                    <SelectComponent
                        name={filingName('thirdLevelTags')}
                        options={options?.THIRD_LVL_TAG}
                        placeholder={'Выберите теги 3-уровня'}
                        multi={true}
                        disabled={form.thirdLevelTags.isDisabled}
                        disabledOptionsIds={thirdLevelOptionsDisabled}
                        maxTagCount={'responsive'}
                        dropdownRender={
                            <Checkbox
                                className={styles.checkboxInSelect}
                                onChange={selectCheckboxThirdTag}
                                checked={options?.THIRD_LVL_TAG.length ? form.thirdLevelTags?.value?.length === thirdFilteredOptions?.length : false}
                                disabled={form.thirdLevelTags.isDisabled}
                            >
                                <span className={styles.checkboxText}>Выбрать все</span>
                            </Checkbox>
                        }
                    />
                </Spin>
            </div>
            <div className={styles.tagsAssignment1}>
                <Spin spinning={firstAssignmentLoading}>
                    <ReloadList className="ident" handlerUpdateList={handlerUpdateFirstAssignmentTag}/>
                    <SelectComponent
                        name={filingName('firstLevelAssignmentTags')}
                        placeholder={'Выберите теги поручений 1-уровня'}
                        options={options?.FIRST_LVL_ASSIGNMENT_TAG}
                        multi={true}
                        disabled={form.firstLevelAssignmentTags.isDisabled}
                        disabledOptionsIds={firstLevelAssignmentOptionsDisabled}
                        maxTagCount={'responsive'}
                        dropdownRender={
                            <Checkbox
                                className={styles.checkboxInSelect}
                                onChange={selectCheckboxFirstAssignmentTag}
                                checked={options?.FIRST_LVL_ASSIGNMENT_TAG.length ? form.firstLevelAssignmentTags?.value?.length === firstAssignmentFilteredOptions?.length : false}
                                disabled={form.firstLevelAssignmentTags.isDisabled}
                            >
                                <span className={styles.checkboxText}>Выбрать все</span>
                            </Checkbox>
                        }
                    />
                </Spin>
            </div>
            <div className={styles.tagsAssignment2}>
                <Spin spinning={secondAssignmentLoading}>
                    <ReloadList className="ident" handlerUpdateList={handlerUpdateSecondAssignmentTag}/>
                    <SelectComponent
                        name={filingName('secondLevelAssignmentTags')}
                        placeholder={'Выберите теги поручений 2-уровня'}
                        options={options?.SECOND_LVL_ASSIGNMENT_TAG}
                        multi={true}
                        disabled={form.secondLevelAssignmentTags.isDisabled}
                        disabledOptionsIds={secondLevelAssignmentOptionsDisabled}
                        maxTagCount={'responsive'}
                        dropdownRender={
                            <Checkbox
                                className={styles.checkboxInSelect}
                                onChange={selectCheckboxSecondAssignmentTag}
                                checked={options?.SECOND_LVL_ASSIGNMENT_TAG.length ? form.secondLevelAssignmentTags?.value?.length === secondAssignmentFilteredOptions?.length : false}
                                disabled={form.secondLevelAssignmentTags.isDisabled}
                            >
                                <span className={styles.checkboxText}>Выбрать все</span>
                            </Checkbox>
                        }
                    />
                </Spin>
            </div>
        </>
    )
};
export default React.memo(TagsField);
