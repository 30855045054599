import {ReportType, TypesTask} from "entities/task/types";
import {Content, Report, TActions} from "entities/task/types/records";

export function validateForProducerAndProjectManager({
    archival,
    typeTask,
    recordStatus,
    reportType
}: Content | Report): TActions {
    const view: TActions = {
        edit: 'hidden',
        delete: 'hidden',
        changeStatus: 'hidden',
        send: 'hidden',
        cancel: 'hidden',
        save: 'hidden'
    };
    if (archival) {
        if (typeTask === TypesTask.CONTENT) {
            if (
                recordStatus === 'READY_FOR_CUSTOMER_VERIFICATION' ||
                recordStatus === 'CREATED_BY_CUSTOMER'
            ) {
                view.edit = 'view';
                view.delete = 'view';
                view.send = 'disabled';
            } else if (recordStatus === 'APPROVED') {
                view.edit = 'view';
                view.delete = 'view';
            }
        }
        if (typeTask === TypesTask.REPORT) {
            if (recordStatus === 'APPROVED_OUTDATED') {
                view.edit = 'disabled';
                view.delete = 'disabled';
                view.send = 'disabled';
            } else if (recordStatus === 'REJECTED' || recordStatus === 'REJECTED_AFTER_VOTE_RESET') {
                view.edit = 'view';
                view.delete = 'disabled';
                view.send = 'disabled';
            } else if (recordStatus === 'APPROVED') {
                view.edit = 'view';
                view.delete = 'view';
            } else if (
                recordStatus === 'READY_FOR_CUSTOMER_VERIFICATION' ||
                recordStatus === 'CREATED_BY_CUSTOMER'
            ) {
                if (reportType === ReportType.CONTENT_REACH_REPORT) {
                    view.edit = 'view';
                    view.delete = 'disabled';
                    view.send = 'disabled';
                } else if (
                    reportType === ReportType.CONTENT_POSTING_REPORT ||
                    reportType === ReportType.COPYRIGHT_LIST ||
                    reportType === ReportType.PROJECT_ESTIMATE
                ) {
                    view.edit = 'view';
                    view.delete = 'view';
                    view.send = 'disabled';
                }
            }
        }
    } else {
        if (recordStatus === 'APPROVED') {
            view.edit = 'view';
            view.delete = 'view';
            view.send = 'disabled';
        } else {
            view.edit = 'disabled';
            view.delete = 'disabled';
            view.send = 'disabled';
        }
    }

    return view;
}
