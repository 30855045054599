import React, {FC, SyntheticEvent, useState} from 'react';
import {TProjectResponse, TypeContent} from 'models/content'
import {Tooltip} from "antd";

type TTags = {
    content: TypeContent | null
    project: TProjectResponse | null
}

const Tags: FC<TTags> = ({ content, project }) => {
    const zeroTags: {tag: Set<string>} = {
        tag: new Set([]),
    }
    const firstTags: {tag: Set<string>} = {
        tag: new Set([]),
    }
    const secondTags: {tag: Set<string>} = {
        tag: new Set([]),
    }
    const thirdTags: {tag: Set<string>} = {
        tag: new Set([]),
    }
    const firstLevelAssignmentTags: {tag: Set<string>} = {
        tag: new Set([]),
    }
    const secondLevelAssignmentTags: {tag: Set<string>} = {
        tag: new Set([]),
    }

    content?.zeroLevelTags?.forEach((el) => {
        zeroTags.tag.add(el.name)
    })
    content?.firstLevelTags?.forEach((el) => {
        firstTags.tag.add(el.name)
    })
    content?.secondLevelTags?.forEach((el) => {
        secondTags.tag.add(el.name)
	})
    content?.thirdLevelTags?.forEach((el) => {
        thirdTags.tag.add(el.name)
    })
    content?.firstLevelAssignmentTags?.forEach((el) => {
        firstLevelAssignmentTags.tag.add(el.name)
	})
    content?.secondLevelAssignmentTags?.forEach((el) => {
        secondLevelAssignmentTags.tag.add(el.name)
    })

    return (
        <>
            {project && (
                <div className="item tags">
                    <span>Формат проекта: </span>
                    <span className="blue">{project?.categoryTypes?.length ? Array.from(project?.categoryTypes.map(it => it.name)).join(', ') : 'нет данных'}</span>
                    <br/>
                    <span>Жанр проекта: </span>
                    <span className="blue">{project?.genreTypes?.length ? Array.from(project?.genreTypes.map(it => it.name)).join(', ') : 'нет данных'}</span>
                </div>
            )}
            {content && (
            <>
                <span className="title">Регион контента</span>
                <div className="item tags">
                    <div className="tags__content">
                        <TagsRegions arr={content.regionAboutSet} title="О каком регионе контент:" />
                        <TagsRegions arr={content.regionForSet} title="Для какого региона контент:" />
                    </div>
                </div>
            </>
            )}
            {content && (
                <div className="item tags">
                    <span>Теги ед. контента 0-го уровня: </span>
                    <span className="blue">{zeroTags.tag.size > 0 ? Array.from(zeroTags.tag).join(', ') : 'нет данных'}</span>
                    <br/>
                    <span>Теги ед. контента 1-го уровня: </span>
                    <span className="blue">{firstTags.tag.size > 0 ? Array.from(firstTags.tag).join(', ') : 'нет данных'}</span>
                    <br/>
                </div>
            )}
            {content && (
                <div className="item tags">
                    <span>Теги ед. контента 2-го уровня: </span>
                    <span
                        className="blue">{secondTags.tag.size > 0 ? Array.from(secondTags.tag).join(', ') : 'нет данных'}</span>
                    <br/>
                    <span>Теги ед. контента 3-го уровня: </span>
                    <span className="blue">{thirdTags.tag.size > 0 ? Array.from(thirdTags.tag).join(', ') : 'нет данных'}</span>
                </div>
            )}
            {content && (
                <div className="item tags">
                    <span>Теги поручений ед. контента 1-го уровня: </span>
                    <span className="blue">{firstLevelAssignmentTags.tag.size > 0 ? Array.from(firstLevelAssignmentTags.tag).join(', ') : 'нет данных'}</span>
                    <br/>
                    <span>Теги поручений ед. контента 2-го уровня: </span>
                    <span className="blue">{secondLevelAssignmentTags.tag.size > 0 ? Array.from(secondLevelAssignmentTags.tag).join(', ') : 'нет данных'}</span>
                </div>
            )}
        </>
    )
};

export default Tags;

function TagsRegions({arr, title}: { arr: any, title: string }) {
    const [visible, setVisible] = useState<boolean>(false)
    const length = visible ? arr.length : 1 // длина либо 1 эдемент либо все элементы при visible - true
    const text = !!arr?.length ?
        arr.slice(0, length)
        .map((el: any) => el.name).join(', ')
        : 'нет данных'

    const handlerClickShow = (e: SyntheticEvent) => {
        e.stopPropagation()
        setVisible(prevState => !prevState)
    }


    return (
        <div className="tags__content-item">
            <strong>{title}</strong>
            <span>{text}</span>
            {arr.length > 1 && (
                <div
                    className="tags__content-amount"
                    onClick={handlerClickShow}
                >
                    <Tooltip placement="top" title={visible ? "Свернуть" : "Посмотреть все"}>
                        {!visible ? `+${arr.length - 1}` : '—'}
                    </Tooltip>
                </div>
            )}
        </div>
    )
}
