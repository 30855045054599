import { z } from 'zod'
import { FilterRequest, Id_Name_Code_Hidden_Schema, SearchResponse } from 'shared/types/common'

export const GenreSchema = Id_Name_Code_Hidden_Schema

export type Genre = z.infer<typeof GenreSchema>

export type GenreRequest = {
    code: string | null
    name: string
    hidden: boolean
}

export type FilterData = {
    searchString?: string
    hidden?: boolean
}

export type Genres = SearchResponse<Genre>
export type Filter = FilterRequest<FilterData>
