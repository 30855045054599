import {
	InferValueTypes,
	Nullable,
	T_ID_NAME,
	T_ID_NAME_CODE,
	T_ID_NAME_CODE_HIDDEN,
	TFormatTypes, TOrganization
} from 'models/common'
import * as actions from './actions'
import {AppActionTypes} from './action-types'
import {ErrorActionState, StartActionState, SuccessActionState} from 'utils/reducerHalper'
import {TPerson} from 'models/user'
import {TFormatSubmission} from "models/projects/projectFeels";
import {AuthActionTypes} from "../auth/action-types";
import {Logout} from "../auth/reducer";
import { ProjectTypes } from 'entities/project'

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>

export type TAppState = {
	path: { title: string, path: string } | null
	tourPage: string | null
	internetResources: Array<{ hidden: boolean, domainLink: string, image: string } & T_ID_NAME> | null
	internetResourcesPostingTask: Array<{ hidden: boolean, domainLink: string, image: string } & T_ID_NAME> | null
	statuses: T_ID_NAME_CODE<ProjectTypes.ProjectStatus>[] | null
	directions: Array<{hidden: boolean, image: string} & T_ID_NAME_CODE> | null
	formatTypes: TFormatTypes[] | null
	thematics: Array<{ hidden: boolean, contest: Nullable<{hidden: boolean} & T_ID_NAME_CODE> } & T_ID_NAME_CODE> | null
	formatKpi: Array<{ hidden: boolean } & T_ID_NAME_CODE> | null
	reportType: T_ID_NAME_CODE[] | null
	contractors: TPerson[] | null
	contractorsId: TPerson[] | null
	coordinators: TPerson[] | null
	analysts: TPerson[] | null
	producers: TPerson[] | null
	financiers: TPerson[] | null
	lawyers: TPerson[] | null
	contentEditors: TPerson[] | null
	projectManagers: TPerson[] | null
	roles: Array<{ruName: string} & T_ID_NAME> | null
	organizations: Array<T_ID_NAME & {isContentCustomer: boolean}> | null
	organizationsWithContractors: TOrganization[] | null
	formatSubmission: TFormatSubmission[] | null
	ranks: T_ID_NAME_CODE_HIDDEN[] | null
	regions: T_ID_NAME_CODE_HIDDEN[] | null
	categories: T_ID_NAME_CODE_HIDDEN[] | null
	genres: T_ID_NAME_CODE_HIDDEN[] | null
	isLoading: boolean
	error: any
}

export const initialState: TAppState = {
	path: null,
	tourPage: null,
	internetResources: null,
	internetResourcesPostingTask: null,
	statuses: null,
	directions: null,
	formatTypes: null,
	thematics: null,
	formatKpi: null,
	reportType: null,
	contractors: null,
	contractorsId: null,
	coordinators: null,
	producers: null,
	financiers: null,
	analysts: null,
	lawyers: null,
	contentEditors: null,
	projectManagers: null,
	roles: null,
	organizations: null,
	organizationsWithContractors: null,
	formatSubmission: null,
	ranks: null,
	regions: null,
	categories: null,
	genres: null,
	isLoading: false,
	error: null,
}

export default function reducer(state: TAppState = initialState, action: ActionTypes | Logout): TAppState {
	switch (action.type) {
		case AuthActionTypes.LOGOUT:
			return {...initialState}
		case AppActionTypes.GET_FEELS_START:
			return StartActionState(state)
		case AppActionTypes.GET_FEELS_SUCCESS:
			return {
				...SuccessActionState(state),
				...action.data,
			}
		case AppActionTypes.GET_FEELS_ERROR:
			return ErrorActionState(state, action.error)
		case AppActionTypes.SET_PATH:
			return {...state, path: action.path}
		case AppActionTypes.WRITE_PAGE_NAME:
			return {...state, tourPage: action.page}
		case AppActionTypes.CLEAR_PAGE_NAME:
			return {...state, tourPage: null}
		default:
			return state
	}
}
