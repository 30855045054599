import {z} from "zod";
import {UserTypes} from "entities/user";
import {SessionTypes} from "entities/session";
import {ContentFormatsSchema} from "./content-formats";
import {KpisSchema} from "./kpi";
import {ContentDirectionSchema} from "./content-direction";
import {ProjectStatusNativeEnum} from "./statuses";
import {ContractorOrganizationSchema} from "./contractor-organization";
import {ChannelsSchema} from "./channels";
import {FileSchema} from "shared/types/file";
import {Id_Name_Code_Hidden_Schema, Id_Name_Code_Schema} from "shared/types/common";
import {TagTypes} from 'entities/tags';
import {RegionTypes} from "entities/region";
import {ProjectLabelSchema} from "./project-label";
import { TaskTypes } from "entities/task";
import { AwardsTypes } from "entities/award";
import {FamousPeopleTypes} from 'entities/famous-people'

export const TypeSchema = Id_Name_Code_Schema.extend({
    code: z.enum(['CONTENT_CREATION_AND_PLACEMENT', 'CONTENT_CREATION'])
})
export type Type = z.infer<typeof TypeSchema>;


const UserProjectSchema = UserTypes.UserSchema.pick({
    id: true,
    lastName: true,
    firstName: true,
    middleName: true,
    telegram: true,
    image: true,
    leader: true,
    status: true,
    organizations: true,
    isTutor: true,
    tutorVisible: true,
})
export type UserProject = z.infer<typeof UserProjectSchema>

export const ProjectSchema = z.object({
    projectFilesExist: z.boolean(),//наличия файлов проектов

    id: z.string(), //ID проекта
    name: z.string(), //Название проекта
    code: z.string(), //Код проекта
    contractorOrganization: ContractorOrganizationSchema, //Организация исполнителя
    contractorAgentVisible: z.boolean(),//Признак видимости представителя исполнителя
    status: Id_Name_Code_Schema.extend({
        code: ProjectStatusNativeEnum,
    }), //Статус проекта
    lastReachTask: z.object({
        id: z.string(),
        deadline: z.string(),
        status: Id_Name_Code_Schema.extend({code: TaskTypes.TasksStatusEnum})
    }).nullable().optional(), //Последняя задача проекта на фиксацию охватов

    contractNumber: z.string().nullable(),//Номер договора
    requestNumber: z.string(),//Номер заявки
    contractDate: z.string().nullable(),//Дата договора
    advancePayment: z.boolean(),//Авансирование
    igk: z.string().nullable(),//ИГК
    budget: z.number().nullable(),//Бюджет проекта
    promotionalBudget: z.number().nullable(), //Бюджет на продвижение
    session: SessionTypes.SessionSchema.nullable(),
    goal: z.string().nullable(),
    description: z.string().nullable(),
    completionDate: z.string().nullable(),//Дата завершения проекта
    firstContentCreationDate: z.string().nullable(),//Создание первой единицы контента
    allContentCreationDate: z.string().nullable(),//Создание всех единиц контента
    firstContentPostingDate: z.string().nullable(),//Начало размещения первой единицы контента
    allContentPostingDate: z.string().nullable(),//Размещение последней единицы контента
    allContentReachFixDate: z.string().nullable(),//Конечная дата фиксации охвата аудитории
    files: z.array(FileSchema),
    contentThematics: z.array(z.object({
        code: z.string(),
        id: z.string(),
        name: z.string(),
        hidden: z.boolean(),
    })), //Список тематик контента проекта
    contentDirection: ContentDirectionSchema.nullable(), //Направление контента проекта
    contentFormats: z.array(ContentFormatsSchema), //Форматы контента проекта
    channels: z.array(ChannelsSchema),//Каналы проекта
    kpis: z.array(KpisSchema),//КПЭ проекта
    actualKpi: z.number(),
    taskStageProgress: z.object({
        'CONTENT_DELIVERY': z.number(),
        'CONTENT_PUBLISHING': z.number(),
        'PROJECT_REPORTING': z.number(),
    }).nullable(),//Прогресс выполнения проекта по этапам зада
    image: FileSchema.nullable(),
    defaultImage: z.string().nullable(),
    tasksExist: z.boolean(),//Признак наличия задач у проекта
    type: TypeSchema.nullable(),
    zeroLevelTags: z.array(TagTypes.TagSchema),
    firstLevelTags: z.array(TagTypes.TagSchema),
    secondLevelTags: z.array(TagTypes.TagSchema),
    thirdLevelTags: z.array(TagTypes.TagSchema),
    firstLevelAssignmentTags: z.array(TagTypes.TagSchema),
    secondLevelAssignmentTags: z.array(TagTypes.TagSchema),
    regionAboutSet: z.array(RegionTypes.RegionSchema),
    regionForSet: z.array(RegionTypes.RegionSchema),

    contractors: z.array(UserProjectSchema),
    producers: z.array(UserProjectSchema),
    coordinators: z.array(UserProjectSchema),
    analysts: z.array(UserProjectSchema),
    financiers: z.array(UserProjectSchema),
    lawyers: z.array(UserProjectSchema),
    contentEditors: z.array(UserProjectSchema),
    projectManagers: z.array(UserProjectSchema),

    isPrivateProject: z.boolean(),//Признак конфиденциальности проекта
    isFavoriteProject: z.boolean().optional(),//Признак избранного проекта

    isNationalProject: z.boolean().nullable(),//Признак национального проекта
    firstLevelNationalTags: z.array(TagTypes.TagSchema).nullable(),
    secondLevelNationalTags: z.array(TagTypes.TagSchema).nullable(),
    firstLevelNationalAssignmentTags: z.array(TagTypes.TagSchema).nullable(),
    secondLevelNationalAssignmentTags: z.array(TagTypes.TagSchema).nullable(),
    licenseDate: z.string().nullable(),//Дата приема-передачи лицензии
    targetAudienceAgeFrom: z.number().nullable(),//Целевая аудитория (от)
    targetAudienceAgeTo: z.number().nullable(),//Целевая аудитория (до)
    projectDataCheckedDatetime: z.string().nullable(),//Дата и время проверки данных проекта
    contentLocationRegions: z.array(RegionTypes.RegionSchema),
    famousPeople: z.array(FamousPeopleTypes.FamousPeopleShortSchema),
    agreementDate: z.string().nullable(),//Дата соглашения
    categoryTypes: z.array(Id_Name_Code_Hidden_Schema).nullable(),
    genreTypes: z.array(Id_Name_Code_Hidden_Schema).nullable(),
    filmClusters: z.array(Id_Name_Code_Hidden_Schema).nullable(),
    contentEditorLabels: z.array(ProjectLabelSchema).nullable(), //Метка редактора контента
    moderatorLabels: z.array(ProjectLabelSchema).nullable(), //Метка редактора каталога
    awards: z.array(AwardsTypes.AwardSchema).nullable(),//Список наград проекта
    factContentCount: z.number().nullable(),//Целевая аудитория (от)
    factApprovedCountedContentCount: z.number().nullable(),//Кол-во согласованных учитываемых ед.контента
    factApprovedUncountedContentCount: z.number().nullable(),//Кол-во согласованных неучитываемых ед.контента

})


export type Project = z.infer<typeof ProjectSchema>;
