import React, {FC, useEffect, useState} from 'react'
import {FieldArray, FieldArrayRenderProps} from 'formik'

import {Button, Col, Row, Tooltip} from 'antd'
import {DeleteOutlined, PlusCircleOutlined} from '@ant-design/icons'

import {InputNumberComponent, SelectComponent} from 'components/formic-control'
import {IOptions} from 'models/feels'
import AppService from 'services/appService'
import {AxiosResponse} from 'axios'
import {T_ID_NAME_CODE} from 'models/common'
import ReloadList from 'components/forms/components/reload-list/reload-list'

const getData = async (id: string) => {
	const { data }: AxiosResponse<T_ID_NAME_CODE[]> = await AppService.getFormatTypesById(id)
	return data.map((el) => {
		return { value: el.id, label: el.name } as IOptions
	})
}
type InternetResourcesForPublicItemProps = {
	name: string
	shortName: string
	project: IOptions | null
	disabled?: boolean
	isContent?: boolean
	isAccountedContent?: boolean
	labelName?: 'accountedContent' | 'nonAccountedContent' | 'workingMaterial'
}
type IState = {
	value: IOptions[] | null
	id: null | string
}

const TaskFormContentItem: FC<InternetResourcesForPublicItemProps> = ({
	shortName,
	name,
	project,
	disabled,
	isContent = false,
  	isAccountedContent = false,
  	labelName
}) => {
	const [state, setState] = useState<IState>({ id: null, value: null })
	useEffect(() => {
		const setDataToState = (id: string) => {
			getData(id).then((res) => setState({ id: id, value: res }))
		}
		if (project && !state) setDataToState(String(project.value || project.key))
		else if (project && (state.id !== project.value || project.key))
			setDataToState(String(project.value || project.key))
		// eslint-disable-next-line
	}, [project])

	const handlerUpdateContentFormatType = () => {
		const setDataToState = (id: string) => {
			getData(id).then((res) => setState({ id: id, value: res }))
		}

		if (project) setDataToState(String(project.value || project.key))
	}

	return (
		<FieldArray
			name={name}
			render={(props: FieldArrayRenderProps) => {
				return (
					<>
						{props.form.values.stepSecond[shortName].value &&
							props.form.values.stepSecond[shortName].value.map((item: any, index: number) => {
                                if (item.isContent !== isContent) return null
                                if (item.isAccountedContent !== isAccountedContent && labelName !== 'workingMaterial') return null

								return (
									<Row key={index} className="form-group__row" justify={'space-between'}>
										<Tooltip title={!state.value ? 'Сначала выберите проект' : undefined}>
											<Col lg={10} xs={22} className="form-group__row-item">
												<ReloadList handlerUpdateList={handlerUpdateContentFormatType} />
												<SelectComponent
													options={state.value}
													name={`${name}[${index}].contentFormatType`}
													placeholder={'Выберите единицу контента'}
													disabled={disabled}
												/>
											</Col>
										</Tooltip>

										<Col lg={11} xs={22}>
											<InputNumberComponent
												name={`${name}[${index}].contentCount`}
												placeholder={'Введите количество контента'}
												disabled={disabled}
											/>
										</Col>
										<Col lg={1} xs={2}>
											{!disabled && (
												<div className="form-group__btn">
													<Tooltip title="Удалить элемент?">
														<Button type="link" onClick={() => props.remove(index)}>
															<DeleteOutlined />
														</Button>
													</Tooltip>
												</div>
											)}
										</Col>
									</Row>
								)
							})}
						{!disabled && (
							<Button type="link" onClick={() => props.push({
                                contentFormatType: '',
                                contentCount: null,
                                isAccountedContent: isAccountedContent,
                                isContent: isContent
                            })}>
								<PlusCircleOutlined /> Добавить контент
							</Button>
						)}
					</>
				)
			}}
		/>
	)
}
export default TaskFormContentItem
