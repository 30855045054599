import {put} from 'redux-saga/effects'
import {NotificationOpen} from 'store/notification/actions'
import CreateErrorMessage from 'utils/validation/createErrorMessage'
import HandbookService from 'services/handbookService'
import {
	DownloadTemplateErrorAction,
	DownloadTemplateStartAction,
	DownloadTemplateSuccessAction,
} from 'store/options/actions/actions-handbook'
import {AxiosResponse} from 'axios'
import {downloadXLXS} from 'utils/downloadXLXS'
import LegalFormOrganizationService from "services/legalFormOrganizationService";
import SessionTypeService from "services/sessionTypeService";
import CategoryProjectService from "services/categoryProjectService";
import InternetResourcesService from "services/internetResourcesService";

type TDownloadAction = {
	[key: string]: any
}

const DownloadAction: TDownloadAction = {
	'organization': HandbookService.downloadOrganizationsTemplate,
	'internet': InternetResourcesService.downloadInternetTemplate,
	'content-type': HandbookService.downloadContentTypeTemplate,
	'content-direction': HandbookService.downloadContentDirectionTemplate,
	'content-delivery': HandbookService.downloadContentDeliveryTemplate,
	'legal-form-organization': LegalFormOrganizationService.downloadLegalFormOrganizationTemplate,
	'sessions-type': SessionTypeService.downloadSessionsTypeTemplate,
	'category-project': CategoryProjectService.downloadCategoryProjectTemplate,
}

export function* downloadHandbookTemplate({ typeName }: ReturnType<typeof DownloadTemplateStartAction>) {
	try {
		const { data: response }: AxiosResponse<any> = yield DownloadAction[typeName]()
		yield put(DownloadTemplateSuccessAction())
		downloadXLXS(response, `${typeName}-template`)
		yield put(NotificationOpen('success', 'Успешно'))
	} catch (error: any) {
		yield put(DownloadTemplateErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось выгрузить'))
	}
}
