import {ColumnsType} from "antd/es/table";
import LinkPropsCell from "components/table-cells/link-props-cell";
import PathConverter from "utils/path-converter";
import {TASKS} from "routing/names";
import {useHistory} from "react-router-dom";
import NameCenterCell from "components/table-cells/name-center-cell";
import moment from "moment/moment";
import {StatusCell} from "../table-cells";
import { LightSvg } from 'assets/svg';
import styles from './columns.module.less';
import classNames from 'classnames';
import { Tooltip } from 'antd';
import { TaskTypes } from "entities/task";
import { ModalEvent } from 'shared/ui/modal';

export function useColumns() {
    const history = useHistory();
    const handlerLink = (id: string, name: string) => () => {
        ModalEvent.close()
        history.push(PathConverter(TASKS.TASK, id), {tooltip: name})
    }
    const columns: ColumnsType<TaskTypes.Statistic> = [
        {
            title: 'Код проекта и номер задачи',
            dataIndex: 'code',
            key: 'code',
            width: 100,
            render: (_, record) => {
                const today = new Date().toISOString().split('T')[0];
                const isStatusReady = record.status.code === 'READY' ;
                const isUntilDays = record?.deadline >= today
                const textTooltip = isStatusReady ? "" : (!isUntilDays ? 'Срок задачи просрочен' : `До окончания задачи осталось: ${record?.taskUntilEndDays} дн.`);

                return LinkPropsCell(
                    record.id,
                    handlerLink(record.id, record.name),
                  (
                    <div className={styles.item}>
                        <Tooltip title={`Открыть задачу - ${record.name}`}>
                            {`${record.code}-${record.number}`}
                        </Tooltip>
                        <Tooltip
                          title={textTooltip}
                          placement={'right'}>
                            <LightSvg className={classNames(styles.icon, {red: !(isStatusReady || isUntilDays)})}/>
                        </Tooltip>
                    </div>
                  ))
            }
        },
        {
            title: 'Наименование задачи',
            dataIndex: 'name',
            key: 'name',
            width: 170,
            render: NameCenterCell
        },
        {
            title: 'Дата окончания задачи',
            dataIndex: 'deadline',
            key: 'deadline',
            width: 100,
            render: (date: string) => NameCenterCell(date ? moment(date).format('DD.MM.yy') : '-'),
        },
        {
            title: 'Кол-во согласованных записей',
            dataIndex: 'approvedRecordsCount',
            key: 'approvedRecordsCount',
            width: 93,
            render: NameCenterCell
        },
        {
            title: 'Кол-во отклоненных записей',
            dataIndex: 'rejectedRecordsCount',
            key: 'rejectedRecordsCount',
            width: 85,
            render: NameCenterCell
        },
        {
            title: 'Кол-во требуемых проверки записей',
            dataIndex: 'verificationRequiredRecordsCount',
            key: 'verificationRequiredRecordsCount',
            width: 80,
            render: NameCenterCell
        },
        {
            title: 'Статус задачи',
            dataIndex: 'status',
            key: 'status',
            width: 100,
            render: StatusCell,
        },
    ]
    return columns;
}
