import React, {FC, useState} from 'react';
import styles from './styles.module.less';
import './index.less'
import {Button, Modal, Upload, UploadProps} from "antd";
import {PlusCircleOutlined} from '@ant-design/icons';
import useHandlerModal from "hooks/handlModal";
import {InterimVoteTaskContentStartAction, VoteTaskContentChangeStatus} from "store/tasks/actions/content";
import {InterimVoteTaskReportStartAction, VoteTaskReportChangeStatus} from "store/tasks/actions/report";
import {UploadFile} from "antd/es/upload/interface";
import {TaskTypes} from 'entities/task';
import ReactQuill from "react-quill";

export type TModalVoteUserData = {
    title: string,
    type: TaskTypes.TypesTask
    comment: string | null,
    recordId: string
    isApproved: boolean,
    isInterimApproved: boolean,
    files: UploadFile[] | null
    reportType: TaskTypes.ReportType;
    archival?: boolean
}

type Props = {
    status: boolean
    data: TModalVoteUserData
}
const ModalVoteUser: FC<Props> = ({status, data}) => {
    const [fileList, setFileList] = useState<UploadFile[]>(
        !!data.files?.length ? data.files : []);
    const {isVisible, closeModal, put} = useHandlerModal(status);
    const [state, setState] = useState({
        comment: data.comment,
        count: data.comment?.length || 0
    });



    const props: UploadProps = {
        onRemove: file => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            setFileList([...fileList, file]);

            return false;
        },
        fileList,
        accept: '.doc, .docx, .xlsx, .xls, .pdf, .xml, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    };
    const handlerSaveVote = () => {
        const requestData = {
            vote: {
                comment: state.comment,
                recordId: data.recordId,
                isApproved: data.isApproved,
                isInterimApproved: data.isInterimApproved,
            },
            files: fileList
        }
        if (data.type === 'CONTENT') {
            put(VoteTaskContentChangeStatus(requestData))
            if (!data.archival) put(InterimVoteTaskContentStartAction(requestData))
        } else if (data.type === 'REPORT') {
            put(VoteTaskReportChangeStatus(requestData))
            if (!data.archival) put(InterimVoteTaskReportStartAction(requestData, data.reportType))
        }
        closeModal()
    }
    const isDisabled = state.count <= 3000 && (!!fileList.length || !!state.comment?.length)

    return (
        <Modal
            className='modal-vote-user'
            title={(
                <>
                    <span>Комментарии по</span> <br/>
                    <span>{`"${data.title}"`}</span>
                </>
            )}
            visible={isVisible}
            footer={(
                <>
                    <Button type={"default"} onClick={handlerSaveVote}>Закрыть</Button>
                    <Button type={"primary"} disabled={!isDisabled} onClick={handlerSaveVote}>Сохранить</Button>
                </>
            )}
            maskClosable={true}
            onCancel={handlerSaveVote}
            onOk={handlerSaveVote}
        >
            <div className='modal-vote__list'>
                <div className={styles.files}>
                    <Upload {...props} className={styles.uploader}>
                        <div className={styles.title} onClick={(e) => {
                            e.stopPropagation();
                        }}>Дополнительные материалы</div>
                        {fileList.length < 3 && (
                            <Button
                                className={styles.btn}
                                type={'text'}
                            >
                                <PlusCircleOutlined /> Добавить файл
                            </Button>
                        )}
                    </Upload>
                </div>
                <div className="item__content">
                    <div className="form-group form-group__quill">
                        <ReactQuill
                            placeholder={'Оставьте комментарий по какой причине материал отклонен '}
                            onChange={value => {
                                setState({comment: value, count: value.length})
                            }}
                            theme="snow"
                            value={state.comment || ''}
                        />
                        <span className='count'>{state.count}/3000</span>
                    </div>
                </div>
            </div>
        </Modal>
    )
};
export default ModalVoteUser;
