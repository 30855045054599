import { ErrorActionState, StartActionState, SuccessActionState } from 'utils/reducerHalper'

import { InferValueTypes, T_ID_NAME_CODE } from 'models/common'
import * as actions from './actions'
import { Logout } from '../../auth/reducer'
import {
	TProjectResponse,
	TypeContentFilter,
	TypeContentResponse,
} from 'models/content'
import { ContentActionTypes } from './action-types'

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>
export type TContentTypes =
	| 'VIDEO_CONTENT'
	| 'AUDIO_CONTENT'
	| 'PHOTO_CONTENT'
	| 'TEXT_CONTENT'
	| 'SOFTWARE_CONTENT'
	| 'OTHER_CONTENT'
	| string

export type TContentState = {
	contentFilter: TypeContentFilter
	project: TProjectResponse | null
	content: TypeContentResponse | null
	typeContent: TContentTypes | null
	contentModuleSections: T_ID_NAME_CODE[] | null
	isLoading: boolean
	error: any
}
const initialState: TContentState = {
	contentFilter: {
		page: 1,
		pageSize: 10,
		filterData: {
			isMasterFile: true,
			isContent: true,
			isAccountedContent: true,
		},
	},
	project: null,
	content: null,
	typeContent: null,
	contentModuleSections: null,
	isLoading: false,
	error: null,
}
export default function reducer(state: TContentState = initialState, action: ActionTypes | Logout): TContentState {
	switch (action.type) {
		case ContentActionTypes.SET_SORTING_FILTER:
			return { ...state, contentFilter: action.filter }

		case ContentActionTypes.GET_PROJECT_START:
			return StartActionState(state)
		case ContentActionTypes.GET_PROJECT_SUCCESS:
			return {
				...SuccessActionState(state),
				project: action.project,
			}
		case ContentActionTypes.GET_PROJECT_ERROR:
			return ErrorActionState(state, action)


		case ContentActionTypes.GET_CONTENT_START:
			return {...StartActionState(state)}
		case ContentActionTypes.GET_CONTENT_SUCCESS:
			return {
				...SuccessActionState(state),
				content: action.content,
			}
		case ContentActionTypes.GET_CONTENT_ERROR:
			return ErrorActionState(state, action)

		case ContentActionTypes.GET_CONTENT_MODULE_SECTIONS_START:
			return StartActionState(state)
		case ContentActionTypes.GET_CONTENT_MODULE_SECTIONS_SUCCESS:
			return {
				...SuccessActionState(state),
				contentModuleSections: action.data,
			}
		case ContentActionTypes.GET_CONTENT_MODULE_SECTIONS_ERROR:
			return ErrorActionState(state, action)
		default:
			return state
	}
}
