import {downloadXLXS} from 'utils/downloadXLXS';
import {AxiosResponse} from 'axios';
import {
    TasksReportCopyrightService,
    TasksReportEstimateService,
    TasksReportPostingService,
    TasksReportReachService
} from "services/tasksReportService";
import {TaskTypes} from 'entities/task'
import TasksContentService from "services/tasksContentService";
import {Notification} from 'processes/notification';


const resolveServiceFunction = (type: TaskTypes.TypesTask, reportType?: TaskTypes.ReportType)=> {
    if (type === TaskTypes.TypesTask.CONTENT) {
        return TasksContentService.recordsExport;
    }

    if (type === TaskTypes.TypesTask.REPORT && reportType) {
        switch (reportType) {
            case TaskTypes.ReportType.CONTENT_REACH_REPORT:
                return TasksReportReachService.recordsExport;
            case TaskTypes.ReportType.COPYRIGHT_LIST:
                return TasksReportCopyrightService.recordsExport;
            case TaskTypes.ReportType.PROJECT_ESTIMATE:
                return TasksReportEstimateService.recordsExport;
            case TaskTypes.ReportType.CONTENT_POSTING_REPORT:
                return TasksReportPostingService.recordsExport;
            default:
                return null;
        }
    }

    return null;
};


const useRecordsExport = (type: TaskTypes.TypesTask, reportType?: TaskTypes.ReportType) => {
    const serviceFunction = resolveServiceFunction(type, reportType);

    const downloadExcelData = async (rowIds: string[], name: string) => {
        if (!serviceFunction) throw new Error('Invalid type or report type provided');
        try {
            const { data }: AxiosResponse<any> = await serviceFunction(rowIds);
            downloadXLXS(data, name);
            Notification.open({ type: 'success', info: {message: 'Данные выгружены успешно'}});
        } catch (error) {
            Notification.open({ type: 'error', info: {message: 'Не удалось выгрузить данные'}});
        }
    };

    return {
        downloadExcelData,
    };
};

export default useRecordsExport;

